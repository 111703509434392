// This file is automatically generated. Do not edit manually.

import { PartView } from "src/api/generated/erp/db/types/tables/partView";
import { ServerSideDataModelRequest } from "src/api/generated/common/dataGrids/serverSideDataModelRequest";
import { ServerSideDataModelResult } from "src/api/generated/common/dataGrids/serverSideDataModelResult";
import { PartConfigurationView } from "src/api/generated/erp/db/types/tables/partConfigurationView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace SitePartQueryApi {
	export async function getPartSelectionOptions(args: {
		currentPartId?: number | null | undefined;
		searchQuery?: string;
		siteId: number;
		withWarehouseLocations?: boolean;
	}): Promise<Array<PartView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/warehouse/part/PartQuery/getPartSelectionOptions",
			method: "POST",
			data: {
				currentPartId: args.currentPartId,
				searchQuery: args.searchQuery,
				siteId: args.siteId,
				withWarehouseLocations: args.withWarehouseLocations,
			},
		});
		return response as Array<PartView>;
	}

	export async function getParts(args: {
		dataModelRequest: ServerSideDataModelRequest;
		searchQuery?: string;
		withWarehouseLocations?: boolean;
	}): Promise<ServerSideDataModelResult<PartView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/warehouse/part/PartQuery/getParts",
			method: "POST",
			data: {
				dataModelRequest: args.dataModelRequest,
				searchQuery: args.searchQuery,
				withWarehouseLocations: args.withWarehouseLocations,
			},
		});
		return response as ServerSideDataModelResult<PartView>;
	}

	export async function getPart(args: { partId: number }): Promise<PartView> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/warehouse/part/PartQuery/getPart",
			method: "POST",
			data: {
				partId: args.partId,
			},
		});
		return response as PartView;
	}

	export async function getPartConfigurationSelectionOptions(args: {
		currentPartConfigurationId?: number | null | undefined;
		partId: number | null | undefined;
		searchQuery?: string;
	}): Promise<Array<PartConfigurationView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/warehouse/part/PartQuery/getPartConfigurationSelectionOptions",
			method: "POST",
			data: {
				currentPartConfigurationId: args.currentPartConfigurationId,
				partId: args.partId,
				searchQuery: args.searchQuery,
			},
		});
		return response as Array<PartConfigurationView>;
	}

	export async function getPartConfigurations(args: {
		partId: number;
		searchQuery?: string;
		dataModelRequest: ServerSideDataModelRequest;
	}): Promise<ServerSideDataModelResult<PartConfigurationView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/warehouse/part/PartQuery/getPartConfigurations",
			method: "POST",
			data: {
				partId: args.partId,
				searchQuery: args.searchQuery,
				dataModelRequest: args.dataModelRequest,
			},
		});
		return response as ServerSideDataModelResult<PartConfigurationView>;
	}
}
