import { ComponentSelectionView } from "src/components/views/erp/configurator/managing/catalogPart/configuratorSelections/ComponentSelectionView.tsx";
import { VerticalBox } from "src/components/common/box/VerticalBox.tsx";
import { AavoMosaic } from "src/components/common/mosaic/AavoMosaic.tsx";
import { CatalogPartRevisionConfiguratorSelectionsViewFooter } from "src/components/views/erp/configurator/managing/catalogPart/configuratorSelections/CatalogPartRevisionConfiguratorSelectionsViewFooter.tsx";
import i18n from "i18next";
import {
	PropertySelectionView
} from "src/components/views/erp/configurator/managing/catalogPart/configuratorSelections/PropertySelectionView.tsx";

export const CatalogPartRevisionConfiguratorSelectionsStatelessView = () => {
	return (
		<VerticalBox flex={1}>
			<AavoMosaic
				layout={{
					type: "tab",
					items: [
						{
							type: "panel",
							title: i18n.t("connected_components"),
							content: <ComponentSelectionView />,
						},
						{
							type: "panel",
							title: i18n.t("connected_properties"),
							content: <PropertySelectionView />,
						},
					],
				}}
			/>
			<CatalogPartRevisionConfiguratorSelectionsViewFooter />
		</VerticalBox>
	);
};
