import { CustomerOrderPackageView } from "src/api/generated/erp/db/types/tables/customerOrderPackageView.ts";
import { CustomerOrderPackagesDataGridBase } from "./CustomerOrderPackagesDataGridBase.tsx";
import { useRefreshRef } from "src/utils/useRefreshRef.ts";
import { useState } from "react";

export const AllCustomerOrderPackagesPage = () => {
	const [selectedRows, setSelectedRows] = useState<CustomerOrderPackageView | undefined>(undefined);
	const refreshRef = useRefreshRef();
	return (
		<CustomerOrderPackagesDataGridBase
			gridId={"DD5074D67212A7DC"}
			selectedRows={selectedRows == undefined ? [] : [selectedRows]}
			showSearchField={true}
			refreshRef={refreshRef}
			onSelectedPackageChanged={(selectedPackages) => setSelectedRows(selectedPackages[0])}
			showDeliveryStateSelection={true}
			showCustomerSelection={true}
			showPrintPackageLabelButton={false}
			showCalculateWeightButton={false}
			sortModel={[
				{
					field: "createdDate",
					sort: "desc",
				},
			]}
		/>
	);
};
