import { AsyncFetchRender } from "src/components/common/async/AsyncFetchRender";
import { HorizontalBox } from "src/components/common/box/HorizontalBox";
import { ReclamationPlanningReclamationList } from "src/components/views/erp/reclamation/planning/ReclamationPlanningReclamationList.tsx";
import { ReclamationPlanningApi } from "src/api/generated/erp/reclamations/api/reclamationPlanningApi";
import { AavoMosaic } from "src/components/common/mosaic/AavoMosaic";
import {
	ReclamationPlanningMapView,
	ReclamationPlanningMapViewApi,
} from "src/components/views/erp/reclamation/planning/ReclamationPlanningMapView.tsx";
import { useMemo, useRef, useState } from "react";
import { ReclamationWithCoordinates } from "src/api/generated/erp/reclamations/api/reclamationWithCoordinates.ts";
import { ReclamationPlanningTasksView } from "src/components/views/erp/reclamation/planning/ReclamationPlanningTasksView.tsx";
import i18n from "i18next";
import { SendReclamationPlanButton } from "src/components/views/erp/reclamation/planning/SendReclamationPlanButton.tsx";
import { useStoredState } from "src/utils/useStoredState.ts";
import { Coordinates } from "src/api/generated/common/address/coordinates.ts";
import { useDebounce } from "src/utils/useDebounce.ts";
import { useRefreshRef } from "src/utils/useRefreshRef.ts";

export interface ReclamationPlanningViewProps {
	reclamationIds: number[];
}

export const ReclamationPlanningView = ({ reclamationIds }: ReclamationPlanningViewProps) => {
	return (
		<AsyncFetchRender
			fetch={() => ReclamationPlanningApi.getReclamationsWithCoordinates({ reclamationIds })}
			content={(reclamations) => (
				<ReclamationPlanningViewContent reclamations={reclamations} />
			)}
		/>
	);
};

interface ReclamationPlanningViewContentProps {
	reclamations: ReclamationWithCoordinates[];
}

const ReclamationPlanningViewContent = ({ reclamations }: ReclamationPlanningViewContentProps) => {
	const mapViewRef = useRef<ReclamationPlanningMapViewApi | null>(null);
	const tasksViewRefreshRef = useRefreshRef();

	const [selectedReclamationIds, setSelectedReclamationIds] = useState<number[]>(
		reclamations.map((reclamation) => reclamation.reclamationId),
	);

	const [selectedTaskExecutorAppUserId, setSelectedTaskExecutorAppUserId] = useState<
		number | null
	>(null);

	const [startLocationCoords, setStartLocationCoords] = useStoredState<Coordinates>(
		{
			lng: 24.945831,
			lat: 60.192059,
		},
		"CE0FB9051294B117",
	);

	const debounceRefreshTasks = useDebounce();

	const setReclamationSelected = (reclamation: ReclamationWithCoordinates, selected: boolean) => {
		const reclamationId = reclamation.reclamationId;
		setSelectedReclamationIds((prevSelected) => {
			if (selected) {
				return [...prevSelected, reclamationId];
			} else {
				return prevSelected.filter((id) => id !== reclamationId);
			}
		});

		if (mapViewRef.current) {
			if (selected) {
				mapViewRef.current.addMarker(reclamation);
			} else {
				mapViewRef.current.removeMarker(reclamationId);
			}
		}

		debounceRefreshTasks(1000, () => {
			tasksViewRefreshRef.refresh();
		});
	};

	const selectedReclamations = useMemo(
		() =>
			reclamations.filter((reclamation) =>
				selectedReclamationIds.includes(reclamation.reclamationId),
			),
		[selectedReclamationIds, reclamations],
	);

	const sendPlanButton = (
		<SendReclamationPlanButton
			reclamations={selectedReclamations}
			taskExecutorAppUserId={selectedTaskExecutorAppUserId}
			startLocation={startLocationCoords}
		/>
	);

	return (
		<HorizontalBox
			sx={{
				flex: 1,
			}}
		>
			<ReclamationPlanningReclamationList
				reclamations={reclamations}
				selectedReclamationIds={selectedReclamationIds}
				setReclamationSelected={setReclamationSelected}
				refreshTasks={() => {
					tasksViewRefreshRef.refresh();
				}}
			/>
			<AavoMosaic
				viewId={"7A083E8A7999116A"}
				layout={{
					type: "tab",
					items: [
						{
							type: "panel",
							title: i18n.t("map"),
							content: (
								<ReclamationPlanningMapView
									ref={mapViewRef}
									actionBarComponents={sendPlanButton}
									startLocationCoords={startLocationCoords}
									setStartLocationCoords={setStartLocationCoords}
									onLoaded={(api) => {
										selectedReclamations.forEach((reclamation) => {
											api.addMarker(reclamation);
										});
										api.fitToMarkers();
										api.showRoute()
									}}
								/>
							),
						},
						{
							type: "panel",
							title: i18n.t("tasks"),
							content: (
								<ReclamationPlanningTasksView
									refreshRef={tasksViewRefreshRef}
									selectedReclamations={selectedReclamations}
									onSelectedTaskExecutorChanged={setSelectedTaskExecutorAppUserId}
									actionBarComponents={sendPlanButton}
								/>
							),
						},
					],
				}}
			/>
		</HorizontalBox>
	);
};
