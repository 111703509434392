import { useEffect, useState } from "react";
import { GridFilterModel, GridPaginationModel, GridRowId, GridSortModel } from "@mui/x-data-grid-pro";
import { useAsyncState } from "src/utils/async/asyncState.ts";
import { logError } from "src/errorHandling/errorLogging.ts";
import { DEFAULT_PAGINATION_MODEL } from "src/components/common/dataGrid/utils.ts";
import { ControlledAsyncCrudDataGridProps } from "src/components/common/dataGrid/crud/ControlledAsyncCrudDataGrid.tsx";
import {
	dataGridPersistentStateProps,
	DEFAULT_GRID_INITIAL_STATE,
} from "src/components/common/dataGrid/dataGridStateStorage.ts";
import {
	ServerSideDataModelRequest,
	ServerSideDataModelRequest_FilterModel,
	ServerSideDataModelRequest_SortItem,
} from "src/api/generated/common/dataGrids/serverSideDataModelRequest.ts";
import {
	ServerSideDataModelResult,
	ServerSideDataModelResult_DataGridData,
} from "src/api/generated/common/dataGrids/serverSideDataModelResult.ts";
import { useForwardedRef } from "src/utils/useForwardedRef.ts";
import { useMaybeControlledState } from "src/utils/useMaybeControlledState.ts";
import {
	UseDataGridModelGridCommonProps,
	UseDataGridModelCommonParams,
	UseDataGridModelCommonReturn,
} from "src/components/common/dataGrid/gridModel/dataGridModelCommon.ts";
import { setRefreshRefValue } from "src/utils/useRefreshRef.ts";
import { expectNonFileResult } from "src/components/common/dataGrid/gridModel/serverSideDataModelUtils.ts";
import { DataGridCsvExportButton } from "src/components/common/dataGrid/csvDownload/DataGridCsvExportButton.tsx";
import { usePartiallyStoredState } from "src/utils/usePartiallyStoredState.ts";

export interface UseServerSideDataGridModelParams<
	RowData extends object,
	TParams extends ParamsWithoutDataModelRequest<unknown>,
> extends UseDataGridModelCommonParams<RowData, TParams> {
	fetchData: (params: ParamsWithDataModelRequest<TParams>) => Promise<ServerSideDataModelResult<RowData>>;
	usePagination?: boolean;
}

export interface UseServerSideDataGridModelReturn<
	RowData extends object,
	TParams extends ParamsWithoutDataModelRequest<unknown>,
> extends UseDataGridModelCommonReturn<RowData, TParams> {
	dataGridProps: UseServerSideDataGridModelGridProps<RowData>;
	setLocalRow: (index: number, setter: (currentValue: RowData) => RowData) => void;
}

export type UseServerSideDataGridModelGridProps<RowData extends object> =
	UseDataGridModelGridCommonProps<RowData> &
		Required<
			Pick<
				ControlledAsyncCrudDataGridProps<RowData>,
				| "rowCount"
				| "pagination"
				| "sortingMode"
				| "paginationMode"
				| "filterMode"
				| "onSortModelChange"
				| "onPaginationModelChange"
				| "onFilterModelChange"
				| "filterModel"
				| "hideFooter"
			>
		>;

type ParamsWithoutDataModelRequest<T> = Omit<T, "dataModelRequest">;

type ParamsWithDataModelRequest<TParams> = TParams & {
	dataModelRequest: ServerSideDataModelRequest;
};

export type ServerSideDataGridModelRefreshFunc<
	FetchFunc extends (
		params: ParamsWithDataModelRequest<unknown>,
	) => Promise<ServerSideDataModelResult<unknown>>,
> = UseServerSideDataGridModelReturn<object, ServerSideDataGridModelFetchParams<FetchFunc>>["refreshData"];

export type ServerSideDataGridModelFetchParams<
	FetchFunc extends (
		params: ParamsWithDataModelRequest<unknown>,
	) => Promise<ServerSideDataModelResult<unknown>>,
> = Required<ParamsWithoutDataModelRequest<Parameters<FetchFunc>[0]>>;

export const useServerSideDataGridModel = <
	RowData extends object,
	TParams extends ParamsWithoutDataModelRequest<any>,
>({
	gridId,
	refreshRef,
	getRowId,
	fetchData,
	initialParams,
	storedParams = [],
	apiRef: apiRefProp,
	usePagination = true,
	selectedRows: selectedRowsProp,
	defaultSelectedRows = [],
	onSelectionChanged: setSelectedRowsProp,
	selectFirstRowOnLoad,
	defaultGridState = DEFAULT_GRID_INITIAL_STATE,
}: UseServerSideDataGridModelParams<RowData, TParams>): UseServerSideDataGridModelReturn<
	RowData,
	TParams
> => {
	const apiRef = useForwardedRef(apiRefProp);
	const [currentParams, setCurrentParams] = usePartiallyStoredState({
		initialState: initialParams,
		storedKeys: storedParams,
		key: "data-grid-params--" + gridId,
	});
	const [dataAsync, setDataAsync, setDataImmediate] =
		useAsyncState<ServerSideDataModelResult_DataGridData<RowData>>();

	const { initialState: initialGridState, onStateChange: persistGridState } = dataGridPersistentStateProps(
		gridId,
		defaultGridState,
	);

	const [sortModel, setSortModel] = useState<GridSortModel>(initialGridState.sorting?.sortModel ?? []);

	const [filterModel, setFilterModel] = useState<GridFilterModel>(
		initialGridState.filter?.filterModel ?? DEFAULT_FILTER_MODEL,
	);

	const [paginationModel, setPaginationModel] = useState<GridPaginationModel | undefined>({
		pageSize:
			initialGridState?.pagination?.paginationModel?.pageSize ?? DEFAULT_PAGINATION_MODEL.pageSize,
		page: 0,
	});

	const currentDataModelRequest: ServerSideDataModelRequest = {
		resultType: "DATA",
		sortModel: mapSortModelToBackend(sortModel),
		paginationModel: usePagination ? paginationModel : undefined,
		filterModel: mapFilterModelToBackend(filterModel),
	};

	const [selectedRows, setSelectedRows] = useMaybeControlledState({
		controlledValue: selectedRowsProp,
		onChange: setSelectedRowsProp,
		defaultValue: defaultSelectedRows,
	});
	const onlySelectedRow = selectedRows.length === 1 ? selectedRows[0] : undefined;

	const onRowSelectionChanged = (selectedRowIds: GridRowId[]) => {
		const selectedRows =
			dataAsync.data?.rows?.filter((row) => selectedRowIds.includes(getRowId(row))) ?? [];
		setSelectedRows(selectedRows);
	};

	const handleSortModelChange = async (model: GridSortModel) => {
		setSortModel(model);
		await refreshDataWithParams({
			newSortModel: model,
		});
	};

	const handlePaginationModelChange = async (model: GridPaginationModel) => {
		setPaginationModel(model);
		await refreshDataWithParams({
			newPaginationModel: model,
		});
	};

	const handleFilterModelChange = async (model: GridFilterModel) => {
		setFilterModel(model);
		await refreshDataWithParams({
			newFilterModel: model,
		});
	};

	const refreshDataWithParams = async ({
		newSortModel = sortModel,
		newPaginationModel = paginationModel,
		newFilterModel = filterModel,
		newParams = currentParams,
	}: {
		newSortModel?: GridSortModel;
		newPaginationModel?: GridPaginationModel | undefined;
		newFilterModel?: GridFilterModel;
		newParams?: TParams;
	}) => {
		try {
			const dataModelRequest: ServerSideDataModelRequest = {
				resultType: "DATA",
				sortModel: mapSortModelToBackend(newSortModel),
				paginationModel: usePagination ? newPaginationModel : undefined,
				filterModel: mapFilterModelToBackend(newFilterModel),
			};
			const paramsWithDataGridRequest = {
				...newParams,
				dataModelRequest: dataModelRequest,
			};
			const newData = await setDataAsync(async () => {
				const result = await fetchData(paramsWithDataGridRequest);
				return expectNonFileResult(result);
			});

			if (selectFirstRowOnLoad) {
				setSelectedRows(newData.rows.slice(0, 1));
			} else {
				const newSelectedRows = newData.rows.filter((row) =>
					selectedRows.some((selectedRow) => getRowId(selectedRow) === getRowId(row)),
				);
				setSelectedRows(newSelectedRows);
			}

			return newData.rows;
		} catch (e) {
			// Errors in grid state may cause persistent errors, so we reset the state on error.
			setFilterModel(DEFAULT_FILTER_MODEL);
			setSortModel([]);
			persistGridState(DEFAULT_GRID_INITIAL_STATE);
			return [];
		}
	};

	const refreshData = (params: Partial<TParams> = {}) => {
		setCurrentParams((prev) => {
			return {
				...prev,
				...params,
			};
		});
		return refreshDataWithParams({
			newParams: {
				...currentParams,
				...params,
			},
		});
	};

	const setLocalRow = (index: number, setter: (currentValue: RowData) => RowData) => {
		setDataImmediate((currentData) => {
			if (!currentData) {
				return currentData;
			}
			const currentRow = currentData.rows[index];
			if (currentRow === undefined) {
				return currentData;
			}
			const newRows = [...currentData.rows];
			newRows[index] = setter(currentRow);
			return {
				...currentData,
				rows: newRows,
			};
		});
	};

	if (refreshRef) setRefreshRefValue(refreshRef, refreshData);

	const getApi = () => {
		if (!apiRef.current) {
			throw new Error("DataGrid API ref is not set");
		}
		return apiRef.current;
	};

	useEffect(() => {
		refreshData().catch(logError);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {
		dataGridProps: {
			apiRef: apiRef,
			pagination: true,
			hideFooter: !usePagination,
			sortingMode: "server",
			paginationMode: "server",
			filterMode: "server",
			onSortModelChange: handleSortModelChange,
			onPaginationModelChange: handlePaginationModelChange,
			onFilterModelChange: handleFilterModelChange,
			filterModel: filterModel,
			initialState: initialGridState,
			onStateChange: persistGridState,
			refreshData: refreshData,
			selectedRows: selectedRows.map(getRowId),
			onRowSelectionChanged: onRowSelectionChanged,
			getRowId: getRowId,
			rowCount: dataAsync.data?.totalRowCount ?? 0,
			rowsAsync: {
				...dataAsync,
				data: dataAsync.data?.rows,
				loading: dataAsync.loading,
			},
			actionBarMenuComponents2: [
				<DataGridCsvExportButton
					key={"dataGridDefaultExportDataButton"}
					dataModel={{
						type: "serverSide",
						fetchData: async (dataModelRequest) =>
							fetchData({
								...currentParams,
								dataModelRequest: {
									...currentDataModelRequest,
									...dataModelRequest,
								},
							}),
					}}
					getApi={getApi}
				/>,
			],
		},
		currentParams: currentParams,
		refreshData: refreshData,
		setLocalRow: setLocalRow,
		selectedRows: selectedRows,
		setSelectedRows: setSelectedRows,
		onlySelectedRow: onlySelectedRow,
		getApi: getApi,
	};
};

const mapSortModelToBackend = (sortModel: GridSortModel): ServerSideDataModelRequest_SortItem[] =>
	sortModel.map((sortItem) => ({
		field: sortItem.field,
		sort: sortItem.sort === "desc" ? "DESC" : "ASC",
	}));

const mapFilterModelToBackend = (filterModel: GridFilterModel): ServerSideDataModelRequest_FilterModel => ({
	...filterModel,
	items: filterModel.items.map((item) => ({
		...item,
		value: item.value ?? null,
	})),
	logicOperator: filterModel.logicOperator === "or" ? "OR" : "AND",
});

const DEFAULT_FILTER_MODEL: GridFilterModel = {
	items: [],
};
