import {AavoMosaic} from "src/components/common/mosaic/AavoMosaic";
import {PurchaseOrdersDataGrid} from "./PurchaseOrdersDataGrid";
import i18n from "i18next";
import {CenteredTypography} from "src/components/common/CenteredTypography.tsx";
import {useState} from "react";
import {
    SinglePurchaseOrderLinesDataGrid
} from "src/components/views/erp/purchase/purchaseOrders/SinglePurchaseOrderLinesDataGrid.tsx";
import {PurchaseOrderView} from "src/api/generated/erp/db/types/tables/purchaseOrderView.ts";
import {
    PurchaseOrderReceiptsDataGrid
} from "src/components/views/erp/purchase/reception/PurchaseOrderReceiptsDataGrid.tsx";
import {PurchaseOrderReceipt} from "src/api/generated/erp/db/types/tables/purchaseOrderReceipt.ts";
import {
    PurchaseOrderReceiptLinesDataGrid
} from "src/components/views/erp/purchase/reception/PurchaseOrderReceiptLinesDataGrid.tsx";
import {useRefreshRef} from "src/utils/useRefreshRef.ts";

export interface PurchaseOrdersViewProps {
	onlyPurchaseOrderId?: number;
}

export const PurchaseOrdersView = ({ onlyPurchaseOrderId }: PurchaseOrdersViewProps) => {
	const [selectedPurchaseOrder, setSelectedPurchaseOrder] = useState<PurchaseOrderView | undefined>(
		undefined,
	);
	const [selectedPurchaseOrderReceipt, setSelectedPurchaseOrderReceipt] = useState<
		PurchaseOrderReceipt | undefined
	>(undefined);

	const selectPurchaseOrderTypography = <CenteredTypography children={i18n.t("select_a_purchase_order")} />;

	const purchaseOrderLinesRefreshRef = useRefreshRef();

	return (
		<AavoMosaic
			viewId="341E6FC3DCDEEA2E"
			layout={{
				type: "column",
				first: {
					type: "panel",
					title: i18n.t("purchase_orders"),
					content: (
						<PurchaseOrdersDataGrid
							onlyPurchaseOrderId={onlyPurchaseOrderId}
							onSelectionChanged={(selection) => {
								setSelectedPurchaseOrder(selection);
								setSelectedPurchaseOrderReceipt(undefined);
							}}
							purchaseOrderLinesRefreshRef={purchaseOrderLinesRefreshRef}
						/>
					),
				},
				second: {
					type: "tab",
					items: [
						{
							type: "panel",
							title: i18n.t("purchase_order_lines"),
							content:
								selectedPurchaseOrder == null ?
									selectPurchaseOrderTypography
								:	<SinglePurchaseOrderLinesDataGrid
										key={selectedPurchaseOrder.purchaseOrderId}
										purchaseOrder={selectedPurchaseOrder}
										refreshRef={purchaseOrderLinesRefreshRef}
									/>,
						},
						{
							type: "row",
							title: i18n.t("receipts"),
							first: {
								type: "panel",
								title: i18n.t("receipts"),
								content:
									selectedPurchaseOrder == null ?
										selectPurchaseOrderTypography
									:	<PurchaseOrderReceiptsDataGrid
											key={selectedPurchaseOrder.purchaseOrderId}
											purchaseOrderId={selectedPurchaseOrder.purchaseOrderId}
											onSelectionChanged={(selection) => {
												setSelectedPurchaseOrderReceipt(selection);
											}}
											showOpenReceptionButton
										/>,
							},
							second: {
								type: "panel",
								title: i18n.t("receipt_lines"),
								content:
									selectedPurchaseOrderReceipt == null ?
										<CenteredTypography children={i18n.t("select_a_receipt")} />
									:	<PurchaseOrderReceiptLinesDataGrid
											key={selectedPurchaseOrderReceipt.purchaseOrderReceiptId}
											purchaseOrderReceipt={
												selectedPurchaseOrderReceipt
											}
										/>,
							},
						},
					],
				},
			}}
		/>
	);
};
