import { VerticalBox } from "src/components/common/box/VerticalBox";
import { AavoCheckbox } from "src/components/common/inputFields/AavoCheckbox.tsx";
import { useState } from "react";
import i18n from "i18next";
import { useParameterizedAsyncData } from "src/utils/async/parameterizedAsyncData.ts";
import { ProjectActivityJobQueueApi } from "src/api/generated/erp/project/projectActivity/api/projectActivityJobQueueApi.ts";
import { emptyDataModelRequest } from "src/utils/serverSideDataModelRequestUtils.ts";
import { AsyncRender } from "src/components/common/async/AsyncRender.tsx";
import { SelectField } from "src/components/common/inputFields/SelectField.tsx";
import { ProjectActivityCustomerOrderView } from "src/api/generated/erp/db/types/tables/projectActivityCustomerOrderView.ts";
import { concatWithPipe } from "src/utils/strings.tsx";
import { HorizontalBox } from "src/components/common/box/HorizontalBox.tsx";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import {
	faCheck,
	faInfo,
	faMoneyBill,
	faPaperclip,
	faRefresh,
	faSwatchbook,
	faTasks,
	faUndo,
} from "@fortawesome/pro-regular-svg-icons";
import { Box, Typography } from "@mui/material";
import { DocumentsOfObjectButton } from "src/components/views/documents/objectDocuments/DocumentsOfObjectButton.tsx";
import { OpenCustomerOrderInfoButton } from "src/components/views/erp/utilComponents/OpenCustomerOrderInfoButton.tsx";
import { CenteredTypography } from "src/components/common/CenteredTypography.tsx";
import { nullableAavoObjectRef } from "src/utils/aavoObjectRefUtils.ts";
import { useConfirmDialog } from "src/components/common/dialogs/confirmDialog/useConfirmDialog.ts";
import { ProjectJobQueueActions } from "src/components/views/erp/project/activityJobQueue/projectJobQueueActions.ts";
import { useContextOrThrow } from "src/utils/useContextOrThrow.tsx";
import { ProjectActivityJobQueueViewContext } from "src/components/views/erp/project/activityJobQueue/ProjectActivityJobQueueViewContext.ts";
import { setRefreshRefValue } from "src/utils/useRefreshRef.ts";
import { useGenericDialog } from "src/components/common/dialogs/useGenericDialog.ts";
import { SingleSourceTasksView } from "src/components/views/tasks/pages/SingleSourceTasksView.tsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CostEventsOfObjectDataGrid } from "src/components/views/erp/common/costEvents/CostEventsOfObjectDataGrid.tsx";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { SingleActivityMaterialLinesDataGrid } from "src/components/views/erp/project/projectActivity/material/SingleActivityMaterialLinesDataGrid.tsx";
import { expectNonFileResult } from "src/components/common/dataGrid/gridModel/serverSideDataModelUtils.ts";

export const ProjectActivityJobQueueInProgressMobileView = () => {
	const [selectedActivity, setSelectedActivity] = useState<ProjectActivityCustomerOrderView | null>(null);

	const {
		dataAsync: activityOptionsAsync,
		paramValues: { onlyActivitiesStartedByMe },
		refresh: refreshActivities,
	} = useParameterizedAsyncData({
		fetchData: getActivityOptions,
		initialParams: {
			onlyActivitiesStartedByMe: true,
		},
		fetchOnMount: true,
		afterRefresh: (activities) => {
			if (selectedActivity == null) {
				setSelectedActivity(activities[0] ?? null);
			}
		},
	});

	const { initialActivitiesRefreshRef, inProgressActivitiesRefreshRef, completedActivitiesRefreshRef } =
		useContextOrThrow(ProjectActivityJobQueueViewContext);
	setRefreshRefValue(inProgressActivitiesRefreshRef, () => refreshActivities());

	return (
		<VerticalBox flex={1}>
			<AavoCheckbox
				label={i18n.t("only_started_by_me")}
				checked={onlyActivitiesStartedByMe}
				onChange={(checked) => refreshActivities({ onlyActivitiesStartedByMe: checked })}
			/>
			<AsyncRender
				asyncData={activityOptionsAsync}
				reloadOnError={() => refreshActivities()}
				content={(activityOptions) => {
					return (
						<VerticalBox flex={1} padding={1} gap={1}>
							<HorizontalBox gap={1}>
								<SelectField
									label={i18n.t("activity")}
									options={activityOptions}
									getOptionKey={(option) => option.activityId}
									getOptionLabel={(option) =>
										concatWithPipe(
											option.projectDescription,
											option.subProjectDescription,
											option.activityName,
										)
									}
									disableClearable
									value={selectedActivity?.activityId ?? null}
									onChange={(_, newActivity) => setSelectedActivity(newActivity)}
									placeholder={
										activityOptions.length > 0 ?
											i18n.t("select_a_activity")
										:	i18n.t("no_activities_in_progress")
									}
									sx={{
										flex: 1,
									}}
								/>
								<AsyncButton icon={faRefresh} onClick={() => refreshActivities()} />
							</HorizontalBox>
							{selectedActivity == null ?
								<CenteredTypography children={i18n.t("select_a_activity")} />
							:	<ContentForSelectedActivity
									activity={selectedActivity}
									onActivityNoLongerInProgress={async () => {
										await refreshActivities();
										setSelectedActivity(null);
										await Promise.all([
											initialActivitiesRefreshRef.refresh(),
											completedActivitiesRefreshRef.refresh(),
										]);
									}}
								/>
							}
						</VerticalBox>
					);
				}}
			/>
		</VerticalBox>
	);
};

async function getActivityOptions({
	onlyActivitiesStartedByMe,
}: {
	onlyActivitiesStartedByMe: boolean;
}): Promise<ProjectActivityCustomerOrderView[]> {
	const {data} = await ProjectActivityJobQueueApi.getActivitiesInProgress({
		searchQuery: "",
		subProjectTypeId: null,
		resourceId: null,
		onlyActivitiesStartedByMe: onlyActivitiesStartedByMe,
		dataModelRequest: emptyDataModelRequest,
	});
	return expectNonFileResult(data).rows
}

interface ContentForSelectedActivityProps {
	activity: ProjectActivityCustomerOrderView;
	onActivityNoLongerInProgress: () => Promise<unknown>;
}

const ContentForSelectedActivity = ({
	activity,
	onActivityNoLongerInProgress,
}: ContentForSelectedActivityProps) => {
	const showConfirmDialog = useConfirmDialog();
	const { openDialog } = useGenericDialog();

	return (
		<Box
			sx={{
				flex: 1,
				display: "grid",
				gridTemplateColumns: "1fr 1fr",
				gap: 1,
				"& > *": {
					minWidth: 0,
				},
			}}
		>
			<AsyncButton
				variant={"outlined"}
				onClick={() => {
					openDialog({
						title: i18n.t("tasks"),
						content: (
							<SingleSourceTasksView
								taskSourceRef={{
									sourceType: "PROJECT_ACTIVITY",
									sourceId: activity.activityId,
								}}
							/>
						),
					});
				}}
			>
				<ActivityButtonContent label={i18n.t("tasks")} icon={faTasks} />
			</AsyncButton>

			<AsyncButton
				variant={"outlined"}
				onClick={() => {
					openDialog({
						title: i18n.t("materials"),
						content: <SingleActivityMaterialLinesDataGrid activityId={activity.activityId} />,
					});
				}}
			>
				<ActivityButtonContent label={i18n.t("materials")} icon={faSwatchbook} />
			</AsyncButton>

			<DocumentsOfObjectButton
				variant={"outlined"}
				icon={null}
				objectRef={{
					objectType: "PROJECT_ACTIVITY",
					objectId: activity.activityId,
				}}
			>
				<ActivityButtonContent label={i18n.t("activity_documents")} icon={faPaperclip} />
			</DocumentsOfObjectButton>

			<DocumentsOfObjectButton
				variant={"outlined"}
				icon={null}
				objectRef={nullableAavoObjectRef("CUSTOMER_ORDER", activity.customerOrderId)}
			>
				<ActivityButtonContent label={i18n.t("customer_order_documents")} icon={faPaperclip} />
			</DocumentsOfObjectButton>

			<OpenCustomerOrderInfoButton
				icon={null}
				variant={"outlined"}
				customerOrderId={activity.customerOrderId}
			>
				<ActivityButtonContent label={i18n.t("customer_order_info")} icon={faInfo} />
			</OpenCustomerOrderInfoButton>

			<AsyncButton
				variant={"outlined"}
				disabled={activity.costEventPriceListId == null}
				onClick={() => {
					if (activity.costEventPriceListId == null) return;
					openDialog({
						title: i18n.t("cost_events"),
						content: (
							<CostEventsOfObjectDataGrid
								objectRef={{
									objectType: "PROJECT_ACTIVITY",
									objectId: activity.activityId,
								}}
								costEventPriceListId={activity.costEventPriceListId}
							/>
						),
					});
				}}
			>
				<ActivityButtonContent label={i18n.t("cost_events.word_break")} icon={faMoneyBill} />
			</AsyncButton>

			<AsyncButton
				variant={"outlined"}
				onClick={async () => {
					const reverted = await ProjectJobQueueActions.revertStartActivities(
						[activity],
						showConfirmDialog,
					);
					if (reverted) await onActivityNoLongerInProgress();
				}}
			>
				<ActivityButtonContent label={i18n.t("revert_start")} icon={faUndo} />
			</AsyncButton>

			<AsyncButton
				variant={"outlined"}
				onClick={async () => {
					const completed = await ProjectJobQueueActions.completeActivities(
						[activity],
						showConfirmDialog,
					);
					if (completed) await onActivityNoLongerInProgress();
				}}
			>
				<ActivityButtonContent icon={faCheck} label={i18n.t("completed")} />
			</AsyncButton>
		</Box>
	);
};

const ActivityButtonContent = ({ icon, label }: { icon: IconDefinition; label: string }) => {
	return (
		<HorizontalBox
			sx={{
				flexWrap: "wrap",
				alignItems: "center",
				gap: 1,
				justifyContent: "center",
				overflow: "hidden",
			}}
		>
			<FontAwesomeIcon icon={icon} />
			<VerticalBox>
				{label.split("\n").map((line, index) => (
					<Typography key={index} variant={"button"} children={line} />
				))}
			</VerticalBox>
		</HorizontalBox>
	);
};
