// This file is automatically generated. Do not edit manually.

import { Customer } from "src/api/generated/erp/db/types/tables/customer";
import { CustomerAddress } from "src/api/generated/erp/db/types/tables/customerAddress";
import { CustomerGroup } from "src/api/generated/erp/db/types/tables/customerGroup";
import { CustomerPriceGroup } from "src/api/generated/erp/db/types/tables/customerPriceGroup";
import { EInvoiceOperator } from "src/api/generated/erp/db/types/tables/eInvoiceOperator";
import { PaymentTerm } from "src/api/generated/erp/db/types/tables/paymentTerm";
import { TransportRoute } from "src/api/generated/erp/db/types/tables/transportRoute";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace CustomerFormApi {
	export async function getInitData(args: {
		customerId: number | null | undefined;
	}): Promise<CustomerFormApi_InitData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customer/getInitData",
			method: "POST",
			data: {
				customerId: args.customerId,
			},
		});
		return response as CustomerFormApi_InitData;
	}

	export async function save(args: {
		customer: Customer;
		deliveryAddress: CustomerAddress;
		invoiceAddress: CustomerAddress;
	}): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customer/save",
			method: "POST",
			data: {
				customer: args.customer,
				deliveryAddress: args.deliveryAddress,
				invoiceAddress: args.invoiceAddress,
			},
		});
		return response as number;
	}
}

export interface CustomerFormApi_InitData {
	customer: Customer | null | undefined;
	customerGroupOptions: Array<CustomerGroup>;
	customerPriceGroupOptions: Array<CustomerPriceGroup>;
	deliveryAddress: CustomerAddress | null | undefined;
	eInvoiceOperatorOptions: Array<EInvoiceOperator>;
	invoiceAddress: CustomerAddress | null | undefined;
	paymentTermOptions: Array<PaymentTerm>;
	transportRouteOptions: Array<TransportRoute>;
}
