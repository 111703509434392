import {
	faBan,
	faChartGantt,
	faCheck,
	faCopy,
	faExternalLinkAlt,
	faFileInvoiceDollar,
	faMoneyBill,
	faPlus,
	faShare,
	faSwatchbook,
	faTasks,
	faUndo,
} from "@fortawesome/pro-regular-svg-icons";
import { AsyncMenuButton } from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import i18n from "i18next";
import { DocumentsOfObjectButton } from "src/components/views/documents/objectDocuments/DocumentsOfObjectButton.tsx";
import { ProjectGanttView } from "src/components/views/erp/project/gantt/ProjectGanttView.tsx";
import { useGenericDialog } from "src/components/common/dialogs/useGenericDialog.ts";
import { ProjectAllActivityMaterialLinesDataGrid } from "src/components/views/erp/project/projectActivity/material/ProjectAllActivityMaterialLinesDataGrid.tsx";
import { ProjectView } from "src/api/generated/erp/db/types/tables/projectView.ts";
import { OpenObjectChangeLogButton } from "src/components/views/changeLogging/OpenObjectChangeLogButton.tsx";
import { ProjectsContainerView } from "src/components/views/erp/project/ProjectsContainerView.tsx";
import { useConfirmDialog } from "src/components/common/dialogs/confirmDialog/useConfirmDialog.ts";
import { useFormInput } from "src/components/common/dialogs/formInput/useFormInput.tsx";
import { ProjectActionsApi } from "src/api/generated/erp/project/project/api/projectActionsApi.ts";
import { FormCheckbox } from "src/components/common/forms/fields/FormCheckbox.tsx";
import { openLegacySourceSetTasksView } from "src/components/views/legacy/legacyViewAdapters.ts";
import { useOpenLegacyView } from "src/components/views/legacy/useOpenLegacyView.ts";
import { ProjectCustomerOrdersDataGrid } from "src/components/views/erp/project/project/ProjectCustomerOrdersDataGrid.tsx";
import { ProjectCostEventsDataGrid } from "src/components/views/erp/project/project/ProjectCostEventsDataGrid.tsx";
import { openFormOnDialog } from "src/components/common/dialogs/formDialog/openFormOnDialog.ts";
import { CreateNewSubProjectForm } from "src/components/views/erp/project/subProject/CreateNewSubProjectForm.tsx";
import { IsoDateString } from "src/types/dateTime.ts";
import { requireRule } from "src/components/common/forms/validation.ts";
import { FormDateField } from "src/components/common/forms/fields/FormDateField.tsx";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import { deCapitalize } from "src/utils/strings.tsx";

export interface ProjectContextMenuProps {
	project: ProjectView;
	refreshData?: () => Promise<unknown>;
}

export const ProjectContextMenu = ({ project, refreshData }: ProjectContextMenuProps) => {
	const { openDialog } = useGenericDialog();
	const showFormInput = useFormInput();
	const showConfirmDialog = useConfirmDialog();
	const openLegacyView = useOpenLegacyView();

	const projectId = project.projectId;

	return [
		<AsyncMenuButton
			key={"openProject"}
			label={i18n.t("open")}
			icon={faExternalLinkAlt}
			onClick={() => {
				openDialog({
					title: i18n.t("project"),
					content: <ProjectsContainerView onlyProjectId={project.projectId} />,
				});
			}}
		/>,
		project.projectState === "INITIAL" && (
			<AsyncMenuButton
				key={"release"}
				label={i18n.t("release")}
				icon={faShare}
				onClick={onReleaseProjectClick}
			/>
		),
		project.projectState === "RELEASED" && (
			<AsyncMenuButton
				key={"complete"}
				label={i18n.t("mark_as_completed")}
				icon={faCheck}
				onClick={async () => {
					const confirmed = await showConfirmDialog({});
					if (!confirmed) return;
					await ProjectActionsApi.completeProject({ projectId: projectId });
					await refreshData?.();
				}}
			/>
		),
		project.projectState === "READY" && (
			<AsyncMenuButton
				key={"revertComplete"}
				label={i18n.t("revert_complete")}
				icon={faUndo}
				onClick={async () => {
					await ProjectActionsApi.revertCompleteProject({ projectId: projectId });
					await refreshData?.();
				}}
			/>
		),
		["INITIAL", "RELEASED"].includes(project.projectState) && (
			<AsyncMenuButton
				key={"cancel"}
				label={i18n.t("cancel")}
				icon={faBan}
				onClick={async () => {
					const confirmed = await showConfirmDialog({
						message: i18n.t("confirm_cancel_project"),
					});
					if (!confirmed) return;
					await ProjectActionsApi.cancelProject({ projectId: projectId });
					await refreshData?.();
				}}
			/>
		),
		["INITIAL", "RELEASED"].includes(project.projectState) && (
			<AsyncMenuButton
				key={"addSubProject"}
				label={i18n.t("add_sub_project")}
				icon={faPlus}
				onClick={() => {
					openFormOnDialog({
						openDialog,
						title: i18n.t("new_sub_project"),
						size: "sm",
						component: CreateNewSubProjectForm,
						props: (closeDialog) => ({
							projectId: projectId,
							closeDialog: closeDialog,
						}),
						confirmCloseIfEdited: false,
						onSubmit: refreshData,
					});
				}}
			/>
		),
		<AsyncMenuButton
			key={"clone"}
			label={i18n.t("create_a_copy")}
			icon={faCopy}
			onClick={onCloneProjectClick}
		/>,
		<DocumentsOfObjectButton
			key={"documents"}
			variant={"menu"}
			label={i18n.t("documents")}
			objectRef={{ objectType: "PROJECT", objectId: project.projectId }}
		/>,
		<AsyncMenuButton
			key={"gantt"}
			label={i18n.t("gantt")}
			icon={faChartGantt}
			onClick={() => {
				openDialog({
					title: i18n.t("gantt"),
					content: <ProjectGanttView projectIds={[project.projectId]} />,
				});
			}}
		/>,
		<AsyncMenuButton
			key={"tasks"}
			label={i18n.t("tasks")}
			icon={faTasks}
			onClick={() => {
				openLegacySourceSetTasksView({
					openLegacyView,
					sourceSetType: "Project",
					sourceSetId: projectId,
				});
			}}
		/>,
		<AsyncMenuButton
			key={"materials"}
			label={i18n.t("materials")}
			icon={faSwatchbook}
			onClick={() => {
				openDialog({
					title: i18n.t("materials"),
					content: <ProjectAllActivityMaterialLinesDataGrid projectId={project.projectId} />,
				});
			}}
		/>,
		project.costEventPriceListId != null && (
			<AsyncMenuButton
				key={"costEvents"}
				label={i18n.t("cost_events")}
				icon={faMoneyBill}
				onClick={() => {
					openDialog({
						title: i18n.t("cost_events"),
						size: "xl",
						content: (
							<ProjectCostEventsDataGrid
								projectId={project.projectId}
								costEventPriceListId={project.costEventPriceListId!}
							/>
						),
					});
				}}
			/>
		),
		project.hasCustomerOrders && (
			<AsyncMenuButton
				key={"customerOrders"}
				label={i18n.t("customer_orders")}
				icon={faFileInvoiceDollar}
				onClick={() => {
					openDialog({
						title: i18n.t("project_customer_orders"),
						size: "xl",
						content: <ProjectCustomerOrdersDataGrid projectId={projectId} />,
					});
				}}
			/>
		),
		<OpenObjectChangeLogButton
			key={"changeLog"}
			objectRef={{ objectType: "PROJECT", objectId: project.projectId }}
		/>,
	];

	async function onReleaseProjectClick() {
		interface FormInput {
			releaseSubProjectsAndActivities: boolean;
			releaseTasks: boolean;
		}

		const input = await showFormInput<FormInput>({
			title: i18n.t("release_project"),
			defaultValues: {
				releaseSubProjectsAndActivities: false,
				releaseTasks: false,
			},
			submitLabel: i18n.t("release"),
			content: ({ control, watch }) => (
				<>
					<FormCheckbox
						control={control}
						name={"releaseSubProjectsAndActivities"}
						label={i18n.t("release_also_sub_projects_and_activities")}
					/>
					{watch("releaseSubProjectsAndActivities") && (
						<FormCheckbox
							control={control}
							name={"releaseTasks"}
							label={i18n.t("release_also_tasks")}
						/>
					)}
				</>
			),
		});
		if (input === undefined) return;

		await ProjectActionsApi.releaseProject({
			projectId: projectId,
			releaseSubProjectsAndActivities: input.releaseSubProjectsAndActivities,
			releaseTasks: input.releaseTasks,
		});
		await refreshData?.();
	}

	async function onCloneProjectClick() {
		const formInput = await showFormInput<{ name: string; plannedStartDate: IsoDateString }>({
			title: i18n.t("clone_project"),
			submitLabel: i18n.t("clone"),
			content: ({ control }) => (
				<>
					<FormTextField
						control={control}
						name={"name"}
						label={i18n.t("name")}
						rules={requireRule()}
					/>
					<FormDateField
						control={control}
						name={"plannedStartDate"}
						label={i18n.t("planned_start")}
						rules={requireRule()}
					/>
				</>
			),
			defaultValues: {
				name: project.projectDescription + " -" + deCapitalize(i18n.t("copy.noun")),
			},
		});
		if (!formInput) return;

		await ProjectActionsApi.cloneProject({
			projectId: projectId,
			newProjectPlannedStartDate: formInput.plannedStartDate,
			newProjectName: formInput.name,
		});
		await refreshData?.();
	}
};
