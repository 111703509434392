import { AavoMosaic } from "src/components/common/mosaic/AavoMosaic.tsx";
import { ShopOrderBomTreeView } from "src/components/views/erp/production/shopOrderBom/ShopOrderBomTreeView.tsx";
import i18n from "i18next";
import { DocumentsOfObjectsDataGrid } from "src/components/views/documents/objectDocuments/DocumentsOfObjectsDataGrid.tsx";
import { useState } from "react";
import { DocumentConnectionView } from "src/api/generated/postgres/db/types/documents/tables/documentConnectionView.ts";
import { ShopOrderBomTreeItemModel } from "src/components/views/erp/production/shopOrderBom/treeItemMapping.ts";
import { DocumentPreviewView } from "src/components/views/documents/preview/DocumentPreviewView.tsx";
import { CenteredTypography } from "src/components/common/CenteredTypography.tsx";

export interface ShopOrderBomTreeContainerProps {
	shopOrderId: number;
}

export const ShopOrderBomTreeContainer = ({ shopOrderId }: ShopOrderBomTreeContainerProps) => {
	const [selectedTreeItem, setSelectedTreeItem] = useState<ShopOrderBomTreeItemModel | null>(null);
	const selectedPartRevisionId =
		selectedTreeItem?.type === "part" ? selectedTreeItem?.activeRevisionId
		: selectedTreeItem?.type === "revision" ? selectedTreeItem?.partRevisionId
		: null;

	const [selectedDocument, setSelectedDocument] = useState<DocumentConnectionView | undefined>(undefined);

	return (
		<AavoMosaic
			layout={{
				type: "row",
				first: {
					type: "column",
					first: {
						type: "panel",
						title: i18n.t("bom"),
						content: (
							<ShopOrderBomTreeView
								shopOrderId={shopOrderId}
								onSelectionChanged={(value) => setSelectedTreeItem(value)}
							/>
						),
					},
					second: {
						type: "tab",
						items: [
							{
								type: "panel",
								title: i18n.t("revision_documents"),
								content:
									selectedPartRevisionId == null ?
										<CenteredTypography>{i18n.t("select_bom_line")}</CenteredTypography>
									:	<DocumentsOfObjectsDataGrid
											key={selectedPartRevisionId}
											onSelectionChanged={(value) => setSelectedDocument(value[0])}
											editable={false}
											objectRefs={[
												{
													objectType: "PART_REVISION",
													objectId: selectedPartRevisionId,
												},
											]}
										/>,
							},
							{
								type: "panel",
								title: i18n.t("part_documents"),
								content:
									selectedTreeItem == null ?
										<CenteredTypography>{i18n.t("select_bom_line")}</CenteredTypography>
									:	<DocumentsOfObjectsDataGrid
											key={selectedTreeItem.id}
											onSelectionChanged={(value) => setSelectedDocument(value[0])}
											editable={false}
											objectRefs={[
												{
													objectType: "PART",
													objectId: selectedTreeItem.partId,
												},
											]}
										/>,
							},
						],
					},
				},
				second: {
					type: "panel",
					title: i18n.t("preview"),
					content:
						selectedDocument == null ?
							<CenteredTypography>{i18n.t("select_document")}</CenteredTypography>
						:	<DocumentPreviewView
								key={selectedDocument.documentId}
								documentId={selectedDocument.documentId}
							/>,
				},
			}}
		/>
	);
};
