import { useServerSideDataGridModel } from "src/components/common/dataGrid/gridModel/useServerSideDataGridModel";
import { SitePartDataGridApi } from "src/api/generated/erp/parts/sitePart/api/sitePartDataGridApi.ts";
import { PartView } from "src/api/generated/erp/db/types/tables/partView";
import { ControlledAsyncCrudDataGrid } from "src/components/common/dataGrid/crud/ControlledAsyncCrudDataGrid";
import {
	booleanColumn,
	coloredBooleanColumn,
	enumColumn,
	floatColumn,
	integerColumn,
	textColumn,
} from "src/components/common/dataGrid/columns";
import i18n from "i18next";
import {
	getPartTypeLabel,
	getPartTypeLabels,
	PartType,
	PartTypeValues,
} from "src/api/generated/erp/db/types/enums/partType.ts";
import { getAcquisitionMethodLabels } from "src/api/generated/erp/db/types/enums/acquisitionMethod.ts";
import { useTenantCustomizations } from "src/tenantCustomizations/useTenantCustomizations.tsx";
import { getPartValuationMethodLabels } from "src/api/generated/erp/db/types/enums/partValuationMethod.ts";
import { DocumentsOfObjectButton } from "src/components/views/documents/objectDocuments/DocumentsOfObjectButton.tsx";
import { nullableAavoObjectRef } from "src/utils/aavoObjectRefUtils";
import { AavoTextField } from "src/components/common/inputFields/AavoTextField.tsx";
import { SelectField } from "src/components/common/inputFields/SelectField.tsx";
import { AsyncUserSelectField } from "src/components/views/users/AsyncUserSelectField.tsx";
import { genericNullableValue } from "src/utils/genericNullableValue.ts";
import { MenuCheckbox } from "src/components/common/contextMenu/MenuCheckbox.tsx";
import { AsyncMenuButton } from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import { useConfirmDialog } from "src/components/common/dialogs/confirmDialog/useConfirmDialog.ts";
import { SitePartActionApi } from "src/api/generated/erp/parts/sitePart/api/sitePartActionApi.ts";
import { faBan, faCheck, faTachometerAlt, faWindowRestore } from "@fortawesome/pro-regular-svg-icons";
import { useOpenLegacyView } from "src/components/views/legacy/useOpenLegacyView.ts";
import { OpenObjectChangeLogButton } from "src/components/views/changeLogging/OpenObjectChangeLogButton.tsx";
import { RefreshableElementProps } from "src/utils/useRefreshRef.ts";
import { SitePartForm } from "./SitePartForm";
import { concatWithPipe } from "src/utils/strings.tsx";

export interface SitePartsDataGridProps extends RefreshableElementProps {
	partCategoryId: number | undefined;
	onSelectionChanged?: (parts: PartView[]) => void;
	sitePartId?: number | undefined;
}

export const SitePartsDataGrid = ({
	partCategoryId,
	refreshRef,
	onSelectionChanged,
	sitePartId,
}: SitePartsDataGridProps) => {
	const { tenantConfig } = useTenantCustomizations();
	const confirm = useConfirmDialog();
	const openLegacyView = useOpenLegacyView();

	const { dataGridProps, onlySelectedRow, refreshData, currentParams } = useServerSideDataGridModel({
		gridId: "8760CB4E8E028D07",
		refreshRef: refreshRef,
		fetchData: SitePartDataGridApi.searchSiteParts,
		getRowId: (row) => row.partId,
		initialParams: {
			searchQuery: "",
			partCategoryId: partCategoryId,
			partType: genericNullableValue<PartType | null>(null),
			showInactiveParts: false,
			responsiblePersonId: genericNullableValue<number | null>(null),
			onlyDefaultSite: true,
			showPartsWithDesignedRevision: false,
			onlySitePartId: sitePartId,
		},
		onSelectionChanged: onSelectionChanged,
		selectFirstRowOnLoad: sitePartId != null,
	});

	return (
		<ControlledAsyncCrudDataGrid<PartView>
			disableMultipleRowSelection
			columns={[
				textColumn({
					field: "siteName",
					headerName: i18n.t("site_short"),
					width: 50,
				}),
				textColumn({
					field: "partNo",
					headerName: i18n.t("part_number_shortened"),
					width: 75,
				}),
				textColumn({
					field: "partDescription_1",
					headerName: i18n.t("description_1"),
					width: 250,
				}),
				textColumn({
					field: "partDescription_2",
					headerName: i18n.t("description_2"),
					width: 150,
				}),
				textColumn({
					field: "partCategory",
					headerName: i18n.t("category"),
				}),
				textColumn({
					field: "manufacturerPartNumber",
					headerName: i18n.t("manufacturer_code"),
					width: 135,
				}),
				textColumn({
					field: "additionalNumber",
					headerName: i18n.t("additional_code"),
					width: 80,
				}),
				textColumn({
					field: "partUnit",
					headerName: i18n.t("base_unit"),
				}),
				enumColumn({
					field: "partType",
					headerName: i18n.t("type"),
					enumLabels: getPartTypeLabels(),
				}),
				enumColumn({
					field: "acquisitionMethod",
					headerName: i18n.t("acquisition_method"),
					enumLabels: getAcquisitionMethodLabels(),
				}),
				enumColumn({
					field: "valuationMethod",
					headerName: i18n.t("valuation_method"),
					enumLabels: getPartValuationMethodLabels(),
					width: 150,
				}),
				floatColumn({
					field: "lastPurchasePrice",
					headerName: i18n.t("last_purchase_price"),
					width: 130,
				}),
				coloredBooleanColumn({
					field: "active",
					headerName: i18n.t("active"),
					width: 80,
				}),
				floatColumn({
					field: "weight",
					headerName: i18n.t("weight"),
					width: 70,
				}),
				textColumn({
					field: "responsiblePersonName",
					headerName: i18n.t("responsible_person"),
				}),
				booleanColumn({
					field: "automaticRelease",
					headerName: i18n.t("automatic_release"),
					width: 160,
				}),
				booleanColumn({
					field: "automaticClose",
					headerName: i18n.t("automatic_close"),
					width: 160,
				}),
				integerColumn({
					field: "partId",
					headerName: i18n.t("id"),
				}),
			]}
			actionBarComponents={
				<>
					<DocumentsOfObjectButton
						objectRef={nullableAavoObjectRef("PART", onlySelectedRow?.partId)}
					/>
					<AavoTextField
						label={i18n.t("search")}
						onSubmit={async (value) => {
							await refreshData({ searchQuery: value });
						}}
					/>
					<SelectField
						label={i18n.t("type")}
						options={PartTypeValues}
						getOptionKey={(option) => option}
						getOptionLabel={getPartTypeLabel}
						onChange={async (value) => {
							await refreshData({ partType: value ?? null });
						}}
						minWidth={150}
					/>
					<AsyncUserSelectField
						label={i18n.t("responsible_person")}
						onChange={async (value) => {
							await refreshData({ responsiblePersonId: value?.id ?? null });
						}}
					/>
				</>
			}
			actionBarMenuComponents={[
				<MenuCheckbox
					key={"showInactiveParts"}
					label={i18n.t("show_inactive_parts")}
					onChange={async (value) => {
						await refreshData({ showInactiveParts: value });
					}}
					checked={currentParams.showInactiveParts}
				/>,
				<MenuCheckbox
					key={"onlyDefaultSite"}
					label={i18n.t("only_default_site")}
					onChange={async (value) => {
						await refreshData({ onlyDefaultSite: value });
					}}
					checked={currentParams.onlyDefaultSite}
				/>,
				<MenuCheckbox
					key={"showPartsWithDesignedRevision"}
					label={i18n.t("show_unreleased")}
					onChange={async (value) => {
						await refreshData({ showPartsWithDesignedRevision: value });
					}}
					checked={currentParams.showPartsWithDesignedRevision}
				/>,
			]}
			rowContextMenuComponents={({ row }) => [
				row.active && (
					<AsyncMenuButton
						key={"decactivate"}
						label={i18n.t("set_to_inactive")}
						icon={faBan}
						onClick={async () => {
							const confirmed = await confirm({
								title: i18n.t("are_you_sure"),
								message: i18n.t("deactivate_site_part_confirm"),
							});
							if (!confirmed) return;

							await SitePartActionApi.deactivatePart({ partId: row.partId });
							await refreshData();
						}}
					/>
				),
				!row.active && (
					<AsyncMenuButton
						key={"activate"}
						label={i18n.t("set_to_active")}
						icon={faCheck}
						onClick={async () => {
							const confirmed = await confirm({
								title: i18n.t("are_you_sure"),
								message: i18n.t("activate_site_part_confirm"),
							});
							if (!confirmed) return;

							await SitePartActionApi.activatePart({ partId: row.partId });
							await refreshData();
						}}
					/>
				),
				tenantConfig.erp.configuratorEnabled && row.isConfigurable && (
					<AsyncMenuButton
						key={"configurations"}
						label={i18n.t("configurations")}
						icon={faWindowRestore}
						onClick={() => {
							openLegacyView("C5A629B2C30FB9FE", { partId: row.partId });
						}}
					/>
				),
				<OpenObjectChangeLogButton
					key={"changeLog"}
					objectRef={{ objectType: "PART", objectId: row.partId }}
				/>,
				<AsyncMenuButton
					key={"inventoryLevelProfile"}
					label={i18n.t("inventory_level_profile")}
					icon={faTachometerAlt}
					onClick={() => openLegacyView("F697DE7933C323D9", { _partIdFilter: row.partId })}
				/>,
			]}
			form={{
				dialogSize: "lg",
				addRowEnabled: false,
				editEnabled: true,
				dialogTitle: (row) =>
					row != null ?
						concatWithPipe(row.partNo, row.partDescription_1, row.partDescription_2)
					:	i18n.t("new_part"),
				component: ({ row, onCompleted, onFormEdited }) => {
					if (row?.partId == null) return;
					return (
						<SitePartForm
							partId={row.partId}
							onFormEdited={onFormEdited}
							onCompleted={onCompleted}
						/>
					);
				},
			}}
			{...dataGridProps}
		/>
	);
};
