import { Checkbox, CheckboxProps, FormControlLabel, Tooltip } from "@mui/material";
import { mergeSx } from "src/utils/styles.ts";
import React from "react";

export interface AavoCheckboxProps extends Omit<CheckboxProps, "value" | "defaultValue" | "onChange" | "disabled"> {
	disabled?: boolean | string;
	required?: boolean;
	label?: string;
	onChange?: (value: boolean, event: React.SyntheticEvent) => void;
	tooltip?: string;
}

export const AavoCheckbox = ({
	disabled: disabledProp,
	required,
	label,
	onChange: onChangeProp,
	sx,
	tooltip: tooltipProp,
	...other
}: AavoCheckboxProps) => {
	const onChangeSwapped = (event: React.SyntheticEvent, checked: boolean) => {
		onChangeProp?.(checked, event);
	};

	const disabled = typeof disabledProp === "string" ? disabledProp.length > 0 : disabledProp;
	const tooltip = typeof disabledProp === "string" ? disabledProp : tooltipProp;

	const checkbox =
		label != null ?
			<FormControlLabel
				control={<Checkbox {...other} />}
				required={required}
				disabled={disabled}
				label={label}
				onChange={onChangeSwapped}
				sx={mergeSx(
					{
						margin: 0,
					},
					sx,
				)}
			/>
		:	<Checkbox onChange={onChangeSwapped} {...other} />;

	return (
		<Tooltip title={tooltip} arrow placement={"bottom-start"}>
			{checkbox}
		</Tooltip>
	);
};
