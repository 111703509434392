import { CustomerOrderBillingPlanLineView } from "src/api/generated/erp/db/types/tables/customerOrderBillingPlanLineView.ts";
import { CustomerOrderBillingPlanApi } from "src/api/generated/erp/sales/billingPlan/customerOrder/api/customerOrderBillingPlanApi.ts";
import {
	booleanColumn,
	dateColumn,
	dateTimeColumn, enumColumn,
	floatColumn,
	textColumn
} from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { dataGridRowClassNames } from "src/components/common/dataGrid/styles/dataGridClassNames.ts";
import { ClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel.tsx";
import { CrudDataGrid } from "src/components/common/dataGrid/crud/CrudDataGrid.tsx";
import { CustomerOrderBillingPlanLineForm } from "src/components/views/erp/sales/billingPlan/customerOrder/CustomerOrderBillingPlanLineForm.tsx";
import { faCheck, faPercent, faUndo } from "@fortawesome/pro-regular-svg-icons";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import React from "react";
import { RefreshableElementProps, RefreshableElementRef } from "src/utils/useRefreshRef.ts";
import { AsyncMenuButton } from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import { useInputDialog } from "src/components/common/dialogs/input/useInputDialog.tsx";
import { VatCodeApi } from "src/api/generated/erp/sales/basedata/api/vatCodeApi.ts";
import { MenuCheckbox } from "src/components/common/contextMenu/MenuCheckbox.tsx";
import {
	getCustomerOrderBillingPlanLineStateLabels
} from "src/api/generated/erp/db/types/enums/customerOrderBillingPlanLineState.ts";
import { BillingPlanSummaryText } from "../base/BillingPlanSummaryText";

export interface CustomerOrderBillingPlanDataGridBaseProps extends RefreshableElementProps {
	customerOrderId: number;
	actionBarComponents?: React.ReactNode;
	onSelectionChanged?: (selectedRows: CustomerOrderBillingPlanLineView[]) => void;
	editable?: boolean;
	enableCancelledLines?: boolean;
	customerOrderRefreshRef?: RefreshableElementRef;
}

export const CustomerOrderBillingPlanDataGridBase = ({
	refreshRef,
	customerOrderId,
	actionBarComponents,
	onSelectionChanged,
	editable = false,
	enableCancelledLines = false,
	customerOrderRefreshRef,
}: CustomerOrderBillingPlanDataGridBaseProps) => {
	const showInputDialog = useInputDialog();
	return (
		<ClientSideDataGridModel
			fetchData={({ includeCancelledLines }) =>
				CustomerOrderBillingPlanApi.getCustomerOrderBillingPlan({
					customerOrderId,
					includeCancelledLines,
				})
			}
			refreshRef={refreshRef}
			getRows={(data) => data.lines}
			initialParams={{
				includeCancelledLines: false,
			}}
			gridId={"E9E5FA35C483F3C5"}
			getRowId={(row) => row.customerOrderBillingPlanLineId}
			onSelectionChanged={onSelectionChanged}
			render={({ data: { summary }, onlySelectedRow, dataGridProps, refreshData, currentParams }) => (
				<CrudDataGrid<CustomerOrderBillingPlanLineView>
					disableMultipleRowSelection
					getRowClassNames={({ row }) => {
						switch (row.billingPlanLineState) {
							case "INVOICED":
								return dataGridRowClassNames.green;
							case "APPROVED":
								return dataGridRowClassNames.yellow;
							case "CANCELLED":
								return dataGridRowClassNames.red;
							default:
								return "";
						}
					}}
					columns={[
						textColumn({
							field: "description",
							headerName: i18n.t("description"),
							width: 200,
						}),
						floatColumn({
							field: "billingPlanSum",
							headerName: i18n.t("sum"),
						}),
						floatColumn({
							field: "billingPlanSumWithVat",
							headerName: i18n.t("sum_with_vat"),
							width: 140,
						}),
						floatColumn({
							field: "billingPlanPercent",
							headerName: i18n.t("share_percent"),
							width: 100,
						}),
						dateColumn({
							field: "estimatedApprovalDate",
							headerName: i18n.t("estimated_approval_date"),
							width: 170,
						}),
						dateColumn({
							field: "estimatedPaymentDate",
							headerName: i18n.t("estimated_payment_date"),
							width: 150,
						}),
						enumColumn({
							field: "billingPlanLineState",
							headerName: i18n.t("state"),
							width: 150,
							enumLabels: getCustomerOrderBillingPlanLineStateLabels(),
						}),
						dateTimeColumn({
							field: "approvedAt",
							headerName: i18n.t("approved_at"),
							width: 150,
						}),
						textColumn({
							field: "approvedByUserName",
							headerName: i18n.t("approved_by"),
							width: 120,
						}),
						dateColumn({
							field: "invoiceDate",
							headerName: i18n.t("invoice_date"),
						}),
						textColumn({
							field: "invoicedByUserName",
							headerName: i18n.t("invoiced_by"),
						}),
						booleanColumn({
							field: "funded",
							headerName: i18n.t("funded"),
						}),
						floatColumn({
							field: "vatPercent",
							headerName: i18n.t("vat_percent"),
						}),
					]}
					form={{
						addRowEnabled: editable,
						editEnabled: editable,
						dialogSize: "md",
						dialogTitle: i18n.t("billing_plan_line"),
						component: ({ row, ...other }) => (
							<CustomerOrderBillingPlanLineForm
								customerOrderId={customerOrderId}
								billingPlanLine={row}
								customerOrderDataRefreshRef={customerOrderRefreshRef}
								{...other}
							/>
						),
					}}
					remove={{
						type: "enabled",
						action: async ({ items }) => {
							await CustomerOrderBillingPlanApi.deleteBillingPlanLines({
								billingPlanLineIds: items.map((i) => i.customerOrderBillingPlanLineId),
							});
						},
					}}
					actionBarComponents={
						<>
							{actionBarComponents}
							{editable && onlySelectedRow?.billingPlanLineState == "INITIAL" && (
								<AsyncButton
									label={i18n.t("approve")}
									icon={faCheck}
									disabled={
										summary.billingPlanIsComplete ? false : (
											i18n.t("billing_plan_is_not_complete")
										)
									}
									variant={"outlined"}
									onClick={async () => {
										if (!onlySelectedRow) return;
										await CustomerOrderBillingPlanApi.approveCustomerOrderBillingPlanLine(
											{
												billingPlanLineId:
													onlySelectedRow.customerOrderBillingPlanLineId,
											},
										);
										await refreshData();
									}}
								/>
							)}
							{editable && onlySelectedRow?.billingPlanLineState == "APPROVED" && (
								<AsyncButton
									label={i18n.t("undo_approval")}
									icon={faUndo}
									variant={"outlined"}
									onClick={async () => {
										await CustomerOrderBillingPlanApi.revertApproveCustomerOrderBillingPlanLine(
											{
												billingPlanLineId:
													onlySelectedRow.customerOrderBillingPlanLineId,
											},
										);
										await refreshData();
									}}
								/>
							)}
							<BillingPlanSummaryText summary={summary} />
						</>
					}
					actionBarMenuComponents={[
						enableCancelledLines && (
							<MenuCheckbox
								label={i18n.t("show_cancelled")}
								checked={currentParams.includeCancelledLines}
								onChange={(checked) => refreshData({ includeCancelledLines: checked })}
							/>
						),
					]}
					rowContextMenuComponents={({ row }) => (
						<AsyncMenuButton
							icon={faPercent}
							label={i18n.t("change_vat_code")}
							onClick={async () => {
								const vatCodeOptions = await VatCodeApi.getVatCodes();
								const newVatCodeId = await showInputDialog({
									defaultValue: row.vatCodeId,
									type: "singleNumberSelect",
									title: i18n.t("change_vat_code"),
									fieldLabel: i18n.t("vat_code"),
									required: true,
									fieldProps: {
										selection: {
											options: vatCodeOptions.map((vc) => ({
												value: vc.vatCodeId,
												label: vc.vatCodeName,
											})),
										},
									},
								});
								if (!newVatCodeId) return;
								await CustomerOrderBillingPlanApi.changeBillingPlanLineVatCode({
									billingPlanLineId: row.customerOrderBillingPlanLineId,
									newVatCodeId: newVatCodeId,
								});
								await refreshData();
							}}
						/>
					)}
					{...dataGridProps}
				/>
			)}
		/>
	);
};
