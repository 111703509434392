import { CustomerOrderDeliveryView } from "src/api/generated/erp/db/types/tables/customerOrderDeliveryView.ts";
import {
	dateColumn,
	dateTimeColumn,
	integerColumn,
	textColumn,
} from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { useGenericDialog } from "src/components/common/dialogs/useGenericDialog.ts";
import { AavoButton } from "src/components/common/buttons/AavoButton.tsx";
import { faFileInvoice } from "@fortawesome/pro-regular-svg-icons";
import { ControlledAsyncDataGrid } from "src/components/common/dataGrid/ControlledAsyncDataGrid.tsx";
import { CustomerOrderInvoicingApi } from "src/api/generated/erp/sales/invoicing/api/customerOrderInvoicingApi.ts";
import { InvoiceCreationApi } from "src/api/generated/erp/sales/invoicing/api/invoiceCreationApi.ts";
import { CustomerOrderInvoiceCreationForm } from "src/components/views/erp/sales/invoicing/customerOrderInvoicing/CustomerOrderInvoiceCreationForm.tsx";
import { OpenCustomerOrderButton } from "src/components/views/erp/utilComponents/OpenCustomerOrderButton.tsx";
import { OpenCustomerOrderDeliveryViewButton } from "src/components/views/erp/utilComponents/OpenCustomerOrderDeliveryViewButton.tsx";
import { useClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/useClientSideDataGridModel";

export interface CustomerOrderDeliveryInvoicingViewProps {
	customerOrderId: number;
	onInvoicesModified: () => void;
}

export const CustomerOrderDeliveryInvoicingView = ({
	customerOrderId,
	onInvoicesModified,
}: CustomerOrderDeliveryInvoicingViewProps) => {
	const { openDialog } = useGenericDialog();

	const { dataGridProps, onlySelectedRow, refreshData } = useClientSideDataGridModel({
		fetchData: () =>
			CustomerOrderInvoicingApi.getCustomerOrderBillableDeliveries({ customerOrderId }),
		initialParams: {},
		getRowId: (row) => row.customerOrderDeliveryId,
		gridId: "D9351E6EEB620764",
	});
	return (
		<ControlledAsyncDataGrid<CustomerOrderDeliveryView>
			disableMultipleRowSelection
			columns={[
				integerColumn({
					field: "customerOrderDeliveryId",
					headerName: i18n.t("number_shortened"),
					width: 80,
				}),
				textColumn({
					field: "cargoDriverName",
					headerName: i18n.t("driver"),
				}),
				dateColumn({
					field: "plannedLoadingDate",
					headerName: i18n.t("planned_loading_date"),
					width: 160,
				}),
				dateColumn({
					field: "plannedUnloadingDate",
					headerName: i18n.t("planned_unloading_date"),
					width: 160,
				}),
				textColumn({
					field: "trackingCode",
					headerName: i18n.t("tracking_code"),
					width: 140,
				}),
				textColumn({
					field: "note",
					headerName: i18n.t("note"),
					width: 140,
				}),
				textColumn({
					field: "createdByUserName",
					headerName: i18n.t("created_by"),
				}),
				dateTimeColumn({
					field: "createdDate",
					headerName: i18n.t("created_at"),
					width: 140,
				}),
			]}
			actionBarComponents={
				<>
					<AavoButton
						icon={faFileInvoice}
						label={i18n.t("create_invoice")}
						disabled={!onlySelectedRow}
						variant={"outlined"}
						onClick={showInvoiceCreationForm}
					/>
					<OpenCustomerOrderButton
						key={"openCustomerOrder"}
						customerOrderId={customerOrderId}
						variant={"outlined"}
					/>
				</>
			}
			rowContextMenuComponents={({ row }) => [
				<OpenCustomerOrderDeliveryViewButton
					key={"openCustomerOrderDelivery"}
					customerOrderId={customerOrderId}
					onlyCustomerOrderDeliveryId={row.customerOrderDeliveryId}
				/>,
			]}
			{...dataGridProps}
		/>
	);

	function showInvoiceCreationForm() {
		if (!onlySelectedRow) return;

		openDialog(({ closeDialog }) => ({
			title: i18n.t("create_invoice"),
			size: "sm",
			content: (
				<CustomerOrderInvoiceCreationForm
					customerOrderId={customerOrderId}
					closeForm={closeDialog}
					submit={async ({ invoiceDate, selectedCustomerOrderLineIds }) => {
						await InvoiceCreationApi.createInvoiceFromCustomerOrderDelivery({
							customerOrderDeliveryId: onlySelectedRow.customerOrderDeliveryId,
							invoiceDate: invoiceDate,
							appendCustomerOrderLineIds: selectedCustomerOrderLineIds,
						});
						await refreshData();
						onInvoicesModified();
					}}
				/>
			),
		}));
	}
};
