import { AavoMosaic } from "src/components/common/mosaic/AavoMosaic";
import { ProjectTreeView } from "./ProjectTreeView";
import i18n from "i18next";
import { ProjectTreeItemModel } from "src/components/views/erp/project/treeView/treeItemMapping.ts";
import { useState } from "react";
import { AavoMosaicTabItem } from "src/components/common/mosaic/types.ts";
import { CenteredTypography } from "src/components/common/CenteredTypography.tsx";
import { DocumentsOfSingleObjectView } from "src/components/views/documents/objectDocuments/DocumentsOfSingleObjectView.tsx";
import { AavoObjectRef } from "src/api/generated/common/sourceType/aavoObjectRef.ts";
import { SingleSourceTasksView } from "src/components/views/tasks/pages/SingleSourceTasksView.tsx";
import { CostEventsOfObjectDataGrid } from "src/components/views/erp/common/costEvents/CostEventsOfObjectDataGrid.tsx";
import { ProjectInspectView } from "src/components/views/erp/project/project/ProjectInspectView.tsx";
import { useRefreshRef } from "src/utils/useRefreshRef.ts";
import { SubProjectInspectView } from "src/components/views/erp/project/subProject/SubProjectInspectView.tsx";
import { ProjectActivityInspectView } from "src/components/views/erp/project/projectActivity/ProjectActivityInspectView.tsx";
import { SingleActivityMaterialLinesDataGrid } from "src/components/views/erp/project/projectActivity/material/SingleActivityMaterialLinesDataGrid.tsx";
import { ProjectAllActivityMaterialLinesDataGrid } from "src/components/views/erp/project/projectActivity/material/ProjectAllActivityMaterialLinesDataGrid.tsx";
import { ProjectGanttView } from "src/components/views/erp/project/gantt/ProjectGanttView.tsx";
import { SubProjectGanttView } from "../gantt/SubProjectGanttView";
import { ProjectActivityGanttView } from "../gantt/ProjectActivityGanttView";
import { ProjectNotesView } from "src/components/views/erp/project/project/ProjectNotesView.tsx";
import { ProjectActivityCatalogPartsDataGrid } from "src/components/views/erp/project/projectActivity/connectedParts/ProjectActivityCatalogPartsDataGrid.tsx";
import { ProjectActivityProductChangeRequestsDataGrid } from "src/components/views/erp/project/projectActivity/ProjectActivityProductChangeRequestsDataGrid.tsx";

export interface ProjectTreeViewContainerProps {
	onlyProjectId?: number;
}

export const ProjectTreeViewContainer = ({ onlyProjectId }: ProjectTreeViewContainerProps) => {
	const [selectedItem, setSelectedItem] = useState<ProjectTreeItemModel | null>(null);

	const treeViewRefreshRef = useRefreshRef();
	const projectInspectViewRefreshRef = useRefreshRef();
	const subProjectInspectViewRefreshRef = useRefreshRef();
	const activityInspectViewRefreshRef = useRefreshRef();
	const activityTasksViewRefreshRef = useRefreshRef();

	const ganttViewCommonProps = {
		onProjectScheduled: onGanttProjectScheduled,
		onSubProjectScheduled: onGanttSubProjectScheduled,
		onActivityScheduled: onGanttActivityScheduled,
		onTaskScheduled: onGanttTaskScheduled,
	};

	return (
		<AavoMosaic
			viewId={"F71A6858FA1B5329"}
			layout={{
				type: "row",
				splitPercentage: 40,
				first: {
					type: "panel",
					title: i18n.t("projects"),
					content: (
						<ProjectTreeView
							refreshRef={treeViewRefreshRef}
							tasksViewRefreshRef={activityTasksViewRefreshRef}
							onlyProjectId={onlyProjectId}
							onSelectionChanged={setSelectedItem}
							onSelectedItemUpdated={() =>
								Promise.all([
									projectInspectViewRefreshRef.refresh(),
									subProjectInspectViewRefreshRef.refresh(),
									activityInspectViewRefreshRef.refresh(),
								])
							}
						/>
					),
				},
				second: {
					type: "column",
					first: {
						type: "tab",
						items: getUpperTabItems(),
					},
					second: {
						type: "tab",
						items: getBottomTabItems(),
					},
				},
			}}
		/>
	);

	function getUpperTabItems(): AavoMosaicTabItem[] {
		switch (selectedItem?.type) {
			case undefined:
				return [
					{
						type: "panel",
						title: i18n.t("info"),
						content: <CenteredTypography children={i18n.t("select_a_item_from_tree_view")} />,
					},
				];
			case "project":
				return [
					{
						type: "panel",
						title: i18n.t("project"),
						content: (
							<ProjectInspectView
								key={selectedItem.projectId}
								project={selectedItem}
								refreshRef={projectInspectViewRefreshRef}
								onProjectUpdated={async () => {
									await treeViewRefreshRef.refresh();
								}}
							/>
						),
					},
					{
						type: "panel",
						title: i18n.t("gantt"),
						content: (
							<ProjectGanttView
								key={selectedItem.projectId}
								projectIds={[selectedItem.projectId]}
								{...ganttViewCommonProps}
							/>
						),
					},
					{
						type: "panel",
						title: i18n.t("memo"),
						content: <ProjectNotesView key={selectedItem.projectId} project={selectedItem} />,
					},
				];
			case "subProject":
				return [
					{
						type: "panel",
						title: i18n.t("sub_project"),
						content: (
							<SubProjectInspectView
								key={selectedItem.subProjectId}
								subProject={selectedItem}
								refreshRef={subProjectInspectViewRefreshRef}
								onSubProjectUpdated={async () => {
									await treeViewRefreshRef.refresh();
								}}
							/>
						),
					},
					{
						type: "panel",
						title: i18n.t("gantt"),
						content: (
							<SubProjectGanttView
								key={selectedItem.subProjectId}
								subProjectIds={[selectedItem.subProjectId]}
								{...ganttViewCommonProps}
							/>
						),
					},
				];
			case "activity":
				return [
					{
						type: "panel",
						title: i18n.t("activity"),
						content: (
							<ProjectActivityInspectView
								key={selectedItem.activityId}
								activity={selectedItem}
								refreshRef={activityInspectViewRefreshRef}
								onActivityUpdated={() =>
									Promise.all([
										treeViewRefreshRef.refresh(),
										activityTasksViewRefreshRef.refresh(),
									])
								}
							/>
						),
					},
					{
						type: "panel",
						title: i18n.t("gantt"),
						content: (
							<ProjectActivityGanttView
								key={selectedItem.activityId}
								activityIds={[selectedItem.activityId]}
								{...ganttViewCommonProps}
							/>
						),
					},
				];
		}
	}

	function getBottomTabItems(): (AavoMosaicTabItem | false)[] {
		switch (selectedItem?.type) {
			case undefined:
				return [
					{
						type: "panel",
						title: i18n.t("documents"),
						content: <CenteredTypography children={i18n.t("select_a_item_from_tree_view")} />,
					},
				];
			case "project":
				return [
					makeDocumentsPanel({
						objectType: "PROJECT",
						objectId: selectedItem.projectId,
					}),
					{
						type: "panel",
						title: i18n.t("materials"),
						content: (
							<ProjectAllActivityMaterialLinesDataGrid
								key={selectedItem.projectId}
								projectId={selectedItem.projectId}
							/>
						),
					},
				];
			case "subProject":
				return [
					makeDocumentsPanel({
						objectType: "SUB_PROJECT",
						objectId: selectedItem.subProjectId,
					}),
				];
			case "activity":
				return [
					makeDocumentsPanel({
						objectType: "PROJECT_ACTIVITY",
						objectId: selectedItem.activityId,
					}),
					{
						type: "panel",
						title: i18n.t("tasks"),
						content: (
							<SingleSourceTasksView
								key={selectedItem.activityId}
								refreshRef={activityTasksViewRefreshRef}
								taskSourceRef={{
									sourceType: "PROJECT_ACTIVITY",
									sourceId: selectedItem.activityId,
								}}
							/>
						),
					},
					{
						type: "panel",
						title: i18n.t("materials"),
						content: (
							<SingleActivityMaterialLinesDataGrid
								key={selectedItem.activityId}
								activityId={selectedItem.activityId}
							/>
						),
					},
					{
						type: "panel",
						title: i18n.t("connected_parts"),
						content: (
							<ProjectActivityCatalogPartsDataGrid
								key={selectedItem.activityId}
								projectActivityId={selectedItem.activityId}
							/>
						),
					},
					{
						type: "panel",
						title: i18n.t("product_change_requests"),
						content: (
							<ProjectActivityProductChangeRequestsDataGrid
								key={selectedItem.activityId}
								activityId={selectedItem.activityId}
							/>
						),
					},
					selectedItem.costEventPriceListId != null && {
						type: "panel",
						title: i18n.t("cost_events"),
						content: (
							<CostEventsOfObjectDataGrid
								key={selectedItem.activityId}
								objectRef={{
									objectType: "PROJECT_ACTIVITY",
									objectId: selectedItem.activityId,
								}}
								costEventPriceListId={selectedItem.costEventPriceListId}
							/>
						),
					},
				];
		}
	}

	function makeDocumentsPanel(objectRef: AavoObjectRef): AavoMosaicTabItem {
		return {
			type: "panel",
			title: i18n.t("documents"),
			content: (
				<DocumentsOfSingleObjectView key={JSON.stringify(objectRef)} hidePreview objectRef={objectRef} />
			),
		};
	}

	async function onGanttProjectScheduled() {
		await Promise.all([projectInspectViewRefreshRef.refresh(), treeViewRefreshRef.refresh()]);
	}

	async function onGanttSubProjectScheduled() {
		await Promise.all([subProjectInspectViewRefreshRef.refresh(), treeViewRefreshRef.refresh()]);
	}

	async function onGanttActivityScheduled() {
		await Promise.all([activityInspectViewRefreshRef.refresh(), treeViewRefreshRef.refresh()]);
	}

	async function onGanttTaskScheduled() {
		await activityTasksViewRefreshRef.refresh();
	}
};
