import { PartWarehouseLocationView } from "src/api/generated/erp/db/types/tables/partWarehouseLocationView";
import { MenuCheckbox } from "src/components/common/contextMenu/MenuCheckbox";
import { useClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/useClientSideDataGridModel";
import i18n from "i18next";
import {
	booleanColumn,
	enumColumn,
	floatColumn,
	integerColumn,
	textColumn,
} from "src/components/common/dataGrid/columns.tsx";
import { faBan, faBarcode, faCheck, faClipboardCheck, faUndo } from "@fortawesome/pro-regular-svg-icons";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { downloadFile } from "src/utils/fileDownloading.ts";
import { useConfirmDialog } from "src/components/common/dialogs/confirmDialog/useConfirmDialog.ts";
import { useOpenLegacyView } from "src/components/views/legacy/useOpenLegacyView.ts";
import { FrontendDownloadFileHandle } from "src/api/generated/common/frontendDownloadFileHandle.ts";
import { PartWarehouseLocationQueryApi } from "src/api/generated/erp/parts/partWarehouseLocations/api/partWarehouseLocationQueryApi.ts";
import { PartWarehouseLocationPrintApi } from "src/api/generated/erp/parts/partWarehouseLocations/api/partWarehouseLocationPrintApi.ts";
import { PartWarehouseLocationActionsApi } from "src/api/generated/erp/parts/partWarehouseLocations/api/partWarehouseLocationActionsApi.ts";
import { PartWarehouseLocationForm } from "src/components/views/erp/parts/siteParts/partWarehouseLocations/PartWarehouseLocationForm.tsx";
import { PartWarehouseLocationEditApi } from "src/api/generated/erp/parts/partWarehouseLocations/api/partWarehouseLocationEditApi.ts";
import { ControlledAsyncCrudDataGrid } from "src/components/common/dataGrid/crud/ControlledAsyncCrudDataGrid.tsx";
import { getPartWarehouseLocationReplenishmentTriggerLabels } from "src/api/generated/erp/db/types/enums/partWarehouseLocationReplenishmentTrigger.ts";
import {
	getWarehouseReplenishmentMethodLabels
} from "src/api/generated/erp/db/types/enums/warehouseReplenishmentMethod.ts";

export interface PartWarehouseLocationsDataGridProps {
	sitePartId: number;
}

export const PartWarehouseLocationsDataGrid = ({ sitePartId }: PartWarehouseLocationsDataGridProps) => {
	const showConfirmDialog = useConfirmDialog();
	const openLegacyView = useOpenLegacyView();

	const { dataGridProps, refreshData, currentParams, selectedRows } = useClientSideDataGridModel({
		fetchData: (params) =>
			PartWarehouseLocationQueryApi.getPartWarehouseLocations({
				sitePartId: sitePartId,
				showDisabledLocations: params.showDisabledLocations,
			}),
		getRowId: (row) => row.partWarehouseLocationId,
		gridId: "BF14E250406E461E",
		initialParams: {
			showDisabledLocations: false,
		},
	});

	return (
		<ControlledAsyncCrudDataGrid<PartWarehouseLocationView>
			columns={[
				textColumn({
					field: "locationCode",
					headerName: i18n.t("warehouse_location"),
				}),
				floatColumn({
					field: "locationQuantity",
					headerName: i18n.t("quantity"),
				}),
				enumColumn({
					field: "replenishmentMethod",
					headerName: i18n.t("replenishment_method"),
					enumLabels: getWarehouseReplenishmentMethodLabels(),
				}),
				textColumn({
					field: "givingSiteName",
					headerName: i18n.t("giving_site"),
					width: 170,
				}),
				textColumn({
					field: "givingWarehouseLocationCode",
					headerName: i18n.t("giving_warehouse_location"),
					width: 170,
				}),
				enumColumn({
					field: "replenishmentTrigger",
					headerName: i18n.t("replenish_at"),
					enumLabels: getPartWarehouseLocationReplenishmentTriggerLabels(),
					width: 150
				}),
				floatColumn({
					field: "defaultReplenishmentQuantity",
					headerName: i18n.t("default_replenishment_quantity"),
					width: 180,
				}),
				booleanColumn({
					field: "isDefault",
					headerName: i18n.t("default"),
				}),
				booleanColumn({
					field: "inventoryLevelEnabled",
					headerName: i18n.t("inventory_level_enabled"),
					width: 160,
				}),
				booleanColumn({
					field: "askQuantity",
					headerName: i18n.t("ask_quantity"),
				}),
				booleanColumn({
					field: "askTransferInfo",
					headerName: i18n.t("ask_supply_request_info"),
					width: 160,
				}),
				booleanColumn({
					field: "isActive",
					headerName: i18n.t("active"),
				}),
				integerColumn({
					field: "partWarehouseLocationId",
					headerName: i18n.t("id"),
				}),
			]}
			form={{
				addRowEnabled: true,
				editEnabled: true,
				dialogTitle: i18n.t("part_warehouse_location"),
				dialogSize: "lg",
				component: ({ row, ...other }) => (
					<PartWarehouseLocationForm
						partId={sitePartId}
						partWarehouseLocationId={row?.partWarehouseLocationId}
						{...other}
					/>
				),
			}}
			remove={{
				type: "enabled",
				action: async ({ items }) => {
					await PartWarehouseLocationEditApi.delete_({
						partWarehouseLocationIds: items.map((row) => row.partWarehouseLocationId),
					});
					await refreshData();
				},
			}}
			actionBarMenuComponents={[
				<MenuCheckbox
					key={"showDisabled"}
					label={i18n.t("show_disabled")}
					checked={currentParams.showDisabledLocations}
					onChange={async (checked) => {
						await refreshData({ showDisabledLocations: checked });
					}}
				/>,
			]}
			actionBarComponents={
				<>
					{printLabelsButton(i18n.t("shelf_labels"), PartWarehouseLocationPrintApi.printLabels)}
					{printLabelsButton(
						i18n.t("shelf_labels_small"),
						PartWarehouseLocationPrintApi.printSmallLabels,
					)}
				</>
			}
			rowContextMenuComponents={({ row, onlySingleRowSelected }) => [
				onlySingleRowSelected && !row.isDefault && (
					<AsyncButton
						key={"setToDefault"}
						label={i18n.t("set_to_default")}
						icon={faCheck}
						variant={"menu"}
						onClick={async () => {
							await PartWarehouseLocationActionsApi.setPartWarehouseLocationToDefault({
								partWarehouseLocationId: row.partWarehouseLocationId,
							});
							await refreshData();
						}}
					/>
				),
				onlySingleRowSelected && row.isActive && (
					<AsyncButton
						key={"deactivate"}
						label={i18n.t("deactivate")}
						icon={faBan}
						variant={"menu"}
						onClick={async () => {
							const confirmed = await showConfirmDialog();
							if (!confirmed) return;

							await PartWarehouseLocationActionsApi.deactivatePartWarehouseLocation({
								partWarehouseLocationId: row.partWarehouseLocationId,
							});
							await refreshData();
						}}
					/>
				),

				onlySingleRowSelected && !row.isActive && (
					<AsyncButton
						key={"activate"}
						label={i18n.t("activate")}
						icon={faUndo}
						variant={"menu"}
						onClick={async () => {
							const confirmed = await showConfirmDialog();
							if (!confirmed) return;

							await PartWarehouseLocationActionsApi.activatePartWarehouseLocation({
								partWarehouseLocationId: row.partWarehouseLocationId,
							});
							await refreshData();
						}}
					/>
				),
				onlySingleRowSelected && row.inventoryLevelEnabled && (
					<AsyncButton
						key={"warehouseEvents"}
						label={i18n.t("warehouse_events")}
						icon={faClipboardCheck}
						variant={"menu"}
						onClick={async () => {
							openLegacyView("10B3A4F30A54BFCD", {
								partWarehouseLocationId: row.partWarehouseLocationId,
							});
						}}
					/>
				),
			]}
			{...dataGridProps}
		/>
	);

	function printLabelsButton(
		label: string,
		apiFunction: (params: { partWarehouseLocationIds: number[] }) => Promise<FrontendDownloadFileHandle>,
	) {
		return (
			<AsyncButton
				label={label}
				icon={faBarcode}
				disabled={selectedRows.length === 0}
				variant={"outlined"}
				onClick={async () => {
					const fileHandle = await apiFunction({
						partWarehouseLocationIds: selectedRows.map((row) => row.partWarehouseLocationId),
					});
					downloadFile(fileHandle);
				}}
			/>
		);
	}
};
