// This file is automatically generated. Do not edit manually.

import { ShopOrderState } from "src/api/generated/erp/db/types/enums/shopOrderState";
import { ShopOrderLineAcquisitionMethod } from "src/api/generated/erp/db/types/enums/shopOrderLineAcquisitionMethod";
import { PurchaseOrderLineState } from "src/api/generated/erp/db/types/enums/purchaseOrderLineState";
import { IsoDateTimeString } from "src/types/dateTime";
import { ShopOrderLineViewPartType } from "src/api/generated/erp/db/types/enums/shopOrderLineViewPartType";
import { IsoDateString } from "src/types/dateTime";
import { ShopOrderLineState } from "src/api/generated/erp/db/types/enums/shopOrderLineState";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ShopOrderBomDataGridApi {
	export async function getGridData(args: {
		searchQuery: string;
		shopOrderId: number;
		filterByShopOrderOperationId?: number | null | undefined;
		includeCancelledLines?: boolean;
	}): Promise<ShopOrderBomDataGridApi_GridData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/shopOrderBom/getGridData",
			method: "POST",
			data: {
				searchQuery: args.searchQuery,
				shopOrderId: args.shopOrderId,
				filterByShopOrderOperationId: args.filterByShopOrderOperationId,
				includeCancelledLines: args.includeCancelledLines,
			},
		});
		return response as ShopOrderBomDataGridApi_GridData;
	}
}

export interface ShopOrderBomDataGridApi_GridData {
	lines: Array<ShopOrderBomDataGridApi_ShopOrderLineData>;
	shopOrderState: ShopOrderState;
}

export interface ShopOrderBomDataGridApi_ShopOrderLineData {
	acquisitionPurchaseOrderId: number | null | undefined;
	acquisitionShopOrderId: number | null | undefined;
	acquisitionMethod: ShopOrderLineAcquisitionMethod;
	acquisitionPurchaseOrderLineState: PurchaseOrderLineState | null | undefined;
	acquisitionShopOrderState: ShopOrderState | null | undefined;
	cancelledDate: IsoDateTimeString | null | undefined;
	configurationOrPartDescription: string;
	createdAt: IsoDateTimeString;
	lastUpdated: IsoDateTimeString;
	note: string;
	operationDescription: string | null | undefined;
	operationId: number | null | undefined;
	operationNo: string | null | undefined;
	partCategory: string | null | undefined;
	partCategoryDescription: string;
	partConfigurationId: number | null | undefined;
	partDescription_1: string;
	partDescription_2: string;
	partEstimatedLeadtime: number | null | undefined;
	partId: number;
	partIsConfigurable: boolean | null | undefined;
	partLongName: string | null | undefined;
	partNo: string;
	partType: ShopOrderLineViewPartType;
	partUnit: string | null | undefined;
	pickedQuantity: number;
	quantity: number;
	referenceNo: number | null | undefined;
	releasedAt: IsoDateTimeString | null | undefined;
	reservedQuantity: number;
	shopOrderDemandDate: IsoDateString | null | undefined;
	shopOrderId: number;
	shopOrderLineId: number;
	shopOrderLineState: ShopOrderLineState;
	shopOrderPlannedBeginDate: IsoDateTimeString | null | undefined;
	siteId: number;
	sourceRefData: string | null | undefined;
}
