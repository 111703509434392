import { OpenLegacyViewFunction } from "src/components/views/legacy/useOpenLegacyView.ts";
import { AavoObjectRef } from "src/api/generated/common/sourceType/aavoObjectRef.ts";
import { Dayjs } from "dayjs";

export function openLegacyCustomerOrderDeliveryView({
	openLegacyView,
	customerOrderId,
	customerOrderDeliveryId,
}: {
	openLegacyView: OpenLegacyViewFunction;
	customerOrderId: number;
	customerOrderDeliveryId?: number;
}) {
	openLegacyView("dacfe8c5-b910-4f3e-8d44-1b34e5d1d91d", {
		customer_order_id: customerOrderId,
		_customer_order_delivery_id_filter: customerOrderDeliveryId ?? -1,
	});
}

export async function openLegacyCustomerView({
	openLegacyView,
	customerId,
}: {
	openLegacyView: OpenLegacyViewFunction;
	customerId: number;
}) {
	openLegacyView("c2c12b20-9e60-4938-b01f-b89db4273bb1", {
		_customerIdFilter: customerId,
	});
}

export async function openLegacyDocumentSendingView({
	openLegacyView,
	objectRef,
}: {
	openLegacyView: OpenLegacyViewFunction;
	objectRef: AavoObjectRef;
}) {
	openLegacyView("CDDD616477CFC82E", {
		objectType: objectRef.objectType,
		objectId: objectRef.objectId,
	});
}

export async function openLegacyObjectChangeLogDataTableView({
	openLegacyView,
	objectRef,
}: {
	openLegacyView: OpenLegacyViewFunction;
	objectRef: AavoObjectRef;
}) {
	openLegacyView("DD6E864593E5311A", {
		_changeLogObjectType: objectRef.objectType,
		_changeLogObjectId: objectRef.objectId,
	});
}

export function openLegacyWorkspaceView({
	openLegacyView,
	workspaceIds,
}: {
	openLegacyView: OpenLegacyViewFunction;
	workspaceIds: number[];
}) {
	openLegacyView("E5EA27DCCC63FBB4", {
		_workspace_id_filter_list: JSON.stringify(workspaceIds),
	});
}

export function openLegacyCustomerOrderPreLineImportView({
	openLegacyView,
	targetType,
	targetId,
	targetPackageLineId,
}: {
	openLegacyView: OpenLegacyViewFunction;
	targetType: "CUSTOMER_ORDER" | "CUSTOMER_ORDER_OFFER";
	targetId: number;
	targetPackageLineId: number | null;
}) {
	openLegacyView("3477EC20B1F69BB2", {
		targetType: targetType,
		targetId: targetId,
		targetPackageLineId: targetPackageLineId,
	});
}

export function openLegacyControlChartLimitCalculationForm({
	openLegacyView,
	controlChartId,
	recordId,
	refreshSource,
}: {
	openLegacyView: OpenLegacyViewFunction;
	controlChartId: number;
	recordId?: number;
	refreshSource: () => void;
}) {
	openLegacyView(
		"171b53fd-b881-4189-8aed-f6fee5319684",
		{
			chart_id: controlChartId,
			record_id: recordId,
			_CALCULATE_FROM_RECORD_: recordId != null,
		},
		refreshSource,
	);
}

export function openLegacyControlChartLimitsDataView({
	openLegacyView,
	controlChartId,
}: {
	openLegacyView: OpenLegacyViewFunction;
	controlChartId: number;
}) {
	openLegacyView("7545ebd0-26d8-4ea1-b9f5-30de0e7fc398", {
		chart_id: controlChartId,
	});
}

export function openLegacyControlChartStatisticsView({
	openLegacyView,
	viewTitle,
	controlChartId,
	sampleSize,
	beforeDate,
}: {
	openLegacyView: OpenLegacyViewFunction;
	viewTitle: string;
	controlChartId: number;
	sampleSize: number;
	beforeDate?: Dayjs;
}) {
	openLegacyView("d08caec5-dc08-4705-9a30-425b9089177c", {
		chart_name: viewTitle,
		chart_id: controlChartId,
		sample_size: sampleSize,
		before_date: beforeDate?.toISOString() ?? null,
	});
}

export function openLegacyProcessImprovementView({
	openLegacyView,
	processImprovementId,
}: {
	openLegacyView: OpenLegacyViewFunction;
	processImprovementId: number;
}) {
	openLegacyView("6d2b012a-7f8e-43b3-8e00-9b57fcfff360", {
		_process_improvement_id_filter: processImprovementId,
	});
}

export function openLegacyConnectSpcRecordToImprovementView({
	openLegacyView,
	recordId,
	refreshSource,
}: {
	openLegacyView: OpenLegacyViewFunction;
	recordId: number;
	refreshSource: () => void;
}) {
	openLegacyView(
		"0c1be08b-03bb-417a-a303-38e40f94755a",
		{
			record_id: recordId,
		},
		refreshSource,
	);
}

export function openLegacyControlChartGroupBoxPlotView({
	openLegacyView,
	chartGroupId,
	chartGroupName,
}: {
	openLegacyView: OpenLegacyViewFunction;
	chartGroupId: number;
	chartGroupName: string;
}) {
	openLegacyView("7cfa2d04-88c1-4453-bd22-e0de8687e1f2", {
		chart_group_name: chartGroupName,
		chart_group_id: chartGroupId,
	});
}

export function openLegacyProductChangeRequestView({
	openLegacyView,
	productChangeRequestId,
}: {
	openLegacyView: OpenLegacyViewFunction;
	productChangeRequestId: number;
}) {
	openLegacyView("F49697C072CC73FF", {
		_product_change_request_id_filter: productChangeRequestId,
	});
}

export function openLegacySourceSetTasksView({
	openLegacyView,
	sourceSetType,
	sourceSetId,
}: {
	openLegacyView: OpenLegacyViewFunction;
	sourceSetType: "ProjectGroup" | "Project" | "SubProject" | "ActivityResource" | "ProjectActivity";
	sourceSetId: number;
}) {
	openLegacyView("AD2FA2826E4F9AC1", {
		_taskSourceSetType: sourceSetType,
		_tasksSourceSetId: sourceSetId,
	});
}

export function openLegacyTaskWeekPlanningView({
	openLegacyView,
	sourceSetType,
	sourceSetId,
}: {
	openLegacyView: OpenLegacyViewFunction;
	sourceSetType: "ProjectGroup" | "Project" | "SubProject" | "ActivityResource" | "ProjectActivity";
	sourceSetId: number;
}) {
	openLegacyView("A11B95237860E69C", {
		_taskSourceSetType: sourceSetType,
		_tasksSourceSetId: sourceSetId,
	});
}

export function openLegacySurveysOfSourcesWithDocumentView({
	openLegacyView,
	objectRefs,
}: {
	openLegacyView: OpenLegacyViewFunction;
	objectRefs: AavoObjectRef[];
}) {
	const sourceParams = objectRefs.map((object) => ({
		source_type: object.objectType.toLowerCase(),
		source_id: object.objectId,
	}));
	openLegacyView("DFA8566E238111D", {
		_sources: JSON.stringify(sourceParams),
	});
}

export function openLegacySingleShopOrderBatchContainerView({
	openLegacyView,
	shopOrderBatchId,
}: {
	openLegacyView: OpenLegacyViewFunction;
	shopOrderBatchId: number;
}) {
	openLegacyView("969408BD69B46F42", {
		shopOrderBatchId: shopOrderBatchId,
	});
}

export function openLegacyShopOrderStateView({
	openLegacyView,
	shopOrderId,
}: {
	openLegacyView: OpenLegacyViewFunction;
	shopOrderId: number;
}) {
	openLegacyView("b4d3d611-77ce-4af1-afe5-42a12ced76c6", {
		shop_order_id: shopOrderId,
	});
}

export function openLegacyWorkCenterWarehouseSupplyRequestContainerView({
	openLegacyView,
}: {
	openLegacyView: OpenLegacyViewFunction;
}) {
	openLegacyView("926E077D1932BD95", {});
}

export function openLegacyIncompleteOperationUserWorkcenterReportingForm({
	openLegacyView,
	shopOrderId,
	shopOrderOperationId,
	refreshSource,
}: {
	openLegacyView: OpenLegacyViewFunction;
	shopOrderId: number;
	shopOrderOperationId: number;
	refreshSource: () => void;
}) {
	openLegacyView(
		"DD073E7E8A87167",
		{
			shopOrderId: shopOrderId,
			shopOrderOperationId: shopOrderOperationId,
		},
		refreshSource,
	);
}

export function openLegacyCompletedOperationUserWorkcenterReportingForm({
	openLegacyView,
	shopOrderId,
	shopOrderOperationId,
	refreshSource,
}: {
	openLegacyView: OpenLegacyViewFunction;
	shopOrderId: number;
	shopOrderOperationId: number;
	refreshSource: () => void;
}) {
	openLegacyView(
		"733AF1AB5C9A2DD5",
		{
			shopOrderId: shopOrderId,
			shopOrderOperationId: shopOrderOperationId,
		},
		refreshSource,
	);
}

export function openLegacyWorkcenterOperationActionView({
	openLegacyView,
	shopOrderId,
	sourceRefData,
}: {
	openLegacyView: OpenLegacyViewFunction;
	shopOrderId: number;
	sourceRefData: string;
}) {
	openLegacyView("1def3123-3f51-49aa-8e4f-23913349d5d4", {
		shopOrderId: shopOrderId,
		sourceRefData: sourceRefData,
	});
}
