// This file is automatically generated. Do not edit manually.

import { PartView } from "src/api/generated/erp/db/types/tables/partView";
import { ShopOrder } from "src/api/generated/erp/db/types/tables/shopOrder";
import { ShopOrderView } from "src/api/generated/erp/db/types/tables/shopOrderView";
import { Site } from "src/api/generated/erp/db/types/tables/site";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ShopOrderFormApi {
	export async function getInitData(args: {
		shopOrderId: number | null | undefined;
	}): Promise<ShopOrderFormApi_InitData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/shopOrderForm/getInitData",
			method: "POST",
			data: {
				shopOrderId: args.shopOrderId,
			},
		});
		return response as ShopOrderFormApi_InitData;
	}

	export async function getPartSelectionOptions(args: {
		siteId: number;
		currentSelection?: number | null | undefined;
		searchQuery?: string;
	}): Promise<Array<PartView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/shopOrderForm/getPartSelectionOptions",
			method: "POST",
			data: {
				siteId: args.siteId,
				currentSelection: args.currentSelection,
				searchQuery: args.searchQuery,
			},
		});
		return response as Array<PartView>;
	}

	export async function insert(args: { shopOrder: ShopOrder; partId: number }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/shopOrderForm/insert",
			method: "POST",
			data: {
				shopOrder: args.shopOrder,
				partId: args.partId,
			},
		});
		return response as number;
	}

	export async function update(args: { shopOrder: ShopOrder }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/shopOrderForm/update",
			method: "POST",
			data: {
				shopOrder: args.shopOrder,
			},
		});
		return response as void;
	}
}

export interface ShopOrderFormApi_InitData {
	part: PartView | null | undefined;
	shopOrder: ShopOrderView | null | undefined;
	siteOptions: Array<Site>;
}
