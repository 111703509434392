import { FormCommonProps } from "../../../common/forms/types.ts";
import { Reclamation } from "src/api/generated/erp/db/types/tables/reclamation.ts";
import { DeepPartial } from "react-hook-form";
import { FormTextField } from "../../../common/forms/fields/FormTextField.tsx";
import i18n from "i18next";
import { requireRule } from "../../../common/forms/validation.ts";
import { FormSelectField } from "../../../common/forms/fields/FormSelectField.tsx";
import { FormDateField } from "../../../common/forms/fields/FormDateField.tsx";
import { Divider } from "@mui/material";
import { currentLocalDateAsIsoString } from "src/utils/dayjsUtils.ts";
import {
	ReclamationFormApi,
	ReclamationFormApi_InitData,
} from "src/api/generated/erp/reclamations/api/reclamationFormApi.ts";
import { getCountryLabels } from "src/api/generated/erp/db/types/enums/country.ts";
import { FormAsyncUserSelectField } from "src/components/views/users/FormAsyncUserSelectField.tsx";
import { FormEnumSelectField } from "../../../common/forms/fields/FormEnumSelectField.tsx";
import { AsyncForm, AsyncFormContentParams } from "src/components/common/forms/AsyncForm.tsx";

export interface ReclamationFormProps extends FormCommonProps<number> {
	reclamationId: number | undefined;
	customerOrderId: number | undefined;
}

export const ReclamationForm = (props: ReclamationFormProps) => {
	const { reclamationId, customerOrderId, onFormEdited, onCompleted } = props;
	return (
		<AsyncForm
			fetch={() =>
				ReclamationFormApi.getInitData({
					reclamationId: reclamationId,
					customerOrderId: customerOrderId,
				})
			}
			getDefaultValues={getDefaultValues}
			submit={submitForm}
			columns={3}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			render={(params) => <ReclamationFormContent {...props} {...params} />}
		/>
	);

	function getDefaultValues({
		reclamation,
		customerOrder,
		reclamationCategories,
		reclamationCauses,
		costEventPriceLists,
	}: ReclamationFormApi_InitData): DeepPartial<Reclamation> {
		return (
			reclamation ?? {
				plannedCompletedDate: currentLocalDateAsIsoString(),
				categoryId: reclamationCategories[0]?.reclamationCategoryId ?? undefined,
				causeId: reclamationCauses[0]?.reclamationCauseId ?? undefined,
				complainerName: customerOrder?.contactName,
				complainerPhone: customerOrder?.contactPhone,
				complainerEmail: customerOrder?.contactEmail,
				address: {
					address_1: customerOrder?.deliveryAddress_1,
					address_2: customerOrder?.deliveryAddress_2,
					city: customerOrder?.deliveryCity,
					postalCode: customerOrder?.deliveryPostalCode,
					country:
						customerOrder?.deliveryCountry != null ? customerOrder?.deliveryCountry : "FINLAND",
				},
				costEventPriceListId: costEventPriceLists.find(
					(priceList) => priceList.defaultForObjectType === "RECLAMATION",
				)?.costEventPriceListId,
			}
		);
	}

	async function submitForm(reclamation: Reclamation): Promise<number> {
		if (reclamation.reclamationId == null)
			return await ReclamationFormApi.insert({
				reclamation: reclamation,
				customerOrderId: customerOrderId,
			});
		else
			return await ReclamationFormApi.update({
				reclamation: reclamation,
			});
	}
};

interface ReclamationFormContentProps
	extends ReclamationFormProps,
		AsyncFormContentParams<ReclamationFormApi_InitData, Reclamation> {}

const ReclamationFormContent = ({
	control,
	data: { reclamationCategories, reclamationCauses, costEventPriceLists },
}: ReclamationFormContentProps) => {
	return (
		<>
			<FormTextField control={control} name={"title"} label={i18n.t("title")} rules={requireRule()} />
			<FormAsyncUserSelectField
				control={control}
				name={"responsiblePersonUserId"}
				label={i18n.t("responsible_person")}
				rules={requireRule()}
			/>
			<FormTextField control={control} name={"subjectCode"} label={i18n.t("subjectCode")} />
			<FormSelectField
				control={control}
				name={"categoryId"}
				label={i18n.t("category")}
				options={reclamationCategories}
				getOptionKey={(category) => category.reclamationCategoryId}
				getOptionLabel={(category) => category.name}
				rules={requireRule()}
			/>
			<FormSelectField
				control={control}
				name={"causeId"}
				label={i18n.t("cause")}
				options={reclamationCauses}
				getOptionKey={(cause) => cause.reclamationCauseId}
				getOptionLabel={(cause) => cause.name}
				rules={requireRule()}
			/>
			<FormDateField
				control={control}
				name={"plannedCompletedDate"}
				label={i18n.t("planned_completed_date")}
				rules={requireRule()}
			/>
			<FormTextField
				control={control}
				name={"description"}
				label={i18n.t("description")}
				multiline={true}
				minRows={2}
				sx={{
					gridColumn: "1 / -1",
				}}
			/>
			<FormTextField
				control={control}
				name={"demand"}
				label={i18n.t("demand")}
				multiline={true}
				minRows={2}
				sx={{
					gridColumn: "1 / -1",
				}}
			/>
			<FormTextField
				control={control}
				name={"act"}
				label={i18n.t("act")}
				multiline={true}
				minRows={2}
				sx={{
					gridColumn: "1 / -1",
				}}
			/>
			<FormSelectField
				control={control}
				name={"costEventPriceListId"}
				label={i18n.t("price_list")}
				options={costEventPriceLists}
				getOptionKey={(priceList) => priceList.costEventPriceListId}
				getOptionLabel={(priceList) => priceList.name}
			/>
			<Divider
				textAlign={"left"}
				sx={{
					gridColumn: "1 / -1",
				}}
			>
				{i18n.t("complainer")}
			</Divider>
			<FormTextField control={control} name={"complainerName"} label={i18n.t("complainer")} />
			<FormTextField control={control} name={"complainerEmail"} label={i18n.t("complainer_email")} />
			<FormTextField control={control} name={"complainerPhone"} label={i18n.t("complainer_phone")} />
			<Divider
				textAlign={"left"}
				sx={{
					gridColumn: "1 / -1",
				}}
			>
				{i18n.t("address")}
			</Divider>
			<FormTextField control={control} name={"address.address_1"} label={i18n.t("street_address")} />
			<FormTextField control={control} name={"address.address_2"} label={i18n.t("address_2")} />
			<FormTextField control={control} name={"address.city"} label={i18n.t("city")} />
			<FormTextField control={control} name={"address.postalCode"} label={i18n.t("postal_code")} />
			<FormEnumSelectField
				control={control}
				name={"address.country"}
				label={i18n.t("country")}
				options={getCountryLabels()}
			/>
		</>
	);
};
