import React, { useState } from "react";
import { useAddressAutofillCore } from "@mapbox/search-js-react";
import { getMapboxAccessToken } from "src/config/appConfig.ts";
import { SxProps, Theme } from "@mui/material/styles";
import { defaultProximity } from "src/components/common/mapbox/mapboxUtils.ts";
import i18n from "i18next";
import { Autocomplete, CircularProgress, TextField, Typography } from "@mui/material";
import { CustomPopper } from "src/components/common/popper/CustomPopper.tsx";
import { useDebounce } from "src/utils/useDebounce.ts";
import { useAsyncFetch } from "src/utils/async/asyncFetch.ts";
import { useErrorDialog } from "src/components/common/dialogs/errorDialog/userErrorDialog.ts";
import { AddressAutofillSuggestion } from "@mapbox/search-js-core/dist/autofill/types";
import { mergeSx } from "src/utils/styles.ts";
import { nullIfBlank } from "src/utils/strings.tsx";
import { HorizontalBox } from "../box/HorizontalBox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/pro-regular-svg-icons";

export interface AavoAddressAutofillProps {
	label: string;
	sx?: SxProps<Theme>;
	error?: string;
	defaultValue?: string;
	onSelectLocation: (value: AddressAutofillSuggestion) => string | undefined;
	onSubmitFreeText: (value: string) => void;
	disabled?: boolean;
}

interface FetchSuggestionsParams {
	input: string;
}

export const AavoAddressAutofill = ({
	label,
	onSelectLocation,
	onSubmitFreeText,
	defaultValue,
	error,
	sx,
	disabled = false,
}: AavoAddressAutofillProps) => {
	const { logErrorAndShowOnDialog } = useErrorDialog();

	const [inputText, setInputText] = useState<string>("");
	const [value, setValue] = React.useState<string | AddressAutofillSuggestion | null>(defaultValue ?? null);

	const debounceInput = useDebounce();

	const mapboxAutofill = useAddressAutofillCore({
		accessToken: getMapboxAccessToken(),
		language: i18n.language,
		country: "fi",
		proximity: defaultProximity,
	});

	const [optionsAsync, fetchOptions] = useAsyncFetch<AddressAutofillSuggestion[], FetchSuggestionsParams>(
		async (params) => {
			try {
				const input = params?.input ?? inputText;
				if (nullIfBlank(input) == null) return [];

				const response = await mapboxAutofill.suggest(input, {
					sessionToken: "default",
				});
				return response.suggestions;
			} catch (e) {
				logErrorAndShowOnDialog(e);
				return [];
			}
		},
		{
			fetchOnMount: false,
		},
	);

	return (
		<Autocomplete
			options={optionsAsync.data || []}
			filterOptions={(x) => x}
			value={value}
			getOptionLabel={(option) => (typeof option === "string" ? option : (option.place_name ?? ""))}
			autoComplete
			freeSolo
			onChange={async (_, value) => {
				if (typeof value === "string") {
					onSubmitFreeText(inputText);
					setValue(value);
				} else if (value === null) {
					onSubmitFreeText("");
					setValue(value);
				} else {
					const requestedText = onSelectLocation(value);
					if (requestedText != null) setValue(requestedText);
				}
			}}
			onInputChange={(_, newInputValue) => {
				onInputChanged(newInputValue, true);
			}}
			onBlur={() => {
				if (typeof value === "string") {
					onSubmitFreeText(inputText);
				}
			}}
			includeInputInList
			filterSelectedOptions
			PopperComponent={(defaultPopperProps) => <CustomPopper {...defaultPopperProps} />}
			sx={mergeSx(sx)}
			disabled={disabled}
			renderInput={(params) => {
				return (
					<TextField
						{...params}
						label={label}
						error={error !== undefined}
						helperText={error}
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<>
									{optionsAsync.loading && <CircularProgress size={20} color={"inherit"} />}
									{params.InputProps.endAdornment}
								</>
							),
						}}
					/>
				);
			}}
			renderOption={(props, option) => {
				const { key, ...optionProps } = props;
				return (
					<li key={key} {...optionProps}>
						<HorizontalBox gap={1.5} marginY={0.5} alignItems={"center"}>
							<FontAwesomeIcon icon={faLocationDot} />
							<Typography>{option.place_name}</Typography>
						</HorizontalBox>
					</li>
				);
			}}
		/>
	);

	function onInputChanged(newInput: string, shouldRefreshOptions: boolean) {
		setInputText(newInput);
		if (shouldRefreshOptions)
			debounceInput(200, async () => {
				await fetchOptions({
					input: newInput,
				});
			});
	}
};
