import {ServerSideDataGridModel} from "src/components/common/dataGrid/gridModel/ServerSideDataGridModel.tsx";
import {genericNullableValue} from "src/utils/genericNullableValue.ts";
import {WarehouseTransferView} from "src/api/generated/erp/db/types/tables/warehouseTransferView.ts";
import {
    getWarehouseTransferStateLabel,
    getWarehouseTransferStateLabels,
    WarehouseTransferState,
    WarehouseTransferStateValues,
} from "src/api/generated/erp/db/types/enums/warehouseTransferState.ts";
import {CrudDataGrid} from "src/components/common/dataGrid/crud/CrudDataGrid.tsx";
import {
    dateColumn,
    dateTimeColumn,
    enumColumn,
    floatColumn,
    textColumn,
} from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import {SelectField} from "src/components/common/inputFields/SelectField.tsx";
import {AavoTextField} from "src/components/common/inputFields/AavoTextField.tsx";
import {useRefreshRef} from "src/utils/useRefreshRef.ts";
import {
    WarehouseTransferDataGridApi,
    WarehouseTransferDataGridApi_DataGridData,
} from "src/api/generated/erp/warehouse/warehouseTransfer/api/warehouseTransferDataGridApi.ts";
import {faBan} from "@fortawesome/pro-regular-svg-icons";
import {
    WarehouseTransferActionApi
} from "src/api/generated/erp/warehouse/warehouseTransfer/api/warehouseTransferActionApi.ts";
import {AsyncMenuButton} from "src/components/common/contextMenu/AsyncMenuButton.tsx";

interface WarehouseTransferFetchDataParams {
	transferState: WarehouseTransferState;
	requestingSiteId: number | null;
	requestingWarehouseLocationCode: string | null;
	searchQuery: string;
}

export const WarehouseTransferDataGrid = () => {
	const refreshRef = useRefreshRef();
	return (
		<ServerSideDataGridModel<
			WarehouseTransferDataGridApi_DataGridData,
			WarehouseTransferView,
			WarehouseTransferFetchDataParams
		>
			gridId={"A9D9B269DE120A45C6"}
			fetchData={WarehouseTransferDataGridApi.getWarehouseTransfers}
			getDataModelResult={(data) => data.data}
			initialParams={{
				transferState: "INITIAL",
				requestingSiteId: genericNullableValue<number>(),
				requestingWarehouseLocationCode: genericNullableValue<string>(),
				searchQuery: "",
			}}
			refreshRef={refreshRef}
			getRowId={(row) => row.warehouseTransferId}
			render={({ data: { siteOptions }, dataGridProps, refreshData, currentParams }) => {
				return (
					<CrudDataGrid<WarehouseTransferView>
						columns={[
							textColumn({
								field: "warehouseTransferId",
								headerName: i18n.t("number_short"),
								width: 60,
							}),
							dateColumn({
								field: "transferRequestDate",
								headerName: i18n.t("request_date_short"),
							}),
							textColumn({
								field: "partLongName",
								headerName: i18n.t("part"),
								width: 300,
							}),
							floatColumn({
								field: "quantity",
								headerName: i18n.t("quantity"),
								width: 60,
							}),
							textColumn({
								field: "requestingSiteName",
								headerName: i18n.t("requesting_site_name"),
								width: 140,
							}),
							textColumn({
								field: "warehouseTransferUnit",
								headerName: i18n.t("unit"),
								width: 80,
							}),
							textColumn({
								field: "requestingWarehouseLocationCode",
								headerName: i18n.t("requesting_warehouse_location_code"),
								width: 150,
							}),
							textColumn({
								field: "info",
								headerName: i18n.t("info"),
							}),
							textColumn({
								field: "operator",
								headerName: i18n.t("operator"),
							}),
							dateTimeColumn({
								field: "reservedDate",
								headerName: i18n.t("reserved_date_short"),
								width: 120,
							}),
							dateTimeColumn({
								field: "pickedDate",
								headerName: i18n.t("picked_date_short"),
								width: 120,
							}),
							dateTimeColumn({
								field: "transferredDate",
								headerName: i18n.t("transferred_date_short"),
								width: 120,
							}),
							enumColumn({
								field: "warehouseTransferState",
								headerName: i18n.t("state"),
								enumLabels: getWarehouseTransferStateLabels(),
							}),
							textColumn({
								field: "warehousePickerGroupCode",
								headerName: i18n.t("warehouse_picker_group_code"),
								width: 150,
							}),
							textColumn({
								field: "givingWarehouseLocationCode",
								headerName: i18n.t("giving_warehouse_location_code"),
								width: 150,
							}),
							textColumn({
								field: "createdBy",
								headerName: i18n.t("created_by"),
								width: 150,
							}),
							textColumn({
								field: "givingSiteName",
								headerName: i18n.t("giving_site_name"),
								width: 150,
							}),
						]}
						actionBarComponents={
							<>
								<SelectField
									label={i18n.t("requesting_site")}
									options={siteOptions}
									getOptionKey={(o) => o.siteId}
									getOptionLabel={(o) => o.siteName}
									onChange={async (value) => {
										await refreshData({ requestingSiteId: value });
									}}
									minWidth={200}
								/>
								<SelectField
									label={i18n.t("state")}
									options={WarehouseTransferStateValues}
									value={currentParams.transferState}
									getOptionKey={(o) => o}
									getOptionLabel={(o) => getWarehouseTransferStateLabel(o)}
									onChange={async (value) => {
										await refreshData({ transferState: value });
									}}
									disableClearable={true}
								/>
								<AavoTextField
									label={i18n.t("warehouse_location")}
									onSubmit={(value) =>
										refreshData({ requestingWarehouseLocationCode: value })
									}
								/>
								<AavoTextField
									label={i18n.t("search")}
									onSubmit={(value) => refreshData({ searchQuery: value })}
								/>
							</>
						}
						rowContextMenuComponents={({ row }) => [
							<AsyncMenuButton
								key={"cancelWarehouseTransfer"}
								icon={faBan}
								label={i18n.t("cancel")}
								onClick={async () => {
									await WarehouseTransferActionApi.cancelWarehouseTransfers({
										warehouseTransferIds: [row.warehouseTransferId],
									});
									await refreshRef.refresh();
								}}
								disabled={
									(
										!(
											row.warehouseTransferState == "INITIAL" ||
											row.warehouseTransferState == "RELEASED"
										)
									) ?
										i18n.t("only_initial_or_released_transfer_can_be_canceled")
									:	false
								}
							/>,
						]}
						{...dataGridProps}
					/>
				);
			}}
		/>
	);
};
