import { Project } from "src/api/generated/erp/db/types/tables/project.ts";
import { FormCommonProps } from "src/components/common/forms/types.ts";
import { AsyncForm, AsyncFormContentParams } from "src/components/common/forms/AsyncForm.tsx";
import {
	ProjectEditApi,
	ProjectEditApi_FormInitData,
} from "src/api/generated/erp/project/project/api/projectEditApi.ts";
import { DeepPartial } from "react-hook-form";
import { useGlobalInitData } from "src/contexts/useGlobalInitData.ts";
import { FormSelectField } from "src/components/common/forms/fields/FormSelectField.tsx";
import i18n from "i18next";
import { requireRule } from "src/components/common/forms/validation.ts";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import { FormAsyncUserSelectField } from "src/components/views/users/FormAsyncUserSelectField.tsx";
import { FormDateField } from "src/components/common/forms/fields/FormDateField.tsx";
import { FormCheckbox } from "src/components/common/forms/fields/FormCheckbox.tsx";
import { FormSection } from "src/components/common/forms/FormSection.tsx";

export interface ProjectFormProps extends FormCommonProps<number> {
	projectId: number | null;
}

interface FormValues extends Project {
	inheritScheduleChange: boolean;
}

export const ProjectForm = (props: ProjectFormProps) => {
	const { projectId, onCompleted, onFormEdited } = props;
	const { defaultSiteId } = useGlobalInitData();

	return (
		<AsyncForm
			fetch={() => ProjectEditApi.getFormInitData({ projectId })}
			getDefaultValues={getDefaultValues}
			submit={submit}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			columns={3}
			layoutSx={{
				marginTop: 1,
			}}
			render={(contentParams) => <FormContent {...props} {...contentParams} />}
		/>
	);

	function getDefaultValues({
		project,
		projectTypeOptions,
	}: ProjectEditApi_FormInitData): DeepPartial<FormValues> {
		if (project != null) {
			return {
				...project,
				inheritScheduleChange: true,
			};
		} else {
			return {
				siteId: defaultSiteId,
				projectTypeId: projectTypeOptions[0]?.projectTypeId,
			};
		}
	}

	async function submit({ inheritScheduleChange, ...project }: FormValues) {
		if (projectId != null) {
			await ProjectEditApi.update({
				project,
				inheritScheduleChange,
			});
			return projectId;
		} else {
			return await ProjectEditApi.insert({
				project,
			});
		}
	}
};

interface FormContentProps
	extends ProjectFormProps,
		AsyncFormContentParams<ProjectEditApi_FormInitData, FormValues> {}

const FormContent = ({
	projectId,
	control,
	formState: { dirtyFields },
	data: { siteOptions, projectGroupOptions, projectTypeOptions, costEventPriceListOptions },
}: FormContentProps) => {
	const isExistingRecord = projectId != null;
	return (
		<>
			<FormSelectField
				control={control}
				name={"siteId"}
				label={i18n.t("site")}
				options={siteOptions}
				getOptionKey={(o) => o.siteId}
				getOptionLabel={(o) => o.siteName}
				rules={requireRule()}
				disableClearable
			/>
			<FormSelectField
				control={control}
				name={"projectTypeId"}
				label={i18n.t("type")}
				options={projectTypeOptions}
				getOptionKey={(o) => o.projectTypeId}
				getOptionLabel={(o) => o.projectType}
				rules={requireRule()}
				disableClearable
			/>
			<FormSelectField
				control={control}
				name={"groupId"}
				label={i18n.t("group")}
				options={projectGroupOptions}
				getOptionKey={(o) => o.projectGroupId}
				getOptionLabel={(o) => o.name}
			/>
			<FormTextField
				control={control}
				name={"projectDescription"}
				label={i18n.t("name")}
				rules={requireRule()}
				spanGridColumns
			/>
			<FormTextField
				control={control}
				name={"externalId"}
				label={i18n.t("external_id")}
				spanGridColumns
			/>
			<FormAsyncUserSelectField
				control={control}
				name={"responsiblePersonId"}
				label={i18n.t("responsible_person")}
				rules={requireRule()}
			/>
			<FormSelectField
				control={control}
				name={"costEventPriceListId"}
				label={i18n.t("price_list")}
				options={costEventPriceListOptions}
				getOptionKey={(o) => o.costEventPriceListId}
				getOptionLabel={(o) => o.name}
			/>
			<FormDateField
				control={control}
				name={"plannedStartDate"}
				label={i18n.t("planned_start")}
				rules={requireRule()}
				startNewGridRow
			/>
			<FormDateField
				control={control}
				name={"plannedEndDate"}
				label={i18n.t("planned_end")}
				rules={requireRule()}
			/>
			{isExistingRecord && dirtyFields.plannedStartDate && (
				<FormCheckbox
					control={control}
					name={"inheritScheduleChange"}
					label={i18n.t("inherit_schedule_change_to_lower_level_items")}
					spanGridColumns
				/>
			)}
			<FormTextField
				control={control}
				name={"comment"}
				label={i18n.t("comment")}
				multiline
				spanGridColumns
			/>
			<FormCheckbox control={control} name={"isTemplate"} label={i18n.t("template_project")} />
			<FormSection label={i18n.t("address")}>
				<FormTextField control={control} name={"address_1"} label={i18n.t("address_1")} />
				<FormTextField control={control} name={"address_2"} label={i18n.t("address_2")} />
				<FormTextField control={control} name={"postalCode"} label={i18n.t("postal_code")} />
				<FormTextField control={control} name={"city"} label={i18n.t("city")} />
				<FormTextField control={control} name={"addressName"} label={i18n.t("address_description")} />
				<FormTextField
					control={control}
					name={"addressContact"}
					label={i18n.t("contact")}
					multiline
					spanGridColumns
				/>
			</FormSection>
		</>
	);
};
