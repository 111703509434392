import { SpcSamplingMethod } from "src/components/views/spc/basedata/controlCharts/form/samplingMethod/SpcSamplingMethod.tsx";
import i18n from "i18next";
import { FormLazySelectField } from "src/components/common/forms/fields/FormLazySelectField.tsx";
import { SubProjectApi } from "src/api/generated/erp/project/subProject/api/subProjectApi.ts";
import { requireRule } from "src/components/common/forms/validation.ts";

export const getSubProjectDurationSpcSamplingMethod = (): SpcSamplingMethod => {
	return {
		key: "sub_project_duration",
		label: i18n.t("sub_project_duration"),
		renderParameterFields: ({ control }) => (
			<>
				<FormLazySelectField
					control={control}
					name={"samplingParams.param1"}
					label={i18n.t("sub_project_type")}
					fetchOptions={SubProjectApi.getSubProjectTypes}
					getOptionKey={(o) => o.subProjectTypeId.toString()}
					getOptionLabel={(o) => o.name}
					rules={requireRule()}
				/>
			</>
		),
	};
};
