import { CustomerOrderDeliveriesDataGridBase } from "./CustomerOrderDeliveriesDataGridBase";
import { DocumentsOfObjectButton } from "src/components/views/documents/objectDocuments/DocumentsOfObjectButton.tsx";
import { nullableAavoObjectRef } from "src/utils/aavoObjectRefUtils.ts";
import i18n from "i18next";
import { CustomerOrderDeliveryView } from "src/api/generated/erp/db/types/tables/customerOrderDeliveryView.ts";

export interface CustomerOrderDeliveriesDataGridProps {
	customerOrderId: number;
	setSelectedDelivery: (selectedDelivery: CustomerOrderDeliveryView | undefined) => void;
	onlyCustomerOrderDeliveryId?: number;
}

export const CustomerOrderDeliveriesDataGrid = ({
	customerOrderId,
	setSelectedDelivery,
	onlyCustomerOrderDeliveryId,
}: CustomerOrderDeliveriesDataGridProps) => {
	return (
		<CustomerOrderDeliveriesDataGridBase
			showCustomerFilter={false}
			showSearchField={false}
			showOnlyUndeliveredByDefault={false}
			customerOrderId={customerOrderId}
			onlyCustomerOrderDeliveryId={onlyCustomerOrderDeliveryId}
			onSelectionChanged={(selectedDelivery) => {
				setSelectedDelivery(selectedDelivery);
			}}
			actionBarExtraComponents={
				<>
					<DocumentsOfObjectButton
						label={i18n.t("order_documents")}
						objectRef={nullableAavoObjectRef("CUSTOMER_ORDER", customerOrderId)}
						variant={"outlined"}
					/>
				</>
			}
		/>
	);
};
