// This file is automatically generated. Do not edit manually.

import { FrontendDownloadFileHandle } from "src/api/generated/common/frontendDownloadFileHandle";
import { FrontendUploadFileHandle } from "src/api/generated/common/frontendUploadFileHandle";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace WinnerToFusionApi {
	export async function downloadWinnerDesignAscii(args: {
		designGuid: string;
		shopGuid: string;
	}): Promise<FrontendDownloadFileHandle> {
		const response = await makeAxiosRequest({
			url: "/api/ext/mellano/winner/downloadWinnerDesignAscii",
			method: "POST",
			data: {
				designGuid: args.designGuid,
				shopGuid: args.shopGuid,
			},
		});
		return response as FrontendDownloadFileHandle;
	}

	export async function convertWinnerDesignToLuukkuXml(args: {
		designGuid: string;
		shopGuid: string;
	}): Promise<FrontendDownloadFileHandle> {
		const response = await makeAxiosRequest({
			url: "/api/ext/mellano/winner/convertWinnerDesignToLuukkuXml",
			method: "POST",
			data: {
				designGuid: args.designGuid,
				shopGuid: args.shopGuid,
			},
		});
		return response as FrontendDownloadFileHandle;
	}

	export async function convertWinnerAsciiToLuukkuXml(args: {
		fileHandle: FrontendUploadFileHandle;
	}): Promise<FrontendDownloadFileHandle> {
		const response = await makeAxiosRequest({
			url: "/api/ext/mellano/winner/convertWinnerAsciiToLuukkuXml",
			method: "POST",
			data: {
				fileHandle: args.fileHandle,
			},
		});
		return response as FrontendDownloadFileHandle;
	}
}
