import { PartialTenantCustomizations } from "src/tenantCustomizations/TenantCustomizations";
import { getRandomValueSamplingMethod } from "src/tenantCustomizations/tenants/demo1/spcSamplingMethods/randomValueSamplingMethod.tsx";

export const demo1Customizations: PartialTenantCustomizations = {
	tenantConfig: {
		erp: {
			enabled: true,
			customerOrderCheckingEnabled: true,
			reclamationsEnabled: true,
			customerOrderPreLineImportEnabled: true,
			configuratorEnabled: true,
			projectActivitySchedulingEnabled: true,
		},
		spc: {
			processImprovementsEnabled: true,
		},
		tasks: {
			enabled: true,
		},
		documentsEnabled: true,
	},
	spcSamplingMethods: () => [getRandomValueSamplingMethod()],
} as const;
