import {
	CustomerOrderPackagesDataGridBase,
	CustomerOrderPackagesDataGridBaseProps,
} from "./CustomerOrderPackagesDataGridBase.tsx";
import { PickRequired } from "src/utils/pickRequired.ts";
import { RefreshableElementRef } from "src/utils/useRefreshRef.ts";

export interface CustomerOrderUndeliveredPackagesDataGridProps
	extends PickRequired<
			CustomerOrderPackagesDataGridBaseProps,
			| "gridId"
			| "onSelectedPackageChanged"
			| "customerOrderId"
			| "selectedRows"
			| "showCustomerOrderDocumentsButton"
		>,
		Pick<
			CustomerOrderPackagesDataGridBaseProps,
			| "showStateChangeActions"
			| "showSearchField"
			| "showCalculateWeightButton"
			| "showPrintPackageLabelButton"
			| "addEnabled"
			| "editable"
			| "actionBarExtraComponents"
		> {
	pickableLinesDataGridRefreshRef?: RefreshableElementRef;
	deliveryPackagesRefreshRef?: RefreshableElementRef;
	refreshRef?: RefreshableElementRef;
}

export const CustomerOrderUndeliveredPackagesDataGrid = ({
	gridId,
	customerOrderId,
	onSelectedPackageChanged,
	pickableLinesDataGridRefreshRef,
	selectedRows,
	showCustomerOrderDocumentsButton,
	showStateChangeActions = true,
	showSearchField = true,
	showCalculateWeightButton = true,
	showPrintPackageLabelButton = true,
	addEnabled = true,
	editable = true,
	actionBarExtraComponents,
	refreshRef,
}: CustomerOrderUndeliveredPackagesDataGridProps) => {
	return (
		<CustomerOrderPackagesDataGridBase
			gridId={gridId}
			customerOrderId={customerOrderId}
			customerOrderDeliveryId={null}
			onSelectedPackageChanged={onSelectedPackageChanged}
			selectedRows={selectedRows}
			showCustomerOrderDocumentsButton={showCustomerOrderDocumentsButton}
			refreshRef={refreshRef}
			showSetStateContextMenuButtons={false}
			addEnabled={addEnabled}
			editable={editable}
			showStateChangeActions={showStateChangeActions}
			showSearchField={showSearchField}
			showCalculateWeightButton={showCalculateWeightButton}
			showPrintPackageLabelButton={showPrintPackageLabelButton}
			actionBarExtraComponents={actionBarExtraComponents}
			onStateChanged={() => {
				pickableLinesDataGridRefreshRef?.refresh();
			}}
		/>
	);
};
