// This file is automatically generated. Do not edit manually.

import { IsoDateString } from "src/types/dateTime";
import { InvoiceLineRefundRequest } from "src/api/generated/erp/sales/invoicing/service/invoiceLineRefundRequest";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace InvoiceCreationApi {
	export async function createInvoiceFromCustomerOrderBillingPlanLine(args: {
		customerOrderBillingPlanLineId: number;
		invoiceDate: IsoDateString;
	}): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/invoicing/createInvoiceFromCustomerOrderBillingPlanLine",
			method: "POST",
			data: {
				customerOrderBillingPlanLineId: args.customerOrderBillingPlanLineId,
				invoiceDate: args.invoiceDate,
			},
		});
		return response as number;
	}

	export async function createInvoiceFromCustomerOrderDelivery(args: {
		customerOrderDeliveryId: number;
		invoiceDate: IsoDateString;
		appendCustomerOrderLineIds: Array<number>;
	}): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/invoicing/createInvoiceFromCustomerOrderDelivery",
			method: "POST",
			data: {
				customerOrderDeliveryId: args.customerOrderDeliveryId,
				invoiceDate: args.invoiceDate,
				appendCustomerOrderLineIds: args.appendCustomerOrderLineIds,
			},
		});
		return response as number;
	}

	export async function createInvoiceFromCustomerOrderLines(args: {
		customerOrderId: number;
		customerOrderLineIds: Array<number>;
		invoiceDate: IsoDateString;
	}): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/invoicing/createInvoiceFromCustomerOrderLines",
			method: "POST",
			data: {
				customerOrderId: args.customerOrderId,
				customerOrderLineIds: args.customerOrderLineIds,
				invoiceDate: args.invoiceDate,
			},
		});
		return response as number;
	}

	export async function createRefundInvoice(args: {
		chargeInvoiceId: number;
		refundInvoiceDate: IsoDateString;
		invoiceLineRefundRequests: Array<InvoiceLineRefundRequest>;
	}): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/invoicing/createRefundInvoice",
			method: "POST",
			data: {
				chargeInvoiceId: args.chargeInvoiceId,
				refundInvoiceDate: args.refundInvoiceDate,
				invoiceLineRefundRequests: args.invoiceLineRefundRequests,
			},
		});
		return response as number;
	}

	export async function cancelInvoice(args: { chargeInvoiceId: number }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/invoicing/cancelInvoice",
			method: "POST",
			data: {
				chargeInvoiceId: args.chargeInvoiceId,
			},
		});
		return response as number;
	}
}
