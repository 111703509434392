import { Primitive } from "src/types/primitive";
import { SelectField, SelectFieldProps } from "src/components/common/inputFields/SelectField";
import { ConditionalNullable } from "../../inputFields/types";
import { FieldPath, FieldPathValue, useController, UseControllerProps } from "react-hook-form";
import { FormFieldLayoutProps, formFieldLayoutPropsToSx } from "src/components/common/forms/styles.ts";
import { mergeSx } from "src/utils/styles.ts";

export interface FormSelectFieldProps<
	TFieldValues extends object,
	TFieldName extends FieldPath<TFieldValues>,
	Key extends Primitive,
	TOption,
	DisableClearable extends boolean | undefined,
> extends Omit<SelectFieldProps<TOption, Key, DisableClearable>, "value">,
		Pick<UseControllerProps<TFieldValues, TFieldName>, "control" | "name" | "rules">,
		FormFieldLayoutProps {}

export const FormSelectField = <
	TFieldValues extends object,
	TFieldName extends FieldPath<TFieldValues>,
	Key extends Primitive,
	TOption,
	DisableClearable extends boolean | undefined,
>({
	control,
	name,
	rules,
	onChange,
	spanGridColumns,
	startNewGridRow,
	sx,
	...other
}: FormSelectFieldProps<TFieldValues, TFieldName, Key, TOption, DisableClearable>) => {
	const { field, fieldState } = useController({
		name,
		control,
		rules,
		defaultValue: null as FieldPathValue<TFieldValues, TFieldName>,
	});

	const transformedField = {
		...field,
		onChange: (key: Key | null, option: TOption | null) => {
			field.onChange(key);
			onChange?.(
				key as ConditionalNullable<Key, DisableClearable>,
				option as ConditionalNullable<TOption, DisableClearable>,
			);
		},
		ref: undefined,
	};

	return (
		<SelectField
			error={fieldState.error?.message}
			sx={mergeSx(
				formFieldLayoutPropsToSx({
					spanGridColumns,
					startNewGridRow,
				}),
				sx,
			)}
			{...transformedField}
			{...other}
		/>
	);
};
