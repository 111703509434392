import { ClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel.tsx";
import { CrudDataGrid } from "src/components/common/dataGrid/crud/CrudDataGrid.tsx";
import {
	dateTimeColumn,
	enumColumn,
	floatColumn,
	integerColumn,
	textColumn,
} from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { concatWithPipe } from "src/utils/strings.tsx";
import { getShopOrderOperationStateLabels } from "src/api/generated/erp/db/types/enums/shopOrderOperationState.ts";
import { ShopOrderOperationView } from "src/api/generated/erp/db/types/tables/shopOrderOperationView.ts";
import { DocumentsOfObjectButton } from "src/components/views/documents/objectDocuments/DocumentsOfObjectButton.tsx";
import { nullableAavoObjectRef } from "src/utils/aavoObjectRefUtils.ts";
import { faAngleDown, faAngleUp } from "@fortawesome/pro-regular-svg-icons";
import { ShopOrderOperationEditApi } from "src/api/generated/erp/production/api/shopOrderOperation/shopOrderOperationEditApi.ts";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { AavoTextField } from "src/components/common/inputFields/AavoTextField.tsx";
import { ImportDocumentsToShopOrderOperationsButton } from "src/components/views/erp/production/shopOrderOperation/ImportDocumentsToShopOrderOperationsButton.tsx";
import { ShopOrderOperationForm } from "src/components/views/erp/production/shopOrderOperation/ShopOrderOperationForm.tsx";
import { ShopOrderOperationDataGridApi } from "src/api/generated/erp/production/api/shopOrderOperation/shopOrderOperationDataGridApi.ts";

export interface ShopOrderOperationsDataGridProps {
	shopOrderId: number;
}

export const ShopOrderOperationsDataGrid = ({ shopOrderId }: ShopOrderOperationsDataGridProps) => {
	return (
		<ClientSideDataGridModel
			gridId={"EE371799EFA8D206"}
			fetchData={async ({ searchQuery }) =>
				ShopOrderOperationDataGridApi.getData({
					searchQuery: searchQuery,
					shopOrderId: shopOrderId,
				})
			}
			getRows={(data) => data.shopOrderOperations}
			getRowId={(row) => row.shopOrderOperationId}
			initialParams={{
				shopOrderId: shopOrderId,
				searchQuery: "",
			}}
			render={({ data: { shopOrder }, dataGridProps, onlySelectedRow, refreshData }) => {
				const shopOrderIsClosed =
					shopOrder.shopOrderState === "READY" || shopOrder.shopOrderState === "CANCELLED";
				return (
					<CrudDataGrid<ShopOrderOperationView>
						columns={[
							integerColumn({
								field: "position",
								headerName: i18n.t("number_short"),
								width: 50,
							}),
							textColumn({
								field: "operationId",
								headerName: i18n.t("operation"),
								width: 150,
								valueGetter: (_, row) =>
									concatWithPipe(row.operationNo, row.operationDescription),
							}),
							dateTimeColumn({
								field: "actualBeginDate",
								headerName: i18n.t("actual_begin_date"),
								width: 150,
							}),
							dateTimeColumn({
								field: "actualEndDate",
								headerName: i18n.t("actual_end_date"),
								width: 150,
							}),
							textColumn({
								field: "operator",
								headerName: i18n.t("shop_order_operation.operator"),
							}),
							enumColumn({
								field: "shopOrderOperationState",
								headerName: i18n.t("state"),
								enumLabels: getShopOrderOperationStateLabels(),
							}),
							textColumn({
								field: "materialOperationId",
								headerName: i18n.t("material_operation"),
								width: 150,
								valueGetter: (_, row) =>
									concatWithPipe(row.materialOperationNo, row.materialOperationDescription),
							}),
							floatColumn({
								field: "setUpTime",
								headerName: i18n.t("set_up_time"),
							}),
							floatColumn({
								field: "workingTime",
								headerName: i18n.t("working_time"),
							}),
						]}
						actionBarComponents={
							<>
								<DocumentsOfObjectButton
									objectRef={nullableAavoObjectRef(
										"SHOP_ORDER_OPERATION",
										onlySelectedRow?.shopOrderOperationId,
									)}
								/>
								<AavoTextField
									label={i18n.t("search")}
									onSubmit={(value) => refreshData({ searchQuery: value })}
								/>
								<AsyncButton
									icon={faAngleUp}
									disabled={!onlySelectedRow}
									onClick={async () => {
										await ShopOrderOperationEditApi.movePosition({
											shopOrderOperationId: onlySelectedRow!.shopOrderOperationId,
											direction: "UP",
										});
										await refreshData();
									}}
								/>
								<AsyncButton
									icon={faAngleDown}
									disabled={!onlySelectedRow}
									onClick={async () => {
										await ShopOrderOperationEditApi.movePosition({
											shopOrderOperationId: onlySelectedRow!.shopOrderOperationId,
											direction: "DOWN",
										});
										await refreshData();
									}}
								/>
							</>
						}
						actionBarMenuComponents={[
							<ImportDocumentsToShopOrderOperationsButton shopOrderId={shopOrderId} />,
						]}
						remove={{
							type: "enabled",
							action: async (params) => {
								await ShopOrderOperationEditApi.delete_({
									shopOrderOperations: params.items,
								});
							},
						}}
						form={{
							addRowEnabled: !shopOrderIsClosed,
							editEnabled: !shopOrderIsClosed,
							dialogSize: "sm",
							dialogTitle: i18n.t("shop_order_operation"),
							component: ({ row, ...other }) => (
								<ShopOrderOperationForm
									shopOrderId={shopOrderId}
									shopOrderOperationId={row?.shopOrderOperationId ?? null}
									{...other}
								/>
							),
						}}
						{...dataGridProps}
					/>
				);
			}}
		/>
	);
};
