// This file is automatically generated. Do not edit manually.

import { ServerSideDataModelRequest } from "src/api/generated/common/dataGrids/serverSideDataModelRequest";
import { ServerSideDataModelResult } from "src/api/generated/common/dataGrids/serverSideDataModelResult";
import { CustomerOrderDeliveryView } from "src/api/generated/erp/db/types/tables/customerOrderDeliveryView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace CustomerOrderDeliveryQueryApi {
	export async function searchCustomerOrderDeliveries(args: {
		customerId?: number | null | undefined;
		customerOrderId?: number | null | undefined;
		onlyUndelivered?: boolean;
		onlyDefaultSite?: boolean;
		searchQuery?: string;
		onlyCustomerOrderDeliveryId?: number | null | undefined;
		dataModelRequest: ServerSideDataModelRequest;
	}): Promise<ServerSideDataModelResult<CustomerOrderDeliveryView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/delivery/searchCustomerOrderDeliveries",
			method: "POST",
			data: {
				customerId: args.customerId,
				customerOrderId: args.customerOrderId,
				onlyUndelivered: args.onlyUndelivered,
				onlyDefaultSite: args.onlyDefaultSite,
				searchQuery: args.searchQuery,
				onlyCustomerOrderDeliveryId: args.onlyCustomerOrderDeliveryId,
				dataModelRequest: args.dataModelRequest,
			},
		});
		return response as ServerSideDataModelResult<CustomerOrderDeliveryView>;
	}
}
