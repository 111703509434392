// This file is automatically generated. Do not edit manually.

import { AcquisitionMethod } from "src/api/generated/erp/db/types/enums/acquisitionMethod";
import { IsoDateString } from "src/types/dateTime";
import { CustomerOrderLineReservationDto } from "src/api/generated/erp/sales/picking/customerOrderLineReservationDto";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ReservableCustomerOrderLinesDataGridApi {
	export async function getViewData(args: {
		customerOrderId: number;
		acquisitionMethodFilter?: AcquisitionMethod | null | undefined;
		plannedDeliveryDateFilter: IsoDateString | null | undefined;
		onlyFromOwnWarehouseArea: boolean;
	}): Promise<ReservableCustomerOrderLinesDataGridApi_ViewData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/picking/customerOrderReservableLines/getRows",
			method: "POST",
			data: {
				customerOrderId: args.customerOrderId,
				acquisitionMethodFilter: args.acquisitionMethodFilter,
				plannedDeliveryDateFilter: args.plannedDeliveryDateFilter,
				onlyFromOwnWarehouseArea: args.onlyFromOwnWarehouseArea,
			},
		});
		return response as ReservableCustomerOrderLinesDataGridApi_ViewData;
	}
}

export interface ReservableCustomerOrderLinesDataGridApi_ViewData {
	deliveryDateOptions: Array<IsoDateString>;
	rows: Array<CustomerOrderLineReservationDto>;
}
