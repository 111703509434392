// This file is automatically generated. Do not edit manually.

import { OAuthAuthenticationRequest } from "src/api/generated/common/oauth/oAuthAuthenticationRequest";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace MailSendingApi {
	export async function ensureMailSendingAuthentication(): Promise<OAuthAuthenticationRequest | null> {
		const response = await makeAxiosRequest({
			url: "/api/ext/mail/ensureMailSendingAuthentication",
			method: "POST",
			data: {},
		});
		return response === "" ? null : (response as OAuthAuthenticationRequest | null);
	}

	export async function sendMailAsCurrentUser(args: {
		receiver: string;
		subject: string;
		body: string;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/mail/sendMailAsCurrentUser",
			method: "POST",
			data: {
				receiver: args.receiver,
				subject: args.subject,
				body: args.body,
			},
		});
		return response as void;
	}
}
