import i18n from "i18next";
import { faFileImport } from "@fortawesome/pro-regular-svg-icons";
import { AsyncButton, AsyncButtonProps } from "src/components/common/buttons/AsyncButton.tsx";
import { useGenericDialog } from "src/components/common/dialogs/useGenericDialog.ts";
import { PurchaseOrdersView } from "src/components/views/erp/purchase/purchaseOrders/PurchaseOrdersView.tsx";

export interface OpenPurchaseOrderButtonProps extends Omit<AsyncButtonProps, "onClick"> {
	purchaseOrderId: number;
}

export const OpenPurchaseOrderButton = ({
	purchaseOrderId,
	variant = "menu",
	...props
}: OpenPurchaseOrderButtonProps) => {
	const { openDialog } = useGenericDialog();

	return (
		<AsyncButton
			label={i18n.t("open_purchase_order")}
			icon={faFileImport}
			variant={variant}
			onClick={() => {
				openDialog({
					title: i18n.t("purchase_order"),
					content: <PurchaseOrdersView onlyPurchaseOrderId={purchaseOrderId} />,
				});
			}}
			{...props}
		/>
	);
};
