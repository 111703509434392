// This file is automatically generated. Do not edit manually.

import { ProductionLineOperationShopOrdersView } from "src/api/generated/erp/db/types/tables/productionLineOperationShopOrdersView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ProductionLineJobQueueInitialsApi {
	export async function getInitialOperations(): Promise<ProductionLineJobQueueInitialsApi_ProductionLineJobQueueData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/productionLineJobQueue/getInitialOperations",
			method: "POST",
			data: {},
		});
		return response as ProductionLineJobQueueInitialsApi_ProductionLineJobQueueData;
	}
}

export interface ProductionLineJobQueueInitialsApi_ProductionLineJobQueueData {
	rows: Array<ProductionLineOperationShopOrdersView>;
	startOperationEnabled: boolean;
}
