import { AsyncFetchRender } from "../async/AsyncFetchRender";
import { DefaultValues, UseFormProps, FieldValues } from "react-hook-form";
import React from "react";
import {
	AavoForm,
	AavoFormProps,
	AavoFormContentParams,
	AavoFormSubmitFunc,
} from "src/components/common/forms/AavoForm.tsx";
import { FormSubmitResult } from "src/components/common/forms/types.ts";
import { useErrorDialog } from "src/components/common/dialogs/errorDialog/userErrorDialog.ts";

export interface AsyncFormProps<TData, TFieldValues extends FieldValues, TResult>
	extends Omit<
		AavoFormProps<TFieldValues, TResult>,
		"defaultValues" | "useFormProps" | "render" | "submit" | "footerExtraComponents"
	> {
	fetch: () => Promise<TData>;
	getDefaultValues: (data: TData) => DefaultValues<TFieldValues>;
	getUseFormProps?: (data: TData) => Omit<UseFormProps<TFieldValues>, "defaultValues">;
	submit: AsyncFormSubmitFunc<TData, TFieldValues, TResult>;
	render: (props: AsyncFormContentParams<TData, TFieldValues>) => React.ReactNode;
	footerExtraComponents?: (
		props: AsyncFormFooterExtraComponentParams<TData, TFieldValues, TResult>,
	) => React.ReactNode;
}

export interface AsyncFormContentParams<TData, TFieldValues extends FieldValues>
	extends AavoFormContentParams<TFieldValues> {
	data: TData;
}

type AsyncFormSubmitFunc<TData, TFieldValues extends object, TResult> = (
	values: TFieldValues,
	props: AsyncFormContentParams<TData, TFieldValues>,
) => FormSubmitResult<TResult> | Promise<FormSubmitResult<TResult>>;

interface AsyncFormFooterExtraComponentParams<TData, TFieldValues extends object, TResult> {
	contentProps: AsyncFormContentParams<TData, TFieldValues>;
	handleSubmit: (submitFunc: AavoFormSubmitFunc<TFieldValues, TResult>) => void;
}

export const AsyncForm = <TData, TFieldValues extends FieldValues, TResult>({
	fetch,
	getDefaultValues: getDefaultValuesProp,
	getUseFormProps,
	render,
	submit,
	footerExtraComponents,
	...other
}: AsyncFormProps<TData, TFieldValues, TResult>) => {
	const { logErrorAndShowOnDialog } = useErrorDialog();

	return (
		<AsyncFetchRender
			fetch={fetch}
			content={(data) => {
				return (
					<AavoForm
						defaultValues={getDefaultValues(data)}
						useFormProps={getUseFormProps?.(data)}
						render={(params) =>
							render({
								...params,
								data: data,
							})
						}
						submit={convertAsyncSubmitFuncToSync(submit)}
						footerExtraComponents={
							footerExtraComponents === undefined ? undefined : (
								({ handleSubmit, contentProps }) =>
									footerExtraComponents({
										contentProps: {
											...contentProps,
											data: data,
										},
										handleSubmit: (
											submitFunc: AsyncFormSubmitFunc<TData, TFieldValues, TResult>,
										) => {
											handleSubmit(convertAsyncSubmitFuncToSync(submitFunc));
										},
									})
							)
						}
						{...other}
					/>
				);

				function convertAsyncSubmitFuncToSync(
					asyncSubmit: AsyncFormSubmitFunc<TData, TFieldValues, TResult>,
				): AavoFormSubmitFunc<TFieldValues, TResult> {
					return (values, props) => asyncSubmit(values, { ...props, data });
				}
			}}
		/>
	);

	function getDefaultValues(data: TData) {
		try {
			return getDefaultValuesProp(data);
		} catch (e) {
			logErrorAndShowOnDialog(e);
		}
	}
};
