import {
	dateColumn,
	dateTimeColumn,
	enumColumn,
	floatColumn,
	integerColumn,
	textColumn,
} from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import {ProjectActivityMaterialView} from "src/api/generated/erp/db/types/tables/projectActivityMaterialView.ts";
import {getPurchaseOrderLineStateLabels} from "src/api/generated/erp/db/types/enums/purchaseOrderLineState.ts";
import {MenuCheckbox} from "src/components/common/contextMenu/MenuCheckbox.tsx";
import {dataGridRowClassNames} from "src/components/common/dataGrid/styles/dataGridClassNames.ts";
import {DocumentsOfObjectButton} from "src/components/views/documents/objectDocuments/DocumentsOfObjectButton.tsx";
import {nullableAavoObjectRef} from "src/utils/aavoObjectRefUtils.ts";
import {useUserPermissions} from "src/components/views/erp/common/userPermissions.ts";
import {faBan, faFileImport, faTools} from "@fortawesome/pro-regular-svg-icons";
import {AsyncButton} from "src/components/common/buttons/AsyncButton.tsx";
import {useConfirmDialog} from "src/components/common/dialogs/confirmDialog/useConfirmDialog.ts";
import {OpenPurchaseOrderButton} from "src/components/views/erp/utilComponents/OpenPurchaseOrderButton.tsx";
import {AsyncMenuButton} from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import {
	ProjectActivityMaterialConfiguratorView
} from "src/components/views/erp/configurator/configuratorForm/impl/ProjectActivityMaterialConfiguratorView.tsx";
import {
	OpenPartConfigurationButton
} from "src/components/views/erp/configurator/inspecting/OpenPartConfigurationButton.tsx";
import {ServerSideDataGridModel} from "src/components/common/dataGrid/gridModel/ServerSideDataGridModel.tsx";
import {CrudDataGrid} from "src/components/common/dataGrid/crud/CrudDataGrid.tsx";
import {useGenericDialog} from "src/components/common/dialogs/useGenericDialog.ts";
import {
	ProjectActivityMaterialActionApi
} from "src/api/generated/erp/project/projectActivity/api/projectActivityMaterialActionApi.ts";
import {
	ProjectActivityMaterialLineForm
} from "src/components/views/erp/project/projectActivity/material/ProjectActivityMaterialLineForm.tsx";
import {logError} from "src/errorHandling/errorLogging.ts";
import {ServerSideDataModelRequest} from "src/api/generated/common/dataGrids/serverSideDataModelRequest.ts";
import {
	ProjectActivityMaterialsDataGridApi_DataGridData
} from "src/api/generated/erp/project/projectActivity/api/projectActivityMaterialsDataGridApi.ts";
import {
	getProjectActivityMaterialAcquisitionMethodLabels
} from "src/api/generated/erp/db/types/enums/projectActivityMaterialAcquisitionMethod.ts";
import {
	getProjectActivityMaterialStateLabels
} from "src/api/generated/erp/db/types/enums/projectActivityMaterialState.ts";

export interface ProjectActivityMaterialLinesDataGridBaseProps {
	gridId: string;
	fetchData: (args: {
		showCancelledLines: boolean;
		dataModelRequest: ServerSideDataModelRequest;
	}) => Promise<ProjectActivityMaterialsDataGridApi_DataGridData>;
	showActivityColumn?: boolean;
	showLineNumberColumn?: boolean;
}

export const ProjectActivityMaterialLinesDataGridBase = ({
	gridId,
	fetchData,
	showActivityColumn = false,
	showLineNumberColumn = false,
}: ProjectActivityMaterialLinesDataGridBaseProps) => {
	const isExternalProjectUser = useUserPermissions().project.isExternalProjectUser;

	const showConfirmDialog = useConfirmDialog();
	const { openDialog } = useGenericDialog();

	return (
		<ServerSideDataGridModel
			fetchData={fetchData}
			initialParams={{
				showCancelledLines: false,
			}}
			getDataModelResult={(data) => data.data}
			getRowId={(row) => row.projectActivityMaterialId}
			gridId={gridId}
			render={({
				dataGridProps,
				refreshData,
				currentParams,
				onlySelectedRow,
				selectedRows,
				data: { activity },
			}) => (
				<CrudDataGrid<ProjectActivityMaterialView>
					columns={[
						showActivityColumn && [
							textColumn({
								field: "subProjectDescription",
								headerName: i18n.t("sub_project"),
								width: 150,
							}),
							textColumn({
								field: "activityName",
								headerName: i18n.t("activity"),
								width: 150,
							}),
						],
						showLineNumberColumn &&
							integerColumn({
								field: "lineNumber",
								headerName: i18n.t("line_no"),
							}),
						textColumn({
							field: "configurationOrSupplierPartDescription",
							headerName: i18n.t("part"),
							width: 200,
						}),
						floatColumn({
							field: "quantityInBaseUnit",
							headerName: i18n.t("quantity"),
						}),
						textColumn({
							field: "partUnit",
							headerName: i18n.t("base_unit"),
						}),
						enumColumn({
							field: "acquisitionMethod",
							headerName: i18n.t("acquisition_method"),
							enumLabels: getProjectActivityMaterialAcquisitionMethodLabels(),
							width: 150,
						}),
						textColumn({
							field: "mark",
							headerName: i18n.t("mark"),
						}),
						dateColumn({
							field: "demandDate",
							headerName: i18n.t("demand_date"),
						}),
						dateTimeColumn({
							field: "releasedAt",
							headerName: i18n.t("ordered_at"),
							width: 150,
						}),
						enumColumn({
							field: "projectActivityMaterialState",
							headerName: i18n.t("state"),
							enumLabels: getProjectActivityMaterialStateLabels(),
						}),
						dateTimeColumn({
							field: "cancelledAt",
							headerName: i18n.t("cancelled_at"),
						}),
						textColumn({
							field: "supplierName",
							headerName: i18n.t("supplier"),
						}),
						enumColumn({
							field: "purchaseOrderLineState",
							headerName: i18n.t("purchase_order_line_state"),
							enumLabels: getPurchaseOrderLineStateLabels(),
							width: 180,
						}),
					]}
					getRowClassNames={({ row }) => {
						if (row.purchaseOrderLineState === "RECEIVED") {
							return dataGridRowClassNames.green;
						}
					}}
					actionBarMenuComponents={[
						<MenuCheckbox
							key={"showCancelled"}
							label={i18n.t("show_cancelled")}
							checked={currentParams.showCancelledLines}
							onChange={(checked) => refreshData({ showCancelledLines: checked })}
						/>,
					]}
					actionBarComponents={
						<>
							<DocumentsOfObjectButton
								objectRef={nullableAavoObjectRef(
									"PROJECT_ACTIVITY_MATERIAL",
									onlySelectedRow?.projectActivityMaterialId,
								)}
							/>
							{!isExternalProjectUser && (
								<AsyncButton
									label={i18n.t("create_purchase_order")}
									icon={faFileImport}
									disabled={selectedRows.length === 0}
									variant={"outlined"}
									onClick={async () => {
										const confirmed = await showConfirmDialog({
											message: i18n.t("confirm_order_project_activity_material_lines"),
										});
										if (!confirmed) return;

										await ProjectActivityMaterialActionApi.orderMaterials({
											projectActivityMaterialIds: selectedRows.map(
												(r) => r.projectActivityMaterialId,
											),
										});
										await refreshData();
									}}
								/>
							)}
						</>
					}
					rowContextMenuComponents={({ row, onlySingleRowSelected }) => [
						onlySingleRowSelected && row.purchaseOrderId != null && !isExternalProjectUser && (
							<OpenPurchaseOrderButton
								key="openPurchaseOrder"
								purchaseOrderId={row.purchaseOrderId}
							/>
						),
						onlySingleRowSelected && row.partIsConfigurable && (
							<AsyncMenuButton
								key={"configure"}
								label={i18n.t("configure")}
								icon={faTools}
								disabled={row.projectActivityMaterialState !== "INITIAL"}
								onClick={() => {
									openDialog(({ closeDialog }) => ({
										title: i18n.t("configure"),
										content: (
											<ProjectActivityMaterialConfiguratorView
												activityMaterialLineId={row.projectActivityMaterialId}
												closeDialog={closeDialog}
												refreshSourceView={refreshData}
											/>
										),
									}));
								}}
							/>
						),
						onlySingleRowSelected && (
							<OpenPartConfigurationButton
								key={"openConfiguration"}
								partConfigurationId={row.partConfigurationId}
							/>
						),
						onlySingleRowSelected && (
							<AsyncButton
								key={"cancel"}
								label={i18n.t("cancel")}
								icon={faBan}
								variant={"menu"}
								onClick={async () => {
									const confirmed = await showConfirmDialog({
										message: i18n.t("confirm_cancel_project_activity_material_line"),
									});
									if (!confirmed) return;

									await ProjectActivityMaterialActionApi.cancelMaterial({
										projectActivityMaterialId: row.projectActivityMaterialId,
									});
									await refreshData();
								}}
							/>
						),
					]}
					form={{
						editEnabled: true,
						addRowEnabled:
							activity != null &&
							["INITIAL", "RELEASED", "STARTED"].includes(activity.activityState),
						dialogSize: "md",
						dialogTitle: i18n.t("material_line"),
						component: ({ row, ...other }) => {
							const activityIdToUse = activity?.activityId ?? row?.activityId;
							if (activityIdToUse == null) {
								logError("Form opened but activityId is null");
								return;
							}
							return (
								<ProjectActivityMaterialLineForm
									activityId={activityIdToUse}
									materialLineId={row?.projectActivityMaterialId}
									{...other}
								/>
							);
						},
					}}
					{...dataGridProps}
				/>
			)}
		/>
	);
};