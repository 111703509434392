// This file is automatically generated. Do not edit manually.

import {FrontendUploadFileHandle} from "src/api/generated/common/frontendUploadFileHandle";
import {IsoDateTimeString} from "src/types/dateTime";
import {ShopOrderLine} from "src/api/generated/erp/db/types/tables/shopOrderLine";
import {makeAxiosRequest} from "src/api/utils/apiClient";

export namespace ShopOrderBomActionApi {
	export async function importShopOrderBom(args: {
		shopOrderId: number;
		file: FrontendUploadFileHandle;
	}): Promise<string> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/shopOrderBom/importShopOrderBom",
			method: "POST",
			data: {
				shopOrderId: args.shopOrderId,
				file: args.file,
			},
		});
		return response as string;
	}

	export async function releaseShopOrderLine(args: {
		shopOrderLineId: number;
		shopOrderLineLastUpdated: IsoDateTimeString;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/shopOrderBom/releaseShopOrderLine",
			method: "POST",
			data: {
				shopOrderLineId: args.shopOrderLineId,
				shopOrderLineLastUpdated: args.shopOrderLineLastUpdated,
			},
		});
		return response as void;
	}

	export async function revertReleaseShopOrderLine(args: {
		shopOrderLineId: number;
		shopOrderLineLastUpdated: IsoDateTimeString;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/shopOrderBom/revertReleaseShopOrderLine",
			method: "POST",
			data: {
				shopOrderLineId: args.shopOrderLineId,
				shopOrderLineLastUpdated: args.shopOrderLineLastUpdated,
			},
		});
		return response as void;
	}

	export async function delete_(args: { shopOrderLines: Array<ShopOrderLine> }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/shopOrderBom/delete",
			method: "POST",
			data: {
				shopOrderLines: args.shopOrderLines,
			},
		});
		return response as void;
	}

	export async function cancelShopOrderLine(args: {
		shopOrderLineId: number;
		lastUpdated: IsoDateTimeString;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/shopOrderBom/cancelShopOrderLine",
			method: "POST",
			data: {
				shopOrderLineId: args.shopOrderLineId,
				lastUpdated: args.lastUpdated,
			},
		});
		return response as void;
	}

	export async function shopOrderLineHasReceivedPurhcaseOrderLines(args: {
		shopOrderLineId: number;
	}): Promise<boolean> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/shopOrderBom/shopOrderLineHasReceivedPurhcaseOrderLines",
			method: "POST",
			data: {
				shopOrderLineId: args.shopOrderLineId,
			},
		});
		return response as boolean;
	}
}
