import { floatColumn, integerColumn, textColumn } from "../../../../common/dataGrid/columns.tsx";
import i18n from "i18next";
import { CustomerOrderPackageLineView } from "src/api/generated/erp/db/types/tables/customerOrderPackageLineView.ts";
import { AsyncButton } from "../../../../common/buttons/AsyncButton.tsx";
import { faMinus } from "@fortawesome/pro-regular-svg-icons";
import { ControlledAsyncCrudDataGrid } from "../../../../common/dataGrid/crud/ControlledAsyncCrudDataGrid.tsx";
import { RefreshableElementProps } from "src/utils/useRefreshRef.ts";
import { CustomerOrderPackageApi } from "src/api/generated/erp/sales/picking/api/customerOrderPackageApi.ts";
import { CustomerOrderPickingApi } from "src/api/generated/erp/sales/picking/api/customerOrderPickingApi.ts";
import { useGenericDialog } from "../../../../common/dialogs/useGenericDialog.ts";
import { DecreaseCustomerOrderPackageLineQuantityForm } from "./DecreaseCustomerOrderPackageLineQuantityForm.tsx";
import { CustomerOrderPackageView } from "src/api/generated/erp/db/types/tables/customerOrderPackageView.ts";
import { useClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/useClientSideDataGridModel";

export interface CustomerOrderPackageLinesDataGridProps extends RefreshableElementProps {
	customerOrderPackage: CustomerOrderPackageView;
	pickableLinesDataGridRefreshRef?: () => void;
	editable?: boolean;
}

export const CustomerOrderPackageLinesDataGrid = ({
	customerOrderPackage,
	pickableLinesDataGridRefreshRef,
	refreshRef,
	editable = true,
}: CustomerOrderPackageLinesDataGridProps) => {
	const { dataGridProps, onlySelectedRow, refreshData } = useClientSideDataGridModel({
		fetchData: () =>
			CustomerOrderPackageApi.getPackageLines({
				customerOrderPackageId: customerOrderPackage.customerOrderPackageId,
			}),
		initialParams: {},
		getRowId: (row) => row.customerOrderPackageLineId,
		gridId: "DF172DA1E15F6901",
		refreshRef: refreshRef,
	});

	const { openDialog } = useGenericDialog();

	return (
		<ControlledAsyncCrudDataGrid<CustomerOrderPackageLineView>
			columns={[
				integerColumn({
					field: "customerOrderLineNumber",
					headerName: i18n.t("line_number"),
				}),
				textColumn({
					field: "partLongName",
					headerName: i18n.t("part"),
					width: 300,
				}),
				floatColumn({
					field: "packageQuantity",
					headerName: i18n.t("picked_quantity"),
					width: 120,
				}),
				textColumn({
					field: "partUnit",
					headerName: i18n.t("unit"),
				}),
				textColumn({
					field: "mark",
					headerName: i18n.t("mark"),
				}),
				textColumn({
					field: "parentLineDescription",
					headerName: i18n.t("package_part"),
				}),
			]}
			actionBarComponents={
				<>
					{editable && (
						<AsyncButton
							icon={faMinus}
							label={i18n.t("delete")}
							disabled={() => {
								if (!onlySelectedRow) return i18n.t("select_line");
								if (customerOrderPackage.deliveryState === "DELIVERED")
									return i18n.t("package_is_delivered");
								if (customerOrderPackage.customerOrderPackageState === "PICKED")
									return i18n.t("package_is_picked");
								return false;
							}}
							variant={"outlined"}
							onClick={async () => {
								if (onlySelectedRow) {
									await openDecreaseQuantityDialog(onlySelectedRow);
								}
							}}
						/>
					)}
				</>
			}
			{...dataGridProps}
		/>
	);

	async function openDecreaseQuantityDialog(row: CustomerOrderPackageLineView) {
		const locationOptions = await CustomerOrderPickingApi.getCustomerOrderLinePickingLocations({
			customerOrderLineId: row.customerOrderLineId,
		});
		openDialog(({ closeDialog }) => ({
			title: i18n.t("delete"),
			size: "sm",
			content: (
				<DecreaseCustomerOrderPackageLineQuantityForm
					customerOrderPackageLineId={row.customerOrderPackageLineId}
					locationOptions={locationOptions}
					packageLineQuantity={row.packageQuantity}
					onCompleted={async () => {
						await closeDialog();
						pickableLinesDataGridRefreshRef?.();
						await refreshData();
					}}
				/>
			),
		}));
	}
};
