import { AavoObjectRef } from "src/api/generated/common/sourceType/aavoObjectRef.ts";
import { faQuestion } from "@fortawesome/pro-regular-svg-icons";
import { OpenSurveyApi } from "src/api/generated/surveys/api/openSurveyApi";
import i18n from "i18next";
import { useHandleLegacyCommandActionRequests } from "src/components/views/legacy/useHandleLegacyCommandActionRequests.ts";
import { AsyncButton } from "src/components/common/buttons/AsyncButton";

export interface OpenSurveyButtonProps {
	surveyConfigurationId: number;
	sourceRef: AavoObjectRef;
	surveyInfo?: string;
	surveyLocked?: boolean;
}

export const OpenSurveyButton = ({
	surveyConfigurationId,
	sourceRef,
	surveyInfo,
	surveyLocked,
}: OpenSurveyButtonProps) => {
	const handleLegacyCommandActionRequests = useHandleLegacyCommandActionRequests();
	return (
		<AsyncButton
			label={i18n.t("survey")}
			icon={faQuestion}
			variant={"outlined"}
			onClick={async () => {
				const actionRequests = await OpenSurveyApi.getOpenSurveyActionRequests({
					surveyConfigurationId,
					sourceRef,
					surveyInfo,
					surveyLocked,
				});
				handleLegacyCommandActionRequests(actionRequests);
			}}
		/>
	);
};
