import i18n from "i18next";
import { faTruckLoading } from "@fortawesome/pro-regular-svg-icons";
import { useTenantCustomizations } from "src/tenantCustomizations/useTenantCustomizations.tsx";
import { AsyncButton, AsyncButtonProps } from "src/components/common/buttons/AsyncButton.tsx";
import { useGenericDialog } from "src/components/common/dialogs/useGenericDialog.ts";
import { CustomerOrderDeliveringView } from "src/components/views/erp/sales/delivery/CustomerOrderDeliveringView.tsx";

export interface OpenCustomerOrderDeliveryViewButtonProps extends Omit<AsyncButtonProps, "onClick"> {
	customerOrderId: number | null;
	onlyCustomerOrderDeliveryId?: number;
}

export const OpenCustomerOrderDeliveryViewButton = ({
	customerOrderId,
	onlyCustomerOrderDeliveryId,
	variant = "menu",
}: OpenCustomerOrderDeliveryViewButtonProps) => {
	const { openDialog } = useGenericDialog();

	const { tenantConfig } = useTenantCustomizations();
	if (!tenantConfig.erp.salesEnabled) return null;

	return (
		<AsyncButton
			label={i18n.t("open_delivery")}
			icon={faTruckLoading}
			variant={variant}
			disabled={customerOrderId == null}
			onClick={() => {
				if (customerOrderId == null) return;
				openDialog({
					title: i18n.t("delivery"),
					content: (
						<CustomerOrderDeliveringView
							customerOrderId={customerOrderId}
							onlyCustomerOrderDeliveryId={onlyCustomerOrderDeliveryId}
						/>
					),
				});
			}}
		/>
	);
};
