import {
    CustomerOrderPackageFormApi,
    CustomerOrderPackageFormApi_InitData,
} from "src/api/generated/erp/sales/picking/api/customerOrderPackageFormApi.ts";
import {AsyncFetchRender} from "../../../../common/async/AsyncFetchRender.tsx";
import {FormCommonProps} from "../../../../common/forms/types.ts";
import {CustomerOrderPackage} from "src/api/generated/erp/db/types/tables/customerOrderPackage.ts";
import {AavoForm} from "src/components/common/forms/AavoForm.tsx";
import {FormTextField} from "../../../../common/forms/fields/FormTextField.tsx";
import {
	nonNegativeFloatRule,
	requiredNonNegativeIntegerRule,
	requireRule
} from "../../../../common/forms/validation.ts";
import i18n from "i18next";
import {FormSelectField} from "../../../../common/forms/fields/FormSelectField.tsx";

export interface CustomerOrderPackageFormProps extends FormCommonProps<number> {
	customerOrderId: number;
	customerOrderPackageId: number | null | undefined;
}

export const CustomerOrderPackageForm = (props: CustomerOrderPackageFormProps) => {
	const { customerOrderId, customerOrderPackageId } = props;
	return (
		<AsyncFetchRender
			fetch={() => CustomerOrderPackageFormApi.getInitData({ customerOrderId, customerOrderPackageId })}
			content={(initData) => <CustomerOrderPackageFormContent initData={initData} {...props} />}
		/>
	);
};

interface CustomerOrderPackageFormContentProps extends CustomerOrderPackageFormProps {
	initData: CustomerOrderPackageFormApi_InitData;
}

const CustomerOrderPackageFormContent = ({
	initData,
	customerOrderId,
	onFormEdited,
	onCompleted,
}: CustomerOrderPackageFormContentProps) => {
	const defaultValues = initData.customerOrderPackage ?? {
		packageNo: initData.nextPackageNumber,
		warehouseLocationId: initData.warehouseLocationOptions[0]?.warehouseLocationId,
		type: "BOX",
		customerOrderId: customerOrderId,
	};
	return (
		<AavoForm
			defaultValues={defaultValues}
			submit={submitForm}
			columns={2}
			onFormEdited={onFormEdited}
			onCompleted={onCompleted}
			render={({ control }) => (
				<>
					<FormTextField
						control={control}
						name={"packageNo"}
						rules={requiredNonNegativeIntegerRule()}
						label={i18n.t("package_no")}
					/>
					<FormTextField control={control} name={"description"} label={i18n.t("description")} />
					<FormSelectField
						control={control}
						name={"customerOrderPackageTypeId"}
						label={i18n.t("type")}
						options={initData.customerOrderPackageTypeOptions}
						getOptionKey={(o) => o.customerOrderPackageTypeId}
						getOptionLabel={(o) => o.name}
						rules={requireRule()}
					/>
					<FormSelectField
						control={control}
						name={"warehouseLocationId"}
						label={i18n.t("shipping_warehouse_location")}
						options={initData.warehouseLocationOptions}
						getOptionKey={(o) => o.warehouseLocationId}
						getOptionLabel={(o) => o.locationCode}
					/>
					<FormTextField
						control={control}
						name={"weight"}
						label={i18n.t("weight")}
						rules={nonNegativeFloatRule()}
					/>

					<FormTextField
						control={control}
						name={"length"}
						label={i18n.t("length_cm")}
						rules={nonNegativeFloatRule()}
					/>
					<FormTextField
						control={control}
						name={"width"}
						label={i18n.t("width_cm")}
						rules={nonNegativeFloatRule()}
					/>
					<FormTextField
						control={control}
						name={"height"}
						label={i18n.t("height_cm")}
						rules={nonNegativeFloatRule()}
					/>
				</>
			)}
		/>
	);
};

const submitForm = async (CustomerOrderPackage: CustomerOrderPackage): Promise<number> => {
	const result = await CustomerOrderPackageFormApi.save({
		customerOrderPackage: CustomerOrderPackage,
	});
	return result.customerOrderPackageId;
};
