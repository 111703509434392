// This file is automatically generated. Do not edit manually.

import { ServerSideDataModelRequest } from "src/api/generated/common/dataGrids/serverSideDataModelRequest";
import { CustomerOrderPackageState } from "src/api/generated/erp/db/types/enums/customerOrderPackageState";
import { CustomerOrderDeliveryState } from "src/api/generated/erp/db/types/enums/customerOrderDeliveryState";
import { ServerSideDataModelResult } from "src/api/generated/common/dataGrids/serverSideDataModelResult";
import { CustomerOrderPackageView } from "src/api/generated/erp/db/types/tables/customerOrderPackageView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace CustomerOrderPackageDataGridApi {
	export async function getCustomerOrderPackages(args: {
		dataModelRequest?: ServerSideDataModelRequest;
		customerOrderId?: number | null | undefined;
		stateFilter?: Array<CustomerOrderPackageState>;
		deliveryState?: CustomerOrderDeliveryState | null | undefined;
		customerId?: number | null | undefined;
		customerOrderDeliveryId?: number | null | undefined;
		customerOrderPackageId?: number | null | undefined;
		onlyDefaultSitePackages?: boolean;
		searchQuery?: string;
	}): Promise<ServerSideDataModelResult<CustomerOrderPackageView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/picking/customerOrderPackagesDataGrid/getCustomerOrderPackages",
			method: "POST",
			data: {
				dataModelRequest: args.dataModelRequest,
				customerOrderId: args.customerOrderId,
				stateFilter: args.stateFilter,
				deliveryState: args.deliveryState,
				customerId: args.customerId,
				customerOrderDeliveryId: args.customerOrderDeliveryId,
				customerOrderPackageId: args.customerOrderPackageId,
				onlyDefaultSitePackages: args.onlyDefaultSitePackages,
				searchQuery: args.searchQuery,
			},
		});
		return response as ServerSideDataModelResult<CustomerOrderPackageView>;
	}

	export async function delete_(args: { customerOrderPackages: Array<CustomerOrderPackageView> }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/picking/customerOrderPackagesDataGrid/delete",
			method: "POST",
			data: {
				customerOrderPackages: args.customerOrderPackages,
			},
		});
		return response as void;
	}
}
