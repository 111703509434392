import { lehtoCustomizations } from "src/tenantCustomizations/tenants/lehto/lehtoCustomizations";
import { omegakeittiotCustomizations } from "src/tenantCustomizations/tenants/omegakeittiot/omegakeittiotCustomizations";
import { PartialTenantCustomizations } from "src/tenantCustomizations/TenantCustomizations.ts";
import { salvosCustomizations } from "src/tenantCustomizations/tenants/salvos/salvosCustomizations.ts";
import { mellanoCustomizations } from "./tenants/mellano/mellanoCustomizations";
import { hawoCustomizations } from "src/tenantCustomizations/tenants/hawo/hawoCustomizations.ts";
import { puukotiCustomizations } from "src/tenantCustomizations/tenants/puukoti/puukotiCustomizations.ts";
import { demo1Customizations } from "src/tenantCustomizations/tenants/demo1/demo1Customizations.ts";
import { lumonCustomizations } from "src/tenantCustomizations/tenants/lumon/lumonCustomizations.ts";
import { kaskipuuCustomizations } from "src/tenantCustomizations/tenants/kaskipuu/kaskipuuCustomizations.ts";
import { uponorCustomizations } from "src/tenantCustomizations/tenants/uponor/uponorCustomizations.ts";
import { devTenantCustomizations } from "src/tenantCustomizations/tenants/dev/devTenantCustomizations.ts";
import { necoCustomizations } from "src/tenantCustomizations/tenants/neco/necoCustomizations.ts";
import { ssaCustomizations } from "src/tenantCustomizations/tenants/ssa/ssaCustomizations.ts";

const realTenantCustomizationValues: Record<string, PartialTenantCustomizations> = {
	demo1: demo1Customizations,
	lehto: lehtoCustomizations,
	omegakeittiot: omegakeittiotCustomizations,
	salvos: salvosCustomizations,
	puukoti: puukotiCustomizations,
	hawo: hawoCustomizations,
	tilaelementti: hawoCustomizations,
	uponor: uponorCustomizations,
	mellano: mellanoCustomizations,
	kaskipuu: kaskipuuCustomizations,
	lumon: lumonCustomizations,
	neco: necoCustomizations,
	ssa: ssaCustomizations,
	jloy: {},
} as const;

const devTenant = import.meta.env.VITE_DEV_TENANT;

export const tenantCustomizationValues: Record<string, PartialTenantCustomizations> = {
	...realTenantCustomizationValues,
	localhost: devTenant === undefined ? devTenantCustomizations : realTenantCustomizationValues[devTenant]!,
} as const;
