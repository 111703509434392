import {
	ClientSideDataGridModel,
	ClientSideDataGridModelRenderProps,
} from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel.tsx";
import { CrudDataGrid } from "src/components/common/dataGrid/crud/CrudDataGrid.tsx";
import {
	dateColumn,
	enumColumn,
	integerColumn,
	textColumn,
} from "src/components/common/dataGrid/columns.tsx";
import { getCatalogPartRevisionStateLabels } from "src/api/generated/erp/db/types/enums/catalogPartRevisionState.ts";
import {
	CatalogPartRevisionsDataGridApi,
	CatalogPartRevisionsDataGridApi_GridData,
} from "src/api/generated/erp/parts/catalogPart/api/catalogPartRevisionsDataGridApi.ts";
import { CatalogPartRevisionView } from "src/api/generated/erp/db/types/tables/catalogPartRevisionView.ts";
import i18n from "i18next";
import { DocumentsOfObjectButton } from "src/components/views/documents/objectDocuments/DocumentsOfObjectButton.tsx";
import { nullableAavoObjectRef } from "src/utils/aavoObjectRefUtils.ts";
import { AavoButton } from "src/components/common/buttons/AavoButton.tsx";
import {
	faArrowUp,
	faFileDownload,
	faIcons,
	faLink,
	faShare,
	faSitemap,
	faStethoscope,
	faTable,
	faTools,
} from "@fortawesome/pro-regular-svg-icons";
import { useConfirmDialog } from "src/components/common/dialogs/confirmDialog/useConfirmDialog.ts";
import { useOpenLegacyView } from "src/components/views/legacy/useOpenLegacyView.ts";
import { AsyncMenuButton } from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import { useGenericDialog } from "src/components/common/dialogs/useGenericDialog.ts";
import { CatalogPartRevisionForm } from "src/components/views/erp/parts/catalogPart/CatalogPartRevisionForm.tsx";
import { CatalogPartRevisionEditApi } from "src/api/generated/erp/parts/catalogPart/api/catalogPartRevisionEditApi.ts";
import { CatalogPartRevisionBomView } from "src/components/views/erp/parts/catalogPart/CatalogPartRevisionBomView.tsx";
import { CatalogPartRevisionConfiguratorSelectionsAsyncView } from "src/components/views/erp/configurator/managing/catalogPart/configuratorSelections/CatalogPartRevisionConfiguratorSelectionsAsyncView.tsx";
import { AavoMenuDivider } from "src/components/common/menu/AavoMenuDivider.tsx";
import { ConfigurationProductFamilyVersionApi } from "src/api/generated/erp/configurator/management/productFamilies/api/configurationProductFamilyVersionApi.ts";
import { useInputDialog } from "src/components/common/dialogs/input/useInputDialog.tsx";
import { CatalogPartRevisionProductFamilyVersionApi } from "src/api/generated/erp/configurator/management/catalogPart/api/catalogPartRevisionProductFamilyVersionApi.ts";
import { getConfiguratorProductFamilyVersionLabel } from "src/components/views/erp/configurator/configuratorUtils.ts";
import { CatalogPartRevisionApi } from "src/api/generated/erp/parts/catalogPart/api/catalogPartRevisionApi.ts";
import { CatalogPartTestConfiguratorView } from "src/components/views/erp/configurator/configuratorTesting/catalogPart/CatalogPartTestConfiguratorView.tsx";
import { CatalogPartRevisionDocumentsView } from "src/components/views/erp/parts/catalogPart/CatalogPartRevisionDocumentsView.tsx";

export interface CatalogPartRevisionsDataGridProps {
	catalogPartId: number;
}

export const CatalogPartRevisionsDataGrid = (props: CatalogPartRevisionsDataGridProps) => {
	const { catalogPartId } = props;
	return (
		<ClientSideDataGridModel
			gridId={"84CB994C61445556"}
			fetchData={() => CatalogPartRevisionsDataGridApi.getData({ catalogPartId: catalogPartId })}
			getRows={(data) => data.revisions}
			getRowId={(row) => row.catalogPartRevisionId}
			initialParams={{}}
			render={(params) => <DataGrid {...props} {...params} />}
		/>
	);
};

interface DataGridProps
	extends CatalogPartRevisionsDataGridProps,
		ClientSideDataGridModelRenderProps<
			CatalogPartRevisionsDataGridApi_GridData,
			CatalogPartRevisionView,
			object
		> {}

const DataGrid = ({
	data: { partIsConfigurable },
	dataGridProps,
	onlySelectedRow,
	refreshData,
	catalogPartId,
}: DataGridProps) => {
	const showConfirm = useConfirmDialog();
	const openLegacyView = useOpenLegacyView();
	const { openDialog } = useGenericDialog();
	const showInputDialog = useInputDialog();

	return (
		<CrudDataGrid<CatalogPartRevisionView>
			disableMultipleRowSelection
			columns={[
				integerColumn({
					field: "revisionNumber",
					headerName: i18n.t("revision"),
				}),
				enumColumn({
					field: "state",
					headerName: i18n.t("state"),
					enumLabels: getCatalogPartRevisionStateLabels(),
				}),
				textColumn({
					field: "note",
					headerName: i18n.t("comment"),
					width: 200,
				}),
				partIsConfigurable &&
					integerColumn({
						field: "configurationProductFamilyVersionNumber",
						headerName: i18n.t("product_family_version"),
					}),
				dateColumn({
					field: "createdAt",
					headerName: i18n.t("created_at"),
				}),
				textColumn({
					field: "createdByUserName",
					headerName: i18n.t("created_by"),
				}),
				dateColumn({
					field: "publishedAt",
					headerName: i18n.t("published_at"),
				}),
				textColumn({
					field: "publishedByUserName",
					headerName: i18n.t("published_by"),
				}),
				integerColumn({
					field: "catalogPartRevisionId",
					headerName: i18n.t("id"),
				}),
			]}
			actionBarComponents={
				<>
					<DocumentsOfObjectButton
						objectRef={nullableAavoObjectRef(
							"CATALOG_PART_REVISION",
							onlySelectedRow?.catalogPartRevisionId,
						)}
						dialogContent={() => (
							<CatalogPartRevisionDocumentsView catalogPartRevision={onlySelectedRow!} />
						)}
					/>
					<AavoButton
						label={i18n.t("product_structure")}
						icon={faTable}
						disabled={onlySelectedRow == null}
						variant={"outlined"}
						onClick={() => {
							if (onlySelectedRow == null) return;
							openDialog(({ onDataDirtyStateChanged }) => ({
								title: `${i18n.t("product_structure")} (${onlySelectedRow.partNo} ${onlySelectedRow.catalogPartDescription_1} ${onlySelectedRow.revisionNumber})`,
								content: (
									<CatalogPartRevisionBomView
										catalogPartRevisionId={onlySelectedRow.catalogPartRevisionId}
										dataDirtyStateChanged={onDataDirtyStateChanged}
									/>
								),
							}));
						}}
					/>
					<AavoButton
						label={i18n.t("product_structure_tree")}
						icon={faSitemap}
						disabled={onlySelectedRow == null}
						variant={"outlined"}
						onClick={() => {
							if (onlySelectedRow == null) return;
							openLegacyView("F0BFD12FB38D1A64", {
								_rootCatalogPartRevisionId: onlySelectedRow.catalogPartRevisionId,
								catalogPartId: null,
							});
						}}
					/>
				</>
			}
			rowContextMenuComponents={({ row }) => [
				row.state === "INITIAL" && (
					<AsyncMenuButton
						key={"publish"}
						label={i18n.t("publish")}
						icon={faShare}
						onClick={() => publishRevision(row!)}
					/>
				),
				row.state === "INITIAL" && (
					<AsyncMenuButton
						key={"importContent"}
						label={i18n.t("import_content_from_another_revision")}
						icon={faFileDownload}
						onClick={() => {
							openLegacyView("1BF128E84F2E549F", {
								catalogPartId: row.catalogPartId,
								_targetCatalogPartRevisionId: row.catalogPartRevisionId,
							});
						}}
					/>
				),
				<AsyncMenuButton
					key={"connections"}
					label={i18n.t("connections")}
					icon={faLink}
					onClick={() => {
						openLegacyView("D5F65736437FBF12", {
							catalogPartRevisionId: row.catalogPartRevisionId,
						});
					}}
				/>,
				row.isConfigurable &&
					row.configurationProductFamilyVersionId != null && [
						<AavoMenuDivider key={"dividerConfigurator"} label={i18n.t("configurator")} />,
						<AsyncMenuButton
							key={"testConfiguration"}
							label={i18n.t("test_configuration")}
							icon={faStethoscope}
							onClick={() => {
								if (row == null) return;
								openDialog({
									title: i18n.t("test_configuration"),
									content: (
										<CatalogPartTestConfiguratorView
											catalogPartRevisionId={row.catalogPartRevisionId}
											productFamilyVersionId={row.configurationProductFamilyVersionId!}
										/>
									),
								});
							}}
						/>,
						<AsyncMenuButton
							key={"configuratorComponents"}
							label={i18n.t("configurator_components")}
							icon={faTools}
							onClick={() => {
								openDialog(({ closeDialog, onDataDirtyStateChanged }) => ({
									title: i18n.t("configurator_components"),
									content: (
										<CatalogPartRevisionConfiguratorSelectionsAsyncView
											catalogPartRevisionId={row.catalogPartRevisionId}
											productFamilyVersionId={row.configurationProductFamilyVersionId!}
											onDataDirtyStateChanged={onDataDirtyStateChanged}
											onSubmitted={async () => {
												await closeDialog();
											}}
											onCancelled={async () => {
												await closeDialog({ confirmIfEdited: true });
											}}
										/>
									),
								}));
							}}
						/>,
						<AsyncMenuButton
							key={"upgradeProductFamilyVersion"}
							label={i18n.t("upgrade_product_family_version")}
							icon={faArrowUp}
							onClick={() => upgradeProductFamilyVersion(row)}
						/>,
						<AsyncMenuButton
							key={"attributeRules"}
							label={i18n.t("attribute_rules")}
							icon={faIcons}
							onClick={() => {
								openLegacyView("A443917350E74173", {
									catalogPartRevisionId: row.catalogPartRevisionId,
									categoryId: row.categoryId,
								});
							}}
						/>,
					],
			]}
			form={{
				editEnabled: true,
				addRowEnabled: true,
				dialogSize: "sm",
				dialogTitle: i18n.t("revision"),
				component: ({ row, onCompleted, onFormEdited }) => (
					<CatalogPartRevisionForm
						catalogPartId={catalogPartId}
						catalogPartRevisionId={row?.catalogPartRevisionId ?? null}
						onCompleted={onCompleted}
						onFormEdited={onFormEdited}
					/>
				),
			}}
			remove={{
				type: onlySelectedRow?.state === "INITIAL" ? "enabled" : "disabled",
				action: async () => {
					if (onlySelectedRow == null) return;
					return await deleteRevision(onlySelectedRow);
				},
			}}
			{...dataGridProps}
		/>
	);

	async function publishRevision(revision: CatalogPartRevisionView) {
		const confirmed = await showConfirm({
			title: i18n.t("publish"),
			message: i18n.t("publishing_locks_the_revision"),
		});
		if (!confirmed) return;

		const hasNewerFamilyVersions =
			await CatalogPartRevisionsDataGridApi.hasProductFamilyNewerReleasedVersions({
				catalogPartRevisionId: revision.catalogPartRevisionId,
			});
		if (hasNewerFamilyVersions) {
			const confirmed2 = await showConfirm({
				title: i18n.t("publish"),
				message: i18n.t("newer_family_versions_exist"),
			});
			if (!confirmed2) return;
		}

		await CatalogPartRevisionsDataGridApi.publishCatalogPartRevision({
			catalogPartRevisionId: revision.catalogPartRevisionId,
		});
		await refreshData();
	}

	async function upgradeProductFamilyVersion(revision: CatalogPartRevisionView) {
		if (revision.configurationProductFamilyId == null) return;

		const allVersions = await ConfigurationProductFamilyVersionApi.getProductFamilyVersions({
			productFamilyId: revision.configurationProductFamilyId,
		});
		const versionOptions = allVersions
			.filter(
				(version) => version.versionNumber > (revision.configurationProductFamilyVersionNumber ?? 0),
			)
			.map((version) => ({
				value: version.configurationProductFamilyVersionId,
				label: getConfiguratorProductFamilyVersionLabel(version),
			}));

		const newVersionId = await showInputDialog({
			title: i18n.t("upgrade_product_family_version"),
			fieldLabel: i18n.t("version"),
			required: true,
			type: "singleNumberSelect",
			defaultValue: versionOptions[versionOptions.length - 1]?.value ?? null,
			fieldProps: {
				selection: {
					options: versionOptions,
				},
			},
		});
		if (newVersionId === undefined) return;

		await CatalogPartRevisionProductFamilyVersionApi.upgradeRevisionProductFamilyVersion({
			catalogPartRevisionId: revision.catalogPartRevisionId,
			newProductFamilyVersionId: newVersionId,
		});

		await refreshData();
	}

	async function deleteRevision(revision: CatalogPartRevisionView) {
		const hasRevisionLines = await CatalogPartRevisionApi.getCatalogPartHasAnyRevisionLines({
			catalogPartRevisionId: revision.catalogPartRevisionId,
		});
		if (hasRevisionLines) {
			const deleteBomConfirmed = await showConfirm({
				message: i18n.t("delete_catalog_part_revision.has_bom_lines.confirm_message"),
			});
			if (!deleteBomConfirmed) return "interrupt";
		}
		await CatalogPartRevisionEditApi.delete_({
			catalogPartRevisionId: revision.catalogPartRevisionId,
		});
	}
};
