import { useServerSideDataGridModel } from "src/components/common/dataGrid/gridModel/useServerSideDataGridModel";
import { dateColumn, integerColumn, textColumn } from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { AavoTextField } from "src/components/common/inputFields/AavoTextField";
import { LazyMultiSelectField } from "src/components/common/inputFields/LazyMultiSelectField.tsx";
import { CustomerOrderTypeApi } from "src/api/generated/erp/sales/basedata/api/customerOrderTypeApi.ts";

import { AavoCheckbox } from "src/components/common/inputFields/AavoCheckbox.tsx";
import { AavoButton } from "src/components/common/buttons/AavoButton.tsx";
import { dataGridCellClassNames } from "src/components/common/dataGrid/styles/dataGridClassNames.ts";
import dayjs from "dayjs";
import { ControlledAsyncDataGrid } from "src/components/common/dataGrid/ControlledAsyncDataGrid.tsx";
import { AsyncMenuButton } from "../../../../common/contextMenu/AsyncMenuButton.tsx";
import { faBoxOpen, faCheckCircle } from "@fortawesome/pro-regular-svg-icons";
import {
	PickableCustomerOrdersDataGridApi,
	PickableCustomerOrdersDataGridApi_PickableCustomerOrder,
} from "src/api/generated/erp/sales/picking/api/pickableCustomerOrdersDataGridApi.ts";
import { useGenericDialog } from "../../../../common/dialogs/useGenericDialog.ts";
import { ReservableCustomerOrderLinesDataGrid } from "./ReservableCustomerOrderLinesDataGrid.tsx";
import { CustomerOrderPickingView } from "./CustomerOrderPickingView.tsx";
import { OpenCustomerOrderButton } from "src/components/views/erp/utilComponents/OpenCustomerOrderButton.tsx";
import { formatDayJs } from "src/utils/dayjsUtils.ts";

export const PickableCustomersOrdersDataGrid = () => {
	const { dataGridProps, refreshData, currentParams, onlySelectedRow } =
		useServerSideDataGridModel({
			gridId: "E1F87A04512667AA",
			fetchData: PickableCustomerOrdersDataGridApi.getRows,
			getRowId: (row) => row.customerOrderId,
			initialParams: {
				searchQuery: "",
				showOnlyDefaultSite: true,
				customerOrderTypeIdFilter: Array<number>(),
			},
		});

	const { openDialog } = useGenericDialog();

	return (
		<ControlledAsyncDataGrid<PickableCustomerOrdersDataGridApi_PickableCustomerOrder>
			disableMultipleRowSelection
			columns={[
				integerColumn({
					field: "customerOrderId",
					headerName: i18n.t("order_number"),
					width: 120,
				}),
				textColumn({
					field: "siteName",
					headerName: i18n.t("site"),
				}),
				textColumn({
					field: "customerName",
					headerName: i18n.t("customer"),
					width: 300,
				}),
				textColumn({
					field: "orderReference",
					headerName: i18n.t("reference"),
					width: 300,
				}),
				textColumn({
					field: "customerOrderTypeName",
					headerName: i18n.t("order_type"),
					width: 300,
				}),
				dateColumn({
					field: "earliestPickingDate",
					headerName: i18n.t("earliest_picking_date"),
					width: 200,
					valueFormatter: (value) =>
						`${formatDayJs(value)} (${i18n.t("week_abbr")} ${value.week()})`,
					cellClassName: (params) => {
						if (dayjs(params.row.earliestPickingDate).isBefore(dayjs(), "day")) {
							return dataGridCellClassNames.warning;
						}
						return "";
					},
				}),
			]}
			onRowDoubleClick={({ row }) => {
				openPickingView(row);
			}}
			actionBarComponents={
				<>
					<AavoTextField
						placeholder={i18n.t("search")}
						onSubmit={async (search) => {
							await refreshData({ searchQuery: search });
						}}
					/>
					<LazyMultiSelectField
						label={i18n.t("order_type")}
						fetchOptions={CustomerOrderTypeApi.getAll}
						getOptionKey={(option) => option.customerOrderTypeId}
						getOptionLabel={(option) => option.name}
						value={currentParams.customerOrderTypeIdFilter}
						onChange={async (newValue) => {
							await refreshData({ customerOrderTypeIdFilter: newValue });
						}}
						sx={{
							minWidth: 160,
						}}
					/>
					<AavoButton
						label={i18n.t("picking")}
						icon={faBoxOpen}
						variant={"outlined"}
						disabled={onlySelectedRow === undefined}
						onClick={() => {
							if (onlySelectedRow === undefined) return;
							openPickingView(onlySelectedRow);
						}}
					/>
					<AavoCheckbox
						label={i18n.t("only_default_site")}
						checked={currentParams.showOnlyDefaultSite}
						onChange={async (checked) => {
							await refreshData({ showOnlyDefaultSite: checked });
						}}
					/>
				</>
			}
			rowContextMenuComponents={({ row }) => [
				<OpenCustomerOrderButton
					key="openCustomerOrder"
					customerOrderId={row.customerOrderId}
				/>,
				<AsyncMenuButton
					key="customerOrderAvailabilityCheck"
					icon={faCheckCircle}
					label={i18n.t("availability_check")}
					onClick={() => {
						openDialog(() => ({
							title: i18n.t("availability_check"),
							size: "lg",
							content: (
								<ReservableCustomerOrderLinesDataGrid
									customerOrderId={row.customerOrderId}
								/>
							),
						}));
					}}
				/>,
			]}
			{...dataGridProps}
		/>
	);

	function openPickingView(
		customerOrder: PickableCustomerOrdersDataGridApi_PickableCustomerOrder,
	) {
		openDialog(() => ({
			title: i18n.t("picking"),
			size: "fullscreen",
			content: <CustomerOrderPickingView customerOrder={customerOrder} />,
		}));
	}
};
