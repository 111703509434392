// This file is automatically generated. Do not edit manually.

import { ServerSideDataModelRequest } from "src/api/generated/common/dataGrids/serverSideDataModelRequest";
import { ServerSideDataModelResult } from "src/api/generated/common/dataGrids/serverSideDataModelResult";
import { CustomerOrderView } from "src/api/generated/erp/db/types/tables/customerOrderView";
import { CustomerOrderDeliveryView } from "src/api/generated/erp/db/types/tables/customerOrderDeliveryView";
import { CustomerOrderLineView } from "src/api/generated/erp/db/types/tables/customerOrderLineView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace CustomerOrderInvoicingApi {
	export async function getBillableCustomerOrders(args: {
		onlyDefaultSite: boolean;
		searchQuery: string;
		dataModelRequest: ServerSideDataModelRequest;
	}): Promise<ServerSideDataModelResult<CustomerOrderView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/invoicing/customerOrder/getBillableCustomerOrders",
			method: "POST",
			data: {
				onlyDefaultSite: args.onlyDefaultSite,
				searchQuery: args.searchQuery,
				dataModelRequest: args.dataModelRequest,
			},
		});
		return response as ServerSideDataModelResult<CustomerOrderView>;
	}

	export async function getCustomerOrderBillableDeliveries(args: {
		customerOrderId: number;
	}): Promise<Array<CustomerOrderDeliveryView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/invoicing/customerOrder/getCustomerOrderBillableDeliveries",
			method: "POST",
			data: {
				customerOrderId: args.customerOrderId,
			},
		});
		return response as Array<CustomerOrderDeliveryView>;
	}

	export async function getCustomerOrderDirectlyBillableLines(args: {
		customerOrderId: number;
	}): Promise<Array<CustomerOrderLineView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/invoicing/customerOrder/getCustomerOrderDirectlyBillableLines",
			method: "POST",
			data: {
				customerOrderId: args.customerOrderId,
			},
		});
		return response as Array<CustomerOrderLineView>;
	}
}
