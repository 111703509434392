import { CustomerOrderLineReservationDto } from "src/api/generated/erp/sales/picking/customerOrderLineReservationDto.ts";
import {
	AcquisitionMethod,
	getAcquisitionMethodLabels,
} from "src/api/generated/erp/db/types/enums/acquisitionMethod.ts";
import { SelectField } from "src/components/common/inputFields/SelectField.tsx";
import i18n from "i18next";
import { enumOptions } from "src/components/common/forms/fields/selectionFieldUtils.ts";
import {
	coloredBooleanColumn,
	dateColumn,
	enumColumn,
	integerColumn,
	textColumn,
} from "../../../../common/dataGrid/columns.tsx";
import { useParameterizedAsyncData } from "src/utils/async/parameterizedAsyncData.ts";
import { dataGridPersistentStateProps } from "src/components/common/dataGrid/dataGridStateStorage.ts";
import { genericNullableValue } from "src/utils/genericNullableValue.ts";
import { IsoDateString } from "src/types/dateTime.ts";
import { AsyncRender } from "src/components/common/async/AsyncRender.tsx";
import { AavoDataGrid } from "src/components/common/dataGrid/AavoDataGrid.tsx";
import { ReservableCustomerOrderLinesDataGridApi } from "src/api/generated/erp/sales/picking/api/reservableCustomerOrderLinesDataGridApi.ts";
import { formatIsoString } from "src/utils/dayjsUtils.ts";
import { faHandPointRight } from "@fortawesome/pro-regular-svg-icons";
import { AavoButton } from "src/components/common/buttons/AavoButton.tsx";
import { dropProps } from "src/utils/dropProps.ts";
import { useGenericDialog } from "src/components/common/dialogs/useGenericDialog.ts";
import { AavoCheckbox } from "src/components/common/inputFields/AavoCheckbox.tsx";
import {
	RefreshableElementProps,
	RefreshableElementRef,
	setRefreshRefValue,
} from "src/utils/useRefreshRef.ts";
import { CustomerOrderLineReservationForm } from "src/components/views/erp/sales/picking/CustomerOrderLineReservationForm.tsx";

export interface ReservableCustomerOrderLinesDataGridProps extends RefreshableElementProps {
	customerOrderId: number;
	pickableLinesDataGridRefreshRef?: RefreshableElementRef;
}

export const ReservableCustomerOrderLinesDataGrid = ({
	customerOrderId,
	pickableLinesDataGridRefreshRef,
	refreshRef,
}: ReservableCustomerOrderLinesDataGridProps) => {
	const { dataAsync, paramValues, refresh } = useParameterizedAsyncData({
		fetchData: ReservableCustomerOrderLinesDataGridApi.getViewData,
		initialParams: {
			customerOrderId: customerOrderId,
			acquisitionMethodFilter: genericNullableValue<AcquisitionMethod>(null),
			plannedDeliveryDateFilter: genericNullableValue<IsoDateString>(null),
			onlyFromOwnWarehouseArea: false,
		},
	});
	setRefreshRefValue(refreshRef, refresh);

	const { openDialog } = useGenericDialog();

	return (
		<AsyncRender
			asyncData={dataAsync}
			content={({ rows, deliveryDateOptions }) => (
				<AavoDataGrid<CustomerOrderLineReservationDto>
					rows={rows}
					getRowId={(row) => row.customerOrderLineId}
					refreshData={refresh}
					columns={[
						{
							type: "string",
							field: "part",
							valueGetter: (_, row) =>
								`${row.partNo} | ${row.partDescription_1} | ${row.partDescription_2}`,
							headerName: i18n.t("part"),
							width: 250,
						},
						{
							type: "number",
							field: "reservableQuantity",
							valueGetter: (_, row) => row.salesQuantityAsWarehouseUnits - row.reservedQuantity,
							headerName: i18n.t("reservable_quantity"),
							width: 120,
						},
						coloredBooleanColumn({
							field: "hasEnoughAvailableQuantity",
							headerName: i18n.t("reservable"),
						}),
						textColumn({
							field: "partUnit",
							headerName: i18n.t("unit"),
						}),
						textColumn({
							field: "parentLineDescription",
							headerName: i18n.t("package_part"),
							width: 130,
						}),
						enumColumn({
							field: "acquisitionMethod",
							headerName: i18n.t("acquisition_method"),
							enumLabels: getAcquisitionMethodLabels(),
							width: 140,
						}),
						integerColumn({
							field: "lineNumber",
							headerName: i18n.t("line_number"),
						}),
						textColumn({
							field: "mark",
							headerName: i18n.t("mark"),
						}),
						dateColumn({
							field: "plannedDeliveryDate",
							headerName: i18n.t("delivery_date"),
						}),
					]}
					actionBarComponents={
						<>
							<AavoButton
								label={i18n.t("reserve")}
								icon={faHandPointRight}
								variant={"outlined"}
								onClick={() => {
									openDialog(({ closeDialog }) => ({
										title: i18n.t("reserve"),
										size: "md",
										content: (
											<CustomerOrderLineReservationForm
												customerOrderLines={rows.filter(
													(row) => row.hasEnoughAvailableQuantity,
												)}
												onCompleted={async (result) => {
													await closeDialog();
													if (result.type === "success") {
														await refresh();
													}
													await pickableLinesDataGridRefreshRef?.refresh();
												}}
											/>
										),
									}));
								}}
							/>
							<SelectField
								label={i18n.t("acquisition_method")}
								options={enumOptions(
									dropProps(getAcquisitionMethodLabels(), [
										"PURCHASE_DIRECT",
										"NO_ACQUISITION",
									]),
								)}
								getOptionLabel={(o) => o.label}
								getOptionKey={(o) => o.key}
								onChange={(v) => refresh({ acquisitionMethodFilter: v })}
								value={paramValues.acquisitionMethodFilter}
								sx={{
									minWidth: 200,
								}}
							/>
							<SelectField
								label={i18n.t("delivery_date")}
								options={deliveryDateOptions}
								getOptionKey={(o) => o}
								getOptionLabel={(o) => formatIsoString(o)}
								onChange={(v) => refresh({ plannedDeliveryDateFilter: v })}
								value={paramValues.plannedDeliveryDateFilter}
								sx={{
									minWidth: 200,
								}}
							/>
							<AavoCheckbox
								label={i18n.t("only_from_own_warehouse_area")}
								onChange={(v) => refresh({ onlyFromOwnWarehouseArea: v })}
								checked={paramValues.onlyFromOwnWarehouseArea}
							/>
						</>
					}
					{...dataGridPersistentStateProps("25E18E12F485E574")}
				/>
			)}
		/>
	);
};
