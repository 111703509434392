import { ProjectActivityCostEventApi } from "src/api/generated/erp/project/projectActivity/api/projectActivityCostEventApi.ts";
import { AavoForm } from "src/components/common/forms/AavoForm.tsx";
import i18n from "i18next";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import { FormDateField } from "src/components/common/forms/fields/FormDateField.tsx";
import { FormNumberField } from "src/components/common/forms/fields/FormNumberField.tsx";
import { FormAsyncSelectField } from "src/components/common/forms/fields/FormAsyncSelectField.tsx";
import { CostEvent } from "src/api/generated/erp/db/types/tables/costEvent.ts";
import { requireRule } from "src/components/common/forms/validation.ts";
import { FetchAsyncOptionParams } from "src/components/common/inputFields/AsyncSelectField.tsx";
import { FormLazySelectField } from "src/components/common/forms/fields/FormLazySelectField.tsx";
import { CostEventFormApi } from "src/api/generated/erp/common/costEvents/costEventFormApi.ts";
import { Project } from "src/api/generated/erp/db/types/tables/project.ts";
import { currentLocalDateAsIsoString } from "src/utils/dayjsUtils.ts";
import { useAavoToast } from "src/utils/toast.ts";
import { useGenericDialog } from "src/components/common/dialogs/useGenericDialog.ts";
import { CostEventsOfObjectDataGrid } from "src/components/views/erp/common/costEvents/CostEventsOfObjectDataGrid.tsx";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { faEyes } from "@fortawesome/pro-regular-svg-icons";

interface FormValues extends Omit<CostEvent, "objectId"> {
	project: Project;
	activityId: number;
}

export const ProjectActivityCostEventForm = () => {
	const showToast = useAavoToast();
	const { openDialog } = useGenericDialog();
	return (
		<AavoForm<FormValues, void>
			defaultValues={{
				objectType: "PROJECT_ACTIVITY",
				eventDate: currentLocalDateAsIsoString(),
			}}
			hideCancel={true}
			onCompleted={(_, { setValue }) => {
				showToast(i18n.t("cost_event_saved"));
				setValue("itemId", null);
				setValue("quantity", null);
				setValue("eventDate", currentLocalDateAsIsoString());
				setValue("description", null);
			}}
			render={({ control, watch, setValue, setFocus }) => {
				const project = watch("project");
				const costEventPriceListId = project?.costEventPriceListId;
				const activityId = watch("activityId");
				return (
					<>
						<FormAsyncSelectField
							control={control}
							name={"project"}
							label={i18n.t("project")}
							fetchOptions={({ searchQuery, currentSelection }: FetchAsyncOptionParams<number>) =>
								ProjectActivityCostEventApi.getProjectOptions({
									searchQuery: searchQuery,
									currentSelection: currentSelection,
								})
							}
							getOptionKey={(o) => o.projectId}
							getOptionLabel={(o) => o.projectDescription}
							formValueType={"option"}
							onChange={async () => {
								setValue("activityId", null);
								setValue("itemId", null);
							}}
							rules={requireRule()}
						/>
						<FormLazySelectField
							control={control}
							name={"activityId"}
							label={i18n.t("activity")}
							fetchOptions={() =>
								ProjectActivityCostEventApi.getProjectActivityOptions({
									projectId: project.projectId,
								})
							}
							getOptionKey={(o) => o.activityId}
							getOptionLabel={(o) => o.activityName}
							rules={requireRule()}
							disabled={project == null}
							disableClearable
						/>
						<AsyncButton
							label={i18n.t("activity_cost_events")}
							icon={faEyes}
							variant={"outlined"}
							sx={{
								alignSelf: "flex-start",
							}}
							disabled={costEventPriceListId == null || activityId == null}
							onClick={() =>
								openDialog(() => ({
									title: i18n.t("activity_cost_events"),
									size: "md",
									content: (
										<CostEventsOfObjectDataGrid
											key={activityId!}
											objectRef={{
												objectType: "PROJECT_ACTIVITY",
												objectId: activityId!,
											}}
											costEventPriceListId={costEventPriceListId!}
										/>
									),
								}))
							}
						/>
						<FormLazySelectField
							control={control}
							name={"itemId"}
							label={i18n.t("item")}
							fetchOptions={() =>
								ProjectActivityCostEventApi.getProjectAvailablePriceListItemOptions({
									projectId: project.projectId,
								})
							}
							rules={requireRule()}
							getOptionKey={(o) => o.costEventItemId}
							getOptionLabel={(o) => o.itemName}
							disabled={project == null}
							disableClearable
							onChange={() => {
								setFocus("quantity");
							}}
						/>
						<FormNumberField
							control={control}
							name={"quantity"}
							label={i18n.t("quantity")}
							rules={requireRule()}
						/>
						<FormTextField
							control={control}
							name={"description"}
							label={i18n.t("description")}
							multiline
							minRows={1}
						/>
						<FormDateField
							control={control}
							name={"eventDate"}
							label={i18n.t("date")}
							rules={requireRule()}
						/>
					</>
				);
			}}
			submit={async (formValues) => {
				if (formValues.project.costEventPriceListId == null) {
					throw new Error("No cost event price list selected");
				}
				if (formValues.costEventId == null) {
					await CostEventFormApi.createNew({
						costEventItemId: formValues.itemId,
						costEventPriceListId: formValues.project.costEventPriceListId,
						description: formValues.description,
						quantity: formValues.quantity,
						eventDate: formValues.eventDate,
						objectRef: {
							objectType: "PROJECT_ACTIVITY",
							objectId: formValues.activityId,
						},
					});
				} else {
					const costEvent = { ...formValues, objectId: formValues.activityId };
					await CostEventFormApi.update({
						costEvent: costEvent,
						costEventPriceListId: formValues.project.costEventPriceListId,
					});
				}
			}}
		/>
	);
};
