// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const CustomerOrderPackageLabelSizeValues = ["_10X10", "A4"] as const;

export type CustomerOrderPackageLabelSize = (typeof CustomerOrderPackageLabelSizeValues)[number];

export const getCustomerOrderPackageLabelSizeLabels = () => ({
	_10X10: t("_10x10"),
	A4: t("a4"),
});

export const getCustomerOrderPackageLabelSizeLabel = (value: CustomerOrderPackageLabelSize): string => {
	return getCustomerOrderPackageLabelSizeLabels()[value];
};
