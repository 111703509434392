// This file is automatically generated. Do not edit manually.

import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ShopOrderOperationStateTransferApi {
	export async function startOperations(args: { shopOrderOperationIds: Array<number> }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/workCenterJobQueue/startOperations",
			method: "POST",
			data: {
				shopOrderOperationIds: args.shopOrderOperationIds,
			},
		});
		return response as void;
	}

	export async function revertStartOperations(args: { shopOrderOperationIds: Array<number> }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/workCenterJobQueue/revertStartOperations",
			method: "POST",
			data: {
				shopOrderOperationIds: args.shopOrderOperationIds,
			},
		});
		return response as void;
	}

	export async function hasWorkcenterIncompleteOperations(args: { shopOrderId: number }): Promise<boolean> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/workCenterJobQueue/hasWorkcenterIncompleteOperations",
			method: "POST",
			data: {
				shopOrderId: args.shopOrderId,
			},
		});
		return response as boolean;
	}

	export async function completeOperations(args: { shopOrderOperationIds: Array<number> }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/workCenterJobQueue/completeOperations",
			method: "POST",
			data: {
				shopOrderOperationIds: args.shopOrderOperationIds,
			},
		});
		return response as void;
	}

	export async function revertCompleteOperations(args: { shopOrderOperationIds: Array<number> }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/workCenterJobQueue/revertCompleteOperations",
			method: "POST",
			data: {
				shopOrderOperationIds: args.shopOrderOperationIds,
			},
		});
		return response as void;
	}
}
