// This file is automatically generated. Do not edit manually.

import { PartConfiguratorType } from "src/api/generated/erp/configurator/configuratorType/partConfiguratorType";
import { ConfigurationTestContextData } from "src/api/generated/erp/configurator/contextData/configurationTestContextData";
import { ObjectAttributeField } from "src/api/generated/erp/common/objectAttributes/objectAttributeField";
import { CatalogPartRevision } from "src/api/generated/erp/db/types/tables/catalogPartRevision";
import { ConfiguratorLibraryVersion } from "src/api/generated/erp/db/types/tables/configuratorLibraryVersion";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace TestConfigurationContextDataFormApi {
	export async function getInitData(args: {
		configuratorType: PartConfiguratorType;
	}): Promise<TestConfigurationContextDataFormApi_InitData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/testing/testContextDataForm/getInitData",
			method: "POST",
			data: {
				configuratorType: args.configuratorType,
			},
		});
		return response as TestConfigurationContextDataFormApi_InitData;
	}

	export async function saveTestConfigurationContextData(args: {
		configuratorType: PartConfiguratorType;
		contextData: ConfigurationTestContextData;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/testing/testContextDataForm/saveTestConfigurationContextData",
			method: "POST",
			data: {
				configuratorType: args.configuratorType,
				contextData: args.contextData,
			},
		});
		return response as void;
	}
}

export interface TestConfigurationContextDataFormApi_InitData {
	configurableBomLines: Array<TestConfigurationContextDataFormApi_InitData_ConfigurableBomLine>;
	contextData: ConfigurationTestContextData;
	customerOrderAttributeFields: Array<ObjectAttributeField>;
	libraries: Array<TestConfigurationContextDataFormApi_InitData_ConfiguratorLibraryDto>;
}

export interface TestConfigurationContextDataFormApi_InitData_ConfigurableBomLine {
	activeRevisionId: number;
	catalogPartId: number;
	partDescription1: string;
	partDescription2: string;
	partNo: string;
	revisionOptions: Array<CatalogPartRevision>;
}

export interface TestConfigurationContextDataFormApi_InitData_ConfiguratorLibraryDto {
	versionOptions: Array<ConfiguratorLibraryVersion>;
	configuratorLibraryId: number;
	name: string;
}
