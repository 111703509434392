import {FormCommonProps} from "src/components/common/forms/types.ts";
import {AsyncFetchRender} from "src/components/common/async/AsyncFetchRender.tsx";
import {ShopOrderBomApi} from "src/api/generated/erp/production/api/shopOrderBom/shopOrderBomApi.ts";
import {
    ShopOrderReservationBaseForm
} from "src/components/views/erp/production/ShopOrderPicking/ShopOrderBomReservationFormBase.tsx";

export interface ShopOrderBomReservationFormProps extends FormCommonProps<void> {
	shopOrderId: number;
}

export const ShopOrderBomReservationForm = (props: ShopOrderBomReservationFormProps) => {
	const { shopOrderId } = props;

	return (
		<AsyncFetchRender
			fetch={() => ShopOrderBomApi.getShopOrderBom({ shopOrderId: shopOrderId })}
			content={(data) => <ShopOrderReservationBaseForm shopOrderBomLines={data} {...props} />}
		/>
	);
};
