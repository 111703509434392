import i18n from "i18next";
import { AavoMosaic } from "src/components/common/mosaic/AavoMosaic.tsx";
import { useRefreshRef } from "src/utils/useRefreshRef.ts";
import {
	JobQueueInitialsView,
	JobQueueInitialsViewProps,
} from "src/components/views/erp/production/jobQueue/base/JobQueueInitialsView.tsx";
import {
	JobQueueCompletedView,
	JobQueueCompletedViewProps,
} from "src/components/views/erp/production/jobQueue/base/JobQueueCompletedView.tsx";
import {
	JobQueueInProgressView,
	JobQueueInProgressViewProps,
} from "src/components/views/erp/production/jobQueue/base/inProgressView/JobQueueInProgressView.tsx";
import { JobQueueViewContext } from "src/components/views/erp/production/jobQueue/base/JobQueueViewContext.ts";

export interface JobQueueViewProps {
	viewId: string;
	initialsFetchData: JobQueueInitialsViewProps["fetchData"];
	inProgressFetchData: JobQueueInProgressViewProps["fetchData"];
	completedFetchData: JobQueueCompletedViewProps["fetchData"];
	inProgressExtraActionBarComponents?: JobQueueInProgressViewProps["extraActionBarComponents"];
	initialsExtraRowContextMenuComponents?: JobQueueInitialsViewProps["extraRowContextMenuComponents"];
}

export const JobQueueView = ({
	viewId,
	initialsFetchData,
	inProgressFetchData,
	completedFetchData,
	inProgressExtraActionBarComponents,
	initialsExtraRowContextMenuComponents,
}: JobQueueViewProps) => {
	return (
		<JobQueueViewContext.Provider
			value={{
				initialsViewRefreshRef: useRefreshRef(),
				inProgressViewRefreshRef: useRefreshRef(),
				completedViewRefreshRef: useRefreshRef(),
				inProgressViewDocumentsRefreshRef: useRefreshRef(),
			}}
		>
			<AavoMosaic
				viewId={"C8B563C5E4FACA0D"}
				layout={{
					type: "tab",
					items: [
						{
							type: "panel",
							title: i18n.t("not_started"),
							content: (
								<JobQueueInitialsView
									fetchData={initialsFetchData}
									gridId={`${viewId}_A09AD21D659D102E`}
									extraRowContextMenuComponents={initialsExtraRowContextMenuComponents}
								/>
							),
						},
						{
							type: "panel",
							title: i18n.t("in_progress"),
							content: (
								<JobQueueInProgressView
									fetchData={inProgressFetchData}
									extraActionBarComponents={inProgressExtraActionBarComponents}
								/>
							),
						},
						{
							type: "panel",
							title: i18n.t("completed"),
							content: (
								<JobQueueCompletedView
									fetchData={completedFetchData}
									gridId={`${viewId}_65A8FA96AF3955F8`}
								/>
							),
						},
					],
				}}
			/>
		</JobQueueViewContext.Provider>
	);
};
