import { FieldPath, FieldPathValue, useController, UseControllerProps } from "react-hook-form";
import { AavoTextField, AavoTextFieldProps } from "../../inputFields/AavoTextField.tsx";
import { FormFieldLayoutProps, formFieldLayoutPropsToSx } from "src/components/common/forms/styles.ts";
import { mergeSx } from "src/utils/styles.ts";

export interface FormTextFieldProps<
	TFieldValues extends object,
	TFieldName extends FieldPath<TFieldValues>,
> extends Omit<AavoTextFieldProps, "value" | "ref" | "name">,
		Pick<UseControllerProps<TFieldValues, TFieldName>, "control" | "name" | "rules">,
		FormFieldLayoutProps {
	mapInputToValue?: (input: string) => FieldPathValue<TFieldValues, TFieldName>;
}

export const FormTextField = <
	TFieldValues extends object,
	TFieldName extends FieldPath<TFieldValues>,
>({
	control,
	name,
	rules,
	mapInputToValue,
	disabled,
	onChange: onChangeProp,
	spanGridColumns,
	startNewGridRow,
	sx,
	...other
}: FormTextFieldProps<TFieldValues, TFieldName>) => {
	const { field, fieldState } = useController({
		name,
		control,
		rules,
		defaultValue: "" as FieldPathValue<TFieldValues, TFieldName>,
	});

	return (
		<AavoTextField
			error={fieldState.error?.message}
			value={field.value !== null ? field.value : ""}
			onChange={(v: string, e) => {
				const mappedValue = mapInputToValue ? mapInputToValue(v) : v;
				field.onChange(mappedValue);
				onChangeProp?.(v, e);
			}}
			onBlur={field.onBlur}
			disabled={disabled || field.disabled}
			name={field.name}
			inputRef={field.ref}
			sx={mergeSx(
				formFieldLayoutPropsToSx({
					spanGridColumns,
					startNewGridRow
				}),
				sx,
			)}
			{...other}
		/>
	);
};
