import { FormCommonProps } from "src/components/common/forms/types.ts";
import { DeepPartial } from "react-hook-form";
import { ConfigurationProperty } from "src/api/generated/erp/db/types/tables/configurationProperty.ts";
import { AavoFormContentParams } from "src/components/common/forms/AavoForm.tsx";
import {
	ConfiguratorPropertyEditApi,
	ConfiguratorPropertyEditApi_FormInitData,
} from "src/api/generated/erp/configurator/management/api/configuratorPropertyEditApi.ts";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import i18n from "i18next";
import { requireRule } from "src/components/common/forms/validation.ts";
import { FormEnumSelectField } from "src/components/common/forms/fields/FormEnumSelectField.tsx";
import { getConfigurationPropertyDatatypeLabels } from "src/api/generated/erp/db/types/enums/configurationPropertyDatatype.ts";
import { FormCheckbox } from "src/components/common/forms/fields/FormCheckbox.tsx";
import Typography from "@mui/material/Typography";
import { AsyncForm } from "src/components/common/forms/AsyncForm.tsx";
import { FormConfiguratorLuaEditor } from "src/components/views/erp/configurator/scripting/FormConfiguratorLuaEditor.tsx";

export interface ConfiguratorPropertyFormProps extends FormCommonProps<number> {
	productFamilyVersionId: number;
	configuratorPropertyId: number | undefined;
}

interface FormValues extends ConfigurationProperty {}

export const ConfiguratorPropertyForm = (props: ConfiguratorPropertyFormProps) => {
	const { onCompleted, onFormEdited, productFamilyVersionId, configuratorPropertyId } = props;
	return (
		<AsyncForm
			fetch={() => ConfiguratorPropertyEditApi.getFormInitData({ propertyId: configuratorPropertyId })}
			getDefaultValues={getDefaultValues}
			submit={submit}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			render={(contentParams) => <FormContent {...props} {...contentParams} />}
		/>
	);

	function getDefaultValues({
		property,
	}: ConfiguratorPropertyEditApi_FormInitData): DeepPartial<FormValues> {
		if (property != null) {
			return property;
		} else
			return {
				productFamilyVersionId: productFamilyVersionId,
				visibleOnPrintouts: true,
				valueScript: "return 0",
				subConfigurationValueScript: "",
			};
	}

	async function submit(values: FormValues) {
		if (configuratorPropertyId != null) {
			await ConfiguratorPropertyEditApi.updateProperty({
				property: values,
			});
			return configuratorPropertyId;
		} else {
			return await ConfiguratorPropertyEditApi.createProperty({
				property: values,
			});
		}
	}
};

interface FormContentProps extends ConfiguratorPropertyFormProps, AavoFormContentParams<FormValues> {}

const FormContent = ({ control, watch, productFamilyVersionId }: FormContentProps) => {
	const valueFromComponentId = watch("valueFromComponentId");

	const disabledBecauseEditingFromComponent =
		valueFromComponentId != null ?
			i18n.t("this_can_be_edited_by_editing_connected_component")
		:	undefined;

	return (
		<>
			<FormTextField
				control={control}
				name="name"
				label={i18n.t("name")}
				disabled={disabledBecauseEditingFromComponent}
				rules={requireRule()}
			/>
			<FormTextField
				control={control}
				name="title"
				label={i18n.t("view_name")}
				disabled={disabledBecauseEditingFromComponent}
				rules={requireRule()}
			/>
			<FormEnumSelectField
				control={control}
				name="datatype"
				label={i18n.t("data_type")}
				rules={requireRule()}
				disabled={disabledBecauseEditingFromComponent}
				options={getConfigurationPropertyDatatypeLabels()}
			/>
			{valueFromComponentId != null ?
				<Typography fontStyle={"italic"} children={i18n.t("value_from_connected_component")} />
			:	<FormConfiguratorLuaEditor
					control={control}
					name={"valueScript"}
					label={i18n.t("value_function")}
					rules={requireRule()}
					productFamilyVersionId={productFamilyVersionId}
					catalogPartRevisionId={null}
				/>
			}
			<FormConfiguratorLuaEditor
				control={control}
				name={"subConfigurationValueScript"}
				label={i18n.t("value_function_when_on_sub_configurator")}
				productFamilyVersionId={productFamilyVersionId}
				catalogPartRevisionId={null}
			/>
			<FormCheckbox
				control={control}
				name="visibleOnPrintouts"
				label={i18n.t("visible_on_printouts")}
			/>
			{watch("visibleOnPrintouts") && (
				<FormCheckbox
					control={control}
					name="hideIfEmptyValue"
					label={i18n.t("hide_if_empty_value")}
				/>
			)}
		</>
	);
};
