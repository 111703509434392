import { TransformedConfigurationComponent_SubConfigurator } from "src/api/generated/erp/configurator/componentTransformation/transformedConfigurationComponent.ts";
import { useGenericDialog } from "src/components/common/dialogs/useGenericDialog.ts";
import { openSubConfiguratorFormHelper } from "src/components/views/erp/configurator/configuratorForm/configuratorFormUtils.tsx";
import { ConfigurationPropertyValue_SubConfiguration } from "src/api/generated/io/aavo/applications/db/erp/types/configurationPropertyValue.ts";
import { PartConfiguratorType } from "src/api/generated/erp/configurator/configuratorType/partConfiguratorType.ts";
import { ConfigurationPropertyValues } from "src/api/generated/erp/configurator/model/configurationPropertyValues.ts";
import { useErrorDialog } from "src/components/common/dialogs/errorDialog/userErrorDialog.ts";
import { VerticalBox } from "src/components/common/box/VerticalBox.tsx";
import { HorizontalBox } from "src/components/common/box/HorizontalBox.tsx";
import { Typography } from "@mui/material";
import { ComponentDocumentButton } from "src/components/views/erp/configurator/configuratorForm/componentDocument/ComponentDocumentButton.tsx";
import { ClickableTypography } from "src/components/common/typography/ClickableTypography.tsx";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { faPen } from "@fortawesome/pro-regular-svg-icons";
import i18n from "i18next";

export interface SubConfiguratorComponentProps {
	component: TransformedConfigurationComponent_SubConfigurator;
	onSubmit: (value: ConfigurationPropertyValue_SubConfiguration) => Promise<unknown>;
	parentConfiguratorType: PartConfiguratorType;
	parentPropertyValues: ConfigurationPropertyValues;
}

export const SubConfiguratorComponent = ({
	component,
	parentConfiguratorType,
	parentPropertyValues,
	onSubmit,
}: SubConfiguratorComponentProps) => {
	const { openDialog } = useGenericDialog();
	const { logErrorAndShowOnDialog } = useErrorDialog();

	const hasError = component.required && Object.keys(component.currentValue.value).length === 0;

	return (
		<VerticalBox
			sx={{
				border: "1px solid",
				borderColor: hasError ? "error.main" : "grey.300",
				borderRadius: "3px",
				padding: "0.5rem",
				gap: 1,
			}}
		>
			<HorizontalBox alignItems={"center"} gap={1}>
				<Typography fontWeight="bold" paragraph={false}>
					{component.label}
				</Typography>
				<ComponentDocumentButton
					componentId={component.configurationComponentId}
					sx={{
						marginY: "-0.5rem",
					}}
				/>
			</HorizontalBox>
			<HorizontalBox alignItems={"center"} gap={"0.5rem"}>
				<ClickableTypography onClick={() => openSubConfiguratorForm().catch(logErrorAndShowOnDialog)}>
					{component.currentValue.label}
				</ClickableTypography>
				<AsyncButton
					icon={faPen}
					tooltip={i18n.t("edit")}
					onClick={async () => {
						await openSubConfiguratorForm();
					}}
				/>
			</HorizontalBox>
		</VerticalBox>
	);

	async function openSubConfiguratorForm() {
		await openSubConfiguratorFormHelper({
			openDialog: openDialog,
			parentConfiguratorType: parentConfiguratorType,
			parentPropertyValues: parentPropertyValues,
			component: component,
			subConfiguratorValue: component.currentValue,
			onSubmit: onSubmit,
		});
	}
};
