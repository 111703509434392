import { useContextOrThrow } from "src/utils/useContextOrThrow.tsx";
import { AavoViewsDispatchContext } from "src/components/views/legacy/aavoViewsContext.tsx";
import { useStartUpData } from "src/contexts/useStartUpData.ts";

export type UseHandleLegacyCommandActionRequests = (actions: any[]) => void;

export function useHandleLegacyCommandActionRequests(): UseHandleLegacyCommandActionRequests {
	const dispatchViewAction = useContextOrThrow(AavoViewsDispatchContext);
	const startUpData = useStartUpData();
	return (actions: any[]) => {
		actions.forEach((action) => {
			dispatchViewAction({
				type: "setViewActionEvents",
				startUpData: startUpData,
				events: [
					{
						action: action,
						senderViewId: "",
						allParams: action.params,
						staticParams: [],
						senderSourceViewId: null,
						senderParentViewId: null,
						initiator: {
							type: "ViewInitiator",
							mouseEvent: null,
						},
					},
				],
			});
		});
	};
}
