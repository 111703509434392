import React, { useEffect, useState } from "react";
import { MosaicWindow } from "react-mosaic-component";
import { Box } from "@mui/material";
import { MosaicBranch } from "react-mosaic-component/src/types.ts";
import { AavoMosaicWindowToolbar } from "./AavoMosaicWindowToolbar";
import {
	AavoMosaicTabItem,
	AavoMosaicTabWithKey,
	resolveAavoMosaicTabItemKey,
} from "src/components/common/mosaic/types.ts";
import { AavoMosaic } from "./AavoMosaic";
import { logError } from "src/errorHandling/errorLogging.ts";
import { VerticalBox } from "src/components/common/box/VerticalBox.tsx";
import { hiddenElementKeepingState, positionAbsoluteFullSize } from "src/styles/sx.ts";
import { mergeSx } from "src/utils/styles.ts";
import { useMaybeStoredState } from "src/utils/useMaybeStoredState.ts";

export interface AavoMosaicWindowProps {
	viewId: string | undefined;
	tabKey: string;
	path: MosaicBranch[];
	tabsByKeys: Record<string, AavoMosaicTabWithKey>;
	resetLayout: () => void;
	toolbarExtraComponents?: React.ReactNode;
}

export const AavoMosaicWindow = ({
	viewId,
	tabKey,
	path,
	tabsByKeys,
	resetLayout,
	toolbarExtraComponents,
}: AavoMosaicWindowProps) => {
	const tab = tabsByKeys[tabKey];

	const [activeItemKey, setActiveItemKey] = useMaybeStoredState<string | null>(
		null,
		`aavoMosaicTabActiveItemKey#${viewId}#${tabKey}`,
		"string",
	);

	if (tab === undefined) {
		// Layout has changed and saved layout is no longer valid.
		// Reset will restore the initial layout.
		resetLayout();
		return null;
	}

	const visibleTabItems = tab.items.reduce<AavoMosaicTabItem[]>((acc, item) => {
		if (item === false || item.hidden) {
			return acc;
		}
		return [...acc, item];
	}, []);

	const activeItemIndex = visibleTabItems.findIndex((item) => {
		return resolveAavoMosaicTabItemKey(item) === activeItemKey;
	});

	// Select last item, if previous active item has been hidden later.
	// Select first, if active item index is -1
	const actualActiveItemIndex = Math.max(Math.min(activeItemIndex, visibleTabItems.length - 1), 0);

	return (
		<VerticalBox
			sx={{
				flex: 1,
				alignItems: "stretch",
				"& > *": {
					flex: 1,
				},
			}}
		>
			<MosaicWindow
				title={""}
				path={path}
				draggable={false}
				renderToolbar={() => {
					if (tab.hideToolbar) return <></>;
					return (
						<>
							<AavoMosaicWindowToolbar
								items={visibleTabItems}
								activeItemIndex={actualActiveItemIndex}
								setActiveItemIndex={setActiveItemIndex}
							/>
							{toolbarExtraComponents}
						</>
					);
				}}
			>
				<Box
					sx={{
						flex: 1,
						display: "flex",
						height: "100%",
					}}
				>
					{visibleTabItems.map((item, index) => (
						<AavoMosaicTabItemComponent
							key={resolveAavoMosaicTabItemKey(item)}
							mosaicViewId={viewId}
							item={item}
							tabKey={tabKey}
							isActive={actualActiveItemIndex === index}
							itemIndex={index}
						/>
					))}
				</Box>
			</MosaicWindow>
		</VerticalBox>
	);

	async function setActiveItemIndex(index: number) {
		if (tab == null) return;

		try {
			const newActiveItem = visibleTabItems[index];
			const newActiveItemKey =
				newActiveItem == null ? null : resolveAavoMosaicTabItemKey(newActiveItem);

			if (tab.confirmChangeActiveItem) {
				const confirmed = await tab.confirmChangeActiveItem(newActiveItemKey);
				if (!confirmed) return;
			}
			setActiveItemKey(newActiveItemKey);
		} catch (e) {
			logError(e);
		}
	}
};

interface AavoMosaicTabItemComponentProps {
	mosaicViewId: string | undefined;
	item: AavoMosaicTabItem;
	tabKey: string;
	isActive: boolean;
	itemIndex: number;
}

const AavoMosaicTabItemComponent = ({
	mosaicViewId,
	item,
	tabKey,
	isActive,
	itemIndex,
}: AavoMosaicTabItemComponentProps) => {
	const [hasRendered, setHasRendered] = useState(false);

	useEffect(() => {
		if (isActive) {
			setHasRendered(true);
		}
	}, [isActive]);

	// Render tabs lazily on first activation.
	if (!hasRendered && !isActive) {
		return null;
	}

	const embeddedMosaicViewId = mosaicViewId != null ? `${mosaicViewId}_${tabKey}_${itemIndex}` : undefined;
	const content =
		item.type === "panel" ? item.content : <AavoMosaic viewId={embeddedMosaicViewId} layout={item} />;

	return (
		<Box
			sx={mergeSx(
				{
					flex: 1,
					display: "flex",
				},
				positionAbsoluteFullSize,
				!isActive && hiddenElementKeepingState,
			)}
			children={content}
		/>
	);
};
