import { AsyncDataGrid } from "src/components/common/dataGrid/AsyncDataGrid";
import { TransformedPartConfigurationBomLine } from "src/api/generated/erp/configurator/configurationResolving/bom/transformedPartConfigurationBomLine.ts";
import {
	enumColumn,
	floatColumn,
	integerColumn,
	textColumn,
} from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { dataGridPersistentStateProps } from "src/components/common/dataGrid/dataGridStateStorage.ts";
import { getAcquisitionMethodLabels } from "src/api/generated/erp/db/types/enums/acquisitionMethod.ts";
import { TestConfigurationResultViewCommonProps } from "src/components/views/erp/configurator/configuratorTesting/TestConfigurationResultViewCommonProps.ts";
import { AsyncMenuButton } from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import { faExternalLink } from "@fortawesome/pro-regular-svg-icons";
import { useGenericDialog } from "src/components/common/dialogs/useGenericDialog.ts";
import React from "react";

export interface TestConfigurationBomDataGridBaseProps extends TestConfigurationResultViewCommonProps {
	fetchRows: () => Promise<TransformedPartConfigurationBomLine[]>;
	subConfigurationResultsView: React.FC<TestConfigurationResultViewCommonProps>;
}

export const TestConfigurationBomDataGridBase = ({
	fetchRows,
	configurationSessionId,
	configuratorType,
	propertyValues,
	subConfigurationResultsView,
}: TestConfigurationBomDataGridBaseProps) => {
	const { openDialog } = useGenericDialog();

	return (
		<AsyncDataGrid<TransformedPartConfigurationBomLineWithIndex>
			fetchRows={async () => {
				const rows = await fetchRows();
				return rows.map((row, index) => ({ ...row, index }));
			}}
			getRowId={(row) => row.index}
			columns={[
				integerColumn({
					field: "referenceNo",
					headerName: i18n.t("reference_no"),
					width: 100,
				}),
				textColumn({
					field: "description",
					headerName: i18n.t("part"),
					width: 200,
				}),
				floatColumn({
					field: "quantity",
					headerName: i18n.t("quantity"),
					width: 100,
				}),
				enumColumn({
					field: "acquisitionMethod",
					headerName: i18n.t("acquisition_method"),
					width: 120,
					enumLabels: getAcquisitionMethodLabels(),
				}),
				textColumn({
					field: "note",
					headerName: i18n.t("note"),
					width: 200,
				}),
				{
					field: "subConfigurationValue",
					headerName: "",
					width: 600,
					valueGetter: (_, row) => {
						JSON.stringify(row.bomLineConfigurationPropertyValues);
					},
				},
			]}
			rowContextMenuComponents={({ row }) => [
				<AsyncMenuButton
					key={"openSubConfiguration"}
					label={i18n.t("sub_configuration")}
					icon={faExternalLink}
					onClick={() => {
						openSubConfigurationResults(row);
					}}
				/>,
			]}
			{...dataGridPersistentStateProps("AE593DFB73807646")}
		/>
	);

	function openSubConfigurationResults(row: TransformedPartConfigurationBomLine) {
		const subConfigValue = row.bomLineConfigurationPropertyValues;
		if (subConfigValue == null) return;
		openDialog(() => ({
			title: row.description,
			content: subConfigurationResultsView({
				configurationSessionId: configurationSessionId,
				propertyValues: subConfigValue,
				configuratorType: {
					type: "subConfigurator",
					parentConfiguratorType: configuratorType,
					parentProperties: propertyValues,
					subConfiguratorCatalogPartId: row.bomLineCatalogPartId,
					isTestConfigurator: configuratorType.isTestConfigurator,
				},
			}),
		}));
	}
};

interface TransformedPartConfigurationBomLineWithIndex extends TransformedPartConfigurationBomLine {
	index: number;
}
