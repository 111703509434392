import { AsyncFunction } from "../../types/functions.ts";
import { useCallback, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { logError, LoggedError } from "src/errorHandling/errorLogging.ts";

export interface AsyncState<Result> {
	loading?: boolean;
	data?: Result;
	error?: LoggedError;
}

export type AsyncDataAsyncSetter<Result> = (fetchData: () => Promise<Result>) => Promise<Result>;

export type AsyncDataImmediateSetter<Result> = (setter: (data: Result) => Result) => void;

export const useAsyncState = <Result>(
	defaultValue?: Result,
): readonly [
	AsyncState<Result>,
	AsyncDataAsyncSetter<Result>,
	AsyncDataImmediateSetter<Result>,
] => {
	const [asyncState, setAsyncState] = useState<AsyncState<Result>>({
		data: defaultValue,
	});

	const callId = useRef<string>("initial");

	const asyncSetter = useCallback(
		async (fetch: AsyncFunction<void, Result>): Promise<Result> => {
			const currentCallId = uuidv4();
			callId.current = currentCallId;
			try {
				if (currentCallId === callId.current) {
					setAsyncState((prev) => {
						return {
							data: prev.data,
							loading: true,
							error: undefined,
						};
					});
				}
				const fetchResult = await fetch();
				if (currentCallId === callId.current) {
					setAsyncState({
						loading: false,
						data: fetchResult,
						error: undefined,
					});
				}
				return fetchResult;
			} catch (error) {
				const loggedError = logError(error);
				if (currentCallId === callId.current) {
					setAsyncState({ error: loggedError });
				}
				throw loggedError;
			}
		},
		[setAsyncState, callId],
	);

	const immediateSetter = useCallback(
		(setter: (data: Result) => Result) => {
			setAsyncState((prev) => mapAsyncState(prev, setter));
		},
		[setAsyncState],
	);

	return [asyncState, asyncSetter, immediateSetter] as const;
};

export const mapAsyncState = <T, U = T>(state: AsyncState<T>, mapper: (data: T) => U) => {
	if (state.data !== undefined)
		return {
			...state,
			data: mapper(state.data),
		};

	return {
		...state,
		data: undefined,
	};
};
