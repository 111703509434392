import { AavoMosaic } from "src/components/common/mosaic/AavoMosaic.tsx";
import {
	CustomerOrderDeliveryForm,
	CustomerOrderDeliveryFormProps,
} from "src/components/views/erp/sales/delivery/CustomerOrderDeliveryForm.tsx";
import { CustomerOrderPackagesDataGridBase } from "src/components/views/erp/sales/picking/CustomerOrderPackagesDataGridBase.tsx";
import { CustomerOrderDeliveryView } from "src/api/generated/erp/db/types/tables/customerOrderDeliveryView.ts";
import i18n from "i18next";

export interface CustomerOrderDeliveryFormWithPackagesProps {
	customerOrderDelivery: CustomerOrderDeliveryView;
	onFormCompleted: CustomerOrderDeliveryFormProps["onCompleted"];
	onFormEdited?: CustomerOrderDeliveryFormProps["onFormEdited"];
}

export const CustomerOrderDeliveryFormWithPackages = ({
	customerOrderDelivery,
	onFormCompleted,
	onFormEdited,
}: CustomerOrderDeliveryFormWithPackagesProps) => {
	return (
		<AavoMosaic
			viewId={"A01310EA80E99B3D"}
			layout={{
				type: "row",
				first: {
					type: "panel",
					title: i18n.t("delivery"),
					content: (
						<CustomerOrderDeliveryForm
							customerOrderId={customerOrderDelivery.customerOrderId}
							customerOrderDeliveryId={customerOrderDelivery.customerOrderDeliveryId}
							onCompleted={onFormCompleted}
							onFormEdited={onFormEdited}
						/>
					),
				},
				second: {
					type: "panel",
					title: i18n.t("delivery_packages"),
					content: (
						<CustomerOrderPackagesDataGridBase
							gridId={"215795492EC3817C"}
							customerOrderDeliveryId={customerOrderDelivery.customerOrderDeliveryId}
							editable={customerOrderDelivery.deliveryState === "INITIAL"}
							addEnabled={false}
							showCalculateWeightButton={false}
							disablePagination
						/>
					),
				},
			}}
		/>
	);
};
