import { useMemo } from "react";
import { BoxProps, Button, Typography } from "@mui/material";
import { mergeSx } from "src/utils/styles";
import i18n from "i18next";
import { linebreaksToHtmlBreaks } from "src/utils/strings";
import { OpenErrorReportDialogButton } from "src/sentry/OpenErrorReportDialogButton";
import { VerticalBox } from "../box/VerticalBox";
import { LoggedError } from "src/errorHandling/errorLogging.ts";
import { faRefresh } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HorizontalBox } from "src/components/common/box/HorizontalBox.tsx";

export interface ErrorTextProps extends BoxProps {
	error: LoggedError;
	showReportButton?: boolean;
	reload?: () => void;
}

export const ErrorText = ({
	error,
	reload,
	showReportButton = true,
	sx,
	...other
}: ErrorTextProps) => {
	const errorContent = useMemo(() => {
		const ret = linebreaksToHtmlBreaks(error.message, {
			textAlign: "center",
		});
		if (error.sentryEventId)
			ret.push(
				<Typography
					key={"aavo-error-text-log-id"}
					sx={{
						textAlign: "center",
					}}
				>{`${i18n.t("log_id")}: ${error.sentryEventId}`}</Typography>,
			);
		return ret;
	}, [error]);

	return (
		<VerticalBox
			sx={mergeSx(
				{
					flex: 1,
					justifyContent: "center",
					alignItems: "stretch",
					gap: 1,
					overflow: "auto",
				},
				sx,
			)}
			{...other}
		>
			{errorContent}
			<HorizontalBox
				sx={{
					justifyContent: "center",
					alignItems: "center",
					flexWrap: "wrap",
					gap: 1,
					marginTop: 1,
				}}
			>
				{reload && (
					<Button
						variant={"outlined"}
						startIcon={<FontAwesomeIcon icon={faRefresh} />}
						onClick={() => {
							reload();
						}}
					>
						<Typography variant={"button"}>{i18n.t("reload")}</Typography>
					</Button>
				)}
				{showReportButton && error.treatAsError && error.sentryEventId && (
					<OpenErrorReportDialogButton eventId={error.sentryEventId} />
				)}
			</HorizontalBox>
		</VerticalBox>
	);
};
