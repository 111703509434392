import { VatCode } from "src/api/generated/erp/db/types/tables/vatCode.ts";
import i18n from "i18next";

export function getDefaultVatCode(vatCodes: VatCode[]): VatCode {
	const ret = vatCodes.find((vatCode) => vatCode.isDefault);
	if (ret == null) throw Error(i18n.t("default_vat_code_not_defined"));
	return ret;
}

export function calculateSalesMarginPercent({
	cost,
	salesPrice,
}: {
	cost: number;
	salesPrice: number;
}): number {
	if(cost === 0) return 0;
	return ((salesPrice - cost) / salesPrice) * 100;
}
