import i18n from "i18next";
import { DocumentPreviewView } from "src/components/views/documents/preview/DocumentPreviewView.tsx";
import { RefreshableElementRef, useRefreshRef } from "src/utils/useRefreshRef.ts";
import { DocumentConnectionView } from "src/api/generated/postgres/db/types/documents/tables/documentConnectionView.ts";
import { AavoObjectRef } from "src/api/generated/common/sourceType/aavoObjectRef.ts";
import { AavoMosaic } from "src/components/common/mosaic/AavoMosaic.tsx";
import { useMediaQueryBreakpointDown } from "src/utils/breakpointUtils.ts";
import { DocumentsOfObjectsDataGrid } from "src/components/views/documents/objectDocuments/DocumentsOfObjectsDataGrid.tsx";
import { DocumentsViewEditable } from "src/components/views/documents/types.ts";
import { AavoMosaicTabItem } from "src/components/common/mosaic/types.ts";
import { useMaybeControlledState } from "src/utils/useMaybeControlledState.ts";
import { filterNulls } from "src/utils/arrayUtils.ts";

export interface DocumentsOfMultipleObjectsViewProps {
	objectRefs: (AavoObjectRef | false)[];
	editable?: DocumentsViewEditable;
	hidePreview?: boolean;
	viewId?: string;
	refreshRef?: RefreshableElementRef;
	extraViews?: AavoMosaicTabItem[];
	selectedDocument?: DocumentConnectionView | null;
	setSelectedDocument?: (doc: DocumentConnectionView | null) => void;
}

export const DocumentsOfMultipleObjectsView = ({
	objectRefs,
	editable = true,
	hidePreview: hidePreviewProp = false,
	viewId = "58D9749A534DEFB8",
	refreshRef,
	selectedDocument: selectedDocumentProp,
	setSelectedDocument: setSelectedDocumentProp,
	extraViews = [],
}: DocumentsOfMultipleObjectsViewProps) => {
	const [selectedDocument, setSelectedDocument] = useMaybeControlledState<DocumentConnectionView | null>({
		controlledValue: selectedDocumentProp,
		defaultValue: null,
		onChange: setSelectedDocumentProp,
	});

	const hidePreview = useMediaQueryBreakpointDown("md") || hidePreviewProp;
	const previewViewRefreshRef = useRefreshRef();

	const documentsOfObjectDataGrid = (
		<DocumentsOfObjectsDataGrid
			refreshRef={refreshRef}
			objectRefs={objectRefs.filter(Boolean) as AavoObjectRef[]}
			editable={editable}
			selectedDocuments={filterNulls([selectedDocument])}
			onSelectionChanged={(docs) => setSelectedDocument(docs[0] ?? null)}
			onDocumentFileChanged={async () => {
				await previewViewRefreshRef.refresh();
			}}
			gridId={`${viewId}_documents_grid`}
		/>
	);

	if (hidePreview) return documentsOfObjectDataGrid;

	return (
		<AavoMosaic
			viewId={viewId}
			layout={{
				type: "row",
				first: {
					type: "panel",
					title: i18n.t("documents"),
					content: documentsOfObjectDataGrid,
				},
				second: {
					type: "tab",
					items: [
						{
							type: "panel",
							title: i18n.t("preview"),
							content: (
								<DocumentPreviewView
									key={selectedDocument?.documentId}
									documentId={selectedDocument?.documentId}
									refreshRef={previewViewRefreshRef}
								/>
							),
						},
						...extraViews,
					],
				},
			}}
		/>
	);
};
