// This file is automatically generated. Do not edit manually.

import {ShopOrderLineView} from "src/api/generated/erp/db/types/tables/shopOrderLineView";
import {ShopOrderLine} from "src/api/generated/erp/db/types/tables/shopOrderLine";
import {makeAxiosRequest} from "src/api/utils/apiClient";

export namespace ShopOrderBomApi {
	export async function getShopOrderBom(args: { shopOrderId: number }): Promise<Array<ShopOrderLineView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/shopOrderBom/getShopOrderBom",
			method: "POST",
			data: {
				shopOrderId: args.shopOrderId,
			},
		});
		return response as Array<ShopOrderLineView>;
	}

	export async function getShopOrderBatchBom(args: { shopOrderBatchId: number }): Promise<Array<ShopOrderLineView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/shopOrderBom/getShopOrderBatchBom",
			method: "POST",
			data: {
				shopOrderBatchId: args.shopOrderBatchId,
			},
		});
		return response as Array<ShopOrderLineView>;
	}

	export async function getShopOrderBomLine(args: { shopOrderLineId: number }): Promise<ShopOrderLine> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/shopOrderBom/getShopOrderBomLine",
			method: "POST",
			data: {
				shopOrderLineId: args.shopOrderLineId,
			},
		});
		return response as ShopOrderLine;
	}
}
