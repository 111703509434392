// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const CustomerOrderPackageStateValues = ["PARTIALLY_PICKED", "PICKED", "DELIVERED"] as const;

export type CustomerOrderPackageState = (typeof CustomerOrderPackageStateValues)[number];

export const getCustomerOrderPackageStateLabels = () => ({
	PARTIALLY_PICKED: t("partially_picked"),
	PICKED: t("picked"),
	DELIVERED: t("delivered"),
});

export const getCustomerOrderPackageStateLabel = (value: CustomerOrderPackageState): string => {
	return getCustomerOrderPackageStateLabels()[value];
};
