import { AavoMosaic } from "../../../common/mosaic/AavoMosaic.tsx";
import i18n from "i18next";
import { ReclamationView } from "src/api/generated/erp/db/types/tables/reclamationView.ts";
import { CenteredTypography } from "../../../common/CenteredTypography.tsx";
import { AavoMosaicNode } from "../../../common/mosaic/types.ts";
import { SingleSourceTasksView } from "../../tasks/pages/SingleSourceTasksView.tsx";
import { RefreshableElementRef } from "src/utils/useRefreshRef.ts";
import { CostEventsOfObjectDataGrid } from "../common/costEvents/CostEventsOfObjectDataGrid.tsx";
import { ReclamationCustomerOrdersView } from "./ReclamationCustomerOrdersView.tsx";

export interface ReclamationsViewProps {
	selectedReclamations: ReclamationView[];
	upperContent: AavoMosaicNode;
	taskViewRefreshRef?: RefreshableElementRef;
	customerOrdersRefreshRef?: RefreshableElementRef;
}

export const ReclamationsView = ({
	selectedReclamations,
	upperContent,
	taskViewRefreshRef,
	customerOrdersRefreshRef,
}: ReclamationsViewProps) => {
	const onlySelectedReclamation =
		selectedReclamations.length === 1 ? selectedReclamations[0] : null;
	return (
		<AavoMosaic
			viewId={"FACAA32FD131C81E"}
			layout={{
				type: "column",
				first: upperContent,
				second: {
					type: "tab",
					items: [
						{
							type: "panel",
							title: i18n.t("tasks"),
							content:
								!onlySelectedReclamation ?
									<CenteredTypography>
										{i18n.t("select_single_reclamation")}
									</CenteredTypography>
								:	<SingleSourceTasksView
										key={onlySelectedReclamation.reclamationId}
										refreshRef={taskViewRefreshRef}
										taskSourceRef={{
											sourceType: "RECLAMATION_V2",
											sourceId: onlySelectedReclamation.reclamationId,
										}}
									/>,
						},
						{
							type: "panel",
							title: i18n.t("cost_events"),
							content:
								!onlySelectedReclamation ?
									<CenteredTypography>
										{i18n.t("select_single_reclamation")}
									</CenteredTypography>
								: onlySelectedReclamation.costEventPriceListId == null ?
									<CenteredTypography>
										{i18n.t(
											"cost_event_price_list_not_selected_for_reclamation",
										)}
									</CenteredTypography>
								:	<CostEventsOfObjectDataGrid
										key={onlySelectedReclamation.reclamationId}
										objectRef={{
											objectType: "RECLAMATION",
											objectId: onlySelectedReclamation.reclamationId,
										}}
										costEventPriceListId={
											onlySelectedReclamation.costEventPriceListId
										}
									/>,
						},
						{
							type: "panel",
							title: i18n.t("connected_customer_orders"),
							content:
								!onlySelectedReclamation ?
									<CenteredTypography>
										{i18n.t("select_single_reclamation")}
									</CenteredTypography>
								:	<ReclamationCustomerOrdersView
										key={onlySelectedReclamation.reclamationId}
										reclamationId={onlySelectedReclamation.reclamationId}
										customerOrdersRefreshRef={customerOrdersRefreshRef}
									/>,
						},
					],
				},
			}}
		/>
	);
};
