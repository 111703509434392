import { useContextOrThrow } from "src/utils/useContextOrThrow.tsx";
import { FeatureChangeHintContext } from "src/components/common/featureChangeHint/FeatureChangeHintContext.tsx";
import React, { useCallback, useRef } from "react";
import dayjs, { Dayjs } from "dayjs";
import { setLocalStorageItemManaged } from "src/storage/localStorageUtils.ts";

interface UseFeatureChangeHintParams {
	key: string;
	hideAfter?: Dayjs;
	content: React.ReactNode;
}

export function useFeatureChangeHint<TAnchorEl extends HTMLElement>({
	key,
	hideAfter,
	content,
}: UseFeatureChangeHintParams): React.Ref<TAnchorEl> {
	const { showHint } = useContextOrThrow(FeatureChangeHintContext);

	const isShownOnThisSession = useRef(false);

	return useCallback((anchorEl: TAnchorEl | null) => {
		if (anchorEl === null) return;
		if (isShownOnThisSession.current) return;

		const now = dayjs();
		if (hideAfter != null && now.isAfter(hideAfter)) return;

		const storageKey = `featureChangeHint.${key}`;
		const hasShownBefore = localStorage.getItem(storageKey);
		if (hasShownBefore != null) return;

		isShownOnThisSession.current = true;
		setLocalStorageItemManaged(storageKey, JSON.stringify({ shownAt: now.toISOString() }));

		showHint({
			anchorEl,
			content,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
}
