import { FormCommonProps } from "src/components/common/forms/types.ts";
import { DeepPartial } from "react-hook-form";
import { AsyncForm, AsyncFormContentParams } from "src/components/common/forms/AsyncForm.tsx";
import {
	CustomerOrderDeliveryEditApi,
	CustomerOrderDeliveryEditApi_FormInitData,
} from "src/api/generated/erp/sales/delivery/api/customerOrderDeliveryEditApi.ts";
import { CustomerOrderDelivery } from "src/api/generated/erp/db/types/tables/customerOrderDelivery.ts";
import i18n from "i18next";
import { FormSelectField } from "src/components/common/forms/fields/FormSelectField.tsx";
import { FormDateTimeField } from "src/components/common/forms/fields/FormDateTimeField.tsx";
import dayjs from "dayjs";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import { requireRule } from "src/components/common/forms/validation.ts";
import { FormEnumSelectField } from "src/components/common/forms/fields/FormEnumSelectField.tsx";
import { getCountryLabels } from "src/api/generated/erp/db/types/enums/country.ts";
import { FormSection } from "src/components/common/forms/FormSection.tsx";

export interface CustomerOrderDeliveryFormProps extends FormCommonProps<number> {
	customerOrderId: number;
	customerOrderDeliveryId?: number;
}

interface FormValues extends CustomerOrderDelivery {}

export const CustomerOrderDeliveryForm = (props: CustomerOrderDeliveryFormProps) => {
	const { onCompleted, onFormEdited, customerOrderId, customerOrderDeliveryId } = props;
	return (
		<AsyncForm
			fetch={() =>
				CustomerOrderDeliveryEditApi.getFormInitData({
					customerOrderId,
					customerOrderDeliveryId,
				})
			}
			getDefaultValues={getDefaultValues}
			submit={submit}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			columns={3}
			render={(contentParams) => <FormContent {...props} {...contentParams} />}
		/>
	);

	function getDefaultValues({
		customerOrderDelivery,
		customerOrder,
	}: CustomerOrderDeliveryEditApi_FormInitData): DeepPartial<FormValues> {
		if (customerOrderDelivery != null) return customerOrderDelivery;
		else {
			const defaultLoadUnloadDateTime = dayjs().startOf("day").add(12, "hour").toISOString();

			return {
				customerOrderId: customerOrder.customerOrderId,
				deliveryName: customerOrder.deliveryName,
				deliveryAddress_1: customerOrder.deliveryAddress_1,
				deliveryAddress_2: customerOrder.deliveryAddress_2,
				deliveryCity: customerOrder.deliveryCity,
				deliveryPostalCode: customerOrder.deliveryPostalCode,
				deliveryCountry: customerOrder.deliveryCountry,
				recipientContact: customerOrder.deliveryContact ?? "",
				transportRouteId: customerOrder.transportRouteId,
				plannedLoadingDate: defaultLoadUnloadDateTime,
				plannedUnloadingDate: defaultLoadUnloadDateTime,
			};
		}
	}

	async function submit(values: FormValues) {
		if (customerOrderDeliveryId == null) {
			return await CustomerOrderDeliveryEditApi.insert({ customerOrderDelivery: values });
		} else {
			await CustomerOrderDeliveryEditApi.update({ customerOrderDelivery: values });
			return customerOrderDeliveryId;
		}
	}
};

interface FormContentProps
	extends CustomerOrderDeliveryFormProps,
		AsyncFormContentParams<CustomerOrderDeliveryEditApi_FormInitData, FormValues> {}

const FormContent = ({
	control,
	data: { cargoDriverOptions, transportRouteOptions, customerOrderDelivery },
}: FormContentProps) => {
	const isDelivered = customerOrderDelivery?.deliveryState === "DELIVERED";

	return (
		<>
			<FormDateTimeField
				control={control}
				name={"plannedLoadingDate"}
				label={i18n.t("planned_loading_time")}
				disabled={isDelivered}
			/>
			<FormDateTimeField
				control={control}
				name={"plannedUnloadingDate"}
				label={i18n.t("planned_unloading_time")}
				disabled={isDelivered}
			/>
			<FormSelectField
				control={control}
				name={"cargoDriverId"}
				label={i18n.t("cargo_driver")}
				options={cargoDriverOptions}
				getOptionKey={(option) => option.cargoDriverId}
				getOptionLabel={(option) => option.cargoDriverName}
				disabled={isDelivered}
				startNewGridRow
				rules={requireRule()}
			/>
			<FormTextField
				control={control}
				name={"trackingCode"}
				label={i18n.t("tracking_code")}
				disabled={isDelivered}
			/>
			<FormTextField
				control={control}
				name={"senderContact"}
				label={i18n.t("sender_contact")}
				disabled={isDelivered}
				startNewGridRow
			/>
			<FormTextField
				control={control}
				name={"recipientContact"}
				label={i18n.t("recipient_contact")}
				disabled={isDelivered}
			/>
			<FormTextField control={control} name={"note"} label={i18n.t("note")} multiline spanGridColumns />

			<FormSection label={i18n.t("delivery_address")}>
				<FormTextField
					control={control}
					name={"deliveryName"}
					label={i18n.t("name")}
					disabled={isDelivered}
				/>
				<FormTextField
					control={control}
					name={"deliveryAddress_1"}
					label={i18n.t("address_1")}
					rules={requireRule()}
					disabled={isDelivered}
				/>
				<FormTextField
					control={control}
					name={"deliveryAddress_2"}
					label={i18n.t("address_2")}
					disabled={isDelivered}
				/>
				<FormTextField
					control={control}
					name={"deliveryPostalCode"}
					label={i18n.t("postal_code")}
					rules={requireRule()}
					disabled={isDelivered}
				/>
				<FormTextField
					control={control}
					name={"deliveryCity"}
					label={i18n.t("city")}
					rules={requireRule()}
					disabled={isDelivered}
				/>
				<FormEnumSelectField
					control={control}
					name={"deliveryCountry"}
					label={i18n.t("country")}
					options={getCountryLabels()}
					disableClearable
					disabled={isDelivered}
				/>
				<FormSelectField
					control={control}
					name={"transportRouteId"}
					label={i18n.t("transport_route")}
					options={transportRouteOptions}
					getOptionKey={(o) => o.transportRouteId}
					getOptionLabel={(o) => o.name}
					disabled={isDelivered}
				/>
			</FormSection>
		</>
	);
};
