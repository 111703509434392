import React from "react";
import {
	Autocomplete,
	AutocompleteProps,
	CircularProgress,
	FormControl,
	FormHelperText,
	InputLabel,
	Select,
	TextField,
	TextFieldProps,
	Tooltip,
} from "@mui/material";
import { ConditionalNullable, InputError } from "./types";
import {
	decodeNativeSelectKey,
	encodeNativeSelectKey,
	getSingleSelectAutocompleteInputWidthStyle,
} from "./selectFieldUtils.ts";
import i18n from "i18next";
import { mergeSx } from "src/utils/styles";
import { EMPTY_SELECTION, LOADING_OPTION_VALUE } from "./constants";
import { isTouchDevice } from "src/utils/isTouchDevice";
import { CustomPopper, CustomPopperProps } from "src/components/common/popper/CustomPopper.tsx";
import { resolveValueOrProvider, ValueOrProvider } from "src/utils/valueOrProvider.ts";

export interface SelectFieldProps<T, Key, DisableClearable extends boolean | undefined>
	extends Omit<
		AutocompleteProps<T, false, DisableClearable, false>,
		| "onChange"
		| "renderInput"
		| "freeSolo"
		| "multiple"
		| "isOptionEqualToValue"
		| "getOptionKey"
		| "getOptionLabel"
		| "value"
		| "defaultValue"
		| "onFocus"
		| "disabled"
	> {
	onChange?: (
		key: ConditionalNullable<Key, DisableClearable>,
		option: ConditionalNullable<T, DisableClearable>,
	) => void;
	value?: Key | null;
	defaultValue?: Key | null;
	getOptionKey: (o: T) => Key;
	getOptionLabel: (o: T) => string;
	label: string;
	error?: InputError;
	onFocus?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement | HTMLDivElement>;
	minWidth?: number | string;
	popperProps?: Partial<CustomPopperProps>;
	disabled?: ValueOrProvider<boolean | string>;
	tooltip?: ValueOrProvider<React.ReactNode>;
	InputProps?: TextFieldProps["InputProps"];
	inputProps?: TextFieldProps["inputProps"];
	placeholder?: string;
}

export const SelectField = <T, Key, DisableClearable extends boolean | undefined>({
	disabled: disabledProvider,
	tooltip: tooltipProvider,
	...other
}: SelectFieldProps<T, Key, DisableClearable>) => {
	const disabled = resolveValueOrProvider(disabledProvider);
	const isDisabled = typeof disabled === "string" ? disabled.length > 0 : disabled;
	const tooltip = typeof disabled === "string" ? disabled : resolveValueOrProvider(tooltipProvider);

	if (isTouchDevice()) return <NativeSelectField disabled={isDisabled} {...other} />;
	else return <AutoCompleteSelectField disabled={isDisabled} tooltip={tooltip} {...other} />;
};

interface AutocompleteSelectFieldProps<T, Key, DisableClearable extends boolean | undefined>
	extends Omit<SelectFieldProps<T, Key, DisableClearable>, "tooltip" | "disabled"> {
	tooltip?: React.ReactNode;
	disabled?: boolean;
}

interface NativeSelectFieldProps<T, Key, DisableClearable extends boolean | undefined>
	extends Omit<SelectFieldProps<T, Key, DisableClearable>, "tooltip" | "disabled"> {
	disabled?: boolean;
}

const AutoCompleteSelectField = <T, Key, DisableClearable extends boolean | undefined>({
	label,
	disableClearable,
	value,
	defaultValue,
	onChange,
	options,
	getOptionKey,
	getOptionLabel,
	loading,
	error = undefined,
	onFocus,
	sx,
	minWidth,
	popperProps,
	tooltip,
	autoFocus,
	InputProps,
	inputProps,
	...other
}: AutocompleteSelectFieldProps<T, Key, DisableClearable>) => {
	const getOptionForKey = (key: Key | null | undefined) => {
		if (key === undefined) return undefined;

		const option =
			options.find((o) => {
				return getOptionKey(o) === key;
			}) ?? null;

		return option as ConditionalNullable<T, DisableClearable>;
	};

	return (
		<Tooltip title={tooltip} arrow placement={"bottom-start"}>
			<Autocomplete
				sx={mergeSx(
					{
						minWidth: minWidth,
					},
					sx,
				)}
				onFocus={onFocus}
				autoFocus={autoFocus}
				options={loading ? [] : options}
				getOptionLabel={getOptionLabel}
				disableClearable={disableClearable}
				isOptionEqualToValue={(o1, o2) => {
					return getOptionKey(o1) === getOptionKey(o2);
				}}
				onChange={(_, newValue) => {
					if (newValue === null) {
						onChange?.(
							null as ConditionalNullable<Key, DisableClearable>,
							null as ConditionalNullable<T, DisableClearable>,
						);
					} else
						onChange?.(
							getOptionKey(newValue) as ConditionalNullable<Key, DisableClearable>,
							newValue as ConditionalNullable<T, DisableClearable>,
						);
				}}
				value={getOptionForKey(value)}
				defaultValue={getOptionForKey(defaultValue)}
				loading={loading}
				PopperComponent={(defaultPopperProps) => <CustomPopper {...defaultPopperProps} {...popperProps} />}
				renderInput={(params) => {
					return (
						<TextField
							{...params}
							label={label}
							error={error !== undefined}
							helperText={error ?? undefined}
							inputProps={{
								...params.inputProps,
								...inputProps,
								style: {
									...getSingleSelectAutocompleteInputWidthStyle(label, params),
									...params.inputProps.style,
								},
							}}
							InputProps={{
								...params.InputProps,
								...InputProps,
								endAdornment: (
									<>
										{loading ?
											<CircularProgress color="inherit" size={20} />
										:	null}
										{params.InputProps.endAdornment}
									</>
								),
							}}
						/>
					);
				}}
				{...other}
			/>
		</Tooltip>
	);
};

const NativeSelectField = <T, Key, DisableClearable extends boolean | undefined>({
	label,
	disableClearable,
	value,
	defaultValue,
	onChange,
	options,
	getOptionKey,
	getOptionLabel,
	loading,
	error = undefined,
	onFocus,
	sx,
	placeholder,
	disabled,
}: NativeSelectFieldProps<T, Key, DisableClearable>) => {
	return (
		<FormControl error={error !== undefined} sx={sx} className={"AavoNativeSelectRoot"}>
			<InputLabel shrink htmlFor="native-select-for-mobile">
				{label}
			</InputLabel>
			<Select
				native
				label={label}
				variant={"outlined"}
				disabled={disabled}
				value={encodeNativeSelectValue(value, loading)}
				defaultValue={defaultValue == undefined ? undefined : encodeNativeSelectKey(defaultValue)}
				onFocus={onFocus}
				inputProps={{
					name: label,
					id: "native-select-for-mobile",
				}}
				onChange={(e) => {
					const newKey = decodeNativeSelectKey(e.target.value);
					const newOption =
						options.find((o) => {
							return getOptionKey(o) === newKey;
						}) ?? null;
					onChange?.(newKey, newOption as ConditionalNullable<T, DisableClearable>);
				}}
			>
				<option hidden={!loading} disabled={true} value={encodeNativeSelectKey(LOADING_OPTION_VALUE)}>
					{i18n.t("loading...")}
				</option>
				{options.map((o, index) => {
					return (
						<option
							key={`select-field-option-${index}`}
							value={encodeNativeSelectKey(getOptionKey(o))}
							hidden={loading}
						>
							{getOptionLabel(o)}
						</option>
					);
				})}
				<option
					hidden={disableClearable || loading}
					value={encodeNativeSelectKey(EMPTY_SELECTION)}
					disabled={disableClearable}
				>
					{placeholder ?? i18n.t("undefined")}
				</option>
			</Select>
			{error && <FormHelperText>{error}</FormHelperText>}
		</FormControl>
	);
};

const encodeNativeSelectValue = <Key,>(value: Key | null | undefined, loading: boolean | undefined) => {
	if (value === undefined) return undefined;
	if (loading === true) return encodeNativeSelectKey(LOADING_OPTION_VALUE);
	if (value === null || value === "") return encodeNativeSelectKey(EMPTY_SELECTION);
	return encodeNativeSelectKey(value);
};
