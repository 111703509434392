import { FormCommonProps } from "../../../../common/forms/types.ts";
import {
	CustomerFormApi,
	CustomerFormApi_InitData,
} from "src/api/generated/erp/sales/basedata/api/customerFormApi.ts";
import { DeepPartial } from "react-hook-form";
import { Customer } from "src/api/generated/erp/db/types/tables/customer.ts";
import { FormSelectField } from "../../../../common/forms/fields/FormSelectField.tsx";
import i18n from "i18next";
import { FormTextField } from "../../../../common/forms/fields/FormTextField.tsx";
import { requireRule } from "../../../../common/forms/validation.ts";
import { getCustomerTypeLabels } from "src/api/generated/erp/db/types/enums/customerType.ts";
import { getLanguageLabels } from "src/api/generated/erp/db/types/enums/language.ts";
import { FormNumberField } from "../../../../common/forms/fields/FormNumberField.tsx";
import { getInvoiceChannelLabels } from "src/api/generated/erp/db/types/enums/invoiceChannel.ts";
import { CustomerAddress } from "src/api/generated/erp/db/types/tables/customerAddress.ts";
import { Divider } from "@mui/material";
import { getCountryLabels } from "src/api/generated/erp/db/types/enums/country.ts";
import { FormCheckbox } from "../../../../common/forms/fields/FormCheckbox.tsx";
import { FormEnumSelectField } from "../../../../common/forms/fields/FormEnumSelectField.tsx";
import { useTenantCustomizations } from "src/tenantCustomizations/useTenantCustomizations.tsx";
import { AsyncForm, AsyncFormContentParams } from "src/components/common/forms/AsyncForm.tsx";
import { FormAddressAutofill } from "src/components/common/forms/fields/FormAddressAutofill.tsx";

export interface CustomerFormProps extends FormCommonProps<number> {
	customerId?: number;
}

export const CustomerForm = (props: CustomerFormProps) => {
	const { customerId, onFormEdited, onCompleted } = props;

	const { tenantConfig } = useTenantCustomizations();

	return (
		<AsyncForm
			fetch={() => CustomerFormApi.getInitData({ customerId: customerId })}
			getDefaultValues={getDefaultValues}
			submit={submitForm}
			columns={2}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			render={(params) => <CustomerFormContent {...props} {...params} />}
		/>
	);

	function getDefaultValues(initData: CustomerFormApi_InitData): DeepPartial<FormValues> {
		if (initData.customer != null) {
			return {
				...initData.customer,
				invoiceSameAsDelivery: false,
			};
		} else {
			return {
				customerType: tenantConfig.erp.defaultCustomerType,
				language: "FINNISH",
				invoiceChannel: "EMAIL",
				discountPercentage: 0,
				invoiceSameAsDelivery: true,
				deliveryAddress: {
					country: "FINLAND",
				},
				invoiceAddress: {
					country: "FINLAND",
				},
			};
		}
	}

	async function submitForm({
		invoiceAddress,
		deliveryAddress,
		invoiceSameAsDelivery,
		...other
	}: FormValues): Promise<number> {
		const invoiceAddressToSave =
			invoiceSameAsDelivery ? deliveryAddress : (invoiceAddress ?? deliveryAddress);
		return await CustomerFormApi.save({
			customer: other,
			deliveryAddress: deliveryAddress,
			invoiceAddress: invoiceAddressToSave,
		});
	}
};

interface CustomerFormContentProps
	extends CustomerFormProps,
		AsyncFormContentParams<CustomerFormApi_InitData, FormValues> {}

interface FormValues extends Customer {
	deliveryAddress: CustomerAddress;
	invoiceAddress: CustomerAddress;
	invoiceSameAsDelivery: boolean;
}

const CustomerFormContent = ({
	control,
	watch,
	setValue,
	data: {
		customer,
		customerGroupOptions,
		paymentTermOptions,
		transportRouteOptions,
		customerPriceGroupOptions,
		eInvoiceOperatorOptions,
	},
}: CustomerFormContentProps) => {
	const isNewRecord = customer == null;

	const customerType = watch("customerType");
	const invoiceSameAsDelivery = watch("invoiceSameAsDelivery");
	const invoiceChannel = watch("invoiceChannel");

	return (
		<>
			<FormTextField
				control={control}
				name={"customerName"}
				label={i18n.t("name")}
				rules={requireRule()}
			/>
			<FormSelectField
				control={control}
				name={"customerGroupId"}
				label={i18n.t("customer_group")}
				options={customerGroupOptions}
				getOptionKey={(option) => option.customerGroupId}
				getOptionLabel={(option) => option.name ?? ""}
			/>
			<FormEnumSelectField
				control={control}
				name={"customerType"}
				label={i18n.t("customer_type")}
				options={getCustomerTypeLabels()}
				rules={requireRule()}
				disableClearable
			/>
			{customerType === "COMPANY" && (
				<FormTextField control={control} name={"businessId"} label={i18n.t("business_id")} />
			)}
			<FormEnumSelectField
				control={control}
				name={"language"}
				label={i18n.t("language")}
				options={getLanguageLabels()}
				rules={requireRule()}
				startNewGridRow
			/>
			<FormSelectField
				control={control}
				name={"customerPriceGroupId"}
				label={i18n.t("price_group")}
				options={customerPriceGroupOptions}
				getOptionKey={(group) => group.customerPriceGroupId}
				getOptionLabel={(group) => group.name ?? ""}
				startNewGridRow
			/>
			<FormNumberField
				control={control}
				name={"discountPercentage"}
				label={i18n.t("discount_percentage")}
				rules={requireRule()}
			/>
			<FormSelectField
				control={control}
				name={"paymentTermId"}
				label={i18n.t("payment_term")}
				rules={requireRule()}
				options={paymentTermOptions}
				getOptionKey={(term) => term.paymentTermId}
				getOptionLabel={(term) => term.paymentTerm ?? ""}
				disableClearable
			/>
			<FormEnumSelectField
				control={control}
				name={"invoiceChannel"}
				label={i18n.t("invoice_channel")}
				options={getInvoiceChannelLabels()}
				disableClearable
			/>
			{invoiceChannel === "EINVOICE" && (
				<FormSelectField
					control={control}
					name={"eInvoiceOperatorId"}
					label={i18n.t("e_invoice_operator")}
					options={eInvoiceOperatorOptions}
					getOptionKey={(o) => o.eInvoiceOperatorId}
					getOptionLabel={(o) => o.eInvoiceOperatorName}
				/>
			)}
			{invoiceChannel === "EINVOICE" && (
				<FormTextField
					control={control}
					name={"einvoiceAddress"}
					label={i18n.t("e_invoice_address")}
				/>
			)}
			<FormTextField control={control} name={"note"} label={i18n.t("note")} multiline spanGridColumns />
			{isNewRecord && (
				<>
					<AddressForm
						control={control}
						setValue={setValue}
						name={"deliveryAddress"}
						label={i18n.t("delivery_address")}
					/>
					<FormSelectField
						control={control}
						name={"deliveryAddress.transportRouteId"}
						label={i18n.t("transport_route")}
						options={transportRouteOptions}
						getOptionKey={(route) => route.transportRouteId}
						getOptionLabel={(route) => route.name ?? ""}
					/>
					<FormCheckbox
						label={i18n.t("invoice_address_same_as_delivery_address")}
						control={control}
						name={"invoiceSameAsDelivery"}
					/>
					{!invoiceSameAsDelivery && (
						<AddressForm
							control={control}
							setValue={setValue}
							name={"invoiceAddress"}
							label={i18n.t("invoice_address")}
						/>
					)}
				</>
			)}
		</>
	);
};

interface AddressFormProps extends Pick<CustomerFormContentProps, "control" | "setValue"> {
	name: "deliveryAddress" | "invoiceAddress";
	label: string;
}

const AddressForm = ({ control, name, label, setValue }: AddressFormProps) => {
	return (
		<>
			<Divider
				sx={{
					gridColumn: "1 / -1",
					color: "primary.main",
				}}
			>
				{label}
			</Divider>
			<FormAddressAutofill
				control={control}
				name={`${name}.address_1`}
				label={i18n.t("address_1")}
				rules={requireRule()}
				onSelectLocation={(location) => {
					setValue(`${name}.address_2`, location.address_line2);
					setValue(`${name}.postalCode`, location.postcode);
					setValue(`${name}.city`, location.address_level2);
					return location.address_line1;
				}}
			/>
			<FormTextField control={control} name={`${name}.address_2`} label={i18n.t("address_2")} />
			<FormTextField control={control} name={`${name}.postalCode`} label={i18n.t("postal_code")} />
			<FormTextField control={control} name={`${name}.city`} label={i18n.t("city")} />
			<FormEnumSelectField
				control={control}
				name={`${name}.country`}
				label={i18n.t("country")}
				options={getCountryLabels()}
			/>
			<FormTextField control={control} name={`${name}.contact`} label={i18n.t("contact_person")} />
			<FormTextField control={control} name={`${name}.phone`} label={i18n.t("phone")} />
			<FormTextField control={control} name={`${name}.email`} label={i18n.t("email")} />
			<FormTextField control={control} name={`${name}.name`} label={i18n.t("address_name")} />
		</>
	);
};
