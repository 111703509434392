// This file is automatically generated. Do not edit manually.

import { FrontendDownloadFileHandle } from "src/api/generated/common/frontendDownloadFileHandle";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace CustomerOrderDeliveryActionApi {
	export async function setCustomerOrderDeliveryToDelivered(args: {
		customerOrderDeliveryId: number;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/delivery/setCustomerOrderDeliveryToDelivered",
			method: "POST",
			data: {
				customerOrderDeliveryId: args.customerOrderDeliveryId,
			},
		});
		return response as void;
	}

	export async function revertCustomerOrderDelivery(args: { customerOrderDeliveryId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/delivery/revertCustomerOrderDelivery",
			method: "POST",
			data: {
				customerOrderDeliveryId: args.customerOrderDeliveryId,
			},
		});
		return response as void;
	}

	export async function printCustomerOrderConsignmentNote(args: {
		customerOrderDeliveryId: number;
	}): Promise<FrontendDownloadFileHandle> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/delivery/printCustomerOrderConsignmentNote",
			method: "POST",
			data: {
				customerOrderDeliveryId: args.customerOrderDeliveryId,
			},
		});
		return response as FrontendDownloadFileHandle;
	}

	export async function printCustomerOrderDeliveryNote(args: {
		customerOrderDeliveryId: number;
	}): Promise<FrontendDownloadFileHandle> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/delivery/printCustomerOrderDeliveryNote",
			method: "POST",
			data: {
				customerOrderDeliveryId: args.customerOrderDeliveryId,
			},
		});
		return response as FrontendDownloadFileHandle;
	}
}
