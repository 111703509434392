import { FormCommonProps } from "src/components/common/forms/types.ts";
import { AsyncForm } from "src/components/common/forms/AsyncForm.tsx";
import {
	CatalogPartEditApi,
	CatalogPartEditApi_FormInitData,
} from "src/api/generated/erp/parts/catalogPart/api/catalogPartEditApi.ts";
import { CatalogPart } from "src/api/generated/erp/db/types/tables/catalogPart.ts";
import { useGlobalInitData } from "src/contexts/useGlobalInitData.ts";
import { FormSelectField } from "src/components/common/forms/fields/FormSelectField.tsx";
import i18n from "i18next";
import { requireRule } from "src/components/common/forms/validation.ts";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import { FormAsyncUserSelectField } from "src/components/views/users/FormAsyncUserSelectField.tsx";
import { FormNumberField } from "src/components/common/forms/fields/FormNumberField.tsx";
import { FormCheckbox } from "src/components/common/forms/fields/FormCheckbox.tsx";
import { useTenantCustomizations } from "src/tenantCustomizations/useTenantCustomizations.tsx";

export interface CatalogPartFormProps extends FormCommonProps<number> {
	catalogPartId: number | null;
	partCategoryId: number | null;
}

export const CatalogPartForm = ({
	catalogPartId,
	partCategoryId,
	onFormEdited,
	onCompleted,
}: CatalogPartFormProps) => {
	const { appUserId } = useGlobalInitData();
	const { tenantConfig } = useTenantCustomizations();
	return (
		<AsyncForm<CatalogPartEditApi_FormInitData, FormValues, number>
			fetch={() => CatalogPartEditApi.getFormInitData({ catalogPartId: catalogPartId })}
			columns={2}
			getDefaultValues={({ catalogPart }) =>
				catalogPart != null ?
					{ ...catalogPart }
				:	{
						categoryId: partCategoryId ?? undefined,
						responsiblePersonId: appUserId,
						designUnitFactor: 1,
						weight: 0,
					}
			}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			render={({
				control,
				watch,
				data: {
					numberSequenceOptions,
					erpUnitOptions,
					partCategoryOptions,
					configurationProductFamilyOptions,
				},
			}) => {
				const setToConfigurable = watch("isConfigurable");
				return (
					<>
						{catalogPartId == null && (
							<FormSelectField
								control={control}
								name={"numberSequenceId"}
								label={i18n.t("number_sequence")}
								options={numberSequenceOptions}
								getOptionKey={(o) => o.numberSequenceId}
								getOptionLabel={(o) => o.sequenceName}
								rules={requireRule()}
								sx={{ gridColumn: "1/-1" }}
							/>
						)}
						<FormTextField
							control={control}
							name={"description_1"}
							label={i18n.t("description")}
							rules={requireRule()}
						/>
						<FormTextField
							control={control}
							name={"description_2"}
							label={i18n.t("description_2")}
						/>
						<FormTextField
							control={control}
							name={"manufacturerPartNumber"}
							label={i18n.t("manufacturer_code")}
						/>
						<FormTextField
							control={control}
							name={"additionalNumber"}
							label={i18n.t("additional_code")}
						/>
						<FormSelectField
							control={control}
							name={"categoryId"}
							label={i18n.t("category")}
							options={partCategoryOptions}
							getOptionKey={(o) => o.partCategoryId}
							getOptionLabel={(o) => o.categoryName}
							rules={requireRule()}
						/>
						<FormAsyncUserSelectField
							control={control}
							name={"responsiblePersonId"}
							label={i18n.t("responsible_person")}
							rules={requireRule()}
						/>
						<FormSelectField
							control={control}
							name={"partUnitId"}
							label={i18n.t("unit")}
							options={erpUnitOptions}
							getOptionKey={(o) => o.unitId}
							getOptionLabel={(o) => o.unitName}
							rules={requireRule()}
							disabled={catalogPartId != null}
						/>
						<FormSelectField
							control={control}
							name={"designUnitId"}
							label={i18n.t("design_unit")}
							options={erpUnitOptions}
							getOptionKey={(o) => o.unitId}
							getOptionLabel={(o) => o.unitName}
							rules={requireRule()}
						/>
						<FormNumberField
							control={control}
							name={"designUnitFactor"}
							label={i18n.t("design_factor")}
							rules={requireRule()}
						/>
						<FormNumberField
							control={control}
							name={"weight"}
							label={i18n.t("weight")}
							rules={requireRule()}
						/>
						<FormCheckbox
							control={control}
							name={"isConfigurable"}
							label={i18n.t("configurable")}
							disabled={catalogPartId != null}
						/>
						{tenantConfig.erp.configuratorEnabled && setToConfigurable && (
							<FormSelectField
								control={control}
								name={"configurationProductFamilyId"}
								label={i18n.t("product_family")}
								options={configurationProductFamilyOptions}
								getOptionKey={(o) => o.configurationProductFamilyId}
								getOptionLabel={(o) => o.name}
								disabled={catalogPartId != null}
								rules={setToConfigurable ? requireRule() : undefined}
							/>
						)}
					</>
				);
			}}
			submit={async (formValues) => {
				if (formValues.catalogPartId == null) {
					return await CatalogPartEditApi.insert({
						catalogPart: formValues,
						partNoSequenceId: formValues.numberSequenceId,
					});
				} else
					return await CatalogPartEditApi.update({
						catalogPart: formValues,
					});
			}}
		/>
	);
};

interface FormValues extends CatalogPart {
	numberSequenceId: number;
}
