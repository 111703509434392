import { CustomerOrderPackageTypesApi } from "src/api/generated/erp/sales/basedata/api/customerOrderPackageTypesApi.ts";
import { booleanColumn, textColumn } from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { CustomerOrderPackageType } from "src/api/generated/erp/db/types/tables/customerOrderPackageType.ts";
import { CustomerOrderPackageTypeForm } from "src/components/views/erp/sales/basedata/packageTypes/CustomerOrderPackageTypeForm.tsx";
import { useClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/useClientSideDataGridModel.tsx";
import { faBan, faUndo } from "@fortawesome/pro-regular-svg-icons";
import { useConfirmDialog } from "src/components/common/dialogs/confirmDialog/useConfirmDialog.ts";
import { MenuCheckbox } from "src/components/common/contextMenu/MenuCheckbox.tsx";
import { ControlledAsyncCrudDataGrid } from "src/components/common/dataGrid/crud/ControlledAsyncCrudDataGrid.tsx";
import { AsyncMenuButton } from "src/components/common/contextMenu/AsyncMenuButton.tsx";

export const CustomerOrderPackageTypesDataGrid = () => {
	const { dataGridProps, refreshData, currentParams } = useClientSideDataGridModel({
		fetchData: ({ showInactive }) =>
			CustomerOrderPackageTypesApi.getCustomerOrderPackageTypes({
				includeInactive: showInactive,
			}),
		initialParams: {
			showInactive: false,
		},
		getRowId: (row) => row.customerOrderPackageTypeId,
		gridId: "25E3274ECFE799AC",
	});

	const showConfirmDialog = useConfirmDialog();

	return (
		<ControlledAsyncCrudDataGrid<CustomerOrderPackageType>
			disableMultipleRowSelection
			columns={[
				textColumn({
					field: "name",
					headerName: i18n.t("name"),
				}),
				booleanColumn({
					field: "isActive",
					headerName: i18n.t("active"),
				}),
			]}
			form={{
				addRowEnabled: true,
				editEnabled: true,
				dialogTitle: i18n.t("package_type"),
				dialogSize: "sm",
				component: ({ row, onCompleted, onFormEdited }) => (
					<CustomerOrderPackageTypeForm
						customerOrderPackageType={row}
						onCompleted={onCompleted}
						onFormEdited={onFormEdited}
					/>
				),
			}}
			remove={{
				type: "enabled",
				action: ({ items }) =>
					CustomerOrderPackageTypesApi.delete_({
						customerOrderPackageTypeId: items[0]!.customerOrderPackageTypeId,
					}),
			}}
			actionBarMenuComponents={[
				<MenuCheckbox
					key={"show_inactive"}
					label={i18n.t("show_inactive")}
					checked={currentParams.showInactive}
					onChange={(showInactive) => refreshData({ showInactive })}
				/>,
			]}
			rowContextMenuComponents={({ row }) => [
				row.isActive && (
					<AsyncMenuButton
						key={"deactivate"}
						label={i18n.t("deactivate")}
						icon={faBan}
						onClick={async () => {
							const confirmed = await showConfirmDialog();
							if (!confirmed) return;

							await CustomerOrderPackageTypesApi.setPackageTypeIsActive({
								customerOrderPackageTypeId: row.customerOrderPackageTypeId,
								isActive: false,
							});
							await refreshData();
						}}
					/>
				),
				!row.isActive && (
					<AsyncMenuButton
						key={"activate"}
						label={i18n.t("activate")}
						icon={faUndo}
						onClick={async () => {
							const confirmed = await showConfirmDialog();
							if (!confirmed) return;

							await CustomerOrderPackageTypesApi.setPackageTypeIsActive({
								customerOrderPackageTypeId: row.customerOrderPackageTypeId,
								isActive: true,
							});
							await refreshData();
						}}
					/>
				),
			]}
			{...dataGridProps}
		/>
	);
};
