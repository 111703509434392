import { CustomerOrderPackageDataGridApi } from "src/api/generated/erp/sales/picking/api/customerOrderPackageDataGridApi.ts";
import { CustomerOrderPackageView } from "src/api/generated/erp/db/types/tables/customerOrderPackageView.ts";
import {
	dateColumn,
	dateTimeColumn,
	enumColumn,
	floatColumn,
	integerColumn,
	textColumn,
} from "../../../../common/dataGrid/columns.tsx";
import i18n from "i18next";
import {
	CustomerOrderPackageState,
	getCustomerOrderPackageStateLabels,
} from "src/api/generated/erp/db/types/enums/customerOrderPackageState.ts";
import {
	CustomerOrderDeliveryState,
	CustomerOrderDeliveryStateValues,
	getCustomerOrderDeliveryStateLabel,
	getCustomerOrderDeliveryStateLabels,
} from "src/api/generated/erp/db/types/enums/customerOrderDeliveryState.ts";
import { getCustomerOrderStateLabels } from "src/api/generated/erp/db/types/enums/customerOrderState.ts";
import { useServerSideDataGridModel } from "src/components/common/dataGrid/gridModel/useServerSideDataGridModel";
import { ControlledAsyncCrudDataGrid } from "../../../../common/dataGrid/crud/ControlledAsyncCrudDataGrid.tsx";
import { CustomerOrderPackageForm } from "./CustomerOrderPackageForm.tsx";
import { DocumentsOfObjectButton } from "../../../documents/objectDocuments/DocumentsOfObjectButton.tsx";
import { AsyncButton } from "../../../../common/buttons/AsyncButton.tsx";
import { faCalculator, faCheck, faPrint, faShare, faUndo } from "@fortawesome/pro-regular-svg-icons";
import { CustomerOrderPackageApi } from "src/api/generated/erp/sales/picking/api/customerOrderPackageApi.ts";
import { downloadFile } from "src/utils/fileDownloading.ts";
import { RefreshableElementProps } from "src/utils/useRefreshRef.ts";
import { AavoTextField } from "../../../../common/inputFields/AavoTextField.tsx";
import { SelectField } from "../../../../common/inputFields/SelectField.tsx";
import { genericNullableValue } from "src/utils/genericNullableValue.ts";
import { AsyncSelectField } from "../../../../common/inputFields/AsyncSelectField.tsx";
import { TypedGridSortModel } from "../../../../common/dataGrid/types.tsx";
import { OpenCustomerOrderDeliveryViewButton } from "src/components/views/erp/utilComponents/OpenCustomerOrderDeliveryViewButton.tsx";
import { OpenCustomerOrderButton } from "src/components/views/erp/utilComponents/OpenCustomerOrderButton.tsx";
import { AsyncMenuButton } from "../../../../common/contextMenu/AsyncMenuButton.tsx";
import { CustomerOrderPackageLinesDataGrid } from "./CustomerOrderPackageLinesDataGrid.tsx";
import { useGenericDialog } from "../../../../common/dialogs/useGenericDialog.ts";
import { useGlobalInitData } from "src/contexts/useGlobalInitData.ts";
import { CustomerApi } from "src/api/generated/erp/sales/customer/customerApi.ts";
import { Customer } from "src/api/generated/erp/db/types/tables/customer.ts";
import { ReactNode } from "react";
import { MenuCheckbox } from "src/components/common/contextMenu/MenuCheckbox.tsx";

export interface CustomerOrderPackagesDataGridBaseProps extends RefreshableElementProps {
	gridId: string;
	onSelectedPackageChanged?: (row: CustomerOrderPackageView[] | []) => void;
	customerOrderId?: number | null;
	customerOrderDeliveryId?: number | null;
	packageStates?: CustomerOrderPackageState[];
	customerOrderPackageId?: number | null;
	showCustomerOrderDocumentsButton?: boolean;
	selectedRows?: CustomerOrderPackageView[];
	showSearchField?: boolean;
	showDeliveryStateSelection?: boolean;
	showCustomerSelection?: boolean;
	showPrintPackageLabelButton?: boolean;
	showCalculateWeightButton?: boolean;
	sortModel?: TypedGridSortModel<CustomerOrderPackageView>;
	showSetStateContextMenuButtons?: boolean;
	addEnabled?: boolean;
	editable?: boolean;
	showStateChangeActions?: boolean;
	onStateChanged?: () => void;
	disablePagination?: boolean;
	actionBarExtraComponents?: ReactNode;
}

export const CustomerOrderPackagesDataGridBase = ({
	gridId,
	onSelectedPackageChanged,
	packageStates,
	customerOrderId = null,
	customerOrderDeliveryId = -1,
	customerOrderPackageId = null,
	showCustomerOrderDocumentsButton = false,
	selectedRows,
	refreshRef,
	showSearchField,
	showDeliveryStateSelection,
	showCustomerSelection,
	showPrintPackageLabelButton = true,
	showCalculateWeightButton = true,
	sortModel = [],
	showSetStateContextMenuButtons = true,
	addEnabled = false,
	editable = true,
	showStateChangeActions = false,
	onStateChanged,
	disablePagination = false,
	actionBarExtraComponents = null,
}: CustomerOrderPackagesDataGridBaseProps) => {
	const { openDialog } = useGenericDialog();
	const { userHasMultipleErpSites } = useGlobalInitData();

	const { dataGridProps, refreshData, currentParams, onlySelectedRow } = useServerSideDataGridModel({
		refreshRef: refreshRef,
		gridId: gridId,
		fetchData: (params) =>
			CustomerOrderPackageDataGridApi.getCustomerOrderPackages({
				customerOrderId: customerOrderId,
				customerOrderDeliveryId: customerOrderDeliveryId,
				stateFilter: packageStates,
				customerOrderPackageId: customerOrderPackageId,
				...params,
			}),
		initialParams: {
			searchQuery: "",
			deliveryState: genericNullableValue<CustomerOrderDeliveryState>(),
			customerId: genericNullableValue<number>(),
			onlyDefaultSite:
				userHasMultipleErpSites && customerOrderId == null && customerOrderDeliveryId == null,
		},
		getRowId: (row) => row.customerOrderPackageId,
		onSelectionChanged: (selectedRows) => {
			onSelectedPackageChanged?.(selectedRows);
		},
		selectedRows: selectedRows,
		usePagination: !disablePagination,
		selectFirstRowOnLoad: true,
		defaultGridState: {
			sorting: {
				sortModel: sortModel,
			},
		},
	});

	const customerOrderIdFromPropOrSelectedRow = customerOrderId ?? onlySelectedRow?.customerOrderId;

	return (
		<ControlledAsyncCrudDataGrid<CustomerOrderPackageView>
			{...dataGridProps}
			columns={[
				textColumn({
					field: "siteName",
					headerName: i18n.t("site_short"),
					width: 60,
				}),
				integerColumn({
					field: "customerOrderId",
					headerName: i18n.t("customer_order_number_short"),
					width: 60,
				}),
				integerColumn({
					field: "packageNo",
					headerName: i18n.t("package_no"),
					width: 80,
				}),
				textColumn({
					field: "description",
					headerName: i18n.t("description"),
					width: 300,
				}),
				textColumn({
					field: "packageTypeName",
					headerName: i18n.t("type"),
				}),
				enumColumn({
					field: "customerOrderPackageState",
					headerName: i18n.t("state"),
					enumLabels: getCustomerOrderPackageStateLabels(),
					width: 125,
				}),
				textColumn({
					field: "locationCode",
					headerName: i18n.t("warehouse_location_short"),
					width: 80,
				}),
				floatColumn({
					field: "weight",
					headerName: i18n.t("weight"),
					width: 75,
				}),
				floatColumn({
					field: "length",
					headerName: i18n.t("length"),
					width: 75,
				}),
				floatColumn({
					field: "width",
					headerName: i18n.t("width"),
					width: 75,
				}),
				floatColumn({
					field: "height",
					headerName: i18n.t("height"),
					width: 75,
				}),
				enumColumn({
					field: "deliveryState",
					headerName: i18n.t("delivery_state"),
					enumLabels: getCustomerOrderDeliveryStateLabels(),
					width: 125,
				}),
				textColumn({
					field: "deliveryPostalCode",
					headerName: i18n.t("postal_code"),
				}),
				textColumn({
					field: "deliveryCity",
					headerName: i18n.t("city"),
				}),
				enumColumn({
					field: "customerOrderState",
					headerName: i18n.t("customer_order_state"),
					enumLabels: getCustomerOrderStateLabels(),
					width: 140,
				}),
				textColumn({
					field: "customerName",
					headerName: i18n.t("customer"),
				}),
				textColumn({
					field: "orderReference",
					headerName: i18n.t("reference"),
					width: 170,
				}),
				textColumn({
					field: "createdByUserName",
					headerName: i18n.t("created_by"),
				}),
				dateTimeColumn({
					field: "createdDate",
					headerName: i18n.t("created_at"),
					width: 150,
				}),
				dateTimeColumn({
					field: "pickedAt",
					headerName: i18n.t("picked_at"),
					width: 150,
				}),
				dateColumn({
					field: "plannedDeliveryDate",
					headerName: i18n.t("planned_delivery_date"),
					width: 170,
				}),
				integerColumn({
					field: "customerOrderPackageId",
					headerName: i18n.t("id"),
					width: 75,
				}),
			]}
			form={{
				dialogSize: "md",
				dialogTitle: i18n.t("package"),
				addRowEnabled: customerOrderId != null && addEnabled,
				editEnabled: editable,
				component: ({ row, onCompleted, onFormEdited }) => {
					const orderId = customerOrderId ?? row?.customerOrderId;
					if (orderId == null) {
						console.error("customerOrderId is null on package form");
						return null;
					}
					return (
						<CustomerOrderPackageForm
							customerOrderId={orderId}
							customerOrderPackageId={row?.customerOrderPackageId}
							onCompleted={(result) => {
								onCompleted(result);
							}}
							onFormEdited={onFormEdited}
						/>
					);
				},
			}}
			remove={
				editable ?
					{
						type: "enabled",
						action: async (params) => {
							await CustomerOrderPackageDataGridApi.delete_({
								customerOrderPackages: params.items,
							});
						},
					}
				:	undefined
			}
			actionBarComponents={
				<>
					{showSearchField && (
						<AavoTextField
							label={i18n.t("search")}
							onSubmit={async (input) => {
								await refreshData({ searchQuery: input });
							}}
						/>
					)}
					{showDeliveryStateSelection && (
						<SelectField
							label={i18n.t("delivery_state")}
							options={CustomerOrderDeliveryStateValues}
							getOptionKey={(option) => option}
							getOptionLabel={(option) => getCustomerOrderDeliveryStateLabel(option)}
							onChange={async (value) => {
								await refreshData({ deliveryState: value });
							}}
						/>
					)}
					{showCustomerSelection && (
						<AsyncSelectField
							label={i18n.t("customer")}
							getOptionKey={(o: Customer) => o.customerId}
							getOptionLabel={(o) => o.customerName}
							fetchOptions={({ currentSelection, searchQuery }) =>
								CustomerApi.getCustomerSelectionOptions({
									searchQuery: searchQuery,
									currentSelection: currentSelection,
								})
							}
							onChange={async (value) => {
								await refreshData({ customerId: value?.customerId });
							}}
						/>
					)}
					{showCustomerOrderDocumentsButton && customerOrderIdFromPropOrSelectedRow != null && (
						<DocumentsOfObjectButton
							objectRef={{
								objectType: "CUSTOMER_ORDER",
								objectId: customerOrderIdFromPropOrSelectedRow!,
							}}
							label={i18n.t("order_documents")}
							variant={"outlined"}
							editable={"onlyOfficeForExistingDocuments"}
						/>
					)}
					{showPrintPackageLabelButton && (
						<AsyncButton
							icon={faPrint}
							label={i18n.t("package_label")}
							variant={"outlined"}
							disabled={() => {
								if (!onlySelectedRow) return i18n.t("select_line");
								return false;
							}}
							onClick={async () => {
								if (!onlySelectedRow) return;
								const fileHandle =
									await CustomerOrderPackageApi.printCustomerOrderPackageLabel({
										customerOrderPackageId: onlySelectedRow.customerOrderPackageId,
									});
								downloadFile(fileHandle);
							}}
						/>
					)}
					{showCalculateWeightButton && (
						<AsyncButton
							icon={faCalculator}
							label={i18n.t("calculate_weight")}
							variant={"outlined"}
							disabled={() => {
								if (!onlySelectedRow) return i18n.t("select_line");
								return false;
							}}
							onClick={async () => {
								if (!onlySelectedRow) return;
								await CustomerOrderPackageApi.calculateCustomerOrderPackageWeight({
									customerOrderPackageId: onlySelectedRow.customerOrderPackageId,
								});
								await refreshData();
							}}
						/>
					)}
					{showStateChangeActions &&
						(onlySelectedRow == undefined ||
							onlySelectedRow.customerOrderPackageState === "PARTIALLY_PICKED") && (
							<AsyncButton
								label={i18n.t("mark_picked")}
								variant={"outlined"}
								icon={faCheck}
								disabled={!onlySelectedRow ? i18n.t("select_line") : false}
								onClick={async () => {
									if (!onlySelectedRow) return;
									await CustomerOrderPackageApi.setPackageStateToPicked({
										customerOrderPackageId: onlySelectedRow.customerOrderPackageId,
									});
									onStateChanged?.();
									await refreshData();
								}}
							/>
						)}
					{showStateChangeActions && onlySelectedRow?.customerOrderPackageState === "PICKED" && (
						<AsyncButton
							label={i18n.t("return_open")}
							variant={"outlined"}
							icon={faUndo}
							onClick={async () => {
								if (!onlySelectedRow) return;
								await CustomerOrderPackageApi.setPackageStateToUnpicked({
									customerOrderPackageId: onlySelectedRow.customerOrderPackageId,
								});
								onStateChanged?.();
								await refreshData();
							}}
						/>
					)}
					{actionBarExtraComponents}
				</>
			}
			actionBarMenuComponents={[
				userHasMultipleErpSites && customerOrderId == null && customerOrderDeliveryId == null && (
					<MenuCheckbox
						key={"onlyDefaultSite"}
						label={i18n.t("default_site")}
						checked={currentParams.onlyDefaultSite}
						onChange={async (checked) => {
							await refreshData({ onlyDefaultSite: checked });
						}}
					/>
				),
			]}
			rowContextMenuComponents={({ row }) => [
				row.customerOrderId != null && (
					<OpenCustomerOrderButton
						key={"openCustomerOrder"}
						customerOrderId={row.customerOrderId}
					/>
				),
				row.customerOrderId != null && row.customerOrderDeliveryId != null && (
					<OpenCustomerOrderDeliveryViewButton
						key={"openCustomerOrderDelivery"}
						customerOrderId={row.customerOrderId}
						onlyCustomerOrderDeliveryId={row.customerOrderDeliveryId}
					/>
				),
				<AsyncMenuButton
					key={"openPackageLines"}
					label={i18n.t("package_lines")}
					icon={faShare}
					onClick={() => {
						openDialog(() => ({
							title: i18n.t("package_lines"),
							size: "xl",
							content: <CustomerOrderPackageLinesDataGrid customerOrderPackage={row} />,
						}));
					}}
				/>,
				showSetStateContextMenuButtons && row.customerOrderPackageState == "PARTIALLY_PICKED" && (
					<AsyncMenuButton
						key={"markPicked"}
						label={i18n.t("mark_picked")}
						icon={faCheck}
						disabled={!row ? i18n.t("select_line") : false}
						onClick={async () => {
							if (!row) return;
							await CustomerOrderPackageApi.setPackageStateToPicked({
								customerOrderPackageId: row.customerOrderPackageId,
							});
							refreshRef?.refresh();
						}}
					/>
				),
				showSetStateContextMenuButtons && row.customerOrderPackageState == "PICKED" && (
					<AsyncMenuButton
						key={"returnToOpen"}
						label={i18n.t("return_open")}
						icon={faUndo}
						disabled={() => {
							if (!row) return i18n.t("select_line");
							return false;
						}}
						onClick={async () => {
							if (!row) return;
							await CustomerOrderPackageApi.setPackageStateToUnpicked({
								customerOrderPackageId: row.customerOrderPackageId,
							});
							refreshRef?.refresh();
						}}
					/>
				),
			]}
		/>
	);
};
