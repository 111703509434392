import { AavoMosaic } from "src/components/common/mosaic/AavoMosaic";
import i18n from "i18next";
import { ProjectActivityJobQueueCompletedActivitiesDataGrid } from "src/components/views/erp/project/activityJobQueue/ProjectActivityJobQueueCompletedActivitiesDataGrid.tsx";
import { useRefreshRef } from "src/utils/useRefreshRef.ts";
import { ProjectActivityJobQueueViewContext } from "./ProjectActivityJobQueueViewContext";
import { useMediaQueryBreakpointDown } from "src/utils/breakpointUtils.ts";
import { ProjectActivityJobQueueInProgressDesktopView } from "src/components/views/erp/project/activityJobQueue/ProjectActivityJobQueueInProgressDesktopView.tsx";
import { ProjectActivityJobQueueInProgressMobileView } from "src/components/views/erp/project/activityJobQueue/ProjectActivityJobQueueInProgressMobileView.tsx";
import { ProjectActivityJobQueueInitialActivitiesView } from "src/components/views/erp/project/activityJobQueue/ProjectActivityJobQueueInitialActivitiesView.tsx";

export interface ProjectActivityJobQueueViewProps {}

export const ProjectActivityJobQueueView = ({}: ProjectActivityJobQueueViewProps) => {
	const isMobile = useMediaQueryBreakpointDown("sm");

	return (
		<ProjectActivityJobQueueViewContext.Provider
			value={{
				initialActivitiesRefreshRef: useRefreshRef(),
				inProgressActivitiesRefreshRef: useRefreshRef(),
				completedActivitiesRefreshRef: useRefreshRef(),
			}}
		>
			<AavoMosaic
				viewId={"9FD09BFF8A0529D3"}
				layout={{
					type: "tab",
					items: [
						{
							type: "panel",
							key: "notStarted",
							title: i18n.t("not_started"),
							content: <ProjectActivityJobQueueInitialActivitiesView />,
						},
						{
							type: "panel",
							key: "inProgress",
							title: i18n.t("in_progress"),
							content:
								isMobile ?
									<ProjectActivityJobQueueInProgressMobileView />
								:	<ProjectActivityJobQueueInProgressDesktopView />,
						},
						{
							type: "panel",
							key: "completed",
							title: i18n.t("completed"),
							content: <ProjectActivityJobQueueCompletedActivitiesDataGrid />,
						},
					],
				}}
			/>
		</ProjectActivityJobQueueViewContext.Provider>
	);
};
