import {
	TransformedConfigurationComponent,
	TransformedConfigurationComponent_SubConfigurator,
	TransformedConfigurationComponent_SubConfiguratorList,
} from "src/api/generated/erp/configurator/componentTransformation/transformedConfigurationComponent.ts";
import { ConfiguratorInput } from "src/api/generated/erp/configurator/model/configuratorInput.ts";
import {
	ConfigurationPropertyValue,
	ConfigurationPropertyValue_SubConfiguration,
} from "src/api/generated/io/aavo/applications/db/erp/types/configurationPropertyValue.ts";
import { logError } from "src/errorHandling/errorLogging.ts";
import { SubConfiguratorFormApi } from "src/api/generated/erp/configurator/api/form/subConfiguratorFormApi.ts";
import { PartConfiguratorView } from "src/components/views/erp/configurator/configuratorForm/PartConfiguratorView.tsx";
import { OpenGenericDialogFunc } from "src/components/common/dialogs/GenericDialogProvider.tsx";
import { SubConfiguratorPartConfiguratorType } from "src/api/generated/erp/configurator/configuratorType/impl/subConfiguratorPartConfiguratorType.ts";
import { ConfigurationPropertyValues } from "src/api/generated/erp/configurator/model/configurationPropertyValues";
import { PartConfiguratorType } from "src/api/generated/erp/configurator/configuratorType/partConfiguratorType";
import { PartConfiguratorFormApi } from "src/api/generated/erp/configurator/api/form/partConfiguratorFormApi.ts";

export function constructConfiguratorInputFromComponents(
	components: TransformedConfigurationComponent[],
): ConfiguratorInput {
	return {
		inputComponentValues: components.map((component) => ({
			componentId: component.configurationComponentId,
			value: component.currentValue,
		})),
	};
}

export function setConfiguratorInputSingleComponentValue({
	configuratorInput,
	componentId,
	newComponentValue,
}: {
	configuratorInput: ConfiguratorInput;
	componentId: number;
	newComponentValue: ConfigurationPropertyValue;
}): ConfiguratorInput {
	const newInputComponentValues = [...configuratorInput.inputComponentValues]
		.filter((item) => item.componentId !== componentId)
		.concat({
			componentId: componentId,
			value: newComponentValue,
		});

	return {
		...configuratorInput,
		inputComponentValues: newInputComponentValues,
	};
}

export function setComponentCurrentValue(
	component: TransformedConfigurationComponent,
	value: ConfigurationPropertyValue,
): TransformedConfigurationComponent {
	switch (component.type) {
		case "field":
			return {
				...component,
				currentValue: value,
			};
		case "subConfigurator":
			if (value.type !== "sub_configuration") {
				logError(`Sub configurator value is not of type sub_configuration but ${value.type}`);
				return component;
			}
			return {
				...component,
				currentValue: value,
			};
		case "subConfiguratorList":
			if (value.type !== "sub_configuration_list") {
				logError(
					`Sub configurator list value is not of type sub_configuration_list but ${value.type}`,
				);
				return component;
			}
			return {
				...component,
				currentValue: value,
			};
		case "sectionBreak":
		case "tab":
			return component;
	}
}

interface OpenSubConfiguratorFormParams {
	openDialog: OpenGenericDialogFunc;
	parentConfiguratorType: PartConfiguratorType;
	parentPropertyValues: ConfigurationPropertyValues;
	component:
		| TransformedConfigurationComponent_SubConfigurator
		| TransformedConfigurationComponent_SubConfiguratorList;
	subConfiguratorValue: ConfigurationPropertyValue_SubConfiguration;
	onSubmit: (newValue: ConfigurationPropertyValue_SubConfiguration) => void;
}

export async function openSubConfiguratorFormHelper({
	openDialog,
	parentConfiguratorType,
	parentPropertyValues,
	component,
	subConfiguratorValue,
	onSubmit,
}: OpenSubConfiguratorFormParams) {
	const subConfiguratorType: SubConfiguratorPartConfiguratorType = {
		type: "subConfigurator",
		subConfiguratorCatalogPartId: component.catalogPartId,
		parentConfiguratorType: parentConfiguratorType,
		parentProperties: parentPropertyValues,
		isTestConfigurator: parentConfiguratorType.isTestConfigurator,
	};

	const configurationSessionId = await PartConfiguratorFormApi.initializeSession({
		configuratorType: subConfiguratorType,
	});

	const subConfiguratorInput = await SubConfiguratorFormApi.getSubConfigurationInputFromValue({
		configuratorType: subConfiguratorType,
		subConfiguratorValue: subConfiguratorValue,
		configurationSessionId: configurationSessionId,
	});

	openDialog(({ closeDialog }) => ({
		title: component.label,
		size: "fullscreen",
		content: (
			<PartConfiguratorView
				configurationSessionId={configurationSessionId}
				configuratorType={subConfiguratorType}
				initialConfiguratorInput={subConfiguratorInput}
				onCompleted={async (result) => {
					if (result.type === "success") {
						const subConfigurationValues =
							await SubConfiguratorFormApi.getSubConfigurationValueFromInput({
								configurationSessionId: result.value.configurationSessionId,
								configuratorType: subConfiguratorType,
								subConfiguratorInput: result.value.configuratorInput,
							});

						onSubmit(subConfigurationValues);
					}
					await closeDialog();
				}}
			/>
		),
	}));
}
