// This file is automatically generated. Do not edit manually.

import { CustomerOrderPackage } from "src/api/generated/erp/db/types/tables/customerOrderPackage";
import { CustomerOrderPackageType } from "src/api/generated/erp/db/types/tables/customerOrderPackageType";
import { WarehouseLocation } from "src/api/generated/erp/db/types/tables/warehouseLocation";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace CustomerOrderPackageFormApi {
	export async function getInitData(args: {
		customerOrderId: number;
		customerOrderPackageId: number | null | undefined;
	}): Promise<CustomerOrderPackageFormApi_InitData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/picking/customerOrderPackageForm/getInitData",
			method: "POST",
			data: {
				customerOrderId: args.customerOrderId,
				customerOrderPackageId: args.customerOrderPackageId,
			},
		});
		return response as CustomerOrderPackageFormApi_InitData;
	}

	export async function save(args: { customerOrderPackage: CustomerOrderPackage }): Promise<CustomerOrderPackage> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/picking/customerOrderPackageForm/save",
			method: "POST",
			data: {
				customerOrderPackage: args.customerOrderPackage,
			},
		});
		return response as CustomerOrderPackage;
	}
}

export interface CustomerOrderPackageFormApi_InitData {
	customerOrderPackage: CustomerOrderPackage | null | undefined;
	customerOrderPackageTypeOptions: Array<CustomerOrderPackageType>;
	nextPackageNumber: number;
	warehouseLocationOptions: Array<WarehouseLocation>;
}
