// This file is automatically generated. Do not edit manually.

import { CustomerOrderPackageLineView } from "src/api/generated/erp/db/types/tables/customerOrderPackageLineView";
import { FrontendDownloadFileHandle } from "src/api/generated/common/frontendDownloadFileHandle";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace CustomerOrderPackageApi {
	export async function getPackageLines(args: {
		customerOrderPackageId: number;
	}): Promise<Array<CustomerOrderPackageLineView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/picking/customerOrderPackage/getPackageLines",
			method: "POST",
			data: {
				customerOrderPackageId: args.customerOrderPackageId,
			},
		});
		return response as Array<CustomerOrderPackageLineView>;
	}

	export async function setPackageStateToPicked(args: { customerOrderPackageId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/picking/customerOrderPackage/setPackageStateToPicked",
			method: "POST",
			data: {
				customerOrderPackageId: args.customerOrderPackageId,
			},
		});
		return response as void;
	}

	export async function setPackageStateToUnpicked(args: { customerOrderPackageId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/picking/customerOrderPackage/setPackageStateToUnpicked",
			method: "POST",
			data: {
				customerOrderPackageId: args.customerOrderPackageId,
			},
		});
		return response as void;
	}

	export async function printCustomerOrderPackageLabel(args: {
		customerOrderPackageId: number;
	}): Promise<FrontendDownloadFileHandle> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/picking/customerOrderPackage/printCustomerOrderPackageLabel",
			method: "POST",
			data: {
				customerOrderPackageId: args.customerOrderPackageId,
			},
		});
		return response as FrontendDownloadFileHandle;
	}

	export async function calculateCustomerOrderPackageWeight(args: { customerOrderPackageId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/picking/customerOrderPackage/calculateCustomerOrderPackageWeight",
			method: "POST",
			data: {
				customerOrderPackageId: args.customerOrderPackageId,
			},
		});
		return response as void;
	}

	export async function movePackagesToDelivery(args: {
		customerOrderDeliveryId: number;
		customerOrderPackageIds: Array<number>;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/picking/customerOrderPackage/movePackagesToDelivery",
			method: "POST",
			data: {
				customerOrderDeliveryId: args.customerOrderDeliveryId,
				customerOrderPackageIds: args.customerOrderPackageIds,
			},
		});
		return response as void;
	}

	export async function deletePackageFromDelivery(args: { customerOrderPackageIds: Array<number> }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/picking/customerOrderPackage/deletePackageFromDelivery",
			method: "POST",
			data: {
				customerOrderPackageIds: args.customerOrderPackageIds,
			},
		});
		return response as void;
	}
}
