import { useState } from "react";
import { ProjectActivityView } from "src/api/generated/erp/db/types/tables/projectActivityView.ts";
import { CenteredTypography } from "src/components/common/CenteredTypography.tsx";
import { SingleSourceTasksView } from "src/components/views/tasks/pages/SingleSourceTasksView.tsx";
import { ProjectActivitiesDataGrid } from "src/components/views/erp/project/projectActivity/ProjectActivitiesDataGrid.tsx";
import i18n from "i18next";
import { SingleActivityMaterialLinesDataGrid } from "src/components/views/erp/project/projectActivity/material/SingleActivityMaterialLinesDataGrid.tsx";
import { ProjectActivityCatalogPartsDataGrid } from "src/components/views/erp/project/projectActivity/connectedParts/ProjectActivityCatalogPartsDataGrid.tsx";
import { CostEventsOfObjectDataGrid } from "src/components/views/erp/common/costEvents/CostEventsOfObjectDataGrid.tsx";
import { AavoMosaic } from "src/components/common/mosaic/AavoMosaic.tsx";
import { ProjectActivityProductChangeRequestsDataGrid } from "src/components/views/erp/project/projectActivity/ProjectActivityProductChangeRequestsDataGrid.tsx";
import { useRefreshRef } from "src/utils/useRefreshRef.ts";

export interface ProjectActivitiesContainerViewProps {
	subProjectId: number;
	onlyActivityId?: number;
}

export const ProjectActivitiesContainerView = ({
	subProjectId,
	onlyActivityId,
}: ProjectActivitiesContainerViewProps) => {
	const [selectedActivity, setSelectedActivity] = useState<ProjectActivityView | undefined>(undefined);

	const selectActivityTypography = <CenteredTypography children={i18n.t("select_an_activity")} />;

	const tasksViewRefreshRef = useRefreshRef();

	return (
		<AavoMosaic
			viewId={"80CEED57FF71A199"}
			layout={{
				type: "column",
				first: {
					type: "panel",
					title: i18n.t("activities"),
					content: (
						<ProjectActivitiesDataGrid
							subProjectId={subProjectId}
							onlyActivityId={onlyActivityId}
							onSelectionChanged={setSelectedActivity}
							tasksViewRefreshRef={tasksViewRefreshRef}
						/>
					),
				},
				second: {
					type: "tab",
					items: [
						{
							type: "panel",
							title: i18n.t("tasks"),
							content:
								selectedActivity == null ? selectActivityTypography : (
									<SingleSourceTasksView
										key={selectedActivity.activityId}
										taskSourceRef={{
											sourceType: "PROJECT_ACTIVITY",
											sourceId: selectedActivity.activityId,
										}}
										refreshRef={tasksViewRefreshRef}
									/>
								),
						},
						{
							type: "panel",
							title: i18n.t("materials"),
							content:
								selectedActivity == null ? selectActivityTypography : (
									<SingleActivityMaterialLinesDataGrid
										key={selectedActivity.activityId}
										activityId={selectedActivity.activityId}
									/>
								),
						},
						{
							type: "panel",
							title: i18n.t("connected_parts"),
							content:
								selectedActivity == null ? selectActivityTypography : (
									<ProjectActivityCatalogPartsDataGrid
										key={selectedActivity.activityId}
										projectActivityId={selectedActivity.activityId}
									/>
								),
						},
						{
							type: "panel",
							title: i18n.t("product_change_requests"),
							content:
								selectedActivity == null ? selectActivityTypography : (
									<ProjectActivityProductChangeRequestsDataGrid
										key={selectedActivity.activityId}
										activityId={selectedActivity.activityId}
									/>
								),
						},
						selectedActivity?.costEventPriceListId != null && {
							type: "panel",
							title: i18n.t("cost_events"),
							content: (
								<CostEventsOfObjectDataGrid
									key={selectedActivity.activityId}
									objectRef={{
										objectType: "PROJECT_ACTIVITY",
										objectId: selectedActivity.activityId,
									}}
									costEventPriceListId={selectedActivity.costEventPriceListId}
								/>
							),
						},
					],
				},
			}}
		/>
	);
};
