// This file is automatically generated. Do not edit manually.

import { CustomerOrderLinePickingObjectLocationView } from "src/api/generated/erp/db/types/tables/customerOrderLinePickingObjectLocationView";
import { FrontendDownloadFileHandle } from "src/api/generated/common/frontendDownloadFileHandle";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace CustomerOrderPickableLinesDataGridApi {
	export async function getLines(args: {
		customerOrderId: number;
	}): Promise<Array<CustomerOrderLinePickingObjectLocationView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/picking/customerOrderPickableLinesDataGrid/getLines",
			method: "POST",
			data: {
				customerOrderId: args.customerOrderId,
			},
		});
		return response as Array<CustomerOrderLinePickingObjectLocationView>;
	}

	export async function printCustomerOrderPickingList(args: {
		customerOrderId: number;
	}): Promise<FrontendDownloadFileHandle> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/picking/customerOrderPickableLinesDataGrid/printCustomerOrderPickingList",
			method: "POST",
			data: {
				customerOrderId: args.customerOrderId,
			},
		});
		return response as FrontendDownloadFileHandle;
	}

	export async function pickCustomerOrderLineToPackage(args: {
		customerOrderLineId: number;
		customerOrderPackageId: number;
		quantityInWarehouseUnit: number;
		partWarehouseLocationId: number | null | undefined;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/picking/customerOrderPickableLinesDataGrid/pickCustomerOrderLineToPackage",
			method: "POST",
			data: {
				customerOrderLineId: args.customerOrderLineId,
				customerOrderPackageId: args.customerOrderPackageId,
				quantityInWarehouseUnit: args.quantityInWarehouseUnit,
				partWarehouseLocationId: args.partWarehouseLocationId,
			},
		});
		return response as void;
	}

	export async function revertCustomerOrderLineReservation(args: {
		customerOrderLineId: number;
		partWarehouseLocationId: number | null | undefined;
		quantityToRevertInWarehouseUnit: number;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/picking/customerOrderPickableLinesDataGrid/revertCustomerOrderLineReservation",
			method: "POST",
			data: {
				customerOrderLineId: args.customerOrderLineId,
				partWarehouseLocationId: args.partWarehouseLocationId,
				quantityToRevertInWarehouseUnit: args.quantityToRevertInWarehouseUnit,
			},
		});
		return response as void;
	}
}
