import {AsyncFetchRender} from "src/components/common/async/AsyncFetchRender.tsx";
import {HorizontalBox} from "src/components/common/box/HorizontalBox.tsx";
import i18n from "i18next";
import {VerticalBox} from "src/components/common/box/VerticalBox";
import {useState} from "react";
import {faRefresh} from "@fortawesome/pro-regular-svg-icons";
import Typography from "@mui/material/Typography";
import {AavoButton} from "src/components/common/buttons/AavoButton.tsx";
import {
	SelectedOperationActionsView,
	SelectedOperationActionsViewProps,
} from "src/components/views/erp/production/jobQueue/base/inProgressView/SelectedOperationActionsView.tsx";
import {Box} from "@mui/material";
import {
	JobQueueShopOrderOperation
} from "src/components/views/erp/production/jobQueue/base/JobQueueShopOrderOperation.ts";
import {Workcenter} from "src/api/generated/erp/db/types/tables/workcenter.ts";
import {OperationList} from "src/components/views/erp/production/jobQueue/base/inProgressView/OperationList.tsx";
import {
	SelectedOperationInfoView
} from "src/components/views/erp/production/jobQueue/base/inProgressView/SelectedOperationInfoView.tsx";
import {
	SelectedOperationTabsView
} from "src/components/views/erp/production/jobQueue/base/inProgressView/SelectedOperationTabsView.tsx";
import {useJobQueueViewContext} from "src/components/views/erp/production/jobQueue/base/JobQueueViewContext.ts";
import {
	ProductionLineJobQueueInProgressApi_WorkCenterControlChartDto
} from "src/api/generated/erp/production/api/jobQueues/productionLine/productionLineJobQueueInProgressApi.ts";

export interface JobQueueInProgressViewProps {
	fetchData: () => Promise<JobQueueInProgressData>;
	extraActionBarComponents?: SelectedOperationActionsViewProps["extraActionComponents"];
}

export interface JobQueueInProgressData {
	shopOrderOperations: JobQueueShopOrderOperation[];
	workCenter: Workcenter;
	workCenterControlChart?: ProductionLineJobQueueInProgressApi_WorkCenterControlChartDto | null | undefined;
}

export const JobQueueInProgressView = ({ fetchData, extraActionBarComponents }: JobQueueInProgressViewProps) => {
	const { inProgressViewRefreshRef } = useJobQueueViewContext();
	const [selectedOperation, setSelectedOperation] = useState<JobQueueShopOrderOperation | undefined>(
		undefined,
	);

	return (
		<AsyncFetchRender
			fetch={() => fetchOperations(fetchData)}
			refreshRef={inProgressViewRefreshRef}
			content={({ shopOrderOperations, workCenter, workCenterControlChart }, reload) => {
				if (shopOrderOperations.length === 0)
					return (
						<VerticalBox flex={1} alignItems={"center"} margin={"auto"}>
							<Typography children={i18n.t("no_jobs_in_progress")} />
							<AavoButton icon={faRefresh} onClick={reload} />
						</VerticalBox>
					);

				return (
					<HorizontalBox flex={1}>
						<Box
							sx={{
								flex: 2,
								display: "grid",
								gridTemplateRows: "auto 1fr 1fr",
								"& > :not(:last-child)": {
									borderBottom: (theme) => `1px solid ${theme.palette.grey[600]}`,
								},
							}}
						>
							<SelectedOperationActionsView
								selectedOperation={selectedOperation}
								workCenter={workCenter}
								extraActionComponents={extraActionBarComponents}
								workCenterControlChart={workCenterControlChart}
							/>
							<OperationList
								rows={shopOrderOperations}
								selectedJob={selectedOperation}
								selectJob={setSelectedOperation}
							/>
							<SelectedOperationInfoView selectedOperation={selectedOperation} />
						</Box>
						<SelectedOperationTabsView selectedOperation={selectedOperation} />
					</HorizontalBox>
				);
			}}
		/>
	);

	async function fetchOperations(fetchData: () => Promise<JobQueueInProgressData>) {
		const result = await fetchData();
		const { shopOrderOperations } = result;
		if (
			selectedOperation == null ||
			!shopOrderOperations.some(
				(job) => job.shopOrderOperationId === selectedOperation.shopOrderOperationId,
			)
		) {
			setSelectedOperation(shopOrderOperations[0]);
		}
		return result;
	}
};
