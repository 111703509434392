import { AavoMosaic } from "src/components/common/mosaic/AavoMosaic.tsx";
import i18n from "i18next";
import { CustomerOrderDeliveriesDataGrid } from "src/components/views/erp/sales/delivery/CustomerOrderDeliveriesDataGrid.tsx";
import { CustomerOrderUndeliveredPackagesDataGrid } from "src/components/views/erp/sales/picking/CustomerOrderUndeliveredPackagesDataGrid.tsx";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { CustomerOrderPackageApi } from "src/api/generated/erp/sales/picking/api/customerOrderPackageApi.ts";
import { CustomerOrderDeliveryView } from "src/api/generated/erp/db/types/tables/customerOrderDeliveryView.ts";
import { useState } from "react";
import { CustomerOrderPackageView } from "src/api/generated/erp/db/types/tables/customerOrderPackageView.ts";
import { CenteredTypography } from "src/components/common/CenteredTypography.tsx";
import { faMinus, faTruckLoading } from "@fortawesome/pro-regular-svg-icons";
import { CustomerOrderPackagesDataGridBase } from "src/components/views/erp/sales/picking/CustomerOrderPackagesDataGridBase.tsx";
import { useRefreshRef } from "src/utils/useRefreshRef.ts";

export interface CustomerOrderDeliveringViewProps {
	customerOrderId: number;
	onlyCustomerOrderDeliveryId?: number;
}

export const CustomerOrderDeliveringView = ({
	customerOrderId,
	onlyCustomerOrderDeliveryId,
}: CustomerOrderDeliveringViewProps) => {
	const [selectedDelivery, setSelectedDelivery] = useState<CustomerOrderDeliveryView | undefined>(
		undefined,
	);
	const [selectedUndeliveredPackages, setSelectedUndeliveredPackages] = useState<
		CustomerOrderPackageView[]
	>([]);
	const [selectedDeliveryPackages, setSelectedDeliveryPackages] = useState<CustomerOrderPackageView[]>([]);
	const deliveryPackagesRefreshRef = useRefreshRef();
	const undeliveredPackagesRefreshRef = useRefreshRef();
	return (
		<AavoMosaic
			viewId={"B898FA3F9CC228BF"}
			layout={{
				type: "column",
				first: {
					type: "panel",
					title: i18n.t("deliveries"),
					content: (
						<CustomerOrderDeliveriesDataGrid
							customerOrderId={customerOrderId}
							setSelectedDelivery={setSelectedDelivery}
							onlyCustomerOrderDeliveryId={onlyCustomerOrderDeliveryId}
						/>
					),
				},
				second: {
					type: "row",
					first: {
						type: "panel",
						title: i18n.t("undelivered_packages"),
						content:
							selectedDelivery == undefined ?
								<CenteredTypography>{i18n.t("select_delivery")}</CenteredTypography>
							:	<CustomerOrderUndeliveredPackagesDataGrid
									key={selectedDelivery.customerOrderDeliveryId}
									gridId={"548BE3FC2D394C94"}
									customerOrderId={customerOrderId}
									onSelectedPackageChanged={(selectedPackages) => {
										setSelectedUndeliveredPackages(selectedPackages);
									}}
									selectedRows={selectedUndeliveredPackages}
									showCustomerOrderDocumentsButton={false}
									showSearchField={false}
									showStateChangeActions={false}
									showCalculateWeightButton={false}
									showPrintPackageLabelButton={false}
									editable={false}
									addEnabled={false}
									refreshRef={undeliveredPackagesRefreshRef}
									actionBarExtraComponents={
										<AsyncButton
											icon={faTruckLoading}
											label={i18n.t("move_to_delivery")}
											variant="outlined"
											disabled={
												selectedUndeliveredPackages.length === 0 ||
												selectedDelivery.deliveryState !== "INITIAL"
											}
											onClick={async () => {
												await CustomerOrderPackageApi.movePackagesToDelivery({
													customerOrderDeliveryId:
														selectedDelivery?.customerOrderDeliveryId,
													customerOrderPackageIds: selectedUndeliveredPackages.map(
														(row) => row.customerOrderPackageId,
													),
												});
												await deliveryPackagesRefreshRef.refresh();
												await undeliveredPackagesRefreshRef?.refresh();
											}}
										/>
									}
								/>,
					},
					second: {
						type: "panel",
						title: i18n.t("delivery_packages"),
						content:
							selectedDelivery == null ?
								<CenteredTypography>{i18n.t("select_delivery")}</CenteredTypography>
							:	<CustomerOrderPackagesDataGridBase
									key={selectedDelivery.customerOrderDeliveryId}
									gridId={"548BE3FC2D394C90"}
									selectedRows={selectedDeliveryPackages}
									onSelectedPackageChanged={(selectedPackages) =>
										setSelectedDeliveryPackages(selectedPackages)
									}
									customerOrderDeliveryId={selectedDelivery.customerOrderDeliveryId}
									showSearchField={false}
									refreshRef={deliveryPackagesRefreshRef}
									editable={false}
									showPrintPackageLabelButton={false}
									showCalculateWeightButton={false}
									actionBarExtraComponents={
										selectedDelivery?.deliveryState === "INITIAL" && (
											<AsyncButton
												label={i18n.t("remove_from_delivery")}
												icon={faMinus}
												variant="outlined"
												disabled={selectedDeliveryPackages.length === 0}
												onClick={async () => {
													await CustomerOrderPackageApi.deletePackageFromDelivery({
														customerOrderPackageIds: selectedDeliveryPackages.map(
															(row) => row.customerOrderPackageId,
														),
													});
													await deliveryPackagesRefreshRef.refresh();
													await undeliveredPackagesRefreshRef?.refresh();
												}}
											/>
										)
									}
								/>,
					},
				},
			}}
		/>
	);
};
