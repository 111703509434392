// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const AavoObjectTypeValues = [
	"PROJECT_ACTIVITY_MATERIAL",
	"CATALOG_PART",
	"CATALOG_PART_REVISION",
	"CHART_GROUP",
	"COMPANY",
	"CONFIGURATION_COMPONENT",
	"CONTROL_CHART",
	"CONTROL_CHART_PARENT_GROUP",
	"C_CHART_EVENT",
	"CUSTOMER_ORDER",
	"CUSTOMER_ORDER_LINE",
	"CUSTOMER_ORDER_OFFER",
	"CUSTOMER_ORDER_OFFER_LINE",
	"SPC_RECORD",
	"SPC_RESOURCE",
	"INVOICE",
	"OPERATION",
	"PART",
	"PART_CONFIGURATION",
	"PART_REVISION",
	"PART_REVISION_OPERATION",
	"PROCESS_IMPROVEMENT",
	"PRODUCT_CHANGE_REQUEST",
	"PROJECT",
	"PROJECT_ACTIVITY",
	"PROJECT_GROUP",
	"PURCHASE_ORDER",
	"PURCHASE_ORDER_LINE",
	"PURCHASE_ORDER_RECEIPT",
	"PURCHASE_CONTRACT",
	"RECLAMATION",
	"RECLAMATION_COST_LINE",
	"RECLAMATION_V2",
	"SAFETY_CONTROL_SURVEY",
	"SAFETY_CONTROL_SURVEY_NOTE",
	"SALES_PART",
	"SALES_CONTRACT",
	"SHOP_ORDER",
	"SHOP_ORDER_LINE",
	"SHOP_ORDER_OPERATION",
	"SALES_COMMISSION",
	"SALES_COMMISSION_LINE",
	"SITE",
	"SUB_PROJECT",
	"SUPPLIER",
	"SUPPLIER_PART",
	"SURVEY",
	"SURVEY_TASK",
	"TASK",
	"WAREHOUSE_REPLENISHMENT",
	"WORKCENTER",
	"WORKSPACE",
] as const;

export type AavoObjectType = (typeof AavoObjectTypeValues)[number];

export const getAavoObjectTypeLabels = () => ({
	PROJECT_ACTIVITY_MATERIAL: t("project_activity_material"),
	CATALOG_PART: t("catalog_part"),
	CATALOG_PART_REVISION: t("catalog_part_revision"),
	CHART_GROUP: t("chart_group"),
	COMPANY: t("company"),
	CONFIGURATION_COMPONENT: t("configuration_component"),
	CONTROL_CHART: t("control_chart"),
	CONTROL_CHART_PARENT_GROUP: t("control_chart_parent_group"),
	C_CHART_EVENT: t("c_chart_event"),
	CUSTOMER_ORDER: t("customer_order"),
	CUSTOMER_ORDER_LINE: t("customer_order_line"),
	CUSTOMER_ORDER_OFFER: t("customer_order_offer"),
	CUSTOMER_ORDER_OFFER_LINE: t("customer_order_offer_line"),
	SPC_RECORD: t("spc_record"),
	SPC_RESOURCE: t("spc_resource"),
	INVOICE: t("invoice"),
	OPERATION: t("operation"),
	PART: t("part"),
	PART_CONFIGURATION: t("part_configuration"),
	PART_REVISION: t("part_revision"),
	PART_REVISION_OPERATION: t("part_revision_operation"),
	PROCESS_IMPROVEMENT: t("process_improvement"),
	PRODUCT_CHANGE_REQUEST: t("product_change_request"),
	PROJECT: t("project"),
	PROJECT_ACTIVITY: t("project_activity"),
	PROJECT_GROUP: t("project_group"),
	PURCHASE_ORDER: t("purchase_order"),
	PURCHASE_ORDER_LINE: t("purchase_order_line"),
	PURCHASE_ORDER_RECEIPT: t("purchase_order_receipt"),
	PURCHASE_CONTRACT: t("purchase_contract"),
	RECLAMATION: t("reclamation"),
	RECLAMATION_COST_LINE: t("reclamation_cost_line"),
	RECLAMATION_V2: t("reclamation_v2"),
	SAFETY_CONTROL_SURVEY: t("safety_control_survey"),
	SAFETY_CONTROL_SURVEY_NOTE: t("safety_control_survey_note"),
	SALES_PART: t("sales_part"),
	SALES_CONTRACT: t("sales_contract"),
	SHOP_ORDER: t("shop_order"),
	SHOP_ORDER_LINE: t("shop_order_line"),
	SHOP_ORDER_OPERATION: t("shop_order_operation"),
	SALES_COMMISSION: t("sales_commission"),
	SALES_COMMISSION_LINE: t("sales_commission_line"),
	SITE: t("site"),
	SUB_PROJECT: t("sub_project"),
	SUPPLIER: t("supplier"),
	SUPPLIER_PART: t("supplier_part"),
	SURVEY: t("survey"),
	SURVEY_TASK: t("survey_task"),
	TASK: t("task"),
	WAREHOUSE_REPLENISHMENT: t("warehouse_replenishment"),
	WORKCENTER: t("workcenter"),
	WORKSPACE: t("workspace"),
});

export const getAavoObjectTypeLabel = (value: AavoObjectType): string => {
	return getAavoObjectTypeLabels()[value];
};
