import {GridColDef, GridSingleSelectColDef} from "@mui/x-data-grid/models/colDef/gridColDef";
import {dayJsToDateIsoString, formatDayJs, isValidDateString, isValidISOString,} from "src/utils/dayjsUtils.ts";
import i18n from "i18next";
import {
	GridEditBooleanCell,
	GridEditInputCell,
	GridEditSingleSelectCell,
	GridRenderEditCellParams,
	GridValueFormatter,
} from "@mui/x-data-grid-pro";
import {logError} from "src/errorHandling/errorLogging";
import dayjs, {Dayjs} from "dayjs";
import {
	ColumnValidationProp,
	columnValidationProps,
} from "src/components/common/dataGrid/crud/validation/columnValidation.tsx";
import {FontAwesomeIcon, FontAwesomeIconProps} from "@fortawesome/react-fontawesome";
import {faCheck, faXmark} from "@fortawesome/pro-regular-svg-icons";
import {AavoTooltip} from "src/components/common/tooltips/AavoTooltip.tsx";
import {AavoDataGridDateEditor} from "./crud/editors/AavoDataGridDateEditor";
import React from "react";
import {AavoDataGridDateTimeEditor} from "src/components/common/dataGrid/crud/editors/AavoDataGridDateTimeEditor.tsx";
import {
	AavoDataGridAsyncSingleSelectEditor,
	AavoDataGridAsyncSingleSelectEditorProps,
} from "src/components/common/dataGrid/crud/editors/AavoDataGridAsyncSingleSelectEditor.tsx";
import {GridValidRowModel} from "@mui/x-data-grid/models/gridRows";
import {
	AavoDataGridAutoCompleteSingleSelectEditor,
	AavoDataGridAutoCompleteSingleSelectEditorProps,
} from "src/components/common/dataGrid/crud/editors/AavoDataGridAutoCompleteSingleSelectEditor.tsx";
import {GridValueOptionsParams} from "@mui/x-data-grid/models/params/gridValueOptionsParams";
import {formatNumber} from "src/utils/numberUtils.ts";

export interface AavoColumnBaseProps<RowData extends object, V>
	extends ColumnValidationProp<RowData, V>,
		PositioningProps<RowData> {
	field: ObjectStringFields<RowData>;
}

export interface PositioningProps<RowData extends object> {
	position?: number;
	afterColumn?: ObjectStringFields<RowData>;
}

type ObjectStringFields<RowData> = Exclude<keyof RowData, symbol | number>;

export const textColumn = <RowData extends object>(
	props: Omit<GridColDef<RowData, string>, "type" | "field"> & AavoColumnBaseProps<RowData, string>,
): GridColDef<RowData, string> => ({
	...props,
	...columnValidationProps(props, GridEditInputCell),
	type: "string",
});

export const singleSelectColumn = <RowData extends object, V = any>(
	props: Omit<GridColDef<RowData>, "type" | "field" | "valueGetter" | "valueOptions"> &
		AavoColumnBaseProps<RowData, V> & {
			// valueOptions is redeclared, because Webstorm has problems detecting it from GridColDef
			valueOptions: GridSingleSelectColDef["valueOptions"];
		},
): GridColDef<RowData> => {
	return {
		...props,
		...columnValidationProps(props, GridEditSingleSelectCell),
		type: "singleSelect",
		valueGetter: (value) => {
			if (value === null || value === undefined) {
				return "";
			} else {
				return value;
			}
		},
	};
};

export const enumColumn = <RowData extends object, TEnum extends string>(
	props: Omit<GridColDef<RowData>, "type" | "field" | "valueGetter"> & {
		field: ObjectStringFields<RowData>;
		enumLabels: { [key in TEnum]: string };
	} & ColumnValidationProp<RowData, TEnum>,
): GridColDef<RowData> => {
	return singleSelectColumn({
		...props,
		...columnValidationProps(props, GridEditSingleSelectCell),
		valueOptions: Object.keys(props.enumLabels).map((key) => ({
			value: key,
			label: props.enumLabels[key as TEnum],
		})),
	});
};

export interface AutoCompleteSingleSelectColumnProps<
	RowData extends GridValidRowModel,
	TOption extends object,
	TKey extends string | number,
	DisableClearable extends boolean | undefined,
> extends Omit<
			GridColDef<RowData>,
			"type" | "field" | "valueGetter" | "valueOptions" | "getOptionLabel" | "getOptionKey"
		>,
		AavoColumnBaseProps<RowData, TKey>,
		Pick<
			AavoDataGridAutoCompleteSingleSelectEditorProps<RowData, TOption, TKey, DisableClearable>,
			"onChange"
		> {
	valueOptions: Array<TOption> | ((params: GridValueOptionsParams<RowData>) => Array<TOption>);
	getOptionValue: (option: TOption) => TKey;
	getOptionLabel: (option: TOption) => string;
	disableClearable?: DisableClearable;
}

export const autoCompleteSingleSelectColumn = <
	RowData extends object,
	TOption extends object,
	TKey extends string | number,
	DisableClearable extends boolean | undefined,
>({
	valueOptions,
	getOptionValue,
	getOptionLabel,
	disableClearable,
	onChange,
	...otherProps
}: AutoCompleteSingleSelectColumnProps<RowData, TOption, TKey, DisableClearable>): GridColDef<RowData> => {
	const renderEditCell = (params: GridRenderEditCellParams<RowData, TKey>) => {
		const options = Array.isArray(valueOptions) ? valueOptions : valueOptions(params);
		return (
			<AavoDataGridAutoCompleteSingleSelectEditor
				options={options}
				getOptionKey={getOptionValue}
				getOptionLabel={getOptionLabel}
				disableClearable={disableClearable}
				onChange={onChange}
				{...params}
			/>
		);
	};

	return {
		...otherProps,
		...columnValidationProps(otherProps, renderEditCell),
		type: "singleSelect",
		renderEditCell: renderEditCell,
		valueOptions: valueOptions,
		getOptionLabel: (value) => {
			// Filter component may pass empty string here.
			if (typeof value !== "object") return "";
			return getOptionLabel(value as TOption);
		},
		getOptionValue: (value) => {
			if (typeof value !== "object") return -1;
			return getOptionValue(value as TOption);
		},
	};
};

export interface AsyncSingleSelectColumnProps<
	RowData extends GridValidRowModel,
	TOption extends object,
	TKey extends string | number,
> extends Omit<GridColDef<RowData>, "type" | "field" | "valueGetter" | "valueOptions">,
		AavoColumnBaseProps<RowData, TKey>,
		Pick<
			AavoDataGridAsyncSingleSelectEditorProps<RowData, TOption, TKey>,
			"fetchOptions" | "getOptionKey" | "disableClearable"
		> {
	getLabel: (rowOrOption: RowData | TOption) => string;
	setLabel: (row: RowData, value: TOption | null) => RowData;
}

export const asyncSingleSelectColumn = <
	RowData extends object,
	TOption extends object,
	TKey extends string | number,
>({
	fetchOptions,
	getOptionKey,
	getLabel,
	setLabel,
	disableClearable,
	...otherProps
}: AsyncSingleSelectColumnProps<RowData, TOption, TKey>): GridColDef<RowData> => {
	const renderEditCell = (cellEditorParams: GridRenderEditCellParams<RowData, TKey>) => (
		<AavoDataGridAsyncSingleSelectEditor
			fetchOptions={fetchOptions}
			getOptionKey={getOptionKey}
			getOptionLabel={getLabel}
			disableClearable={disableClearable}
			{...cellEditorParams}
		/>
	);

	return {
		...otherProps,
		...columnValidationProps(otherProps, renderEditCell),
		type: "singleSelect",
		filterable: false,
		renderEditCell: renderEditCell,
		valueFormatter: (_, row) => {
			return getLabel(row);
		},
		valueSetter: (value, row) => {
			// Value may be selected option (if object) or current key (otherwise)
			const valueIsOption = typeof value === "object";
			if (!valueIsOption) return row;

			const newKey = value == null ? null : getOptionKey(value);

			const withNewKey = {
				...row,
				[otherProps.field]: newKey,
			};

			return setLabel(withNewKey, value);
		},
	};
};

export const booleanColumn = <RowData extends object>(
	props: Omit<GridColDef<RowData>, "type" | "field"> & AavoColumnBaseProps<RowData, boolean>,
): GridColDef<RowData> => {
	return {
		...props,
		type: "boolean",
		...columnValidationProps(props, GridEditBooleanCell),
	};
};

export const integerColumn = <RowData extends object>(
	props: Omit<GridColDef<RowData, number>, "type" | "field" | "align" | "headerAlign"> &
		AavoColumnBaseProps<RowData, number>,
): GridColDef<RowData, number> => {
	return floatColumn(props);
};

export const floatColumn = <RowData extends object>({
	maxDigits = 2,
	minDigits = 0,
	...props
}: Omit<GridColDef<RowData>, "type" | "field" | "align" | "headerAlign"> &
	AavoColumnBaseProps<RowData, number> & {
		maxDigits?: number;
		minDigits?: number;
	}): GridColDef<RowData> => {
	return {
		...props,
		...columnValidationProps(props, GridEditInputCell),
		type: "number",
		align: "left",
		headerAlign: "left",
		valueFormatter: (value: number | null | undefined) => {
			if (value == null) {
				return "";
			} else {
				return formatNumber(value, {
					minDigits: minDigits,
					maxDigits: maxDigits,
				});
			}
		},
	};
};

export const dateTimeColumn = dateColumnFactory({
	format: "L LT",
	editor: AavoDataGridDateTimeEditor,
	toIsoString: (value) => value.toISOString(),
});

export const dateColumn = dateColumnFactory({
	format: "L",
	editor: AavoDataGridDateEditor,
	toIsoString: (value) => dayJsToDateIsoString(value),
});

/**
 * Expects value in RowData to be a ISO-string.
 */
function dateColumnFactory(params: {
	format: string;
	editor: React.ComponentType<GridRenderEditCellParams>;
	toIsoString: (value: Dayjs) => string;
}) {
	return <RowData extends object>(
		props: Omit<GridColDef<RowData>, "type" | "field" | "valueParser" | "valueFormatter"> & {
			field: ObjectStringFields<RowData>;
		} & ColumnValidationProp<RowData, Dayjs> &
			PositioningProps<RowData> & {
				valueFormatter?: GridValueFormatter<RowData, Dayjs, string, Dayjs>;
			},
	): GridColDef<RowData> => {
		const { toIsoString, format } = params;
		const { valueGetter, field, valueFormatter, ...otherProps } = props;
		return {
			...otherProps,
			field: field,
			type: "date",
			renderEditCell: (cellEditorProps) => <params.editor {...cellEditorProps} />,
			valueGetter: (valueParam, row, column, apiRef) => {
				const value = valueGetter?.(valueParam, row, column, apiRef) ?? (valueParam as unknown);
				if (value == null || value === "") return undefined;
				if (dayjs.isDayjs(value)) return value;
				else if (typeof value === "string" && (isValidISOString(value) || isValidDateString(value))) {
					return dayjs(value);
				} else {
					logError(`Invalid date value in data grid (value: '${value}', field: '${field}')`);
					return undefined;
				}
			},
			valueSetter: (value, row) => {
				return {
					...row,
					[field]: value == undefined ? null : toIsoString(value),
				};
			},
			valueFormatter: (value, row, column, apiRef) => {
				if (value == undefined) {
					return "";
				} else if (dayjs.isDayjs(value)) {
					return valueFormatter ?
							valueFormatter(value, row, column, apiRef)
						:	formatDayJs(value, format);
				} else {
					return i18n.t("invalid_date");
				}
			},
			...columnValidationProps(props, params.editor),
		};
	};
}

export const coloredBooleanColumn = <RowData extends object>(
	props: Omit<GridColDef<RowData>, "type" | "field"> & AavoColumnBaseProps<RowData, boolean>,
): GridColDef<RowData> =>
	booleanColumn({
		renderCell: ({ value }) => {
			return value ?
					<FontAwesomeIcon icon={faCheck} color={"green"} size={"lg"} />
				:	<FontAwesomeIcon icon={faXmark} color={"red"} size={"lg"} />;
		},
		...props,
	});

export const iconColumn = <RowData extends object>({
	getIconProps,
	...other
}: Omit<GridColDef<RowData>, "renderCell"> & {
	getIconProps: (rowData: RowData) => IconColumnIconProps | undefined;
}): GridColDef<RowData> => ({
	renderCell: ({ row }) => {
		const iconResult = getIconProps(row);
		if (iconResult === undefined) return null;
		return (
			<AavoTooltip
				title={iconResult.tooltip}
				arrow
				sx={{
					flex: 1,
				}}
			>
				<FontAwesomeIcon {...iconResult} />
			</AavoTooltip>
		);
	},
	width: 10,
	align: "center",
	display: "flex",
	filterable: false,
	...other,
});

interface IconColumnIconProps extends FontAwesomeIconProps {
	tooltip?: string;
}
