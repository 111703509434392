import {genericNullableValue} from "src/utils/genericNullableValue.ts";
import {dateTimeColumn, integerColumn, textColumn} from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import {ClientSideDataGridModel} from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel.tsx";
import {SelectField} from "src/components/common/inputFields/SelectField.tsx";
import {concatWithPipe} from "src/utils/strings.tsx";
import {DocumentsOfObjectButton} from "src/components/views/documents/objectDocuments/DocumentsOfObjectButton.tsx";
import {AavoDataGrid, AavoDataGridRowContextMenuParams,} from "src/components/common/dataGrid/AavoDataGrid.tsx";
import {nullableAavoObjectRef} from "src/utils/aavoObjectRefUtils.ts";
import {AsyncButton} from "src/components/common/buttons/AsyncButton.tsx";
import {faDolly, faPeopleCarry, faPlay, faTable} from "@fortawesome/pro-regular-svg-icons";
import {
	ShopOrderOperationStateTransferApi
} from "src/api/generated/erp/production/api/jobQueues/shopOrderOperationStateTransferApi.ts";
import {
	openLegacyWorkCenterWarehouseSupplyRequestContainerView
} from "src/components/views/legacy/legacyViewAdapters.ts";
import {useOpenLegacyView} from "src/components/views/legacy/useOpenLegacyView.ts";
import {useGenericDialog} from "src/components/common/dialogs/useGenericDialog.ts";
import {
	PartConfigurationPropertiesDataGrid
} from "src/components/views/erp/configurator/inspecting/PartConfigurationPropertiesDataGrid.tsx";
import {
	JobQueueShopOrderOperation
} from "src/components/views/erp/production/jobQueue/base/JobQueueShopOrderOperation.ts";
import {ProductionLine} from "src/api/generated/erp/db/types/tables/productionLine.ts";
import {Operation} from "src/api/generated/erp/db/types/tables/operation.ts";
import {useJobQueueViewContext} from "src/components/views/erp/production/jobQueue/base/JobQueueViewContext.ts";
import React from "react";
import {
	ShopOrderBomReserveAndPickView
} from "src/components/views/erp/production/ShopOrderPicking/ShopOrderBomReserveAndPickView.tsx";

export interface JobQueueInitialsViewProps {
	gridId: string;
	fetchData: (props: JobQueueInitialDataProps) => Promise<JobQueueInitialsData>;
	extraRowContextMenuComponents?: (
		params: JobQueueInitialsViewExtraRowContextComponentsProps,
	) => React.ReactNode;
}

export interface JobQueueInitialDataProps {
	operationId: number | null | undefined;
	productionLineId: number | null | undefined;
}

export interface JobQueueInitialsData {
	rows: JobQueueShopOrderOperation[];
	operationOptions?: Operation[];
	productionLineOptions?: ProductionLine[];
	startOperationEnabled?: boolean;
}

export interface JobQueueInitialsViewExtraRowContextComponentsProps
	extends AavoDataGridRowContextMenuParams<JobQueueShopOrderOperation> {
	refreshData: () => Promise<unknown>;
}

export const JobQueueInitialsView = ({
	fetchData,
	gridId,
	extraRowContextMenuComponents,
}: JobQueueInitialsViewProps) => {
	const { initialsViewRefreshRef, inProgressViewRefreshRef } = useJobQueueViewContext();
	const openLegacyView = useOpenLegacyView();
	const { openDialog } = useGenericDialog();

	return (
		<ClientSideDataGridModel
			fetchData={fetchData}
			initialParams={{
				operationId: genericNullableValue<number>(),
				productionLineId: genericNullableValue<number>(),
			}}
			getRows={(data) => data.rows}
			getRowId={(row) => row.shopOrderOperationId}
			gridId={gridId}
			refreshRef={initialsViewRefreshRef}
			render={({
				data: { operationOptions, productionLineOptions, startOperationEnabled = true },
				dataGridProps,
				currentParams,
				refreshData,
				onlySelectedRow,
				selectedRows,
			}) => {
				return (
					<AavoDataGrid<JobQueueShopOrderOperation>
						disableColumnSorting
						columns={[
							integerColumn({
								field: "shopOrderId",
								headerName: i18n.t("number_shortened"),
							}),
							dateTimeColumn({
								field: "shopOrderPlannedBeginDate",
								headerName: i18n.t("start_date"),
								width: 150,
							}),
							textColumn({
								field: "operationDescription",
								headerName: i18n.t("operation"),
								width: 150,
							}),
							textColumn({
								field: "sourceRefData",
								headerName: i18n.t("reference"),
								width: 200,
							}),
							textColumn({
								field: "configurationOrPartDescription",
								headerName: i18n.t("item"),
								width: 200,
							}),
							integerColumn({
								field: "partRevision",
								headerName: i18n.t("revision"),
								width: 80,
							}),
							textColumn({
								field: "quantity",
								headerName: i18n.t("amount"),
							}),
							textColumn({
								field: "partUnit",
								headerName: i18n.t("unit"),
							}),
							textColumn({
								field: "shopOrderBatchName",
								headerName: i18n.t("production_batch"),
							}),
							textColumn({
								field: "packageInfo",
								headerName: i18n.t("packaging_info"),
								width: 120,
							}),
							textColumn({
								field: "capacityQuantity",
								headerName: i18n.t("capacity"),
							}),
							textColumn({
								field: "operationNo",
								headerName: i18n.t("operation_no"),
							}),
							textColumn({
								field: "productionLineNo",
								headerName: i18n.t("production_line"),
							}),
							textColumn({
								field: "shopOrderNote",
								headerName: i18n.t("note"),
								width: 150,
							}),
							integerColumn({
								field: "partConfigurationId",
								headerName: i18n.t("configuration_id"),
							}),
						]}
						actionBarComponents={
							<>
								<DocumentsOfObjectButton
									objectRef={nullableAavoObjectRef(
										"SHOP_ORDER_OPERATION",
										onlySelectedRow?.shopOrderOperationId,
									)}
								/>
								{operationOptions != undefined && (
									<SelectField
										label={i18n.t("operation")}
										options={operationOptions}
										getOptionKey={(option) => option.operationId}
										getOptionLabel={(option) => option.operationDescription}
										value={currentParams.operationId}
										onChange={(value) => refreshData({ operationId: value })}
									/>
								)}
								{productionLineOptions != undefined && (
									<SelectField
										label={i18n.t("production_line")}
										options={productionLineOptions}
										getOptionKey={(o) => o.productionLineId}
										getOptionLabel={(o) =>
											concatWithPipe(o.productionLineNo, o.productionLineName)
										}
										value={currentParams.productionLineId}
										onChange={(value) => refreshData({ productionLineId: value })}
									/>
								)}
								{startOperationEnabled && (
									<AsyncButton
										label={i18n.t("start")}
										icon={faPlay}
										variant={"outlined"}
										disabled={selectedRows.length === 0}
										onClick={onStartClick}
									/>
								)}
								<AsyncButton
									label={i18n.t("warehouse_transfer")}
									icon={faPeopleCarry}
									variant={"outlined"}
									onClick={() => {
										openLegacyWorkCenterWarehouseSupplyRequestContainerView({
											openLegacyView,
										});
									}}
								/>
								<AsyncButton
									icon={faDolly}
									label={i18n.t("reserve_and_pick")}
									variant={"outlined"}
									disabled={!onlySelectedRow}
									onClick={() => {
										openDialog(() => ({
											title: i18n.t("reserve_and_pick_materials"),
											size: "lg",
											content: (
												<ShopOrderBomReserveAndPickView
													shopOrderId={onlySelectedRow!.shopOrderId}
													shopOrderOperationId={onlySelectedRow!.shopOrderOperationId}
												/>
											),
										}));
									}}
								/>
								<DocumentsOfObjectButton
									label={i18n.t("order")}
									variant={"outlined"}
									objectRef={nullableAavoObjectRef(
										"CUSTOMER_ORDER",
										onlySelectedRow?.customerOrderId,
									)}
								/>
								{onlySelectedRow?.partConfigurationId != null && (
									<AsyncButton
										label={i18n.t("configuration")}
										icon={faTable}
										variant={"outlined"}
										onClick={() => {
											openDialog(() => ({
												title: i18n.t("configuration"),
												content: (
													<PartConfigurationPropertiesDataGrid
														partConfigurationId={
															onlySelectedRow.partConfigurationId!
														}
													/>
												),
											}));
										}}
									/>
								)}
							</>
						}
						rowContextMenuComponents={(params) => [
							extraRowContextMenuComponents?.({
								...params,
								refreshData,
							}),
						]}
						{...dataGridProps}
					/>
				);

				async function onStartClick() {
					await ShopOrderOperationStateTransferApi.startOperations({
						shopOrderOperationIds: selectedRows.map((row) => row.shopOrderOperationId),
					});
					await Promise.all([refreshData(), inProgressViewRefreshRef.refresh()]);
				}
			}}
		/>
	);
};
