import { ConfigurationProductFamily } from "src/api/generated/erp/db/types/tables/configurationProductFamily.ts";
import { DeepPartial } from "react-hook-form";
import { ConfigurationProductFamiliesApi } from "src/api/generated/erp/configurator/management/productFamilies/api/configurationProductFamiliesApi.ts";
import { FormCommonProps } from "src/components/common/forms/types.ts";
import { AavoForm } from "src/components/common/forms/AavoForm.tsx";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import { requireRule } from "src/components/common/forms/validation.ts";
import i18n from "i18next";

export interface ConfigurationProductFamilyFormProps extends FormCommonProps<number> {
	productFamily: ConfigurationProductFamily | undefined;
}

interface FormValues {
	name: string;
}

export const ConfigurationProductFamilyForm = (props: ConfigurationProductFamilyFormProps) => {
	const { onCompleted, onFormEdited, productFamily } = props;
	return (
		<AavoForm
			defaultValues={getDefaultValues()}
			submit={submit}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			render={({ control }) => (
				<FormTextField control={control} name={"name"} label={i18n.t("name")} rules={requireRule()} />
			)}
		/>
	);

	function getDefaultValues(): DeepPartial<FormValues> {
		if (productFamily != null) return productFamily;
		else return {};
	}

	async function submit(values: FormValues) {
		if (productFamily != null) {
			await ConfigurationProductFamiliesApi.update({
				configurationProductFamilyId: productFamily.configurationProductFamilyId,
				name: values.name,
			});
			return productFamily.configurationProductFamilyId;
		} else {
			return await ConfigurationProductFamiliesApi.insert({
				name: values.name,
			});
		}
	}
};
