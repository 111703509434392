import { DeepPartial } from "react-hook-form";
import { AavoForm } from "src/components/common/forms/AavoForm.tsx";
import { FormNumberField } from "src/components/common/forms/fields/FormNumberField";
import { FormCommonProps } from "src/components/common/forms/types.ts";
import { nonNegativeFloatRule, requireRule } from "src/components/common/forms/validation";
import i18n from "i18next";
import { useState } from "react";
import { AavoTextField } from "src/components/common/inputFields/AavoTextField.tsx";
import { PartReorderPointCalculationParams } from "src/api/generated/io/aavo/applications/db/erp/types/partReorderPointCalculationParams.ts";
import { inverseNormalStdDev } from "src/utils/statistics.ts";

export interface PartReorderPointCalculationFormProps extends FormCommonProps<FormResult> {
	calculationParams: PartReorderPointCalculationParams | undefined;
}

export interface FormValues extends PartReorderPointCalculationParams {}

export interface FormResult {
	reorderPoint: number | null;
	calculationParams: PartReorderPointCalculationParams;
}

export const PartReorderPointCalculationForm = ({
	calculationParams: defaultCalculationParams,
	onCompleted,
	onFormEdited,
}: PartReorderPointCalculationFormProps) => {
	const defaultValues: DeepPartial<FormValues> = defaultCalculationParams ?? {
		serviceLevelPercent: 95,
	};

	const [replenishmentDemandStDev, setReplenishmentDemandStDev] = useState<number | null>(
		calculateReplenishmentDemandStDev(defaultValues),
	);
	const [reorderPoint, setReorderPoint] = useState<number | null>(calculateReorderPoint(defaultValues));

	return (
		<AavoForm
			defaultValues={defaultValues}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			submit={submit}
			render={({ control, watch }) => {
				watch((newValues) => {
					setReplenishmentDemandStDev(calculateReplenishmentDemandStDev(newValues));
					setReorderPoint(calculateReorderPoint(newValues));
				});

				return (
					<>
						<FormNumberField
							control={control}
							name={"demandPerDayAvg"}
							label={`${i18n.t("average_demand_per_day")}*`}
							rules={requireRule()}
						/>
						<FormNumberField
							control={control}
							name={"demandPerDayStDev"}
							label={`${i18n.t("demand_st_dev")}*`}
							rules={requireRule()}
						/>
						<FormNumberField
							control={control}
							name={"replenishmentTimeDaysAvg"}
							label={`${i18n.t("replenishment_time_days")}*`}
							rules={requireRule()}
						/>
						<FormNumberField
							control={control}
							name={"replenishmentTimeDaysStDev"}
							label={`${i18n.t("replenishment_time_st_dev")}*`}
							rules={requireRule()}
						/>
						<FormNumberField
							control={control}
							name={"serviceLevelPercent"}
							label={`${i18n.t("service_level_percent")}*`}
							rules={nonNegativeFloatRule((value) => {
								if (value < 0 || value > 100)
									return i18n.t("value_must_be_between_0_and_100");
							})}
						/>
						<AavoTextField
							label={i18n.t("replenishment_demand_st_dev")}
							value={
								replenishmentDemandStDev == null ? "-" : (
									`${replenishmentDemandStDev.toFixed(2)}`
								)
							}
							disabled
						/>
						<AavoTextField
							label={i18n.t("reorder_point")}
							value={reorderPoint == null ? "-" : `${reorderPoint}`}
							disabled
						/>
					</>
				);
			}}
		/>
	);

	function calculateReplenishmentDemandStDev(values: DeepPartial<FormValues>): number | null {
		if (
			values.demandPerDayAvg == null ||
			values.demandPerDayStDev == null ||
			values.replenishmentTimeDaysAvg == null ||
			values.replenishmentTimeDaysStDev == null
		)
			return null;

		return Math.sqrt(
			values.replenishmentTimeDaysAvg * Math.pow(values.demandPerDayStDev, 2) +
				Math.pow(values.demandPerDayAvg, 2) * Math.pow(values.replenishmentTimeDaysStDev, 2),
		);
	}

	function calculateReorderPoint(values: DeepPartial<FormValues>): number | null {
		const replenishmentDemandStDev = calculateReplenishmentDemandStDev(values);
		if (
			replenishmentDemandStDev == null ||
			values.demandPerDayAvg == null ||
			values.replenishmentTimeDaysAvg == null ||
			values.serviceLevelPercent == null ||
			values.serviceLevelPercent < 0 ||
			values.serviceLevelPercent > 100
		)
			return null;

		const serviceLevelFactor = inverseNormalStdDev(values.serviceLevelPercent / 100);

		return Math.ceil(
			values.demandPerDayAvg * values.replenishmentTimeDaysAvg +
				serviceLevelFactor * replenishmentDemandStDev,
		);
	}

	function submit(formValues: FormValues): FormResult {
		return {
			reorderPoint: reorderPoint,
			calculationParams: formValues,
		};
	}
};
