import {
	ProjectTreeViewApi,
	ProjectTreeViewApi_TreeViewData,
} from "src/api/generated/erp/project/api/projectTreeViewApi.ts";
import { useParameterizedAsyncData } from "src/utils/async/parameterizedAsyncData.ts";
import { AsyncRender } from "src/components/common/async/AsyncRender.tsx";
import { mapProjectTreeData, ProjectTreeItemModel } from "./treeItemMapping";
import React, { useEffect, useMemo, useState } from "react";
import { VerticalBox } from "src/components/common/box/VerticalBox.tsx";
import { AsyncButton } from "src/components/common/buttons/AsyncButton";
import { faAngleDown, faAngleLeft, faAngleRight, faPlus } from "@fortawesome/pro-regular-svg-icons";
import { TreeItem2, TreeItem2Content, TreeItem2Props } from "@mui/x-tree-view/TreeItem2";
import { useTreeItem2 } from "@mui/x-tree-view/useTreeItem2/useTreeItem2";
import { HorizontalBox } from "src/components/common/box/HorizontalBox";
import { Box, Typography, useTheme } from "@mui/material";
import i18n from "i18next";
import { AavoTextField } from "src/components/common/inputFields/AavoTextField.tsx";
import { MenuCheckbox } from "src/components/common/contextMenu/MenuCheckbox.tsx";
import { useGlobalInitData } from "src/contexts/useGlobalInitData.ts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { styled } from "@mui/material/styles";
import { RefreshableElementRef } from "src/utils/useRefreshRef.ts";
import { useContextMenu } from "src/components/common/contextMenu/useContextMenu.ts";
import { AavoContextMenu } from "src/components/common/contextMenu/AavoContextMenu.tsx";
import { ProjectContextMenu } from "src/components/views/erp/project/project/ProjectContextMenu.tsx";
import { SubProjectContextMenu } from "src/components/views/erp/project/subProject/SubProjectContextMenu.tsx";
import { ProjectActivityContextMenu } from "src/components/views/erp/project/projectActivity/ProjectActivityContextMenu.tsx";
import { useContextOrThrow } from "src/utils/useContextOrThrow.tsx";
import { openFormOnDialog } from "src/components/common/dialogs/formDialog/openFormOnDialog.ts";
import { CreateNewProjectForm } from "src/components/views/erp/project/project/CreateNewProjectForm.tsx";
import { useGenericDialog } from "src/components/common/dialogs/useGenericDialog.ts";
import { AavoTreeView } from "src/components/common/tree/AavoTreeView.tsx";
import { flatMapTreeItems } from "src/components/common/tree/AavoTreeView.utils.ts";
import { UseTreeItem2Status } from "@mui/x-tree-view/useTreeItem2";

export interface ProjectTreeViewProps {
	onlyProjectId: number | undefined;
	refreshRef: RefreshableElementRef;
	onSelectionChanged: (selectedItem: ProjectTreeItemModel | null) => void;
	onSelectedItemUpdated: () => Promise<unknown>;
	tasksViewRefreshRef: RefreshableElementRef;
}

const PAGE_SIZE = 30;

export const ProjectTreeView = ({
	onlyProjectId,
	refreshRef,
	onSelectionChanged,
	onSelectedItemUpdated,
	tasksViewRefreshRef,
}: ProjectTreeViewProps) => {
	const { dataAsync, refresh, paramValues } = useParameterizedAsyncData<
		ProjectTreeViewApi_TreeViewData,
		ProjectTreeViewParams
	>({
		fetchData: (params) =>
			ProjectTreeViewApi.getTreeViewData({
				onlyProjectId: onlyProjectId,
				pageSize: PAGE_SIZE,
				...params,
			}),
		initialParams: {
			searchQuery: "",
			showCompleted: false,
			showCancelled: false,
			onlyDefaultSite: true,
			showTemplateProjects: false,
			pageOffset: 0,
		},
		refreshRef: refreshRef,
		afterRefresh: async ({ projects, totalProjectCount }, latestParams) => {
			if (projects.length === 0 && totalProjectCount > 0) {
				// There are data, but page offset was too high.
				// Reload first page.
				await refresh({
					...latestParams,
					pageOffset: 0,
				});
			}
			await onSelectedItemUpdated();
		},
	});

	return (
		<AsyncRender
			asyncData={dataAsync}
			reloadOnError={() => refresh()}
			content={(data) => (
				<ProjectTreeViewContent
					data={data}
					refresh={refresh}
					paramValues={paramValues}
					onSelectionChanged={onSelectionChanged}
					onSelectedItemUpdated={onSelectedItemUpdated}
					tasksViewRefreshRef={tasksViewRefreshRef}
					onlyProjectId={onlyProjectId}
				/>
			)}
		/>
	);
};

interface ProjectTreeViewParams {
	searchQuery: string;
	showCompleted: boolean;
	showCancelled: boolean;
	onlyDefaultSite: boolean;
	showTemplateProjects: boolean;
	pageOffset: number;
}

interface ProjectTreeViewContentProps
	extends Pick<
		ProjectTreeViewProps,
		"onSelectionChanged" | "onSelectedItemUpdated" | "onlyProjectId" | "tasksViewRefreshRef"
	> {
	data: ProjectTreeViewApi_TreeViewData;
	paramValues: ProjectTreeViewParams;
	refresh: (params?: Partial<ProjectTreeViewParams>) => Promise<ProjectTreeViewApi_TreeViewData>;
}

const ProjectTreeViewContent = (props: ProjectTreeViewContentProps) => {
	const { data, onSelectionChanged, onSelectedItemUpdated, onlyProjectId, refresh, tasksViewRefreshRef } =
		props;

	const theme = useTheme();

	const [selectedItem, setSelectedItemLocal] = useState<ProjectTreeItemModel | null>(null);
	const setSelectedItem = (item: ProjectTreeItemModel | null) => {
		setSelectedItemLocal(item);
		onSelectionChanged?.(item);
	};

	const projectItems = useMemo(() => mapProjectTreeData(data, theme), [data, theme]);
	const allItems = useMemo(() => flatMapTreeItems(projectItems), [projectItems]);
	const allItemIds = useMemo(() => allItems.map((item) => item.id), [allItems]);
	const allItemsByIds: Record<string, ProjectTreeItemModel> = useMemo(
		() => allItems.reduce((acc, item) => ({ ...acc, [item.id]: item }), {}),
		[allItems],
	);

	const [expandedItems, setExpandedItems] = useState<string[]>(
		allItems.filter((item) => item.type === "subProject").map((item) => item.id),
	);

	useEffect(() => {
		if (selectedItem != null) {
			// Remove selection if selected item doesn't exist
			const item = allItemsByIds[selectedItem.id];
			if (item == null) {
				setSelectedItem(null);
			}
		} else if (onlyProjectId != null) {
			// Select project and expand all if only one project is shown.
			const onlyProject = allItems.find(
				(item) => item.type === "project" && item.projectId === onlyProjectId,
			);
			setSelectedItem(onlyProject ?? null);
			setExpandedItems(allItemIds);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [allItemsByIds]);

	return (
		<ProjectTreeViewContext.Provider
			value={{
				refresh,
				onSelectedItemUpdated,
				setSelectedItem,
				tasksViewRefreshRef,
			}}
		>
			<VerticalBox flex={1}>
				<AavoTreeView<ProjectTreeItemModel>
					items={projectItems}
					slots={{
						item: CustomTreeItemWithStyles,
					}}
					expandedItems={expandedItems}
					onExpandedItemsChange={setExpandedItems}
					selectedItems={selectedItem?.id ?? null}
					onSelectedItemsChange={(_, itemId) => {
						const item = itemId != null ? allItemsByIds[itemId] : null;
						setSelectedItem(item ?? null);
					}}
					refresh={refresh}
					actionBarComponents={<ActionBarComponents {...props} />}
					actionBarMenuComponents={<ActionBarMenuComponents {...props} />}
					sx={{
						borderBottom: "1px solid",
						borderColor: "divider",
					}}
				/>
				<Footer {...props} />
			</VerticalBox>
		</ProjectTreeViewContext.Provider>
	);
};

interface ProjectTreeViewContextValue
	extends Pick<ProjectTreeViewContentProps, "refresh" | "onSelectedItemUpdated" | "tasksViewRefreshRef"> {
	setSelectedItem: (item: ProjectTreeItemModel | null) => void;
}

const ProjectTreeViewContext = React.createContext<ProjectTreeViewContextValue | undefined>(undefined);
const useProjectTreeViewContext = () => useContextOrThrow(ProjectTreeViewContext);

const ActionBarComponents = ({ refresh }: ProjectTreeViewContentProps) => {
	const { openDialog } = useGenericDialog();

	return (
		<>
			<AsyncButton
				icon={faPlus}
				tooltip={i18n.t("create_project")}
				onClick={() => {
					openFormOnDialog({
						openDialog,
						component: CreateNewProjectForm,
						title: i18n.t("new_project"),
						size: "sm",
						props: (closeDialog) => ({
							closeDialog: closeDialog,
						}),
						onSubmit: refresh,
					});
				}}
			/>
			<AavoTextField
				label={i18n.t("search")}
				onSubmit={(searchQuery) => refresh({ searchQuery })}
				sx={{
					ml: 1,
				}}
			/>
		</>
	);
};

const ActionBarMenuComponents = ({ refresh, paramValues }: ProjectTreeViewContentProps) => {
	const { userHasMultipleErpSites } = useGlobalInitData();

	return [
		userHasMultipleErpSites && (
			<MenuCheckbox
				key={"onlyDefaultSite"}
				label={i18n.t("only_default_site")}
				checked={paramValues.onlyDefaultSite}
				onChange={(checked) => refresh({ onlyDefaultSite: checked })}
			/>
		),
		<MenuCheckbox
			key={"showCompleted"}
			label={i18n.t("show_completed")}
			checked={paramValues.showCompleted}
			onChange={(checked) => refresh({ showCompleted: checked })}
		/>,
		<MenuCheckbox
			key={"showCancelled"}
			label={i18n.t("show_cancelled")}
			checked={paramValues.showCancelled}
			onChange={(checked) => refresh({ showCancelled: checked })}
		/>,
		<MenuCheckbox
			key={"showTemplates"}
			label={i18n.t("show_template_projects")}
			checked={paramValues.showTemplateProjects}
			onChange={(checked) => refresh({ showTemplateProjects: checked })}
		/>,
	];
};

const Footer = ({
	refresh,
	paramValues: { pageOffset },
	data: { totalProjectCount },
}: ProjectTreeViewContentProps) => {
	const firstRowNum = pageOffset + 1;
	const lastRowNum = Math.min(pageOffset + PAGE_SIZE, totalProjectCount);

	return (
		<HorizontalBox
			sx={{
				alignItems: "center",
				gap: 1,
				paddingRight: 1,
			}}
		>
			<Box flex={1} />
			<Typography>
				{firstRowNum}-{lastRowNum} / {totalProjectCount}
			</Typography>
			<AsyncButton
				icon={faAngleLeft}
				tooltip={i18n.t("previous_page")}
				disabled={pageOffset <= 0}
				onClick={async () => {
					await refresh({
						pageOffset: Math.max(0, pageOffset - PAGE_SIZE),
					});
				}}
			/>
			<AsyncButton
				icon={faAngleRight}
				tooltip={i18n.t("next_page")}
				disabled={lastRowNum >= totalProjectCount}
				onClick={async () => {
					await refresh({
						pageOffset: lastRowNum,
					});
				}}
			/>
		</HorizontalBox>
	);
};

const CustomTreeItem = React.memo((props: TreeItem2Props) => {
	const { itemId } = props;
	const { publicAPI } = useTreeItem2(props);
	const item = publicAPI.getItem(itemId) as ProjectTreeItemModel;

	return (
		<TreeItem2
			{...props}
			slots={{
				content: CustomTreeItemContent,
				label: CustomTreeItemLabel,
				expandIcon: CustomCollapseExpandIcon,
				collapseIcon: CustomCollapseExpandIcon,
			}}
			slotProps={{
				label: {
					item: item,
				} as any,
				content: {
					item: item,
				} as any,
				expandIcon: {
					icon: faAngleRight,
				} as any,
				collapseIcon: {
					icon: faAngleDown,
				} as any,
			}}
		/>
	);
});

const CustomTreeItemWithStyles = styled(CustomTreeItem)(() => ({
	"& .MuiTreeItem-content": {
		paddingLeft: "0.25rem",
		paddingTop: "0.5rem",
		paddingBottom: "0.5rem",
	},
	"& .MuiTreeItem-iconContainer": {
		width: "2rem",
		"& svg": {
			fontSize: "1.2rem",
		},
	},
}));

const CustomTreeItemContent = ({
	item,
	...other
}: {
	item: ProjectTreeItemModel;
	status: UseTreeItem2Status;
} & unknown) => {
	const [showContextMenu, contextMenuState] = useContextMenu();
	const {
		refresh: refreshData,
		onSelectedItemUpdated,
		setSelectedItem,
		tasksViewRefreshRef,
	} = useProjectTreeViewContext();

	const contextMenuRefreshData = () => Promise.all([refreshData?.(), onSelectedItemUpdated?.()]);

	return (
		<>
			<TreeItem2Content
				onContextMenu={(event) => {
					event.preventDefault();
					setSelectedItem(item);
					showContextMenu({
						mouseEvent: event,
						content:
							item.type === "project" ?
								<ProjectContextMenu project={item} refreshData={contextMenuRefreshData} />
							: item.type === "subProject" ?
								<SubProjectContextMenu
									subProject={item}
									refreshData={contextMenuRefreshData}
								/>
							: item.type === "activity" ?
								<ProjectActivityContextMenu
									activity={item}
									refreshData={contextMenuRefreshData}
									tasksViewRefreshRef={tasksViewRefreshRef}
								/>
							:	null,
					});
				}}
				{...other}
			/>
			<AavoContextMenu state={contextMenuState} />
		</>
	);
};

const CustomTreeItemLabel = ({ item }: { item: ProjectTreeItemModel }) => {
	return (
		<HorizontalBox
			sx={{
				alignItems: "center",
				gap: 1,
			}}
		>
			<FontAwesomeIcon icon={item.icon} color={item.stateColor} />
			<Typography>{item.label}</Typography>
		</HorizontalBox>
	);
};

const CustomCollapseExpandIcon = ({ icon }: { icon: IconDefinition }) => {
	return (
		<HorizontalBox
			sx={{
				marginTop: -1.75,
				marginBottom: -1.75,
				paddingX: 1,
				alignItems: "center",
			}}
		>
			<FontAwesomeIcon icon={icon} />
		</HorizontalBox>
	);
};
