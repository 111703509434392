// This file is automatically generated. Do not edit manually.

import { ShopOrderView } from "src/api/generated/erp/db/types/tables/shopOrderView";
import { ShopOrderAcquisitionObjects } from "src/api/generated/erp/production/service/shopOrderAcquisitionObjects";
import { ShopOrderAcquisitionObjectService_OpenAcquisitionObjects } from "src/api/generated/erp/production/service/shopOrderAcquisitionObjectService";
import { ShopOrderOperationView } from "src/api/generated/erp/db/types/tables/shopOrderOperationView";
import { ShopOrderLine } from "src/api/generated/erp/db/types/tables/shopOrderLine";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ShopOrderApi {
	export async function getSingleShopOrder(args: { shopOrderId: number }): Promise<ShopOrderView> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/shopOrder/getSingleShopOrder",
			method: "POST",
			data: {
				shopOrderId: args.shopOrderId,
			},
		});
		return response as ShopOrderView;
	}

	export async function getShopOrders(args: { shopOrderIds: Array<number> }): Promise<Array<ShopOrderView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/shopOrder/getShopOrders",
			method: "POST",
			data: {
				shopOrderIds: args.shopOrderIds,
			},
		});
		return response as Array<ShopOrderView>;
	}

	export async function isAllShopOrderOperationsCompleted(args: { shopOrderId: number }): Promise<boolean> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/shopOrder/isAllShopOrderOperationsCompleted",
			method: "POST",
			data: {
				shopOrderId: args.shopOrderId,
			},
		});
		return response as boolean;
	}

	export async function getShopOrderAcquisitionObjects(args: {
		shopOrderId: number;
	}): Promise<ShopOrderAcquisitionObjects> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/shopOrder/getShopOrderAcquisitionObjects",
			method: "POST",
			data: {
				shopOrderId: args.shopOrderId,
			},
		});
		return response as ShopOrderAcquisitionObjects;
	}

	export async function getShopOrderLineOpenAcquisitionObjects(args: {
		shopOrderLineId: number;
		allowReceivedPurchaseOrders?: boolean;
	}): Promise<ShopOrderAcquisitionObjectService_OpenAcquisitionObjects> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/shopOrder/getShopOrderLineOpenAcquisitionObjects",
			method: "POST",
			data: {
				shopOrderLineId: args.shopOrderLineId,
				allowReceivedPurchaseOrders: args.allowReceivedPurchaseOrders,
			},
		});
		return response as ShopOrderAcquisitionObjectService_OpenAcquisitionObjects;
	}

	export async function getIncompleteOperationsOfShopOrder(args: {
		shopOrderId: number;
	}): Promise<Array<ShopOrderOperationView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/shopOrder/getIncompleteOperationsOfShopOrder",
			method: "POST",
			data: {
				shopOrderId: args.shopOrderId,
			},
		});
		return response as Array<ShopOrderOperationView>;
	}

	export async function returnShopOrdersWhichHasNotAnyOperations(args: {
		shopOrderIds: Array<number>;
	}): Promise<Array<ShopOrderView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/shopOrder/returnShopOrdersWhichHasNotAnyOperations",
			method: "POST",
			data: {
				shopOrderIds: args.shopOrderIds,
			},
		});
		return response as Array<ShopOrderView>;
	}

	export async function shopOrderHasAnyOperations(args: { shopOrderId: number }): Promise<boolean> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/shopOrder/shopOrderHasAnyOperations",
			method: "POST",
			data: {
				shopOrderId: args.shopOrderId,
			},
		});
		return response as boolean;
	}

	export async function getShopOrderLinesWithReceivedPurchaseOrderLines(args: {
		shopOrderId: number;
	}): Promise<Array<ShopOrderLine>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/shopOrder/getShopOrderLinesWithReceivedPurchaseOrderLines",
			method: "POST",
			data: {
				shopOrderId: args.shopOrderId,
			},
		});
		return response as Array<ShopOrderLine>;
	}
}
