import { useServerSideDataGridModel } from "src/components/common/dataGrid/gridModel/useServerSideDataGridModel";
import { ControlledAsyncCrudDataGrid } from "src/components/common/dataGrid/crud/ControlledAsyncCrudDataGrid";
import {
	booleanColumn,
	coloredBooleanColumn,
	floatColumn,
	integerColumn,
	textColumn,
} from "src/components/common/dataGrid/columns";
import i18n from "i18next";
import { PartType } from "src/api/generated/erp/db/types/enums/partType.ts";
import { useTenantCustomizations } from "src/tenantCustomizations/useTenantCustomizations.tsx";
import { DocumentsOfObjectButton } from "src/components/views/documents/objectDocuments/DocumentsOfObjectButton.tsx";
import { nullableAavoObjectRef } from "src/utils/aavoObjectRefUtils";
import { AavoTextField } from "src/components/common/inputFields/AavoTextField.tsx";
import { AsyncUserSelectField } from "src/components/views/users/AsyncUserSelectField.tsx";
import { genericNullableValue } from "src/utils/genericNullableValue.ts";
import { MenuCheckbox } from "src/components/common/contextMenu/MenuCheckbox.tsx";
import { AsyncMenuButton } from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import { useConfirmDialog } from "src/components/common/dialogs/confirmDialog/useConfirmDialog.ts";
import { faBan, faCheck, faShareAlt, faSync } from "@fortawesome/pro-regular-svg-icons";
import { OpenObjectChangeLogButton } from "src/components/views/changeLogging/OpenObjectChangeLogButton.tsx";
import { RefreshableElementProps } from "src/utils/useRefreshRef.ts";
import { CatalogPartDataGridApi } from "src/api/generated/erp/parts/catalogPart/api/catalogPartDataGridApi.ts";
import { CatalogPartActionApi } from "src/api/generated/erp/parts/catalogPart/api/catalogPartActionApi.ts";
import { useInputDialog } from "src/components/common/dialogs/input/useInputDialog.tsx";
import { UnitsApi } from "src/api/generated/erp/warehouse/basedata/api/unitsApi.ts";
import { CatalogPartView } from "src/api/generated/erp/db/types/tables/catalogPartView";
import { useGenericDialog } from "src/components/common/dialogs/useGenericDialog.ts";
import { CopyCatalogPartToSitesForm } from "src/components/views/erp/parts/catalogPart/CopyCatalogPartToSitesForm.tsx";
import { SitesApi } from "src/api/generated/erp/common/sites/sitesApi.ts";
import { useFormInput } from "src/components/common/dialogs/formInput/useFormInput.tsx";
import { FormMultiSelectField } from "src/components/common/forms/fields/FormMultiSelectField.tsx";
import { CatalogPartForm } from "src/components/views/erp/parts/catalogPart/CatalogPartForm.tsx";
import { CatalogPartEditApi } from "src/api/generated/erp/parts/catalogPart/api/catalogPartEditApi.ts";
import { CopyCatalogPartToSitesFormApi } from "src/api/generated/erp/parts/catalogPart/api/copyCatalogPartToSitesFormApi.ts";
import { useMessageDialog } from "src/components/common/dialogs/messageDialog/useMessageDialog.ts";
import { concatWithPipe } from "src/utils/strings.tsx";

export interface CatalogPartsDataGridProps extends RefreshableElementProps {
	partCategoryId: number | undefined;
	onlyCatalogPartId?: number;
	onSelectionChanged?: (parts: CatalogPartView[]) => void;
}

export const CatalogPartsDataGrid = ({
	partCategoryId,
	onlyCatalogPartId,
	refreshRef,
	onSelectionChanged,
}: CatalogPartsDataGridProps) => {
	const { tenantConfig } = useTenantCustomizations();
	const confirm = useConfirmDialog();
	const showInputDialog = useInputDialog();
	const { openDialog } = useGenericDialog();
	const showFormInput = useFormInput();
	const showMessageDialog = useMessageDialog();

	const { dataGridProps, onlySelectedRow, selectedRows, refreshData, currentParams } =
		useServerSideDataGridModel({
			gridId: "EF879781B2CF1691",
			refreshRef: refreshRef,
			fetchData: (params) =>
				CatalogPartDataGridApi.searchCatalogParts({
					partCategoryId: partCategoryId,
					catalogPartId: onlyCatalogPartId ?? null,
					...params,
				}),
			getRowId: (row) => row.catalogPartId,
			initialParams: {
				searchQuery: "",
				partType: genericNullableValue<PartType | null>(null),
				showInactiveParts: false,
				responsiblePersonId: genericNullableValue<number | null>(null),
				showPartsWithInitialRevision: false,
			},
			onSelectionChanged: onSelectionChanged,
			selectFirstRowOnLoad: onlyCatalogPartId != null,
		});

	return (
		<ControlledAsyncCrudDataGrid<CatalogPartView>
			columns={[
				textColumn({
					field: "partNo",
					headerName: i18n.t("part_number_shortened"),
					width: 75,
				}),
				textColumn({
					field: "description_1",
					headerName: i18n.t("description_1"),
					width: 250,
				}),
				textColumn({
					field: "description_2",
					headerName: i18n.t("description_2"),
					width: 150,
				}),
				textColumn({
					field: "categoryName",
					headerName: i18n.t("category"),
				}),
				textColumn({
					field: "manufacturerPartNumber",
					headerName: i18n.t("manufacturer_code"),
					width: 135,
				}),
				textColumn({
					field: "additionalNumber",
					headerName: i18n.t("additional_code"),
					width: 80,
				}),
				tenantConfig.erp.configuratorEnabled && [
					booleanColumn({
						field: "isConfigurable",
						headerName: i18n.t("configurable"),
					}),
					textColumn({
						field: "configurationProductFamilyName",
						headerName: i18n.t("product_family"),
					}),
				],
				textColumn({
					field: "responsiblePersonName",
					headerName: i18n.t("responsible_person"),
				}),
				coloredBooleanColumn({
					field: "active",
					headerName: i18n.t("active"),
					width: 80,
				}),
				textColumn({
					field: "partUnitName",
					headerName: i18n.t("base_unit"),
				}),
				textColumn({
					field: "designUnitName",
					headerName: i18n.t("design_unit"),
				}),
				floatColumn({
					field: "designUnitFactor",
					headerName: i18n.t("design_factor"),
				}),
				floatColumn({
					field: "weight",
					headerName: i18n.t("weight"),
					width: 70,
				}),
				integerColumn({
					field: "catalogPartId",
					headerName: i18n.t("id"),
				}),
			]}
			actionBarComponents={
				<>
					<DocumentsOfObjectButton
						objectRef={nullableAavoObjectRef("CATALOG_PART", onlySelectedRow?.catalogPartId)}
					/>
					<AavoTextField
						label={i18n.t("search")}
						onSubmit={async (value) => {
							await refreshData({ searchQuery: value });
						}}
					/>
					<AsyncUserSelectField
						label={i18n.t("responsible_person")}
						onChange={async (value) => {
							await refreshData({ responsiblePersonId: value?.id ?? null });
						}}
					/>
				</>
			}
			actionBarMenuComponents={[
				<MenuCheckbox
					key={"showInactiveParts"}
					label={i18n.t("show_inactive_parts")}
					onChange={async (value) => {
						await refreshData({ showInactiveParts: value });
					}}
					checked={currentParams.showInactiveParts}
				/>,
				<MenuCheckbox
					key={"showPartsWithInitialRevision"}
					label={i18n.t("show_only_parts_with_unplanned_revisions")}
					onChange={async (value) => {
						await refreshData({ showPartsWithInitialRevision: value });
					}}
					checked={currentParams.showPartsWithInitialRevision}
				/>,
			]}
			rowContextMenuComponents={({ row, onlySingleRowSelected }) => [
				<AsyncMenuButton
					key={"copyToSites"}
					label={i18n.t("copy_to_sites")}
					icon={faShareAlt}
					disabled={selectedRows.length === 0}
					onClick={async () => {
						await createCopyToSitesDialogs();
					}}
				/>,
				onlySingleRowSelected && row.active && (
					<AsyncMenuButton
						key={"decactivate"}
						label={i18n.t("set_to_inactive")}
						icon={faBan}
						onClick={async () => {
							const confirmed = await confirm({
								title: i18n.t("are_you_sure"),
								message: i18n.t("deactivate_catalog_part_confirm"),
							});
							if (!confirmed) return;

							await CatalogPartActionApi.deactivateCatalogPart({
								catalogPartId: row.catalogPartId,
							});
							await refreshData();
						}}
					/>
				),
				onlySingleRowSelected && !row.active && (
					<AsyncMenuButton
						key={"activate"}
						label={i18n.t("set_to_active")}
						icon={faCheck}
						onClick={async () => {
							const confirmed = await confirm({
								title: i18n.t("are_you_sure"),
								message: i18n.t("activate_catalog_part_confirm"),
							});
							if (!confirmed) return;

							await CatalogPartActionApi.activateCatalogPart({
								catalogPartId: row.catalogPartId,
							});
							await refreshData();
						}}
					/>
				),
				onlySingleRowSelected && (
					<AsyncMenuButton
						key={"changeBaseUnit"}
						label={i18n.t("change_base_unit")}
						icon={faSync}
						onClick={async () => {
							const options = await UnitsApi.getAll();
							const newUnitId = await showInputDialog({
								type: "singleNumberSelect",
								title: i18n.t("change_base_unit"),
								fieldLabel: i18n.t("select_unit"),
								required: true,
								defaultValue: row.partUnitId,
								fieldProps: {
									selection: {
										options: options.map((option) => ({
											value: option.unitId,
											label: option.unitName,
										})),
									},
								},
							});
							if (newUnitId == null) return;
							await CatalogPartActionApi.changeCatalogPartUnit({
								catalogPartId: row.catalogPartId,
								partUnitId: newUnitId,
							});
							await refreshData();
						}}
					/>
				),
				onlySingleRowSelected && (
					<OpenObjectChangeLogButton
						key={"changeLog"}
						objectRef={{ objectType: "CATALOG_PART", objectId: row.catalogPartId }}
					/>
				),
			]}
			form={{
				addRowEnabled: true,
				editEnabled: true,
				dialogSize: "md",
				dialogTitle: (row) =>
					row != null ?
						concatWithPipe(row.partNo, row.description_1, row.description_2)
					:	i18n.t("new_part"),
				component: ({ row, onCompleted, onFormEdited }) => (
					<CatalogPartForm
						catalogPartId={row?.catalogPartId ?? null}
						partCategoryId={partCategoryId ?? null}
						onCompleted={onCompleted}
						onFormEdited={onFormEdited}
					/>
				),
			}}
			remove={{
				type: "enabled",
				action: async () => {
					if (onlySelectedRow == null) return;
					await CatalogPartEditApi.delete_({
						catalogPart: onlySelectedRow,
					});
				},
			}}
			{...dataGridProps}
		/>
	);

	async function createCopyToSitesDialogs() {
		const siteOptions = await SitesApi.getAll();
		const result = await showFormInput<{
			selectedSiteIds: number[];
		}>({
			title: i18n.t("select_sites"),
			size: "sm",
			defaultValues: { selectedSiteIds: siteOptions.map((site) => site.siteId) },
			submitLabel: i18n.t("ok"),
			content: ({ control }) => (
				<FormMultiSelectField
					control={control}
					name={"selectedSiteIds"}
					label={i18n.t("sites")}
					options={siteOptions.map((site) => ({
						key: site.siteId,
						label: site.siteName,
					}))}
				/>
			),
		});
		if (!result) return;
		const { selectedSiteIds } = result;
		const sites = siteOptions.filter((site) => selectedSiteIds.includes(site.siteId));
		await checkIsAlreadyCopiedToSite(selectedSiteIds);
		openDialog(({ closeDialog }) => ({
			title: i18n.t("copy_to_sites"),
			size: "xl",
			content: (
				<CopyCatalogPartToSitesForm
					catalogParts={selectedRows}
					sites={sites}
					onCompleted={async () => {
						await closeDialog();
						await refreshData();
					}}
				/>
			),
		}));
	}

	async function checkIsAlreadyCopiedToSite(selectedSiteIds: number[]) {
		const partsAlreadyOnSites = await CopyCatalogPartToSitesFormApi.getExistingPartsOnSites({
			catalogPartIds: selectedRows.map((part) => part.catalogPartId),
			siteIds: selectedSiteIds,
		});
		if (partsAlreadyOnSites.length === 0) return;

		const first = partsAlreadyOnSites[0];

		if (first == null) return;

		showMessageDialog({
			title: i18n.t("error"),
			content: `${i18n.t("part")}: ${first.partLongName} ${i18n.t("is_already_on_site")}: ${first.siteName}`,
		});
	}
};
