// This file is automatically generated. Do not edit manually.

import { ConfigurationProperty } from "src/api/generated/erp/db/types/tables/configurationProperty";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ConfiguratorPropertyEditApi {
	export async function getFormInitData(args: {
		propertyId: number | null | undefined;
	}): Promise<ConfiguratorPropertyEditApi_FormInitData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/managing/properties/getFormInitData",
			method: "POST",
			data: {
				propertyId: args.propertyId,
			},
		});
		return response as ConfiguratorPropertyEditApi_FormInitData;
	}

	export async function createProperty(args: { property: ConfigurationProperty }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/managing/properties/createProperty",
			method: "POST",
			data: {
				property: args.property,
			},
		});
		return response as number;
	}

	export async function updateProperty(args: { property: ConfigurationProperty }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/managing/properties/updateProperty",
			method: "POST",
			data: {
				property: args.property,
			},
		});
		return response as void;
	}

	export async function deleteProperties(args: { propertyIds: Array<number> }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/configurator/managing/properties/deleteProperties",
			method: "POST",
			data: {
				propertyIds: args.propertyIds,
			},
		});
		return response as void;
	}
}

export interface ConfiguratorPropertyEditApi_FormInitData {
	property: ConfigurationProperty | null | undefined;
}
