import { AavoMosaic } from "src/components/common/mosaic/AavoMosaic.tsx";
import i18n from "i18next";
import { AccountingDimensionsDataGrid } from "src/components/views/erp/sales/accounting/managing/AccountingDimensionsDataGrid.tsx";
import { AccountingDimensionCodesDataGrid } from "src/components/views/erp/sales/accounting/managing/AccountingDimensionCodesDataGrid.tsx";
import { AccountingDimension } from "src/api/generated/erp/db/types/tables/accountingDimension.ts";
import { useState } from "react";
import { CenteredTypography } from "src/components/common/CenteredTypography.tsx";
import { AccountingDimensionTargetsDataGrid } from "src/components/views/erp/sales/accounting/managing/AccountingDimensionTargetsDataGrid.tsx";

export interface AccountingManagingViewProps {}

export const AccountingManagingView = ({}: AccountingManagingViewProps) => {
	const [selectedDimension, setSelectedDimension] = useState<AccountingDimension | undefined>(undefined);

	const selectDimensionTypography = <CenteredTypography>{i18n.t("select_a_dimension")}</CenteredTypography>;

	return (
		<AavoMosaic
			viewId={"D02B6CB02E55E145"}
			layout={{
				type: "row",
				first: {
					type: "panel",
					title: i18n.t("dimensions"),
					content: <AccountingDimensionsDataGrid onSelectionChanged={setSelectedDimension} />,
				},
				second: {
					type: "tab",
					items: [
						{
							type: "panel",
							title: i18n.t("accounting_codes"),
							content:
								selectedDimension == null ? selectDimensionTypography : (
									<AccountingDimensionCodesDataGrid
										key={selectedDimension.accountingDimensionId}
										dimensionId={selectedDimension.accountingDimensionId}
									/>
								),
						},
						{
							type: "panel",
							title: i18n.t("targets"),
							content:
								selectedDimension == null ? selectDimensionTypography : (
									<AccountingDimensionTargetsDataGrid
										key={selectedDimension.accountingDimensionId}
										dimensionId={selectedDimension.accountingDimensionId}
									/>
								),
						},
					],
				},
			}}
		/>
	);
};
