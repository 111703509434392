import { DataGridProProps } from "@mui/x-data-grid-pro";
import { GridValidRowModel } from "@mui/x-data-grid/models/gridRows";
import { GridColumnsState } from "@mui/x-data-grid/hooks/features/columns/gridColumnsInterfaces";
import { GridStateColDef } from "@mui/x-data-grid/models/colDef/gridColDef";

interface SaveDataGridColumnResizingParams<TRow extends GridValidRowModel>
	extends Pick<DataGridProProps<TRow>, "columns" | "autosizeOptions"> {
	initialState: NonNullable<DataGridProProps<TRow>["initialState"]>;
}

interface SaveDataGridColumnResizingReturn<TRow extends GridValidRowModel>
	extends Required<Pick<DataGridProProps<TRow>, "columns" | "autosizeOptions">> {}

export function saveDataGridColumnResizing<TRow extends GridValidRowModel>({
	initialState,
	columns,
	autosizeOptions: autosizeOptionsProp,
}: SaveDataGridColumnResizingParams<TRow>): SaveDataGridColumnResizingReturn<TRow> {
	const getColumnState = (field: string): GridStateColDef<TRow> | undefined => {
		const columnsState = initialState.columns as GridColumnsState | undefined;
		if (columnsState?.lookup == undefined) return undefined;

		return columnsState.lookup[field];
	};

	const mappedColumns = columns.map((column) => {
		const state = getColumnState(column.field);
		return {
			...column,
			width: state?.width ?? column.width,
		};
	});

	// autosize only columns without saved width
	const autosizeOptions = {
		...autosizeOptionsProp,
		columns: columns
			.map((c) => c.field)
			.filter((field) => {
				const state = getColumnState(field);
				return state?.hasBeenResized !== true;
			}),
	};

	return {
		columns: mappedColumns,
		autosizeOptions: autosizeOptions,
	};
}
