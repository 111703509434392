// This file is automatically generated. Do not edit manually.

import { ServerSideDataModelRequest } from "src/api/generated/common/dataGrids/serverSideDataModelRequest";
import { ProjectActivity } from "src/api/generated/erp/db/types/tables/projectActivity";
import { ServerSideDataModelResult } from "src/api/generated/common/dataGrids/serverSideDataModelResult";
import { ProjectActivityMaterialView } from "src/api/generated/erp/db/types/tables/projectActivityMaterialView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ProjectActivityMaterialsDataGridApi {
	export async function getDataGridDataForActivity(args: {
		projectActivityId: number;
		cancelledLines: boolean;
		dataModelRequest: ServerSideDataModelRequest;
	}): Promise<ProjectActivityMaterialsDataGridApi_DataGridData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/project/activityMaterialsDataGrid/getProjectActivityMaterials",
			method: "POST",
			data: {
				projectActivityId: args.projectActivityId,
				cancelledLines: args.cancelledLines,
				dataModelRequest: args.dataModelRequest,
			},
		});
		return response as ProjectActivityMaterialsDataGridApi_DataGridData;
	}

	export async function getDataGridDataForProject(args: {
		projectId: number;
		cancelledLines: boolean;
		dataModelRequest: ServerSideDataModelRequest;
	}): Promise<ProjectActivityMaterialsDataGridApi_DataGridData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/project/activityMaterialsDataGrid/getDataGridDataForProject",
			method: "POST",
			data: {
				projectId: args.projectId,
				cancelledLines: args.cancelledLines,
				dataModelRequest: args.dataModelRequest,
			},
		});
		return response as ProjectActivityMaterialsDataGridApi_DataGridData;
	}
}

export interface ProjectActivityMaterialsDataGridApi_DataGridData {
	activity: ProjectActivity | null | undefined;
	data: ServerSideDataModelResult<ProjectActivityMaterialView>;
}
