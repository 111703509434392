import i18n from "i18next";
import { CustomerOrderPackageType } from "src/api/generated/erp/db/types/tables/customerOrderPackageType.ts";
import { FormCommonProps } from "src/components/common/forms/types.ts";
import { AavoForm } from "src/components/common/forms/AavoForm";
import { DeepPartial } from "react-hook-form";
import { requireRule } from "src/components/common/forms/validation.ts";
import { FormTextField } from "src/components/common/forms/fields/FormTextField.tsx";
import { CustomerOrderPackageTypesApi } from "src/api/generated/erp/sales/basedata/api/customerOrderPackageTypesApi.ts";

export interface CustomerOrderPackageTypeFormProps extends FormCommonProps<number> {
	customerOrderPackageType: CustomerOrderPackageType | undefined;
}

interface FormValues extends CustomerOrderPackageType {}

export const CustomerOrderPackageTypeForm = (props: CustomerOrderPackageTypeFormProps) => {
	const { customerOrderPackageType, onCompleted, onFormEdited } = props;
	return (
		<AavoForm
			defaultValues={getDefaultValues()}
			submit={submit}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			render={({ control }) => (
				<>
					<FormTextField
						control={control}
						name={"name"}
						label={i18n.t("name")}
						rules={requireRule()}
					/>
				</>
			)}
		/>
	);

	function getDefaultValues(): DeepPartial<FormValues> {
		if (customerOrderPackageType != null) return customerOrderPackageType;
		return {};
	}

	async function submit(values: FormValues) {
		if (customerOrderPackageType == null) {
			return await CustomerOrderPackageTypesApi.insert({
				customerOrderPackageType: values,
			});
		} else {
			await CustomerOrderPackageTypesApi.update({
				customerOrderPackageType: values,
			});
			return customerOrderPackageType.customerOrderPackageTypeId;
		}
	}
};
