import { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef";
import i18n from "i18next";
import {
	CellEditorWithValidation,
	CellEditorWithValidationProps
} from "src/components/common/dataGrid/crud/validation/CellEditorWithValidation.tsx";
import {
	dataGridCellClassNames
} from "src/components/common/dataGrid/styles/dataGridClassNames.ts";

export interface ColumnValidationProp<RowData, V> {
	validate?: ColumnValidationRule<RowData, V>;
}

export type ColumnValidationResult = string | undefined

type ColumnValidationRule<RowData, V> =
	| "required"
	| ColumnValidationFunction<RowData, V>;

export type ColumnValidationFunction<RowData, V> = (v: V | undefined, row: RowData) => ColumnValidationResult;

export const requireValue = <V, >(value: V): ColumnValidationResult => {
	if (value === undefined || value === null ||
		(typeof value === "string" && value.trim() === "")) {
		return i18n.t("required");
	}

	return undefined;
};

export const columnValidationProps = <RowData extends object, V>(
	{ validate }: ColumnValidationProp<RowData, V>,
	cellEditor: CellEditorWithValidationProps<RowData, V>["cellEditor"]
): Pick<GridColDef<RowData, V>, "cellClassName" | "renderEditCell"> => {
	if (validate === undefined)
		return {};

	const validationFunc = getValidationFunc(validate);

	return {
		cellClassName: (params) => {
			const error = validationFunc(params.value, params.row);
			return error === undefined ? "" : dataGridCellClassNames.invalid;
		},
		renderEditCell: (params) => {
			return (
				<CellEditorWithValidation
					validationFunc={validationFunc}
					cellEditor={cellEditor}
					params={params}
				/>
			);
		}
	};
};

const getValidationFunc = <RowData, V>(validate: ColumnValidationRule<RowData, V>): ColumnValidationFunction<RowData, V> => {
	if (validate === "required") {
		return (value) => requireValue(value);
	} else {
		return validate;
	}
};