import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StackedIcon, StackedIconProps } from "src/components/common/icons/StackedIcon.tsx";
import { faCloud } from "@fortawesome/pro-regular-svg-icons";
import { faCircleCheck } from "@fortawesome/pro-solid-svg-icons";
import { AavoTooltip } from "../tooltips/AavoTooltip";
import i18n from "i18next";

export interface SyncedToCloudIconProps extends StackedIconProps {
	tooltip?: string;
}

export const SyncedToCloudIcon = ({
	sx,
	tooltip = i18n.t("changes_saved"),
	...other
}: SyncedToCloudIconProps) => {
	return (
		<AavoTooltip title={tooltip} sx={sx}>
			<StackedIcon {...other}>
				<FontAwesomeIcon icon={faCloud} />
				<FontAwesomeIcon
					icon={faCircleCheck}
					transform={{
						size: 12,
						x: 6,
						y: 4,
					}}
				/>
			</StackedIcon>
		</AavoTooltip>
	);
};
