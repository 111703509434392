import { AsyncButton, AsyncButtonProps } from "src/components/common/buttons/AsyncButton.tsx";
import { faPrint } from "@fortawesome/pro-regular-svg-icons";
import i18n from "i18next";
import { CustomerOrderDeliveryActionApi } from "src/api/generated/erp/sales/delivery/api/customerOrderDeliveryActionApi.ts";
import { downloadFile } from "src/utils/fileDownloading.ts";
import { CustomerOrderDeliveryView } from "src/api/generated/erp/db/types/tables/customerOrderDeliveryView.ts";

export const PrintCustomerOrderDeliveryConsignmentNoteButton = ({
	delivery,
	...other
}: AsyncButtonProps & {
	delivery: CustomerOrderDeliveryView | undefined;
}) => {
	return (
		<AsyncButton
			icon={faPrint}
			label={i18n.t("print_consignment_note")}
			variant={"outlined"}
			disabled={delivery == null}
			onClick={async () => {
				if (delivery == null) return;
				const fileHandle = await CustomerOrderDeliveryActionApi.printCustomerOrderConsignmentNote({
					customerOrderDeliveryId: delivery.customerOrderDeliveryId,
				});
				downloadFile(fileHandle);
			}}
			{...other}
		/>
	);
};

export const PrintCustomerOrderDeliveryNoteButton = ({
	delivery,
	...other
}: AsyncButtonProps & {
	delivery: CustomerOrderDeliveryView | undefined;
}) => {
	return (
		<AsyncButton
			icon={faPrint}
			label={i18n.t("print_delivery_note")}
			variant={"outlined"}
			disabled={delivery == null}
			onClick={async () => {
				if (delivery == null) return;
				const fileHandle = await CustomerOrderDeliveryActionApi.printCustomerOrderDeliveryNote({
					customerOrderDeliveryId: delivery.customerOrderDeliveryId,
				});
				downloadFile(fileHandle);
			}}
			{...other}
		/>
	);
};
