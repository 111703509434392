import { CustomerOrderPickableLinesDataGridApi } from "src/api/generated/erp/sales/picking/api/customerOrderPickableLinesDataGridApi.ts";
import { CustomerOrderLinePickingObjectLocationView } from "src/api/generated/erp/db/types/tables/customerOrderLinePickingObjectLocationView.ts";
import { ControlledAsyncCrudDataGrid } from "../../../../common/dataGrid/crud/ControlledAsyncCrudDataGrid.tsx";
import { floatColumn, integerColumn, textColumn } from "../../../../common/dataGrid/columns.tsx";
import i18n from "i18next";
import { AsyncButton } from "../../../../common/buttons/AsyncButton.tsx";
import { faBoxOpen, faPrint, faUndo } from "@fortawesome/pro-regular-svg-icons";
import { downloadFile } from "src/utils/fileDownloading.ts";
import { CustomerOrderPackageView } from "src/api/generated/erp/db/types/tables/customerOrderPackageView.ts";
import { RefreshableElementProps, RefreshableElementRef } from "src/utils/useRefreshRef.ts";
import { useInputDialog } from "../../../../common/dialogs/input/useInputDialog.tsx";
import { useClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/useClientSideDataGridModel";

export interface CustomerOrderPickableLinesDataGridProps extends RefreshableElementProps {
	customerOrderId: number;
	customerOrderPackage: CustomerOrderPackageView | undefined;
	packageLinesDataGridRefreshRef: RefreshableElementRef;
	reservableCustomerOrderLinesDataGridRefreshRef: RefreshableElementRef;
	customerOrderAutomaticReservation: boolean;
}

export const CustomerOrderPickableLinesDataGrid = ({
	customerOrderId,
	customerOrderPackage,
	refreshRef,
	packageLinesDataGridRefreshRef,
	reservableCustomerOrderLinesDataGridRefreshRef,
	customerOrderAutomaticReservation,
}: CustomerOrderPickableLinesDataGridProps) => {
	const { dataGridProps, refreshData, onlySelectedRow } = useClientSideDataGridModel({
		gridId: "AC8A87916766FBE8",
		getRowId: (row) => row.warehousePickingObjectLocationId,
		fetchData: () =>
			CustomerOrderPickableLinesDataGridApi.getLines({
				customerOrderId: customerOrderId,
			}),
		initialParams: {},
		refreshRef,
	});

	const showInputDialog = useInputDialog();

	return (
		<ControlledAsyncCrudDataGrid<CustomerOrderLinePickingObjectLocationView>
			disableMultipleRowSelection
			columns={[
				integerColumn({
					field: "lineNumber",
					headerName: i18n.t("line_number"),
				}),
				textColumn({
					field: "configurationOrPartDescription",
					headerName: i18n.t("part"),
					width: 300,
				}),
				textColumn({
					field: "mark",
					headerName: i18n.t("mark"),
					width: 300,
				}),
				floatColumn({
					field: "locationReservedQuantity",
					headerName: i18n.t("pickable_quantity"),
					width: 150,
				}),
				textColumn({
					field: "partUnit",
					headerName: i18n.t("unit"),
				}),
				textColumn({
					field: "locationCode",
					headerName: i18n.t("warehouse_location"),
				}),
			]}
			actionBarComponents={
				<>
					<AsyncButton
						label={i18n.t("picking_list")}
						variant={"outlined"}
						icon={faPrint}
						onClick={async () => {
							const fileHandle =
								await CustomerOrderPickableLinesDataGridApi.printCustomerOrderPickingList({
									customerOrderId: customerOrderId,
								});
							downloadFile(fileHandle);
						}}
					/>
					<AsyncButton
						label={i18n.t("pick")}
						variant={"outlined"}
						icon={faBoxOpen}
						disabled={
							!onlySelectedRow ? i18n.t("select_line")
							: customerOrderPackage === undefined ?
								i18n.t("select_package")
							: customerOrderPackage.customerOrderPackageState !== "PARTIALLY_PICKED" ?
								i18n.t("package_is_picked")
							:	false
						}
						onClick={async () => {
							if (!onlySelectedRow) {
								return;
							}
							const pickQuantity = await showInputDialog({
								title: i18n.t("quantity"),
								type: "decimal",
								defaultValue: onlySelectedRow.locationReservedQuantity,
								required: true,
							});
							if (pickQuantity === undefined) return;
							if (customerOrderPackage === undefined) return;
							await CustomerOrderPickableLinesDataGridApi.pickCustomerOrderLineToPackage({
								customerOrderLineId: onlySelectedRow.customerOrderLineId,
								customerOrderPackageId: customerOrderPackage.customerOrderPackageId,
								quantityInWarehouseUnit: pickQuantity,
								partWarehouseLocationId: onlySelectedRow.partWarehouseLocationId,
							});
							await Promise.all([packageLinesDataGridRefreshRef?.refresh(), refreshData()]);
						}}
					/>
					{!customerOrderAutomaticReservation && (
						<AsyncButton
							label={i18n.t("revert_reservation")}
							variant={"outlined"}
							icon={faUndo}
							disabled={!onlySelectedRow ? i18n.t("select_line") : false}
							onClick={async () => {
								if (!onlySelectedRow) return;
								const revertQuantity = await showInputDialog({
									title: i18n.t("quantity_to_cancel"),
									type: "decimal",
									defaultValue: onlySelectedRow.locationReservedQuantity,
									required: true,
								});
								if (revertQuantity === undefined) return;
								await CustomerOrderPickableLinesDataGridApi.revertCustomerOrderLineReservation(
									{
										quantityToRevertInWarehouseUnit: revertQuantity,
										customerOrderLineId: onlySelectedRow.customerOrderLineId,
										partWarehouseLocationId: onlySelectedRow.partWarehouseLocationId,
									},
								);
								await Promise.all([
									reservableCustomerOrderLinesDataGridRefreshRef?.refresh(),
									refreshData(),
								]);
							}}
						/>
					)}
				</>
			}
			{...dataGridProps}
		/>
	);
};
