import {
	useClientSideDataGridModel,
	UseClientSideDataGridModelParams,
	UseClientSideDataGridModelReturn,
} from "src/components/common/dataGrid/gridModel/useClientSideDataGridModel";
import { AavoDataGridProps } from "src/components/common/dataGrid/AavoDataGrid.tsx";
import React from "react";
import { AsyncRender } from "../../async/AsyncRender";
import { useAsyncState } from "src/utils/async/asyncState.ts";
import { removeKeyFromObject } from "src/utils/objectUtils.ts";

export interface ClientSideDataGridModelProps<TRemoteData, TRow extends object, TParams extends object>
	extends Omit<UseClientSideDataGridModelParams<TRow, TParams>, "fetchData"> {
	fetchData: (params: TParams) => Promise<TRemoteData>;
	getRows: (remoteData: TRemoteData) => TRow[];
	render: (props: ClientSideDataGridModelRenderProps<TRemoteData, TRow, TParams>) => React.ReactNode;
}

export interface ClientSideDataGridModelRenderProps<TRemoteData, TRow extends object, TParams>
	extends Omit<UseClientSideDataGridModelReturn<TRow, TParams>, "dataGridProps"> {
	dataGridProps: DataGridProps<TRow>;
	data: TRemoteData;
}

interface DataGridProps<TRow extends object>
	extends Required<
		Pick<
			AavoDataGridProps<TRow>,
			| "rows"
			| "refreshData"
			| "initialState"
			| "onStateChange"
			| "selectedRows"
			| "onRowSelectionChanged"
			| "getRowId"
			| "apiRef"
		>
	> {}

export const ClientSideDataGridModel = <TRemoteData, TRow extends object, TParams extends object>({
	render,
	fetchData,
	getRows,
	...other
}: ClientSideDataGridModelProps<TRemoteData, TRow, TParams>) => {
	const [remoteDataAsync, setRemoteDataAsync] = useAsyncState<TRemoteData>();
	const { dataGridProps, ...otherHookReturn } = useClientSideDataGridModel({
		...other,
		fetchData: async (params) => {
			const remoteData = await setRemoteDataAsync(() => fetchData(params));
			return getRows(remoteData);
		},
	});

	return (
		<AsyncRender
			asyncData={remoteDataAsync}
			reloadOnError={dataGridProps.refreshData}
			content={(data) => {
				return render({
					dataGridProps: {
						...removeKeyFromObject(dataGridProps, "rowsAsync"),
						rows: getRows(data),
					},
					data: data,
					...otherHookReturn,
				});
			}}
		/>
	);
};
