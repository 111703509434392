import {
	dateColumn,
	dateTimeColumn,
	integerColumn,
	textColumn,
} from "src/components/common/dataGrid/columns";
import { AsyncButton } from "src/components/common/buttons/AsyncButton";
import { faCheck, faDownload, faExchange, faUndo } from "@fortawesome/pro-regular-svg-icons";
import { useInputDialog } from "src/components/common/dialogs/input/useInputDialog.tsx";
import { uploadFile } from "src/utils/fileUploading.ts";
import { WinnerToFusionApi } from "src/api/generated/tenants/mellano/winner/api/winnerToFusionApi.ts";
import { downloadFile } from "src/utils/fileDownloading.ts";
import { WinnerDesign } from "src/api/generated/postgres/db/types/mellano/tables/winnerDesign.ts";
import { WinnerDesignApi } from "src/api/generated/tenants/mellano/winner/api/winnerDesignApi";
import { useServerSideDataGridModel } from "src/components/common/dataGrid/gridModel/useServerSideDataGridModel.tsx";
import { ControlledAsyncDataGrid } from "src/components/common/dataGrid/ControlledAsyncDataGrid";
import { MenuCheckbox } from "src/components/common/contextMenu/MenuCheckbox.tsx";

export const MellanoWinnerDesignsView = () => {
	const showInputDialog = useInputDialog();

	const { dataGridProps, selectedRows, refreshData, currentParams } = useServerSideDataGridModel({
		fetchData: (params) => WinnerDesignApi.getWinnerDesigns({ ...params }),
		getRowId: (row) => row.designGuid,
		initialParams: {
			includeHandled: false,
		},
		gridId: "11EA5C2C18218DB2",
	});

	return (
		<ControlledAsyncDataGrid<WinnerDesign>
			columns={[
				integerColumn({
					field: "projectId",
					headerName: "ID",
					width: 200,
				}),
				textColumn({
					field: "projectName",
					headerName: "Nimi",
					width: 200,
				}),
				textColumn({
					field: "designName",
					headerName: "Vaihtoehto",
					width: 200,
				}),
				dateColumn({
					field: "estimatedDeliveryDate",
					headerName: "Arvioitu toimitus",
					width: 200,
				}),
				textColumn({
					field: "shopName",
					headerName: "Myymälä",
					width: 200,
				}),
				textColumn({
					field: "contactName",
					headerName: "Yhteystieto",
					width: 200,
				}),
				textColumn({
					field: "email",
					headerName: "Sähköposti",
					width: 200,
				}),
				dateTimeColumn({
					field: "loadedAt",
					headerName: "Ladattu",
					width: 200,
				}),
				dateTimeColumn({
					field: "handledAt",
					headerName: "Käsitelty",
					width: 200,
				}),
			]}
			actionBarComponents={
				<>
					<AsyncButton
						label={"Lataa XML"}
						icon={faDownload}
						variant={"outlined"}
						disabled={selectedRows.length === 0}
						onClick={async () => {
							for (const row of selectedRows) {
								const fileHandle = await WinnerToFusionApi.convertWinnerDesignToLuukkuXml({
									designGuid: row.designGuid,
									shopGuid: row.shopGuid,
								});
								downloadFile(fileHandle);
							}
						}}
					/>
					<AsyncButton
						key={"convertOrd"}
						icon={faExchange}
						variant={"outlined"}
						label={"Muunna manuaalisesti"}
						onClick={onConvertOrdFileClick}
					/>
				</>
			}
			rowContextMenuComponents={({ row, allSelectedRows }) => [
				<AsyncButton
					key={"markAsHandled"}
					label={"Merkitse käsitellyksi"}
					icon={faCheck}
					variant={"menu"}
					disabled={allSelectedRows.some((r) => r.handledAt != null)}
					onClick={async () => {
						await WinnerDesignApi.setWinnerDesignsIsHandled({
							designGuids: allSelectedRows.map((r) => r.designGuid),
							isHandled: true,
						});
						await refreshData();
					}}
				/>,
				<AsyncButton
					key={"markAsUnHandled"}
					label={"Palauta käsittelemättömäksi"}
					icon={faUndo}
					variant={"menu"}
					disabled={allSelectedRows.some((r) => r.handledAt == null)}
					onClick={async () => {
						await WinnerDesignApi.setWinnerDesignsIsHandled({
							designGuids: allSelectedRows.map((r) => r.designGuid),
							isHandled: false,
						});
						await refreshData();
					}}
				/>,
				<AsyncButton
					key={"downloadWinnerDesignAscii"}
					label={"Lataa Winner-tiedosto"}
					icon={faDownload}
					variant={"menu"}
					onClick={async () => {
						const fileHandle = await WinnerToFusionApi.downloadWinnerDesignAscii({
							designGuid: row.designGuid,
							shopGuid: row.shopGuid,
						});
						downloadFile(fileHandle);
					}}
				/>,
			]}
			actionBarMenuComponents={[
				<MenuCheckbox
					key={"includeHandled"}
					label={"Näytä käsitellyt"}
					checked={currentParams.includeHandled}
					onChange={(checked) => refreshData({ includeHandled: checked })}
				/>,
			]}
			{...dataGridProps}
		/>
	);

	async function onConvertOrdFileClick() {
		const file = await showInputDialog({
			type: "singleFile",
			title: "Tiedosto",
			defaultValue: null,
			required: true,
		});
		if (file === undefined) return;

		const uploadFileHandle = await uploadFile(file);
		const downloadFileHandle = await WinnerToFusionApi.convertWinnerAsciiToLuukkuXml({
			fileHandle: uploadFileHandle,
		});
		downloadFile(downloadFileHandle);
	}
};
