import {AavoMosaic} from "../../../../common/mosaic/AavoMosaic.tsx";
import i18n from "i18next";
import {AllUndeliveredPackagesDataGrid} from "src/components/views/erp/sales/picking/AllUndeliveredPackagesDataGrid.tsx";
import {CustomerOrderPackageLinesDataGrid} from "./CustomerOrderPackageLinesDataGrid.tsx";
import {useState} from "react";
import {CustomerOrderPackageView} from "src/api/generated/erp/db/types/tables/customerOrderPackageView.ts";
import {CenteredTypography} from "../../../../common/CenteredTypography.tsx";

export const UndeliveredPackagesPage = () => {
	const [selectedPackage, setSelectedPackage] = useState<CustomerOrderPackageView | undefined>(
		undefined,
	);
	return (
		<AavoMosaic
			viewId={"D741C35965230EC"}
			layout={{
				type: "row",
				first: {
					type: "panel",
					title: i18n.t("packages"),
					content: (
						<AllUndeliveredPackagesDataGrid
							selectedPackage={selectedPackage}
							onSelectedPackageChanged={setSelectedPackage}
						/>
					),
				},
				second: {
					type: "panel",
					title: i18n.t("package_lines"),
					content:
						selectedPackage == null ?
							<CenteredTypography>{i18n.t("select_package")}</CenteredTypography>
						:	<CustomerOrderPackageLinesDataGrid
								key={selectedPackage.customerOrderPackageId}
								customerOrderPackage={selectedPackage}
							/>,
				},
				splitPercentage:55,
			}}
		/>
	);
};
