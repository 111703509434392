import { AavoMosaic } from "src/components/common/mosaic/AavoMosaic.tsx";
import i18n from "i18next";
import { Resource } from "src/api/generated/postgres/db/types/ext_resourcing/tables/resource.ts";
import { useState } from "react";
import { ResourcesDataGrid } from "src/components/views/spc/basedata/resources/ResourcesDataGrid.tsx";
import { Item } from "src/api/generated/postgres/db/types/ext_resourcing/tables/item.ts";
import { ResourceItemsDataGrid } from "src/components/views/spc/basedata/resources/ResourceItemsDataGrid.tsx";
import { CenteredTypography } from "src/components/common/CenteredTypography.tsx";
import { ResourceStateChartsDataGrid } from "src/components/views/spc/basedata/resources/ResourceStateChartsDataGrid.tsx";
import { UserNotificationsOfSourceDataGrid } from "src/components/views/notifications/UserNotificationsOfSourceDataGrid.tsx";
import { ControlChartsDataGrid } from "src/components/views/spc/basedata/controlCharts/ControlChartsDataGrid.tsx";
import { faLink, faUnlink } from "@fortawesome/pro-regular-svg-icons";
import { ResourceItemChartsApi } from "src/api/generated/spc/resources/api/resourceItemChartsApi.ts";
import { ControlChartDataGridApi } from "src/api/generated/spc/controlChart/api/controlChartDataGridApi.ts";
import { AsyncMenuButton } from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import { useRefreshRef } from "src/utils/useRefreshRef.ts";
import { useGenericDialog } from "src/components/common/dialogs/useGenericDialog.ts";
import { ChartConnectedResourceItemsDataGrid } from "src/components/views/spc/basedata/resources/ChartConnectedResourceItemsDataGrid.tsx";

export const ResourcesManagementView = () => {
	const [selectedResource, setSelectedResource] = useState<Resource | undefined>();
	const [selectedResourceItem, setSelectedResourceItem] = useState<Item | undefined>();
	const controlChartsRefreshRef = useRefreshRef();
	const { openDialog } = useGenericDialog();
	return (
		<AavoMosaic
			viewId={"F34ED8818A023F5E"}
			layout={{
				type: "row",
				splitPercentage: 35,
				first: {
					type: "column",
					first: {
						type: "panel",
						title: i18n.t("resources"),
						content: <ResourcesDataGrid setSelectedResource={setSelectedResource} />,
					},
					second: {
						type: "tab",
						items: [
							{
								type: "panel",
								title: i18n.t("resource_items"),
								content:
									selectedResource == null ?
										<CenteredTypography children={i18n.t("select_resource")} />
									:	<ResourceItemsDataGrid
											key={selectedResource.id}
											selectedResource={selectedResource}
											setSelectedResourceItem={setSelectedResourceItem}
											controlChartsRefreshRef={controlChartsRefreshRef}
										/>,
							},
							{
								type: "panel",
								title: i18n.t("state_charts"),
								content:
									selectedResource == null ?
										<CenteredTypography children={i18n.t("select_resource")} />
									:	<ResourceStateChartsDataGrid
											key={selectedResource.id}
											resourceId={selectedResource.id}
										/>,
							},
							{
								type: "panel",
								title: i18n.t("notifications"),
								content:
									selectedResource == null ?
										<CenteredTypography children={i18n.t("select_resource")} />
									:	<UserNotificationsOfSourceDataGrid
											key={selectedResource.id}
											sourceType={"RESOURCE"}
											sourceId={selectedResource?.id}
										/>,
							},
						],
					},
				},
				second: {
					type: "tab",
					items: [
						{
							type: "panel",
							title: i18n.t("control_charts"),
							content:
								selectedResourceItem == null ?
									<CenteredTypography children={i18n.t("select_resource_item")} />
								:	<ControlChartsDataGrid
										key={selectedResourceItem.id}
										fetchData={(props) =>
											ControlChartDataGridApi.getControlChartsForResourceItem({
												resourceItemId: selectedResourceItem.id,
												...props,
											})
										}
										contextMenuExtraComponents={(params) => [
											<AsyncMenuButton
												key={"link"}
												icon={faLink}
												label={i18n.t("connected_items")}
												onClick={async () => {
													openDialog(() => ({
														title: i18n.t("connected_items"),
														content: (
															<ChartConnectedResourceItemsDataGrid
																controlChartId={params.row.id}
															/>
														),
														size: "md",
													}));
												}}
											/>,
											<AsyncMenuButton
												key={"unlink"}
												icon={faUnlink}
												label={i18n.t("unlink")}
												onClick={async () => {
													await ResourceItemChartsApi.disconnectChartFromItem({
														itemId: selectedResourceItem.id,
														chartId: params.row.id,
													});
													await controlChartsRefreshRef.refresh();
												}}
											/>,
										]}
										formProps={{
											itemId: selectedResourceItem.id,
										}}
										refreshRef={controlChartsRefreshRef}
										cloneProps={{
											type: "resourceItem",
											resourceItemId: selectedResourceItem.id,
										}}
									/>,
						},
					],
				},
			}}
		/>
	);
};
