import {RefreshableElementRef} from "src/utils/useRefreshRef.ts";
import {
    WarehouseTransferJobQueueDataGridBase
} from "src/components/views/erp/warehouse/transfer/WarehouseTransferJobQueueDataGridBase.tsx";
import {AsyncButton} from "src/components/common/buttons/AsyncButton.tsx";
import i18n from "i18next";
import {
    WarehouseTransferActionApi
} from "src/api/generated/erp/warehouse/warehouseTransfer/api/warehouseTransferActionApi.ts";
import { faUndo } from "@fortawesome/pro-regular-svg-icons";

export interface WarehouseTransferJobQueueTransferredDataGridProps {
	refreshRef: RefreshableElementRef;
	pickedRefreshRef: RefreshableElementRef;
}

export const WarehouseTransferJobQueueTransferredDataGrid = ({
	refreshRef,
	pickedRefreshRef,
}: WarehouseTransferJobQueueTransferredDataGridProps) => {
	return (
		<WarehouseTransferJobQueueDataGridBase
			warehouseTransferState={"TRANSFERRED"}
			gridId={"C35F8BB91FB465E4"}
			refreshRef={refreshRef}
			sortModel={[
				{ field: "requestingSiteName", sort: "asc" },
				{ field: "transferredDate", sort: "desc" },
			]}
			actionBarComponents={(selectedRows) => (
				<>
					<AsyncButton
						label={i18n.t("revert_delivery")}
						icon={faUndo}
						onClick={async () => {
							await WarehouseTransferActionApi.revertTransferSelectedTransfers({
								warehouseTransferIds: selectedRows.map((row) => row.warehouseTransferId),
							});
							await refreshRef.refresh();
							await pickedRefreshRef.refresh();
						}}
						disabled={selectedRows.length === 0}
						variant={"outlined"}
					/>
				</>
			)}
		/>
	);
};
