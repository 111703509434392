import {
	Box,
	InputAdornment,
	InputBaseComponentProps,
	TextField,
	TextFieldProps,
	Typography,
} from "@mui/material";
import React, { useRef } from "react";
import { prettyBytes } from "src/utils/prettyBytes.ts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip, faXmark } from "@fortawesome/pro-regular-svg-icons";
import i18n from "i18next";
import { AavoFileInputStyledLabel } from "./styles";
import { useFileInputDnd } from "src/components/common/inputFields/fileInput/useFileInputDnd.tsx";
import { mergeSx } from "src/utils/styles.ts";
import { AavoIconButton, AavoIconButtonProps } from "../../buttons/AavoIconButton";
import { useForwardedRef } from "src/utils/useForwardedRef.ts";

export type AavoFileInputProps<TMultiple extends boolean | undefined> = Omit<
	TextFieldProps,
	"onChange" | "ref" | "error" | "helperText" | "inputProps"
> & {
	value: TMultiple extends true ? File[] : File | null;
	multiple?: TMultiple;
	hideSizeText?: boolean;
	getInputText?: (files: TMultiple extends true ? File[] : File | null) => string;
	getSizeText?: (files: TMultiple extends true ? File[] : File | null) => string;
	onChange?: (value: TMultiple extends true ? File[] : File | null) => void;
	clearButtonProps?: AavoIconButtonProps;
	autoFocus?: boolean;
	inputRef?: React.ForwardedRef<HTMLInputElement>;
	accept?: string;
	error?: string;
};

type NonUndefined<T> = T extends undefined ? never : T;

export const AavoFileInput = <TMultiple extends boolean | undefined>({
	value,
	onChange,
	disabled,
	getInputText,
	getSizeText,
	placeholder = "",
	accept = "*/*",
	hideSizeText,
	InputProps,
	multiple = false,
	className,
	clearButtonProps = {},
	sx,
	inputRef: inputRefProp,
	error,
	...restProps
}: AavoFileInputProps<TMultiple>) => {
	const ref = useRef<HTMLDivElement>(null);

	const inputRef = useForwardedRef(inputRefProp);

	const clearInputValue = () => {
		const inputEl = inputRef.current;

		if (inputEl) {
			inputEl.value = "";
		}
	};

	const handleChange = (files: File[]) => {
		clearInputValue();

		if (multiple) {
			onChange?.(files as NonNullable<typeof value>);
		} else {
			const firstFile = files[0] as unknown as NonNullable<typeof value>;
			onChange?.(firstFile);
		}
	};

	const { isDraggingOver } = useFileInputDnd({ ref: ref, onDrop: handleChange });

	const handleChangeEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
		const fileList = event.target.files;
		const files = fileList ? Array.from(fileList) : [];
		handleChange(files);
	};

	const handleClearAll = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();

		if (disabled) {
			return;
		}

		if (multiple) {
			onChange?.([] as unknown as NonNullable<typeof value>);
		} else {
			onChange?.(null as NonUndefined<typeof value>);
		}
	};

	const hasAtLeastOneFile = Array.isArray(value) ? value.length > 0 : value !== null;

	const getInputTextWrapped = () => {
		if (typeof getInputText === "function") {
			return getInputText(value);
		}

		if (value == null || (Array.isArray(value) && value.length === 0)) {
			return placeholder;
		}

		if (value && hasAtLeastOneFile) {
			if (Array.isArray(value) && value.length > 1) {
				return i18n.t("count_files", { count: value.length });
			}

			return getFileDetails(value);
		}

		return "";
	};

	const getTotalSizeText = (): string => {
		if (typeof getSizeText === "function" && value !== undefined) {
			return getSizeText(value);
		}

		if (hasAtLeastOneFile) {
			if (Array.isArray(value)) {
				const totalSize = getTotalFilesSize(value);
				return prettyBytes(totalSize);
			}

			if (value !== null && value instanceof File) {
				return prettyBytes(value.size);
			}
		}

		return "";
	};

	const { inputProps, endAdornment, ...otherInputProps } = InputProps ?? {};

	return (
		<TextField
			ref={ref}
			type="file"
			disabled={disabled}
			onChange={handleChangeEvent}
			className={`MuiFileInput-TextField ${className || ""}`}
			error={error != null}
			helperText={error}
			sx={mergeSx(
				{
					backgroundColor: isDraggingOver ? "rgba(0, 0, 0, 0.1)" : "transparent",
				},
				sx,
			)}
			InputProps={{
				startAdornment: (
					<InputAdornment position="start">
						<FontAwesomeIcon icon={faPaperclip} />
					</InputAdornment>
				),
				endAdornment: endAdornment || (
					<InputAdornment
						position="end"
						sx={{
							visibility: hasAtLeastOneFile ? "visible" : "hidden",
							marginRight: "0.5rem",
						}}
					>
						{!hideSizeText && (
							<Typography
								variant="caption"
								mr="2px"
								className="MuiFileInput-Typography-size-text"
							>
								{getTotalSizeText()}
							</Typography>
						)}
						<AavoIconButton
							aria-label="Clear"
							title="Clear"
							size="small"
							icon={faXmark}
							disabled={disabled}
							onClick={handleClearAll}
							{...clearButtonProps}
						/>
					</InputAdornment>
				),
				inputProps: {
					text: getInputTextWrapped(),
					multiple: multiple,
					ref: inputRef,
					isPlaceholder: value === null || (Array.isArray(value) && value.length === 0),
					placeholder,
					accept: accept,
					...inputProps,
				},
				inputComponent: Input,
				...otherInputProps,
			}}
			{...restProps}
		/>
	);
};
type InputProps = InputBaseComponentProps & {
	text: string | { filename: string; extension: string };
	isPlaceholder: boolean;
	accept: string;
};

const Input = React.forwardRef(
	(
		{ text, isPlaceholder, placeholder, accept, ...restInputProps }: InputProps,
		ref: React.ForwardedRef<HTMLInputElement>,
	) => {
		const inputId = React.useId();

		return (
			<AavoFileInputStyledLabel htmlFor={inputId}>
				<input ref={ref} id={inputId} accept={accept} {...restInputProps} />
				{text ?
					<span
						aria-placeholder={placeholder}
						className={isPlaceholder ? "MuiFileInput-placeholder" : ""}
					>
						{typeof text === "string" ?
							text
						:	<FileName fileName={text.filename} extension={text.extension} />}
					</span>
				:	null}
			</AavoFileInputStyledLabel>
		);
	},
);

const FileName = ({ fileName, extension }: { fileName: string; extension: string }) => {
	return (
		<Box
			sx={{
				display: "flex",
				width: "100%",
			}}
		>
			<Box
				sx={{
					whiteSpace: "nowrap",
					textOverflow: "ellipsis",
					overflow: "hidden",
				}}
			>
				{fileName}
			</Box>
			<Box
				sx={{
					flexShrink: 0,
				}}
			>
				{`.${extension}`}
			</Box>
		</Box>
	);
};

function getTotalFilesSize(files: File[]): number {
	return files.reduce((previousValue, currentFile) => {
		return previousValue + currentFile.size;
	}, 0);
}

function getFileDetails(value: File | File[]) {
	const name = matchIsFile(value) ? value.name : value[0]?.name || "";
	const parts = name.split(".");
	const extension = parts.pop() as string;
	const filenameWithoutExtension = parts.join(".");

	return {
		filename: filenameWithoutExtension,
		extension,
	};
}

function matchIsFile(value: unknown): value is File {
	// Secure SSR
	return typeof window !== "undefined" && value instanceof File;
}
