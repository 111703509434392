// This file is automatically generated. Do not edit manually.

import { CustomerOrderBillingPlanLineView } from "src/api/generated/erp/db/types/tables/customerOrderBillingPlanLineView";
import { AppUser } from "src/api/generated/postgres/db/types/role_management/tables/appUser";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace CustomerOrderBillingPlanLinesToApproveDataGridApi {
	export async function getDataGridData(args: {
		isApproved: boolean;
		responsiblePersonId: number | null | undefined;
	}): Promise<Array<CustomerOrderBillingPlanLineView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerOrder/billingPlanLinesToApproveDataGrid/getDataGridData",
			method: "POST",
			data: {
				isApproved: args.isApproved,
				responsiblePersonId: args.responsiblePersonId,
			},
		});
		return response as Array<CustomerOrderBillingPlanLineView>;
	}

	export async function getResponsiblePersonOptions(args: {
		currentSelection: number | null | undefined;
		searchQuery: string;
	}): Promise<Array<AppUser>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/customerOrder/billingPlanLinesToApproveDataGrid/getResponsiblePersonOptions",
			method: "POST",
			data: {
				currentSelection: args.currentSelection,
				searchQuery: args.searchQuery,
			},
		});
		return response as Array<AppUser>;
	}
}
