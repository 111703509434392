import {faListCheck} from "@fortawesome/pro-regular-svg-icons";
import i18n from "i18next";
import {openFormOnDialog} from "src/components/common/dialogs/formDialog/openFormOnDialog.ts";
import {
    ShopOrderReservationBaseForm
} from "src/components/views/erp/production/ShopOrderPicking/ShopOrderBomReservationFormBase.tsx";
import {AsyncButton} from "src/components/common/buttons/AsyncButton.tsx";
import {useGenericDialog} from "src/components/common/dialogs/useGenericDialog.ts";
import {RefreshableElementRef} from "src/utils/useRefreshRef.ts";
import {floatColumn, textColumn} from "src/components/common/dataGrid/columns.tsx";
import {ShopOrderLineView} from "src/api/generated/erp/db/types/tables/shopOrderLineView.ts";
import {ClientSideDataGridModel} from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel.tsx";
import {CrudDataGrid} from "src/components/common/dataGrid/crud/CrudDataGrid.tsx";
import {SelectField} from "src/components/common/inputFields/SelectField.tsx";
import {
    ShopOrderBomReservationDataGridApi
} from "src/api/generated/erp/production/api/shopOrderPicking/shopOrderBomReservationDataGridApi.ts";

export interface ShopOrderBomReservableDataGridProps {
    shopOrderId: number;
    shopOrderOperationId?: number | null;
    pickableLinesRefreshRef: RefreshableElementRef;
    pickedLinesRefreshRef: RefreshableElementRef;
    refreshRef: RefreshableElementRef;
    showFullOperationProductionBomOnSite: boolean;
}

export const ShopOrderBomReservableDataGrid = ({
                                                   shopOrderId,
                                                   shopOrderOperationId = null,
                                                   pickableLinesRefreshRef,
                                                   pickedLinesRefreshRef,
                                                   refreshRef,
                                                   showFullOperationProductionBomOnSite,
                                               }: ShopOrderBomReservableDataGridProps) => {
    const {openDialog} = useGenericDialog();
    return (
        <ClientSideDataGridModel
            gridId={"B000BC6EB9CA3E4A"}
            refreshRef={refreshRef}
            fetchData={async (params) =>
                await ShopOrderBomReservationDataGridApi.getShopOrderReservableBom({
                    shopOrderId: shopOrderId,
                    shopOrderOperationId: params.shopOrderOperationId,
                })
            }
            initialParams={{
                shopOrderOperationId: !showFullOperationProductionBomOnSite ? shopOrderOperationId : null,
            }}
            getRows={(data) => data.shopOrderLines}
            getRowId={(row) => row.shopOrderLineId}
            render={({
                         data: {operationOptions},
                         selectedRows,
                         dataGridProps,
                         currentParams,
                         refreshData,
                     }) => {
                return (
                    <CrudDataGrid<ShopOrderLineView>
                        checkboxSelection={true}
                        columns={[
                            textColumn({
                                field: "configurationOrPartDescription",
                                headerName: i18n.t("part"),
                                width: 300,
                            }),
                            textColumn({
                                field: "partUnit",
                                headerName: i18n.t("unit"),
                            }),
                            floatColumn({
                                field: "quantity",
                                headerName: i18n.t("reservable_quantity"),
                                valueGetter: (_, row) => row.quantity - row.reservedQuantity,
                                position: 1,
                                width: 130,
                            }),
                        ]}
                        actionBarComponents={
                            <>
                                <SelectField
                                    label={i18n.t("operation")}
                                    options={operationOptions}
                                    getOptionKey={(option) => option.shopOrderOperationId}
                                    getOptionLabel={(option) =>
                                        `${option.operationNo} | ${option.operationDescription}`
                                    }
                                    value={currentParams.shopOrderOperationId}
                                    onChange={async (value) => {
                                        await refreshData({shopOrderOperationId: value});
                                    }}
                                />
                                <AsyncButton
                                    icon={faListCheck}
                                    label={i18n.t("reserve")}
                                    variant={"outlined"}
                                    onClick={() => {
                                        openFormOnDialog({
                                            openDialog,
                                            component: ShopOrderReservationBaseForm,
                                            props: {
                                                shopOrderBomLines: selectedRows,
                                            },
                                            title: i18n.t("reserve"),
                                            size: "md",
                                            onSubmit: async () => {
                                                await Promise.all([
                                                    refreshData(),
                                                    pickableLinesRefreshRef.refresh(),
                                                    pickedLinesRefreshRef.refresh()
                                                ])
                                            },
                                        });
                                    }}
                                    disabled={selectedRows.length === 0}
                                />
                            </>
                        }
                        {...dataGridProps}
                    />
                );
            }}
        />
    );
};
