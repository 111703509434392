import dayjs, { Dayjs } from "dayjs";
import { DateTimePickerProps } from "@mui/x-date-pickers-pro";
import { AavoDateTimePicker } from "../../inputFields/AavoDateTimePicker";
import { FieldPathValue, UseControllerProps } from "react-hook-form";
import { FieldPath, useController } from "react-hook-form";

export interface FormDateTimeFieldProps<
	TFieldValues extends object,
	TFieldName extends FieldPath<TFieldValues>,
> extends Omit<DateTimePickerProps<Dayjs>, "onChange" | "value"> {
	control: UseControllerProps<TFieldValues, TFieldName>["control"];
	name: UseControllerProps<TFieldValues, TFieldName>["name"];
	rules?: UseControllerProps<TFieldValues, TFieldName>["rules"];
	asLocalTime?: boolean;
}

export const FormDateTimeField = <TFieldValues extends object, TFieldName extends FieldPath<TFieldValues>>({
	control,
	name,
	rules,
	asLocalTime = false,
	...other
}: FormDateTimeFieldProps<TFieldValues, TFieldName>) => {
	const { field, fieldState } = useController({
		name,
		control,
		rules,
		defaultValue: null as FieldPathValue<TFieldValues, TFieldName>,
	});

	const dayJsValue = field.value === null ? null : dayjs(field.value);

	const transformedField = {
		...field,
		value: dayJsValue,
		onChange: (date: Dayjs | null) => {
			const asString = asLocalTime ? date?.format("YYYY-MM-DDTHH:mm:ss") : date?.toISOString();
			field.onChange(asString ?? null);
		},
		ref: undefined,
	};

	return <AavoDateTimePicker errorMessage={fieldState.error?.message} {...other} {...transformedField} />;
};
