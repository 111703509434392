import { PartialTenantCustomizations } from "src/tenantCustomizations/TenantCustomizations.ts";
import { getRandomValueSamplingMethod } from "src/tenantCustomizations/tenants/demo1/spcSamplingMethods/randomValueSamplingMethod.tsx";
import { getCurrentTimeSamplingMethod } from "src/tenantCustomizations/tenants/dev/spcSamplingMethods/currentTimeSamplingMethod.tsx";
import { getStaticValueSamplingMethod } from "src/tenantCustomizations/tenants/dev/spcSamplingMethods/staticValueSamplingMethod.tsx";
import { getFailingSamplingMethod } from "src/tenantCustomizations/tenants/dev/spcSamplingMethods/failingSamplingMethod.tsx";

export const devTenantCustomizations: PartialTenantCustomizations = {
	tenantConfig: {
		erp: {
			enabled: true,
			salesEnabled: true,
			ledgerIntegrationEnabled: true,
			customerOrderFreezingEnabled: true,
			reclamationsEnabled: true,
			customerOrderPreLineImportEnabled: true,
			configuratorEnabled: true,
			inputPricesWithVatByDefault: false,
			projectActivitySchedulingEnabled: true,
		},
		tasks: {
			enabled: true,
		},
		maps: {
			enabled: true,
		},
		spc: {
			processImprovementsEnabled: true,
		},
		documentsEnabled: true,
	},
	spcSamplingMethods: () => [
		getRandomValueSamplingMethod(),
		getCurrentTimeSamplingMethod(),
		getStaticValueSamplingMethod(),
		getFailingSamplingMethod(),
	],
} as const;
