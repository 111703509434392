// This file is automatically generated. Do not edit manually.

import { ProductionLine } from "src/api/generated/erp/db/types/tables/productionLine";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ProductionLineApi {
	export async function getProductionLines(args: { siteId: number }): Promise<Array<ProductionLine>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/productionLine/getProductionLines",
			method: "POST",
			data: {
				siteId: args.siteId,
			},
		});
		return response as Array<ProductionLine>;
	}
}
