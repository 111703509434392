import {ProjectActivityMaterialLinesDataGridBase} from "./ProjectActivityMaterialLinesDataGridBase";
import {
	ProjectActivityMaterialsDataGridApi
} from "src/api/generated/erp/project/projectActivity/api/projectActivityMaterialsDataGridApi.ts";

export interface SingleActivityMaterialLinesDataGridProps {
	activityId: number;
}

export const SingleActivityMaterialLinesDataGrid = ({
	activityId,
}: SingleActivityMaterialLinesDataGridProps) => {
	return (
		<ProjectActivityMaterialLinesDataGridBase
			gridId={"D368AAECDED2C493"}
			fetchData={({ showCancelledLines, dataModelRequest }) =>
				ProjectActivityMaterialsDataGridApi.getDataGridDataForActivity({
					projectActivityId: activityId,
					cancelledLines: showCancelledLines,
					dataModelRequest: dataModelRequest,
				})
			}
			showLineNumberColumn
		/>
	);
};
