import { CustomerOrderPackagesDataGridBase } from "./CustomerOrderPackagesDataGridBase.tsx";
import { CustomerOrderPackageView } from "src/api/generated/erp/db/types/tables/customerOrderPackageView.ts";
import { useRefreshRef } from "src/utils/useRefreshRef.ts";

export interface AllUndeliveredPackagesDataGridProps {
	selectedPackage: CustomerOrderPackageView | undefined;
	onSelectedPackageChanged: (selectedPackage: CustomerOrderPackageView | undefined) => void;
}

export const AllUndeliveredPackagesDataGrid = ({
	selectedPackage,
	onSelectedPackageChanged,
}: AllUndeliveredPackagesDataGridProps) => {
	const refreshRef = useRefreshRef();
	return (
		<CustomerOrderPackagesDataGridBase
			gridId={"548BE3FC2D394C90"}
			packageStates={["PARTIALLY_PICKED", "PICKED"]}
			selectedRows={selectedPackage == undefined ? [] : [selectedPackage]}
			onSelectedPackageChanged={(selectedPackages) => onSelectedPackageChanged(selectedPackages[0])}
			showSearchField={true}
			refreshRef={refreshRef}
			sortModel={[
				{
					field: "plannedDeliveryDate",
					sort: "asc",
				},
				{
					field: "customerOrderId",
					sort: "asc",
				},
				{
					field: "packageNo",
					sort: "asc",
				},
			]}
			showCustomerOrderDocumentsButton
		/>
	);
};
