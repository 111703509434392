import { ConfigurationProductFamily } from "src/api/generated/erp/db/types/tables/configurationProductFamily.ts";
import { ClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel";
import { CrudDataGrid } from "src/components/common/dataGrid/crud/CrudDataGrid";
import {
	ConfigurationProductFamiliesApi
} from "src/api/generated/erp/configurator/management/productFamilies/api/configurationProductFamiliesApi";
import { textColumn } from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import {
	ConfigurationProductFamilyForm
} from "src/components/views/erp/configurator/managing/productFamilies/ConfigurationProductFamilyForm.tsx";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { useGenericDialog } from "src/components/common/dialogs/useGenericDialog.ts";
import {
	ConfigurationProductFamilyCatalogPartsDataGrid
} from "src/components/views/erp/configurator/managing/productFamilies/ConfigurationProductFamilyCatalogPartsDataGrid.tsx";
import { faClipboardList } from "@fortawesome/pro-regular-svg-icons";

export interface ConfigurationProductFamiliesDataGridProps {
	onSelectionChanged: (row: ConfigurationProductFamily | undefined) => void;
}

export const ConfigurationProductFamiliesDataGrid = ({
														 onSelectionChanged
													 }: ConfigurationProductFamiliesDataGridProps) => {
	const { openDialog } = useGenericDialog();

	return (
		<ClientSideDataGridModel
			fetchData={() => ConfigurationProductFamiliesApi.getConfigurationProductFamilies()}
			initialParams={{}}
			getRows={(data) => data}
			getRowId={(row) => row.configurationProductFamilyId}
			gridId={"246531108EC72791"}
			onSelectionChanged={(rows) => onSelectionChanged(rows[0])}
			render={({ dataGridProps, onlySelectedRow }) => (
				<CrudDataGrid<ConfigurationProductFamily>
					disableMultipleRowSelection
					columns={[
						textColumn({
							field: "name",
							headerName: i18n.t("name"),
							width: 150
						})
					]}
					form={{
						addRowEnabled: true,
						editEnabled: true,
						dialogSize: "sm",
						dialogTitle: i18n.t("product_family"),
						component: ({ row, onCompleted, onFormEdited }) => (
							<ConfigurationProductFamilyForm
								productFamily={row}
								onCompleted={onCompleted}
								onFormEdited={onFormEdited}
							/>
						)
					}}
					remove={({ items }) =>
						ConfigurationProductFamiliesApi.delete_({
							configurationProductFamilyId: items[0]!.configurationProductFamilyId
						})
					}
					actionBarComponents={
						<>
							<AsyncButton
								icon={faClipboardList}
								label={i18n.t("parts")}
								variant={"outlined"}
								disabled={!onlySelectedRow}
								onClick={() => {
									openDialog({
										title: i18n.t("product_family_parts"),
										size: "lg",
										content: (
											<ConfigurationProductFamilyCatalogPartsDataGrid
												productFamilyId={onlySelectedRow!.configurationProductFamilyId}
											/>
										)
									});
								}}
							/>
						</>
					}
					{...dataGridProps}
				/>
			)}
		/>
	);
};
