export const dataGridRowClassNames = {
	even: "row-even",
	odd: "row-odd",
	edited: "row-edited",
	unsavedNew: "row-unsaved-new",
	green: "row-green",
	yellow: "row-yellow",
	red: "row-red",
	invalid: "row-invalid",
} as const;

export const dataGridCellClassNames = {
	invalid: "cell-invalid",
	warning: "cell-warning",
} as const;
