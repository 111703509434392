import i18n from "i18next";
import { faFileInvoiceDollar } from "@fortawesome/pro-regular-svg-icons";
import { useTenantCustomizations } from "src/tenantCustomizations/useTenantCustomizations.tsx";
import { AsyncButton, AsyncButtonProps } from "src/components/common/buttons/AsyncButton.tsx";
import { useGenericDialog } from "src/components/common/dialogs/useGenericDialog.ts";
import { CustomerOrdersPage } from "src/components/views/erp/sales/customerOrder/CustomerOrdersPage.tsx";

export interface OpenCustomerOrderButtonProps extends Omit<AsyncButtonProps, "onClick"> {
	customerOrderId: number | undefined | null;
}

export const OpenCustomerOrderButton = ({
	customerOrderId,
	variant = "menu",
}: OpenCustomerOrderButtonProps) => {
	const { openDialog } = useGenericDialog();

	const { tenantConfig } = useTenantCustomizations();
	if (!tenantConfig.erp.salesEnabled) return null;

	return (
		<AsyncButton
			label={i18n.t("open_customer_order")}
			icon={faFileInvoiceDollar}
			variant={variant}
			disabled={customerOrderId == null}
			onClick={() => {
				if (customerOrderId == null) return;
				openDialog({
					title: i18n.t("customer_order"),
					content: <CustomerOrdersPage onlyCustomerOrderToShowId={customerOrderId} />,
				});
			}}
		/>
	);
};
