// This file is automatically generated. Do not edit manually.

import { CustomerOrderPackageType } from "src/api/generated/erp/db/types/tables/customerOrderPackageType";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace CustomerOrderPackageTypesApi {
	export async function getCustomerOrderPackageTypes(args: {
		includeInactive?: boolean;
	}): Promise<Array<CustomerOrderPackageType>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/basedata/customerOrderPackageType/getCustomerOrderPackageTypes",
			method: "POST",
			data: {
				includeInactive: args.includeInactive,
			},
		});
		return response as Array<CustomerOrderPackageType>;
	}

	export async function insert(args: { customerOrderPackageType: CustomerOrderPackageType }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/basedata/customerOrderPackageType/insert",
			method: "POST",
			data: {
				customerOrderPackageType: args.customerOrderPackageType,
			},
		});
		return response as number;
	}

	export async function update(args: { customerOrderPackageType: CustomerOrderPackageType }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/basedata/customerOrderPackageType/update",
			method: "POST",
			data: {
				customerOrderPackageType: args.customerOrderPackageType,
			},
		});
		return response as void;
	}

	export async function delete_(args: { customerOrderPackageTypeId: number }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/basedata/customerOrderPackageType/delete",
			method: "POST",
			data: {
				customerOrderPackageTypeId: args.customerOrderPackageTypeId,
			},
		});
		return response as void;
	}

	export async function setPackageTypeIsActive(args: {
		customerOrderPackageTypeId: number;
		isActive: boolean;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/basedata/customerOrderPackageType/setPackageTypeIsActive",
			method: "POST",
			data: {
				customerOrderPackageTypeId: args.customerOrderPackageTypeId,
				isActive: args.isActive,
			},
		});
		return response as void;
	}
}
