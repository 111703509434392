// This file is automatically generated. Do not edit manually.

import { ProductionLineOperationShopOrdersView } from "src/api/generated/erp/db/types/tables/productionLineOperationShopOrdersView";
import { Workcenter } from "src/api/generated/erp/db/types/tables/workcenter";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ProductionLineJobQueueInProgressApi {
	export async function getInProgressOperations(): Promise<ProductionLineJobQueueInProgressApi_ViewData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/productionLineJobQueue/getInProgressOperations",
			method: "POST",
			data: {},
		});
		return response as ProductionLineJobQueueInProgressApi_ViewData;
	}
}

export interface ProductionLineJobQueueInProgressApi_ViewData {
	shopOrderOperations: Array<ProductionLineOperationShopOrdersView>;
	workCenter: Workcenter;
	workCenterControlChart: ProductionLineJobQueueInProgressApi_WorkCenterControlChartDto | null | undefined;
}

export interface ProductionLineJobQueueInProgressApi_WorkCenterControlChartDto {
	controlChartId: number;
	hasIncompleteRecord: boolean;
}
