import { RefreshableElementRef } from "src/utils/useRefreshRef.ts";
import { WarehouseTransferJobQueueDataGridBase } from "src/components/views/erp/warehouse/transfer/WarehouseTransferJobQueueDataGridBase.tsx";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import i18n from "i18next";
import { WarehouseTransferActionApi } from "src/api/generated/erp/warehouse/warehouseTransfer/api/warehouseTransferActionApi.ts";
import { faListCheck } from "@fortawesome/pro-regular-svg-icons";

export interface WarehouseTransferJobQueueNotStartedDataGridProps {
	refreshRef: RefreshableElementRef;
	reservedRefreshRef: RefreshableElementRef;
}

export const WarehouseTransferJobQueueNotStartedDataGrid = ({
	reservedRefreshRef,
	refreshRef,
}: WarehouseTransferJobQueueNotStartedDataGridProps) => {
	return (
		<WarehouseTransferJobQueueDataGridBase
			warehouseTransferState={"RELEASED"}
			gridId={"FD5FAD815A3s62EB"}
			refreshRef={refreshRef}
			sortModel={[
				{ field: "transferRequestDate", sort: "asc" },
				{ field: "warehouseTransferId", sort: "asc" },
			]}
			actionBarComponents={(selectedRows) => (
				<>
					<AsyncButton
						label={i18n.t("reserve")}
						icon={faListCheck}
						onClick={async () => {
							await WarehouseTransferActionApi.reserveWarehouseTransfers({
								warehouseTransferIds: selectedRows.map((row) => row.warehouseTransferId),
							});
							await reservedRefreshRef.refresh();
							await refreshRef.refresh();
						}}
						disabled={selectedRows.length === 0}
						variant={"outlined"}
					/>
				</>
			)}
		/>
	);
};
