import {
    ShopOrderBomPickingDataGridApi
} from "src/api/generated/erp/production/api/shopOrderPicking/shopOrderBomPickingDataGridApi.ts";
import {ClientSideDataGridModel} from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel.tsx";
import {CrudDataGrid} from "src/components/common/dataGrid/crud/CrudDataGrid.tsx";
import {floatColumn, textColumn} from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import {AsyncButton} from "src/components/common/buttons/AsyncButton.tsx";
import {faDolly, faFileDownload, faUndo} from "@fortawesome/pro-regular-svg-icons";
import {
    ShopOrderLinePickingObjectLocationView
} from "src/api/generated/erp/db/types/tables/shopOrderLinePickingObjectLocationView.ts";
import {openFormOnDialog} from "src/components/common/dialogs/formDialog/openFormOnDialog.ts";
import {
    ShopOrderBomPickingForm
} from "src/components/views/erp/production/ShopOrderPicking/ShopOrderBomPickingForm.tsx";
import {useGenericDialog} from "src/components/common/dialogs/useGenericDialog.ts";
import {RefreshableElementRef} from "src/utils/useRefreshRef.ts";
import {
    ShopOrderBomReservationFormApi
} from "src/api/generated/erp/production/api/shopOrderPicking/shopOrderBomReservationFormApi.ts";
import {SelectField} from "src/components/common/inputFields/SelectField.tsx";
import {ShopOrderPrintApi} from "src/api/generated/erp/production/api/shopOrder/shopOrderPrintApi.ts";
import {downloadFile} from "src/utils/fileDownloading.ts";

export interface ShopOrderBomPickingDataGridProps {
    shopOrderId: number;
    shopOrderOperationId?: number | null;
    pickedLinesRefreshRef: RefreshableElementRef;
    reservableLinesRefreshRef: RefreshableElementRef;
    refreshRef: RefreshableElementRef;
    showFullOperationProductionBomOnSite: boolean;
}

export const ShopOrderBomPickingDataGrid = ({
                                                shopOrderId,
                                                shopOrderOperationId,
                                                pickedLinesRefreshRef,
                                                reservableLinesRefreshRef,
                                                refreshRef,
                                                showFullOperationProductionBomOnSite,
                                            }: ShopOrderBomPickingDataGridProps) => {
    const {openDialog} = useGenericDialog();
    return (
        <ClientSideDataGridModel
            refreshRef={refreshRef}
            gridId={"F9AB0DCF28DFD5F3"}
            fetchData={async (params) =>
                ShopOrderBomPickingDataGridApi.getShopOrderPickableBom({
                    shopOrderId: shopOrderId,
                    shopOrderOperationId: params.shopOrderOperationId,
                })
            }
            getRows={(data) => data.shopOrderLinePickingLocations}
            initialParams={{
                shopOrderOperationId: !showFullOperationProductionBomOnSite ? shopOrderOperationId : null,
            }}
            getRowId={(row) => row.objectId}
            render={({
                         data: {operationOptions},
                         dataGridProps,
                         selectedRows,
                         refreshData,
                         currentParams,
                     }) => {
                return (
                    <CrudDataGrid<ShopOrderLinePickingObjectLocationView>
                        checkboxSelection={true}
                        columns={[
                            textColumn({
                                field: "partLongName",
                                headerName: i18n.t("part"),
                                width: 300,
                            }),
                            floatColumn({
                                field: "locationReservedQuantity",
                                headerName: i18n.t("pickable_quantity"),
                                width: 150,
                            }),
                            textColumn({
                                field: "partUnit",
                                headerName: i18n.t("unit"),
                            }),
                            textColumn({
                                field: "locationCode",
                                headerName: i18n.t("warehouse_location"),
                            }),
                        ]}
                        actionBarComponents={
                            <>
                                <SelectField
                                    label={i18n.t("operation")}
                                    options={operationOptions}
                                    getOptionKey={(option) => option.shopOrderOperationId}
                                    getOptionLabel={(option) =>
                                        `${option.operationNo} | ${option.operationDescription}`
                                    }
                                    value={currentParams.shopOrderOperationId}
                                    onChange={(value) =>
                                        refreshData({shopOrderOperationId: value ?? undefined})
                                    }
                                />
                                <AsyncButton
                                    label={i18n.t("picking_list")}
                                    icon={faFileDownload}
                                    variant={"outlined"}
                                    onClick={async () => {
                                        const fileHandle = await ShopOrderPrintApi.printShopOrderPickingList({
                                            shopOrderLineIds: selectedRows.map((row) => row.shopOrderLineId),
                                        });
                                        downloadFile(fileHandle);
                                    }}
                                    disabled={selectedRows.length === 0}
                                />
                                <AsyncButton
                                    icon={faDolly}
                                    label={i18n.t("pick")}
                                    variant={"outlined"}
                                    disabled={selectedRows.length === 0}
                                    onClick={async () => {
                                        openFormOnDialog({
                                            openDialog,
                                            title: i18n.t("pick"),
                                            size: "md",
                                            component: ShopOrderBomPickingForm,
                                            props: (closeDialog) => ({
                                                shopOrderLinePickingObjectLocations: selectedRows,
                                                closeDialog: closeDialog,
                                            }),
                                            onSubmit: async () => {
                                                await Promise.all([
                                                    refreshData(),
                                                    pickedLinesRefreshRef.refresh(),
                                                    reservableLinesRefreshRef.refresh()
                                                ])
                                            }
                                        });
                                    }}
                                />
                                <AsyncButton
                                    icon={faUndo}
                                    label={i18n.t("revert_reservation")}
                                    variant={"outlined"}
                                    disabled={selectedRows.length === 0}
                                    onClick={async () => {
                                        await ShopOrderBomReservationFormApi.createReservations({
                                            reservationRequests: selectedRows.map((row) => ({
                                                shopOrderLineId: row.shopOrderLineId,
                                                quantityInWarehouseUnit: -row.locationReservedQuantity,
                                                partWarehouseLocationId: row.partWarehouseLocationId,
                                            })),
                                        });
                                        await Promise.all([
                                            refreshData(),
                                            pickedLinesRefreshRef.refresh(),
                                            reservableLinesRefreshRef.refresh()
                                        ])
                                    }}
                                />
                            </>
                        }
                        {...dataGridProps}
                    />
                );
            }}
        />
    );
};
