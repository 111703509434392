import {AavoMosaic} from "src/components/common/mosaic/AavoMosaic.tsx";
import i18n from "i18next";
import {
    ShopOrderBomReservableDataGrid
} from "src/components/views/erp/production/ShopOrderPicking/ShopOrderBomReservableDataGrid.tsx";
import {
    ShopOrderBomPickingDataGrid
} from "src/components/views/erp/production/ShopOrderPicking/ShopOrderBomPickingDataGrid.tsx";
import {useRefreshRef} from "src/utils/useRefreshRef.ts";
import {
    ShopOrderBomPickedDataGrid
} from "src/components/views/erp/production/ShopOrderPicking/ShopOrderBomPickedDataGrid.tsx";
import {AsyncFetchRender} from "src/components/common/async/AsyncFetchRender.tsx";
import {ShopOrderApi} from "src/api/generated/erp/production/api/shopOrder/shopOrderApi.ts";

export interface ShopOrderBomReserveAndPickViewProps {
    shopOrderId: number;
    shopOrderOperationId?: number | null;
}

export const ShopOrderBomReserveAndPickView = ({
                                                   shopOrderId,
                                                   shopOrderOperationId,
                                               }: ShopOrderBomReserveAndPickViewProps) => {
    const reservableLinesRefreshRef = useRefreshRef();
    const pickableLinesRefreshRef = useRefreshRef();
    const pickedLinesRefreshRef = useRefreshRef();
    return (
        <AsyncFetchRender
            fetch={() => ShopOrderApi.getSingleShopOrder({shopOrderId})}
            content={(shopOrder) => (
                <AavoMosaic
                    layout={{
                        type: "tab",
                        items: [
                            {
                                type: "panel",
                                title: i18n.t("reserve"),
                                content: (
                                    <ShopOrderBomReservableDataGrid
                                        shopOrderId={shopOrderId}
                                        shopOrderOperationId={shopOrderOperationId}
                                        refreshRef={reservableLinesRefreshRef}
                                        pickableLinesRefreshRef={pickableLinesRefreshRef}
                                        pickedLinesRefreshRef={pickedLinesRefreshRef}
                                        showFullOperationProductionBomOnSite={
                                            shopOrder.siteShowFullOperationProductionBom
                                        }
                                    />
                                ),
                            },
                            {
                                type: "panel",
                                title: i18n.t("pick"),
                                content: (
                                    <ShopOrderBomPickingDataGrid
                                        refreshRef={pickableLinesRefreshRef}
                                        shopOrderId={shopOrderId}
                                        shopOrderOperationId={shopOrderOperationId}
                                        pickedLinesRefreshRef={pickedLinesRefreshRef}
                                        reservableLinesRefreshRef={reservableLinesRefreshRef}
                                        showFullOperationProductionBomOnSite={
                                            shopOrder.siteShowFullOperationProductionBom
                                        }
                                    />
                                ),
                            },
                            {
                                type: "panel",
                                title: i18n.t("picked"),
                                content: (
                                    <ShopOrderBomPickedDataGrid
                                        shopOrderId={shopOrderId}
                                        shopOrderOperationId={shopOrderOperationId}
                                        refreshRef={pickedLinesRefreshRef}
                                        reservableLinesRefreshRef={reservableLinesRefreshRef}
                                        pickableLinesRefreshRef={pickableLinesRefreshRef}
                                        showFullOperationProductionBomOnSite={
                                            shopOrder.siteShowFullOperationProductionBom
                                        }
                                    />
                                ),
                            },
                        ],
                    }}
                />
            )}
        />
    );
};
