import React, { useMemo, useRef } from "react";
import { PartCategory } from "src/api/generated/erp/db/types/tables/partCategory";
import { Box, Typography } from "@mui/material";
import i18n from "i18next";
import { useStoredState } from "src/utils/useStoredState";
import { AavoTreeView } from "src/components/common/tree/AavoTreeView";
import { useDrag, useDrop } from "react-dnd";
import { useContextMenu } from "src/components/common/contextMenu/useContextMenu.ts";
import { AavoContextMenu } from "src/components/common/contextMenu/AavoContextMenu.tsx";
import { PickRequired } from "src/utils/pickRequired.ts";
import { VerticalBox } from "src/components/common/box/VerticalBox.tsx";
import { useErrorDialog } from "src/components/common/dialogs/errorDialog/userErrorDialog.ts";
import { useMaybeControlledState } from "src/utils/useMaybeControlledState.ts";
import { useTreeItem2 } from "@mui/x-tree-view/useTreeItem2/useTreeItem2";
import { TreeItem2, TreeItem2Props } from "@mui/x-tree-view/TreeItem2";

export interface PartCategoriesTreeViewProps {
	partCategories: PartCategory[];
	selectedPartCategory?: PartCategory | null;
	onSelectionChanged?: (partCategory: PartCategory | null) => void;
	partCategoryContextMenuComponents?: (partCategory: PartCategory) => React.ReactNode;
	dndConfig?: PartCategoryTreeItemDnDConfig;
	actionBarComponents?: React.ReactNode;
	refresh: () => Promise<unknown>;
}

interface PartCategoryTreeItemDnDConfig {
	onDropToPartCategory: (droppedItem: PartCategory, targetItem: PartCategory) => Promise<unknown>;
	onDropToRoot: (droppedItem: PartCategory) => Promise<unknown>;
}

interface PartCategoryTreeItem extends PartCategory {
	id: string;
	children: PartCategoryTreeItem[];
}

export const PartCategoriesTreeView = ({
	partCategories,
	onSelectionChanged: onSelectionChangedProp,
	selectedPartCategory: selectedPartCategoryProp,
	partCategoryContextMenuComponents,
	dndConfig,
	actionBarComponents,
	refresh,
}: PartCategoriesTreeViewProps) => {
	const [openNodes, setOpenNodes] = useStoredState<string[]>([], "D52E93C2913BDE80");
	const [openContextMenu, contextMenuState] = useContextMenu();

	const [selectedPartCategory, setSelectedPartCategory] = useMaybeControlledState<PartCategory | null>({
		controlledValue: selectedPartCategoryProp,
		onChange: onSelectionChangedProp,
		defaultValue: null,
	});

	const rootLevelPartCategories = useMemo(() => {
		return partCategories
			.map((partCategory) => partCategoryToTreeItem(partCategory, partCategories))
			.filter((partCategory) => partCategory.parentId === null);
	}, [partCategories]);

	return (
		<VerticalBox flex={1}>
			<VerticalBox
				sx={{
					overflow: "auto",
					alignSelf: "stretch",
					flex: 1,
				}}
			>
				<AavoTreeView<PartCategoryTreeItem, false>
					multiSelect={false}
					items={rootLevelPartCategories}
					getItemLabel={(partCategory) => partCategory.categoryName}
					slots={{
						item: PartCategoryTreeItem as any,
					}}
					slotProps={{
						item: {
							dndConfig: dndConfig,
							expandNode: expandNode,
							onRightClick: onPartCategoryContextMenu,
						} as PartCategoryTreeItemProps,
					}}
					expandedItems={openNodes}
					onExpandedItemsChange={setOpenNodes}
					selectedItems={
						selectedPartCategory === null ? null : getPartCategoryNodeId(selectedPartCategory)
					}
					onSelectedItemsChange={(_: React.SyntheticEvent, nodeId: string | null) => {
						const newSelectedPartCategory = partCategories.find(
							(partCategory) => getPartCategoryNodeId(partCategory) === nodeId,
						);
						setSelectedPartCategory(newSelectedPartCategory ?? null);
					}}
					actionBarComponents={actionBarComponents}
					refresh={refresh}
					sx={{}}
				/>
			</VerticalBox>
			{dndConfig && <RootLevelDropArea dndConfig={dndConfig} />}
			<AavoContextMenu state={contextMenuState} />
		</VerticalBox>
	);

	function expandNode(nodeId: string) {
		setOpenNodes((openNodes) => openNodes.concat(nodeId));
	}

	function onPartCategoryContextMenu(e: React.MouseEvent, partCategory: PartCategoryTreeItem) {
		if (!partCategoryContextMenuComponents) return;

		e.stopPropagation();
		openContextMenu({
			content: partCategoryContextMenuComponents(partCategory),
			mouseEvent: e,
		});
	}
};

const RootLevelDropArea = ({ dndConfig }: PickRequired<PartCategoriesTreeViewProps, "dndConfig">) => {
	const { logErrorAndShowOnDialog } = useErrorDialog();

	const onDrop = async (dropped: PartCategory) => {
		try {
			await dndConfig.onDropToRoot(dropped);
		} catch (e) {
			logErrorAndShowOnDialog(e);
		}
	};

	const [{ canDrop, isDraggingOver }, dropRef] = useDrop(() => ({
		accept: PART_CATEGORY_DND_TYPE,
		drop: onDrop,
		collect: (monitor) => ({
			canDrop: monitor.canDrop(),
			isDraggingOver: monitor.isOver(),
		}),
	}));

	return (
		<Box
			ref={dropRef}
			sx={{
				display: canDrop ? "flex" : "none",
				backgroundColor: (theme) => theme.palette.grey[300],
				opacity: isDraggingOver ? 0.5 : 1,
				padding: 1,
			}}
		>
			<Typography
				sx={{
					margin: "auto",
					fontSize: "1.5rem",
				}}
			>
				{i18n.t("drop_to_root_level")}
			</Typography>
		</Box>
	);
};

interface PartCategoryTreeItemProps extends TreeItem2Props {
	onRightClick: (e: React.MouseEvent, partCategory: PartCategoryTreeItem) => void;
	expandNode: (nodeId: string) => void;
	dndConfig?: PartCategoryTreeItemDnDConfig;
}

const PartCategoryTreeItem = (props: PartCategoryTreeItemProps) => {
	const { itemId, dndConfig, expandNode, onRightClick } = props;
	const { publicAPI } = useTreeItem2(props);
	const partCategory = publicAPI.getItem(itemId) as PartCategoryTreeItem;

	const { logErrorAndShowOnDialog } = useErrorDialog();
	const labelRef = useRef(null);

	const [{ isDragging }, drag] = useDrag(() => ({
		type: PART_CATEGORY_DND_TYPE,
		item: partCategory,
		collect: (monitor) => ({
			isDragging: monitor.isDragging(),
		}),
	}));

	const onDropToThis = async (dropped: PartCategory) => {
		try {
			await dndConfig?.onDropToPartCategory(dropped, partCategory);
			expandNode(getPartCategoryNodeId(partCategory));
		} catch (e) {
			logErrorAndShowOnDialog(e);
		}
	};

	const [{ canDrop, isDraggingOver }, drop] = useDrop(() => ({
		accept: PART_CATEGORY_DND_TYPE,
		drop: onDropToThis,
		collect: (monitor) => ({
			canDrop: monitor.canDrop(),
			isDraggingOver: monitor.isOver(),
		}),
	}));

	if (dndConfig) {
		drag(drop(labelRef));
	}

	return (
		<TreeItem2
			{...props}
			label={
				<Typography
					ref={labelRef}
					onContextMenu={(e) => onRightClick(e, partCategory)}
					sx={{
						opacity: isDragging || (canDrop && isDraggingOver) ? 0.5 : 1,
						display: "flex",
						gap: "0.5rem",
						alignItems: "center",
						minHeight: "2.5rem",
						marginY: "0.1rem",
					}}
				>
					{partCategory.categoryName}
				</Typography>
			}
		/>
	);
};

const partCategoryToTreeItem = (
	partCategory: PartCategory,
	allCategories: PartCategory[],
): PartCategoryTreeItem => {
	return {
		...partCategory,
		id: getPartCategoryNodeId(partCategory),
		children: allCategories
			.filter((child) => child.parentId === partCategory.partCategoryId)
			.map((child) => partCategoryToTreeItem(child, allCategories)),
	};
};

const getPartCategoryNodeId = (partCategory: PartCategory): string => partCategory.partCategoryId.toString();

const PART_CATEGORY_DND_TYPE = "PART_CATEGORY";
