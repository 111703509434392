import { ConfigurationComponent } from "src/api/generated/erp/db/types/tables/configurationComponent.ts";
import { useContextOrThrow } from "src/utils/useContextOrThrow.tsx";
import { ProductFamilyVersionManagingViewContext } from "src/components/views/erp/configurator/managing/productFamilyVersions/ProductFamilyVersionManagingViewContext.tsx";
import { useInputDialog } from "src/components/common/dialogs/input/useInputDialog.tsx";
import { AsyncMenuButton } from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import { faArrowRightFromLine, faClone, faTrash } from "@fortawesome/pro-regular-svg-icons";
import i18n from "i18next";
import { useConfirmDialog } from "src/components/common/dialogs/confirmDialog/useConfirmDialog.ts";
import { ConfiguratorComponentEditApi } from "src/api/generated/erp/configurator/management/api/configuratorComponentEditApi.ts";
import { deCapitalize } from "src/utils/strings.tsx";

interface ConfiguratorComponentPreviewContextMenuProps {
	component: ConfigurationComponent;
}

export const ConfiguratorComponentPreviewContextMenu = ({
	component,
}: ConfiguratorComponentPreviewContextMenuProps) => {
	const { selectedComponents, allComponents, moveComponentsToAnotherTab, refresh, addComponent } =
		useContextOrThrow(ProductFamilyVersionManagingViewContext);
	const showInputDialog = useInputDialog();
	const showConfirmDialog = useConfirmDialog();

	return [
		<AsyncMenuButton
			key={"switchTab"}
			icon={faArrowRightFromLine}
			label={i18n.t("switch_tab")}
			onClick={onSwitchTabClick}
		/>,
		<AsyncMenuButton key={"clone"} icon={faClone} label={i18n.t("clone")} onClick={onCloneClick} />,
		<AsyncMenuButton key={"delete"} icon={faTrash} label={i18n.t("delete")} onClick={onDeleteClick} />,
	];

	async function onSwitchTabClick() {
		const newTabOptions = allComponents.filter(
			(c) => c.componentType === "TAB" && c.configurationComponentId !== component.parentTabComponentId,
		);
		const newTabId = await showInputDialog({
			type: "singleNumberSelect",
			title: i18n.t("switch_tab"),
			fieldLabel: i18n.t("select_tab"),
			defaultValue: newTabOptions[0]?.configurationComponentId ?? null,
			required: true,
			fieldProps: {
				selection: {
					options: newTabOptions.map((tab) => ({
						value: tab.configurationComponentId,
						label: tab.label,
					})),
				},
			},
		});
		if (newTabId === undefined) return;

		await moveComponentsToAnotherTab(
			selectedComponents.map((c) => c.configurationComponentId),
			newTabId,
		);
	}

	async function onCloneClick() {
		const name = await showInputDialog({
			type: "string",
			title: i18n.t("clone_component"),
			fieldLabel: i18n.t("new_component_name"),
			defaultValue: component.name + " - " + deCapitalize(i18n.t("copy.noun")),
			required: true,
		});
		if (name === undefined) return;

		await addComponent({
			...component,
			configurationComponentId: undefined as any,
			name: name,
			orderNum: component.orderNum + 1,
		});
	}

	async function onDeleteClick() {
		const confirmed = await showConfirmDialog();
		if (!confirmed) return;

		await ConfiguratorComponentEditApi.deleteComponent({
			componentId: component.configurationComponentId,
		});
		await refresh();
	}
};
