import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { Box } from "@mui/material";
import { AavoTooltip } from "../tooltips/AavoTooltip";

export interface ContentWithIconProps {
	icon: IconDefinition;
	tooltip?: string;
	color: string;
	content: React.ReactNode;
}

export const ContentWithIcon = ({ icon, tooltip, color, content }: ContentWithIconProps) => {
	return (
		<AavoTooltip title={tooltip} arrow>
			<Box
				sx={{
					display: "flex",
					gap: 1,
					alignItems: "center",
				}}
			>
				<FontAwesomeIcon icon={icon} color={color} />
				{content}
			</Box>
		</AavoTooltip>
	);
};
