import { WarehouseTransferState } from "src/api/generated/erp/db/types/enums/warehouseTransferState.ts";
import React from "react";
import { ServerSideDataGridModel } from "src/components/common/dataGrid/gridModel/ServerSideDataGridModel.tsx";
import { WarehouseTransferView } from "src/api/generated/erp/db/types/tables/warehouseTransferView.ts";
import { WarehouseTransferJobQueueDataGridApi } from "src/api/generated/erp/warehouse/warehouseTransfer/jobQueue/warehouseTransferJobQueueDataGridApi.ts";
import { CrudDataGrid } from "src/components/common/dataGrid/crud/CrudDataGrid.tsx";
import { dateTimeColumn, floatColumn, integerColumn, textColumn } from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { RefreshableElementRef } from "src/utils/useRefreshRef.ts";
import { TypedGridSortModel } from "src/components/common/dataGrid/types.tsx";

export interface WarehouseTransferJobQueueDataGridBaseProps {
	warehouseTransferState: WarehouseTransferState;
	actionBarComponents: (selectedRows: WarehouseTransferView[]) => React.ReactNode;
	gridId: string;
	refreshRef: RefreshableElementRef;
	sortModel: TypedGridSortModel<WarehouseTransferView>;
}

export const WarehouseTransferJobQueueDataGridBase = ({
	warehouseTransferState,
	actionBarComponents,
	gridId,
	refreshRef,
	sortModel,
}: WarehouseTransferJobQueueDataGridBaseProps) => {
	return (
		<ServerSideDataGridModel
			gridId={gridId}
			fetchData={(params) =>
				WarehouseTransferJobQueueDataGridApi.getData({
					warehouseTransferState: warehouseTransferState,
					...params,
				})
			}
			initialParams={{}}
			refreshRef={refreshRef}
			getDataModelResult={(data) => data}
			getRowId={(row) => row.warehouseTransferId}
			defaultGridState={{
				sorting: {
					sortModel: sortModel,
				},
			}}
			render={({ selectedRows, dataGridProps }) => {
				return (
					<CrudDataGrid<WarehouseTransferView>
						columns={[
							dateTimeColumn({
								field: "transferRequestDate",
								headerName: i18n.t("request_date"),
							}),
							textColumn({
								field: "requestingSiteName",
								headerName: i18n.t("requesting_site_short"),
								width: 80,
							}),
							textColumn({
								field: "requestingWarehouseLocationCode",
								headerName: i18n.t("requesting_warehouse_location_short"),
								width: 80,
							}),
							textColumn({
								field: "givingWarehouseLocationCode",
								headerName: i18n.t("giving_warehouse_location_short"),
								width: 80,
							}),
							textColumn({
								field: "partLongName",
								headerName: i18n.t("part"),
								width: 300,
							}),
							floatColumn({
								field: "quantity",
								headerName: i18n.t("quantity"),
								width: 80,
							}),
							textColumn({
								field: "warehouseTransferUnit",
								headerName: i18n.t("transfer_unit"),
							}),
							textColumn({
								field: "partUnit",
								headerName: i18n.t("base_unit_short"),
							}),
							floatColumn({
								field: "warehouseTransferFactor",
								headerName: i18n.t("transfer_factor"),
							}),
							textColumn({
								field: "warehousePickerGroupCode",
								headerName: i18n.t("picker_group"),
							}),
							integerColumn({
								field: "warehouseTransferId",
								headerName: i18n.t("number_short"),
							}),
						]}
						actionBarComponents={actionBarComponents(selectedRows)}
						{...dataGridProps}
					/>
				);
			}}
		/>
	);
};
