// This file is automatically generated. Do not edit manually.

import { CatalogPart } from "src/api/generated/erp/db/types/tables/catalogPart";
import { ConfigurationProductFamily } from "src/api/generated/erp/db/types/tables/configurationProductFamily";
import { ErpUnit } from "src/api/generated/erp/db/types/tables/erpUnit";
import { NumberSequence } from "src/api/generated/erp/db/types/tables/numberSequence";
import { PartCategory } from "src/api/generated/erp/db/types/tables/partCategory";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace CatalogPartEditApi {
	export async function getFormInitData(args: {
		catalogPartId: number | null | undefined;
	}): Promise<CatalogPartEditApi_FormInitData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/productManagement/catalogParts/getFormInitData",
			method: "POST",
			data: {
				catalogPartId: args.catalogPartId,
			},
		});
		return response as CatalogPartEditApi_FormInitData;
	}

	export async function insert(args: { catalogPart: CatalogPart; partNoSequenceId: number }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/productManagement/catalogParts/insert",
			method: "POST",
			data: {
				catalogPart: args.catalogPart,
				partNoSequenceId: args.partNoSequenceId,
			},
		});
		return response as number;
	}

	export async function update(args: { catalogPart: CatalogPart }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/productManagement/catalogParts/update",
			method: "POST",
			data: {
				catalogPart: args.catalogPart,
			},
		});
		return response as number;
	}

	export async function delete_(args: { catalogPart: CatalogPart }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/productManagement/catalogParts/delete",
			method: "POST",
			data: {
				catalogPart: args.catalogPart,
			},
		});
		return response as void;
	}
}

export interface CatalogPartEditApi_FormInitData {
	catalogPart: CatalogPart | null | undefined;
	configurationProductFamilyOptions: Array<ConfigurationProductFamily>;
	erpUnitOptions: Array<ErpUnit>;
	numberSequenceOptions: Array<NumberSequence>;
	partCategoryOptions: Array<PartCategory>;
}
