import { booleanColumn, floatColumn, textColumn } from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { ClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel.tsx";
import { CrudDataGrid } from "src/components/common/dataGrid/crud/CrudDataGrid.tsx";
import { faCopy } from "@fortawesome/pro-regular-svg-icons";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { RefreshableElementProps, RefreshableElementRef } from "src/utils/useRefreshRef.ts";
import { CustomerOfferBillingPlanApi } from "src/api/generated/erp/sales/billingPlan/customerOffer/api/customerOfferBillingPlanApi.ts";
import { BillingPlanSummaryText } from "src/components/views/erp/sales/billingPlan/base/BillingPlanSummaryText.tsx";
import { CustomerOfferBillingPlanLineView } from "src/api/generated/erp/db/types/tables/customerOfferBillingPlanLineView.ts";
import { CustomerOfferBillingPlanLineForm } from "src/components/views/erp/sales/billingPlan/customerOffer/CustomerOfferBillingPlanLineForm.tsx";
import { CustomerOfferBillingPlanEditApi } from "src/api/generated/erp/sales/billingPlan/customerOffer/api/customerOfferBillingPlanEditApi.ts";
import { useInputDialog } from "src/components/common/dialogs/input/useInputDialog.tsx";
import { BillingPlanModelApi } from "src/api/generated/erp/sales/billingPlan/model/api/billingPlanModelApi";

export interface CustomerOfferBillingPlanDataGridProps extends RefreshableElementProps {
	customerOfferId: number;
	onSelectionChanged?: (selectedRows: CustomerOfferBillingPlanLineView[]) => void;
	customerOrderOffersRefreshRef: RefreshableElementRef;
}

export const CustomerOfferBillingPlanDataGrid = ({
	customerOfferId,
	refreshRef,
	onSelectionChanged,
	customerOrderOffersRefreshRef,
}: CustomerOfferBillingPlanDataGridProps) => {
	const showInputDialog = useInputDialog();

	return (
		<ClientSideDataGridModel
			fetchData={() =>
				CustomerOfferBillingPlanApi.getCustomerOfferBillingPlan({
					customerOfferId,
				})
			}
			refreshRef={refreshRef}
			getRows={(data) => data.lines}
			initialParams={{}}
			gridId={"E9E5FA39C483F3C5"}
			getRowId={(row) => row.customerOfferBillingPlanLineId}
			onSelectionChanged={onSelectionChanged}
			render={({ data: { customerOffer, summary, lines }, dataGridProps, refreshData }) => {
				return (
					<CrudDataGrid<CustomerOfferBillingPlanLineView>
						disableMultipleRowSelection
						columns={[
							textColumn({
								field: "description",
								headerName: i18n.t("description"),
								width: 200,
							}),
							floatColumn({
								field: "billingPlanSum",
								headerName: i18n.t("sum"),
							}),
							floatColumn({
								field: "billingPlanSumWithVat",
								headerName: i18n.t("sum_with_vat"),
								width: 140,
							}),
							booleanColumn({
								field: "funded",
								headerName: i18n.t("funded"),
							}),
							floatColumn({
								field: "vatPercent",
								headerName: i18n.t("vat_percent"),
							}),
						]}
						form={{
							addRowEnabled: !customerOffer.isClosed,
							editEnabled: !customerOffer.isClosed,
							dialogSize: "md",
							dialogTitle: i18n.t("billing_plan_line"),
							component: ({ row, onCompleted, onFormEdited }) => (
								<CustomerOfferBillingPlanLineForm
									customerOfferId={customerOfferId}
									billingPlanLine={row}
									onFormEdited={onFormEdited}
									onCompleted={async (result) => {
										onCompleted(result);
										if (result.type === "success") {
											await customerOrderOffersRefreshRef.refresh();
										}
									}}
								/>
							),
						}}
						actionBarComponents={
							<>
								<AsyncButton
									label={i18n.t("create_from_template")}
									icon={faCopy}
									variant={"outlined"}
									disabled={lines.length > 0}
									onClick={createLineFromModel}
								/>
								<BillingPlanSummaryText summary={summary} />
							</>
						}
						remove={
							!customerOffer.isClosed &&
							(({ items }) =>
								CustomerOfferBillingPlanEditApi.delete_({
									billingPlanLineIds: items.map((i) => i.customerOfferBillingPlanLineId),
								}))
						}
						{...dataGridProps}
					/>
				);

				async function createLineFromModel() {
					const modelOptions = await BillingPlanModelApi.getAllBillingPlanModels();

					const billingPlanModelId = await showInputDialog({
						type: "singleNumberSelect",
						title: i18n.t("create_from_template"),
						fieldLabel: i18n.t("template"),
						required: true,
						defaultValue: null,
						fieldProps: {
							selection: {
								options: modelOptions.map((option) => ({
									value: option.billingPlanModelId,
									label: option.name,
								})),
							},
						},
					});
					if (billingPlanModelId === undefined) return;

					await BillingPlanModelApi.copyBillingPlanModelToCustomerOffer({
						customerOfferId,
						billingPlanModelId,
					});

					await Promise.all([refreshData(), customerOrderOffersRefreshRef.refresh()]);
				}
			}}
		/>
	);
};
