// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const CustomerOrderDeliveryStateValues = ["INITIAL", "DELIVERED"] as const;

export type CustomerOrderDeliveryState = (typeof CustomerOrderDeliveryStateValues)[number];

export const getCustomerOrderDeliveryStateLabels = () => ({
	INITIAL: t("initial"),
	DELIVERED: t("delivered"),
});

export const getCustomerOrderDeliveryStateLabel = (value: CustomerOrderDeliveryState): string => {
	return getCustomerOrderDeliveryStateLabels()[value];
};
