import { Box, useTheme } from "@mui/material";
import { AavoAppBar } from "../appBar/AavoAppBar";
import { MainDrawer } from "../drawer/MainDrawer";
import { MainPageContentWrapper } from "./MainPageContentWrapper";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useStoredState } from "src/utils/useStoredState";
import React from "react";

const DRAWER_WIDTH = 300;

export interface MainPageProps extends React.PropsWithChildren {}

export const MainPage = ({ children }: MainPageProps) => {
	const [drawerOpen, setDrawerOpen] = useStoredState<boolean>(true, "7A17E82AFE5E3FE4");
	const theme = useTheme();
	const wideScreen = useMediaQuery(theme.breakpoints.up("md"));
	return (
		<>
			<MainDrawer
				closeDrawer={() => setDrawerOpen(false)}
				drawerOpen={drawerOpen}
				drawerWidth={DRAWER_WIDTH}
				wideScreen={wideScreen}
			/>
			<MainPageContentWrapper
				wideScreen={wideScreen}
				drawerWidth={DRAWER_WIDTH}
				drawerOpen={drawerOpen}
			>
				<AavoAppBar
					openMainDrawer={() => setDrawerOpen(true)}
					showOpenDrawerButton={!drawerOpen}
				/>
				<Box
					sx={{
						flex: 1,
						display: "flex",
						flexDirection: "column",
						position: "relative",
					}}
				>
					{children}
				</Box>
			</MainPageContentWrapper>
		</>
	);
};
