import {ShopOrderView} from "src/api/generated/erp/db/types/tables/shopOrderView.ts";
import {
    getShopOrderStateLabel,
    getShopOrderStateLabels,
    ShopOrderState,
    ShopOrderStateValues,
} from "src/api/generated/erp/db/types/enums/shopOrderState.ts";
import {
    dateColumn,
    dateTimeColumn,
    enumColumn,
    floatColumn,
    integerColumn,
    textColumn,
} from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import {getShopOrderSourceTypeLabels} from "src/api/generated/erp/db/types/enums/shopOrderSourceType.ts";
import {DocumentsOfObjectButton} from "src/components/views/documents/objectDocuments/DocumentsOfObjectButton.tsx";
import {nullableAavoObjectRef} from "src/utils/aavoObjectRefUtils.ts";
import {AavoTextField} from "src/components/common/inputFields/AavoTextField.tsx";
import {
    faBan,
    faCheck,
    faFileDownload,
    faShare,
    faStickyNote,
    faTh,
    faUndo,
} from "@fortawesome/pro-regular-svg-icons";
import {AsyncMenuButton} from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import {useConfirmDialog} from "src/components/common/dialogs/confirmDialog/useConfirmDialog.ts";
import {ShopOrderActionApi} from "src/api/generated/erp/production/api/shopOrder/shopOrderActionApi.ts";
import {SelectField} from "src/components/common/inputFields/SelectField.tsx";
import {ShopOrderDataGridApi} from "src/api/generated/erp/production/api/shopOrder/shopOrderDataGridApi.ts";
import {ShopOrderPrintApi} from "src/api/generated/erp/production/api/shopOrder/shopOrderPrintApi.ts";
import {useOpenLegacyView} from "src/components/views/legacy/useOpenLegacyView.ts";
import {ShopOrderOperationApi} from "src/api/generated/erp/production/api/shopOrderOperation/shopOrderOperationApi.ts";
import {
    openLegacyShopOrderStateView,
    openLegacySingleShopOrderBatchContainerView,
    openLegacySurveysOfSourcesWithDocumentView,
} from "src/components/views/legacy/legacyViewAdapters.ts";
import {faCubes} from "@fortawesome/pro-solid-svg-icons";
import {
    OpenPartConfigurationButton
} from "src/components/views/erp/configurator/inspecting/OpenPartConfigurationButton.tsx";
import {OpenCustomerOrderInfoButton} from "src/components/views/erp/utilComponents/OpenCustomerOrderInfoButton.tsx";
import {AavoObjectRef} from "src/api/generated/common/sourceType/aavoObjectRef.ts";
import {ShopOrderForm} from "src/components/views/erp/production/shopOrder/ShopOrderForm.tsx";
import {AsyncButton} from "src/components/common/buttons/AsyncButton.tsx";
import {releaseShopOrder} from "src/components/views/erp/production/shopOrder/actions/releaseShopOrder.ts";
import {closeShopOrder} from "src/components/views/erp/production/shopOrder/actions/closeShopOrder.ts";
import {cancelShopOrder} from "src/components/views/erp/production/shopOrder/actions/cancelShopOrder.ts";
import {useServerSideDataGridModel} from "src/components/common/dataGrid/gridModel/useServerSideDataGridModel.tsx";
import {genericNullableValue} from "src/utils/genericNullableValue.ts";
import {MenuCheckbox} from "src/components/common/contextMenu/MenuCheckbox.tsx";
import {ControlledAsyncCrudDataGrid} from "src/components/common/dataGrid/crud/ControlledAsyncCrudDataGrid.tsx";
import {downloadFile} from "src/utils/fileDownloading.ts";

export interface ShopOrdersDataGridProps {
    onlyShopOrderId: number | undefined;
    onSelectionChanged: (shopOrder: ShopOrderView | undefined) => void;
}

export const ShopOrdersDataGrid = ({onlyShopOrderId, onSelectionChanged}: ShopOrdersDataGridProps) => {
    const showConfirmDialog = useConfirmDialog();
    const openLegacyView = useOpenLegacyView();

    const {dataGridProps, onlySelectedRow, refreshData, currentParams} = useServerSideDataGridModel({
        fetchData: (params) => ShopOrderDataGridApi.getData(params),
        getRowId: (row) => row.shopOrderId,
        onSelectionChanged: (rows) => onSelectionChanged(rows[0]),
        initialParams: {
            searchQuery: "",
            onlyDefaultSite: true,
            onlyShopOrderId: onlyShopOrderId,
            shopOrderStateFilter: genericNullableValue<ShopOrderState>(),
            includeCompleted: false,
            includeCancelled: false,
        },
        selectFirstRowOnLoad: onlyShopOrderId != null,
        gridId: "A8BE80CB37FC62C2",
    });

    return (
        <ControlledAsyncCrudDataGrid<ShopOrderView>
            columns={[
                integerColumn({
                    field: "shopOrderId",
                    headerName: i18n.t("number_shortened"),
                    width: 70,
                }),
                textColumn({field: "siteName", headerName: i18n.t("site_short"), width: 70}),
                enumColumn({
                    field: "shopOrderState",
                    headerName: i18n.t("state"),
                    enumLabels: getShopOrderStateLabels(),
                }),
                textColumn({field: "shopOrderBatchName", headerName: i18n.t("production_batch")}),
                textColumn({field: "productionLineName", headerName: i18n.t("production_line")}),
                textColumn({field: "sourceRefData", headerName: i18n.t("reference"), width: 200}),
                textColumn({
                    field: "configurationOrPartDescription",
                    headerName: i18n.t("part"),
                    width: 250,
                }),
                floatColumn({field: "quantity", headerName: i18n.t("quantity")}),
                textColumn({field: "partUnit", headerName: i18n.t("unit")}),
                textColumn({field: "packageInfo", headerName: i18n.t("packaging_info")}),
                textColumn({field: "shopOrderNote", headerName: i18n.t("note")}),
                integerColumn({field: "partRevision", headerName: i18n.t("revision")}),
                floatColumn({field: "capacityQuantity", headerName: i18n.t("capacity_quantity")}),
                dateColumn({field: "demandDate", headerName: i18n.t("demand_date")}),
                dateTimeColumn({
                    field: "plannedBeginDate",
                    headerName: i18n.t("planned_begin_date"),
                    width: 150,
                }),
                dateTimeColumn({
                    field: "plannedEndDate",
                    headerName: i18n.t("planned_end_date"),
                    width: 150,
                }),
                dateTimeColumn({
                    field: "creationDate",
                    headerName: i18n.t("created_at"),
                    width: 150,
                }),
                dateTimeColumn({
                    field: "workInstructionPrinted",
                    headerName: i18n.t("work_instruction_printed"),
                    width: 150,
                }),
                dateTimeColumn({
                    field: "releasedDate",
                    headerName: i18n.t("released_at"),
                    width: 150,
                }),
                dateTimeColumn({
                    field: "actualBeginDate",
                    headerName: i18n.t("actual_begin_date"),
                    width: 150,
                }),
                dateTimeColumn({
                    field: "actualEndDate",
                    headerName: i18n.t("actual_end_date"),
                    width: 150,
                }),
                textColumn({field: "createdBy", headerName: i18n.t("created_by")}),
                enumColumn({
                    field: "source",
                    headerName: i18n.t("source"),
                    enumLabels: getShopOrderSourceTypeLabels(),
                    width: 150,
                }),
                floatColumn({
                    field: "completedQuantity",
                    headerName: i18n.t("completed_quantity"),
                    width: 150,
                }),
                dateTimeColumn({field: "cancelledDate", headerName: i18n.t("cancelled_at")}),
            ]}
            actionBarComponents={
                <>
                    <DocumentsOfObjectButton
                        objectRef={nullableAavoObjectRef("SHOP_ORDER", onlySelectedRow?.shopOrderId)}
                    />
                    <AavoTextField
                        label={i18n.t("search")}
                        onSubmit={async (value) => {
                            await refreshData({searchQuery: value});
                        }}
                    />
                    <SelectField
                        label={i18n.t("state")}
                        options={ShopOrderStateValues}
                        getOptionKey={(o) => o}
                        getOptionLabel={(o) => getShopOrderStateLabel(o)}
                        onChange={(value) => refreshData({shopOrderStateFilter: value})}
                    />
                    <AsyncButton
                        label={i18n.t("snapshot")}
                        icon={faTh}
                        onClick={async () => {
                            openLegacyShopOrderStateView({
                                openLegacyView: openLegacyView,
                                shopOrderId: onlySelectedRow!.shopOrderId,
                            });
                        }}
                        variant={"outlined"}
                        disabled={onlySelectedRow == null}
                    />
                </>
            }
            actionBarMenuComponents={[
                <MenuCheckbox
                    key={"showCompleted"}
                    label={i18n.t("show_completed")}
                    checked={currentParams.includeCompleted}
                    onChange={(value) => refreshData({includeCompleted: value})}
                />,
                <MenuCheckbox
                    key={"showCancelled"}
                    label={i18n.t("show_cancelled")}
                    checked={currentParams.includeCancelled}
                    onChange={(value) => refreshData({includeCancelled: value})}
                />,
                <MenuCheckbox
                    key={"onlyDefaultSite"}
                    label={i18n.t("only_default_site")}
                    checked={currentParams.onlyDefaultSite}
                    onChange={async (value) => {
                        await refreshData({onlyDefaultSite: value});
                    }}
                />,
            ]}
            rowContextMenuComponents={({row}) => [
                <AsyncMenuButton
                    key={"release"}
                    label={i18n.t("release")}
                    icon={faShare}
                    onClick={async () => {
                        await releaseShopOrder({
                            shopOrderId: row.shopOrderId,
                            showConfirmDialog: showConfirmDialog,
                        });
                        await refreshData();
                    }}
                    hidden={row.shopOrderState !== "PLANNED"}
                />,
                <AsyncMenuButton
                    key={"revertRelease"}
                    label={i18n.t("revert_release")}
                    icon={faUndo}
                    onClick={async () => {
                        const confirmed = await showConfirmDialog({
                            title: i18n.t("revert_release"),
                            message: i18n.t("confirm_revert_release_shop_order"),
                        });
                        if (!confirmed) return;

                        await ShopOrderActionApi.revertReleaseShopOrder({
                            shopOrderId: row.shopOrderId,
                        });
                        await refreshData();
                    }}
                    hidden={row.shopOrderState !== "RELEASED"}
                />,
                <AsyncMenuButton
                    key={"close"}
                    label={i18n.t("close")}
                    icon={faCheck}
                    onClick={async () => {
                        await closeShopOrder({
                            shopOrderId: row.shopOrderId,
                            showConfirmDialog: showConfirmDialog,
                        });
                        await refreshData();
                    }}
                    hidden={row.shopOrderState !== "STARTED"}
                />,
                <AsyncMenuButton
                    key={"revertClose"}
                    label={i18n.t("revert_close")}
                    icon={faUndo}
                    onClick={async () => {
                        const confirmed = await showConfirmDialog({
                            title: i18n.t("revert_close"),
                            message: i18n.t("confirm_revert_close_shop_order"),
                        });
                        if (!confirmed) return;

                        await ShopOrderActionApi.revertCloseShopOrder({
                            shopOrderId: row.shopOrderId,
                        });
                        await refreshData();
                    }}
                    hidden={row.shopOrderState !== "READY"}
                />,
                <AsyncMenuButton
                    key={"showSurveys"}
                    label={i18n.t("show_surveys")}
                    icon={faStickyNote}
                    onClick={async () => {
                        const operations = await ShopOrderOperationApi.getShopOrderOperations({
                            shopOrderId: row.shopOrderId,
                        });
                        const objectRefs: AavoObjectRef[] = operations.map((o) => ({
                            objectType: "SHOP_ORDER_OPERATION",
                            objectId: o.shopOrderOperationId,
                        }));

                        openLegacySurveysOfSourcesWithDocumentView({
                            openLegacyView: openLegacyView,
                            objectRefs: objectRefs,
                        });
                    }}
                />,
                <AsyncMenuButton
                    key={"printSurveys"}
                    label={i18n.t("print_all_surveys")}
                    icon={faFileDownload}
                    onClick={async () => {
                        const fileHandle = await ShopOrderPrintApi.printSurveysOfShopOrderOperations({
                            shopOrderId: row.shopOrderId,
                        });
                        downloadFile(fileHandle);
                    }}
                />,
                row.shopOrderBatchId != null && (
                    <AsyncMenuButton
                        key={"showProductionBatch"}
                        label={i18n.t("show_production_batch")}
                        icon={faCubes}
                        onClick={async () => {
                            openLegacySingleShopOrderBatchContainerView({
                                openLegacyView: openLegacyView,
                                shopOrderBatchId: row.shopOrderBatchId!,
                            });
                        }}
                    />
                ),
                row.partConfigurationId != null && (
                    <OpenPartConfigurationButton
                        key={"configuration"}
                        partConfigurationId={row.partConfigurationId}
                    />
                ),
                <AsyncMenuButton
                    key={"cancel"}
                    label={i18n.t("cancel")}
                    icon={faBan}
                    onClick={async () => {
                        await cancelShopOrder({
                            shopOrderId: row.shopOrderId,
                            showConfirmDialog: showConfirmDialog,
                        });
                        await refreshData();
                    }}
                    disabled={row.shopOrderState === "CANCELLED" || row.shopOrderState === "READY"}
                />,
                <OpenCustomerOrderInfoButton
                    key={"customerOrderInfo"}
                    customerOrderId={row.customerOrderId}
                />,
            ]}
            form={{
                addRowEnabled: true,
                editEnabled: true,
                dialogSize: "md",
                dialogTitle: i18n.t("shop_order"),
                component: ({row, ...other}) => (
                    <ShopOrderForm shopOrderId={row?.shopOrderId ?? null} {...other} />
                ),
            }}
            {...dataGridProps}
        />
    );
};
