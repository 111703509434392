import {JobQueueView} from "src/components/views/erp/production/jobQueue/base/JobQueueView.tsx";
import {
	WorkCenterJobQueueInitialsApi
} from "src/api/generated/erp/production/api/jobQueues/workCenter/workCenterJobQueueInitialsApi.ts";
import {
	WorkCenterJobQueueInProgressApi
} from "src/api/generated/erp/production/api/jobQueues/workCenter/workCenterJobQueueInProgressApi.ts";
import {
	WorkCenterJobQueueCompletedApi
} from "src/api/generated/erp/production/api/jobQueues/workCenter/workCenterJobQueueCompletedApi.ts";
import {AsyncButton} from "src/components/common/buttons/AsyncButton.tsx";
import i18n from "i18next";
import {faCheckDouble} from "@fortawesome/pro-regular-svg-icons";
import {
	ShopOrderOperationStateTransferApi
} from "src/api/generated/erp/production/api/jobQueues/shopOrderOperationStateTransferApi.ts";
import {
	SelectedOperationActionsViewExtraComponentProps
} from "src/components/views/erp/production/jobQueue/base/inProgressView/SelectedOperationActionsView.tsx";
import {useJobQueueViewContext} from "src/components/views/erp/production/jobQueue/base/JobQueueViewContext.ts";

export const WorkcenterJobQueueView = () => {
	return (
		<JobQueueView
			viewId={"workcenterJobQueueView"}
			initialsFetchData={WorkCenterJobQueueInitialsApi.getInitialOperations}
			inProgressFetchData={WorkCenterJobQueueInProgressApi.getInProgressOperations}
			completedFetchData={WorkCenterJobQueueCompletedApi.getCompletedOperations}
			inProgressExtraActionBarComponents={(params) => <InProgressViewExtraComponents {...params} />}
		/>
	);
};

const InProgressViewExtraComponents = ({
	selectedOperation,
}: SelectedOperationActionsViewExtraComponentProps) => {

	const { inProgressViewRefreshRef, completedViewRefreshRef } = useJobQueueViewContext();

	return (
		<AsyncButton
			label={i18n.t("complete")}
			icon={faCheckDouble}
			variant={"outlined"}
			onClick={async () => {
				await ShopOrderOperationStateTransferApi.completeOperations({
					shopOrderOperationIds: [selectedOperation.shopOrderOperationId],
				});
				await Promise.all([inProgressViewRefreshRef.refresh(), completedViewRefreshRef.refresh()]);
			}}
		/>
	);
};
