// This file is automatically generated. Do not edit manually.

import { WarehousePickingObjectLocationView } from "src/api/generated/erp/db/types/tables/warehousePickingObjectLocationView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace CustomerOrderPickingApi {
	export async function decreasePackageLineQuantity(args: {
		customerOrderPackageLineId: number;
		quantityInWarehouseUnit: number;
		putBackPartWarehouseLocationId: number | null | undefined;
	}): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/picking/customerOrderPicking/decreasePackageQuantity",
			method: "POST",
			data: {
				customerOrderPackageLineId: args.customerOrderPackageLineId,
				quantityInWarehouseUnit: args.quantityInWarehouseUnit,
				putBackPartWarehouseLocationId: args.putBackPartWarehouseLocationId,
			},
		});
		return response as void;
	}

	export async function getCustomerOrderLinePickingLocations(args: {
		customerOrderLineId: number;
	}): Promise<Array<WarehousePickingObjectLocationView>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/picking/customerOrderPicking/getCustomerOrderLinePickingLocations",
			method: "POST",
			data: {
				customerOrderLineId: args.customerOrderLineId,
			},
		});
		return response as Array<WarehousePickingObjectLocationView>;
	}
}
