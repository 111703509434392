// This file is automatically generated. Do not edit manually.

import { AavoObjectRef } from "src/api/generated/common/sourceType/aavoObjectRef";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace OpenSurveyApi {
	export async function getOpenSurveyActionRequests(args: {
		surveyConfigurationId: number;
		sourceRef: AavoObjectRef;
		surveyInfo?: string;
		surveyLocked?: boolean;
	}): Promise<Array<any>> {
		const response = await makeAxiosRequest({
			url: "/api/ext/surveys/getOpenSurveyActionRequests",
			method: "POST",
			data: {
				surveyConfigurationId: args.surveyConfigurationId,
				sourceRef: args.sourceRef,
				surveyInfo: args.surveyInfo,
				surveyLocked: args.surveyLocked,
			},
		});
		return response as Array<any>;
	}
}
