import { PartialTenantCustomizations } from "src/tenantCustomizations/TenantCustomizations.ts";
import { getSalvosTaskContextMenuComponents } from "src/tenantCustomizations/tenants/salvos/GetSalvosTaskContextMenuComponents.tsx";
import i18n from "i18next";
import { SalvosCustomerOrderContextMenuComponents } from "src/tenantCustomizations/tenants/salvos/SalvosCustomerOrderContextMenuComponents.tsx";
import { getSalvosCustomerOrderDataGridCustomColumns } from "src/tenantCustomizations/tenants/salvos/salvosCustomerOrderDataGridCustomColumns.tsx";
import { getCustomerOrderTenantStateSpcSamplingMethod } from "src/tenantCustomizations/tenants/salvos/spcSamplingMethods/customerOrderTenantStateSpcSamplingMethod.tsx";
import { getCompletedFromCustomerOrderTenantStateSpcSamplingMethod } from "src/tenantCustomizations/tenants/salvos/spcSamplingMethods/completedFromCustomerOrderTenantStateSpcSamplingMethod.tsx";
import { getSubProjectDurationSpcSamplingMethod } from "src/tenantCustomizations/tenants/salvos/spcSamplingMethods/subProjectDurationSpcSamplingMethod.tsx";
import { getProjectActivityDurationSpcSamplingMethod } from "src/tenantCustomizations/tenants/salvos/spcSamplingMethods/projectActivityDurationSpcSamplingMethod.tsx";
import { SalvosProjectCustomizations } from "src/tenantCustomizations/tenants/salvos/project/SalvosProjectCustomizations.tsx";

export const salvosCustomizations: PartialTenantCustomizations = {
	tenantConfig: {
		erp: {
			enabled: true,
			ledgerIntegrationEnabled: true,
			useCustomerOrderTenantStates: true,
			customerOrderTenantStates: [
				{ key: "initial", label: "Alustava" },
				{ key: "checked", label: "Tarkistettu" },
				{ key: "confirmed", label: "Vahvistettu" },
				{ key: "first_phase_planning", label: "Alkupään suunnittelussa" },
				{ key: "awaiting_building_licence", label: "Odottaa rakennuslupaa" },
				{ key: "building_licence_received", label: "Rakennuslupa saatu" },
				{ key: "delivery_date_agreed", label: "Toimituspäivä sovittu" },
				{ key: "second_phase_planning", label: "Loppupään suunnittelussa" },
				{ key: "ready_for_manufacturing", label: "Tuotantokelpoinen" },
				{ key: "on_manufacturing", label: "Tuotannossa" },
				{ key: "ready_for_delivery", label: "Toimitusvalmis" },
				{ key: "delivered", label: "Toimitettu" },
				{ key: "completed", label: "Valmis" },
				{ key: "cancelled", label: "Peruttu" },
			],
			customerOrderTenantStateColumnLabel: i18n.t("salvos_state"),
			customerOrderCheckingEnabled: false,
			customerOrderFreezingEnabled: true,
			customerOrderCommentingEnabled: false,
			reclamationsEnabled: true,
			customerOrderPreLineImportEnabled: true,
			configuratorEnabled: true,
			inputPricesWithVatByDefault: true,
			defaultCustomerType: "CONSUMER",
			projectActivitySchedulingEnabled: true,
		},
		tasks: {
			enabled: true,
		},
		maps: {
			enabled: true,
		},
		documentsEnabled: true,
	},
	taskContextMenuComponents: ({ task }) => getSalvosTaskContextMenuComponents(task),
	spcSamplingMethods: ({ tenantConfig }) => [
		getCustomerOrderTenantStateSpcSamplingMethod(tenantConfig),
		getCompletedFromCustomerOrderTenantStateSpcSamplingMethod(tenantConfig),
		getSubProjectDurationSpcSamplingMethod(),
		getProjectActivityDurationSpcSamplingMethod(),
	],
	erp: {
		project: {
			subProjectContextMenuComponents: SalvosProjectCustomizations.SubProjectContextMenuComponents,
			createSubProjectFormComponents: SalvosProjectCustomizations.CreateSubProjectFormComponents,
		},
		sales: {
			customerOrderContextMenuComponents: SalvosCustomerOrderContextMenuComponents,
			customerOrderDataGridCustomColumns: getSalvosCustomerOrderDataGridCustomColumns,
		}
	},
};
