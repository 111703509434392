import { VerticalBox } from "src/components/common/box/VerticalBox.tsx";
import { Box } from "@mui/material";
import React from "react";
import { TransformedConfigurationComponent_Tab } from "src/api/generated/erp/configurator/componentTransformation/transformedConfigurationComponent.ts";

export interface ConfiguratorTabComponentProps {
	tabComponent: TransformedConfigurationComponent_Tab;
	children: React.ReactNode;
}

export const ConfiguratorTabComponent = ({
	tabComponent,
	children,
}: ConfiguratorTabComponentProps) => {
	return (
		<VerticalBox flex={1}>
			<Box flex={1} overflow="auto">
				<Box
					sx={{
						gap: 2,
						flex: 1,
						padding: 1,
						paddingTop: 2,
						alignItems: "stretch",
						display: {
							xs: "flex",
							sm: "flex",
							md: "grid",
						},
						flexDirection: "column",
						gridTemplateColumns: `repeat(${tabComponent.columnCount ?? 1}, 1fr)`,
					}}
				>
					{children}
				</Box>
			</Box>
		</VerticalBox>
	);
};
