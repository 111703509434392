import i18n from "i18next";
import { faFileInvoice } from "@fortawesome/pro-regular-svg-icons";
import { AsyncButton, AsyncButtonProps } from "src/components/common/buttons/AsyncButton.tsx";
import { useGenericDialog } from "src/components/common/dialogs/useGenericDialog.ts";
import { ShopOrdersView } from "src/components/views/erp/production/shopOrder/ShopOrdersView.tsx";

export interface OpenShopOrderButtonProps extends Omit<AsyncButtonProps, "onClick"> {
	shopOrderId: number;
}

export const OpenShopOrderButton = ({
	shopOrderId,
	variant = "menu",
	...props
}: OpenShopOrderButtonProps) => {
	const { openDialog } = useGenericDialog();

	return (
		<AsyncButton
			label={i18n.t("open_shop_order")}
			icon={faFileInvoice}
			variant={variant}
			onClick={() => {
				openDialog({
					title: i18n.t("shop_order"),
					content: <ShopOrdersView onlyShopOrderId={shopOrderId} />,
				});
			}}
			{...props}
		/>
	);
};
