// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const ShopOrderSourceTypeValues = [
	"MANUAL",
	"CUSTOMER_ORDER_LINE",
	"SHOP_ORDER_LINE",
	"REORDER_POINT",
	"WAREHOUSE_REPLENISHMENT",
] as const;

export type ShopOrderSourceType = (typeof ShopOrderSourceTypeValues)[number];

export const getShopOrderSourceTypeLabels = () => ({
	MANUAL: t("manual"),
	CUSTOMER_ORDER_LINE: t("customer_order_line"),
	SHOP_ORDER_LINE: t("shop_order_line"),
	REORDER_POINT: t("reorder_point"),
	WAREHOUSE_REPLENISHMENT: t("warehouse_replenishment"),
});

export const getShopOrderSourceTypeLabel = (value: ShopOrderSourceType): string => {
	return getShopOrderSourceTypeLabels()[value];
};
