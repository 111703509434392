import { CustomerOrderDeliveriesDataGridBase } from "./CustomerOrderDeliveriesDataGridBase";

export const AllCustomerOrderDeliveriesDataGrid = () => {
	return (
		<CustomerOrderDeliveriesDataGridBase
			showCustomerFilter
			showSearchField
			showOnlyUndeliveredByDefault
		/>
	);
};
