// This file is automatically generated. Do not edit manually.

import { CustomerOrderDelivery } from "src/api/generated/erp/db/types/tables/customerOrderDelivery";
import { CargoDriver } from "src/api/generated/erp/db/types/tables/cargoDriver";
import { CustomerOrderView } from "src/api/generated/erp/db/types/tables/customerOrderView";
import { CustomerOrderDeliveryView } from "src/api/generated/erp/db/types/tables/customerOrderDeliveryView";
import { TransportRoute } from "src/api/generated/erp/db/types/tables/transportRoute";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace CustomerOrderDeliveryEditApi {
	export async function getFormInitData(args: {
		customerOrderId: number;
		customerOrderDeliveryId: number | null | undefined;
	}): Promise<CustomerOrderDeliveryEditApi_FormInitData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/delivery/getFormInitData",
			method: "POST",
			data: {
				customerOrderId: args.customerOrderId,
				customerOrderDeliveryId: args.customerOrderDeliveryId,
			},
		});
		return response as CustomerOrderDeliveryEditApi_FormInitData;
	}

	export async function insert(args: { customerOrderDelivery: CustomerOrderDelivery }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/delivery/insert",
			method: "POST",
			data: {
				customerOrderDelivery: args.customerOrderDelivery,
			},
		});
		return response as number;
	}

	export async function update(args: { customerOrderDelivery: CustomerOrderDelivery }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/delivery/update",
			method: "POST",
			data: {
				customerOrderDelivery: args.customerOrderDelivery,
			},
		});
		return response as void;
	}

	export async function delete_(args: { customerOrderDeliveryIds: Array<number> }): Promise<void> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/sales/delivery/delete",
			method: "POST",
			data: {
				customerOrderDeliveryIds: args.customerOrderDeliveryIds,
			},
		});
		return response as void;
	}
}

export interface CustomerOrderDeliveryEditApi_FormInitData {
	cargoDriverOptions: Array<CargoDriver>;
	customerOrder: CustomerOrderView;
	customerOrderDelivery: CustomerOrderDeliveryView | null | undefined;
	transportRouteOptions: Array<TransportRoute>;
}
