import { SpcSamplingMethod } from "src/components/views/spc/basedata/controlCharts/form/samplingMethod/SpcSamplingMethod.tsx";
import i18n from "i18next";
import { FormLazySelectField } from "src/components/common/forms/fields/FormLazySelectField.tsx";
import { ProjectActivityApi } from "src/api/generated/erp/project/projectActivity/api/projectActivityApi.ts";
import {requireRule} from "src/components/common/forms/validation.ts";

export const getProjectActivityDurationSpcSamplingMethod = (): SpcSamplingMethod => {
	return {
		key: "project_activity_duration",
		label: i18n.t("project_activity_duration"),
		renderParameterFields: ({ control }) => (
			<>
				<FormLazySelectField
					control={control}
					name={"samplingParams.param1"}
					label={i18n.t("activity_type")}
					fetchOptions={ProjectActivityApi.getProjectActivityTypes}
					getOptionKey={(o) => o.activityTypeId.toString()}
					getOptionLabel={(o) => o.activityTypeName}
					rules={requireRule()}
				/>
			</>
		),
	};
};
