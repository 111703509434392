import React, { useState, Dispatch, SetStateAction } from "react";
import { useStoredState } from "src/utils/useStoredState.ts";

export const usePartiallyStoredState = <T extends object>({
	initialState,
	storedKeys,
	key,
}: {
	initialState: T;
	storedKeys: Array<keyof T>;
	key: string;
}): [state: T, setState: React.Dispatch<React.SetStateAction<T>>] => {
	const [storedState, setStoredState] = useStoredState(initialState, key);
	const [localState, setLocalState] = useState(initialState);

	const combinedState = { ...localState };
	for (const storedKey of storedKeys) {
		combinedState[storedKey] = storedState[storedKey];
	}

	const setState: Dispatch<SetStateAction<T>> = (valueOrFunction) => {
		setLocalState(valueOrFunction);
		// It doesn't matter if non-stored keys are updated in stored state,
		// because extra keys are not used when states are combined.
		setStoredState(valueOrFunction);
	};

	return [combinedState, setState];
};
