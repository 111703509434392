import { FormCommonProps } from "../../../../common/forms/types.ts";
import { WarehousePickingObjectLocationView } from "src/api/generated/erp/db/types/tables/warehousePickingObjectLocationView.ts";
import { AavoForm } from "../../../../common/forms/AavoForm.tsx";
import { CustomerOrderPickingApi } from "src/api/generated/erp/sales/picking/api/customerOrderPickingApi.ts";
import { FormSelectField } from "../../../../common/forms/fields/FormSelectField.tsx";
import i18n from "i18next";
import { FormTextField } from "../../../../common/forms/fields/FormTextField.tsx";
import { requiredNonNegativeFloatRule } from "../../../../common/forms/validation.ts";

export interface DecreaseCustomerOrderPackageLineQuantityFormProps extends FormCommonProps<void> {
	customerOrderPackageLineId: number;
	locationOptions: WarehousePickingObjectLocationView[];
	packageLineQuantity: number;
}

interface FormValues {
	partWarehouseLocationId: number | null;
	quantityInWarehouseUnits: number;
}

export const DecreaseCustomerOrderPackageLineQuantityForm = ({
	customerOrderPackageLineId,
	locationOptions,
	packageLineQuantity,
	onCompleted,
	onFormEdited,
}: DecreaseCustomerOrderPackageLineQuantityFormProps) => {
	return (
		<AavoForm<FormValues>
			defaultValues={{
				partWarehouseLocationId: locationOptions[0]?.partWarehouseLocationId ?? undefined,
				quantityInWarehouseUnits: packageLineQuantity,
			}}
			submit={async ({ partWarehouseLocationId, quantityInWarehouseUnits }: FormValues) => {
				await CustomerOrderPickingApi.decreasePackageLineQuantity({
					customerOrderPackageLineId: customerOrderPackageLineId,
					putBackPartWarehouseLocationId: partWarehouseLocationId,
					quantityInWarehouseUnit: quantityInWarehouseUnits,
				});
			}}
			onCompleted={onCompleted}
			onFormEdited={onFormEdited}
			render={({ control }) => (
				<>
					<FormSelectField
						control={control}
						name={"partWarehouseLocationId"}
						label={i18n.t("return_warehouse_location")}
						options={locationOptions}
						getOptionKey={(location) => location.partWarehouseLocationId}
						getOptionLabel={(location) => location.locationCode ?? ""}
					/>
					<FormTextField
						control={control}
						name={"quantityInWarehouseUnits"}
						rules={requiredNonNegativeFloatRule()}
					/>
				</>
			)}
		/>
	);
};
