// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const ProjectActivityMaterialAcquisitionMethodValues = ["PURCHASE_TRANSIT", "PURCHASE_DIRECT"] as const;

export type ProjectActivityMaterialAcquisitionMethod = (typeof ProjectActivityMaterialAcquisitionMethodValues)[number];

export const getProjectActivityMaterialAcquisitionMethodLabels = () => ({
	PURCHASE_TRANSIT: t("purchase_transit"),
	PURCHASE_DIRECT: t("purchase_direct"),
});

export const getProjectActivityMaterialAcquisitionMethodLabel = (
	value: ProjectActivityMaterialAcquisitionMethod,
): string => {
	return getProjectActivityMaterialAcquisitionMethodLabels()[value];
};
