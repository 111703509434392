import { AavoMosaic } from "src/components/common/mosaic/AavoMosaic";
import i18n from "i18next";
import { TestConfigurationResultPropertiesWithLogView } from "src/components/views/erp/configurator/configuratorTesting/TestConfigurationResultPropertiesWithLogView.tsx";
import { TestConfigurationTopLevelResultsWithLogView } from "../TestConfigurationTopLevelResultsWithLogView";
import { TestConfigurationResultViewCommonProps } from "src/components/views/erp/configurator/configuratorTesting/TestConfigurationResultViewCommonProps.ts";
import { SitePartTestConfigurationBomWithLogView } from "src/components/views/erp/configurator/configuratorTesting/sitePart/SitePartTestConfigurationBomWithLogView.tsx";
import { TestConfigurationDocumentResultsWithLogView } from "src/components/views/erp/configurator/configuratorTesting/documents/TestConfigurationDocumentResultsWithLogView.tsx";
import { TestConfigurationAttributeResultsWithLogView } from "src/components/views/erp/configurator/configuratorTesting/attributes/TestConfigurationDocumentResultsWithLogView.tsx";
import {
	SitePartTestConfigurationOperationsWithLogView
} from "src/components/views/erp/configurator/configuratorTesting/sitePart/SitePartTestConfigurationOperationsWithLogViewö.tsx";

export const SitePartTestConfiguratorResultsView = (
	props: TestConfigurationResultViewCommonProps,
) => {
	return (
		<AavoMosaic
			viewId={"CD15D4F181643938E"}
			layout={{
				type: "tab",
				items: [
					{
						type: "panel",
						title: i18n.t("configuration"),
						content: <TestConfigurationResultPropertiesWithLogView {...props} />,
					},
					{
						type: "panel",
						title: i18n.t("top_level_info"),
						content: <TestConfigurationTopLevelResultsWithLogView {...props} />,
					},
					{
						type: "panel",
						title: i18n.t("bom"),
						content: <SitePartTestConfigurationBomWithLogView {...props} />,
					},
					{
						type: "panel",
						title: i18n.t("operations"),
						content: <SitePartTestConfigurationOperationsWithLogView {...props} />,
					},
					{
						type: "panel",
						title: i18n.t("documents"),
						content: <TestConfigurationDocumentResultsWithLogView {...props} />,
					},
					{
						type: "panel",
						title: i18n.t("attributes"),
						content: <TestConfigurationAttributeResultsWithLogView {...props} />,
					},
				],
			}}
		/>
	);
};
