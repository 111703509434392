import {ProjectActivityMaterialLinesDataGridBase} from "./ProjectActivityMaterialLinesDataGridBase";
import {
	ProjectActivityMaterialsDataGridApi
} from "src/api/generated/erp/project/projectActivity/api/projectActivityMaterialsDataGridApi.ts";

export interface ProjectAllActivityMaterialLinesDataGridProps {
	projectId: number;
}

export const ProjectAllActivityMaterialLinesDataGrid = ({
	projectId,
}: ProjectAllActivityMaterialLinesDataGridProps) => {
	return (
		<ProjectActivityMaterialLinesDataGridBase
			gridId={"D362AAECDED2C493"}
			fetchData={({ showCancelledLines, dataModelRequest }) =>
				ProjectActivityMaterialsDataGridApi.getDataGridDataForProject({
					projectId: projectId,
					cancelledLines: showCancelledLines,
					dataModelRequest: dataModelRequest,
				})
			}
			showActivityColumn
		/>
	);
};
