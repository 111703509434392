import { DocumentsOfSingleObjectView } from "src/components/views/documents/objectDocuments/DocumentsOfSingleObjectView.tsx";
import i18n from "i18next";
import { DocumentConnectionView } from "src/api/generated/postgres/db/types/documents/tables/documentConnectionView.ts";
import { useState } from "react";
import { CatalogPartRevisionView } from "src/api/generated/erp/db/types/tables/catalogPartRevisionView.ts";
import { CenteredTypography } from "src/components/common/CenteredTypography.tsx";
import { CatalogPartRevisionDocumentConfigurationRulesView } from "src/components/views/erp/configurator/managing/catalogPart/CatalogPartRevisionDocumentConfigurationRulesView.tsx";
import { useConfirmDialog } from "src/components/common/dialogs/confirmDialog/useConfirmDialog.ts";
import { confirmUnsavedChangesWillBeLost } from "src/components/common/dialogs/confirmDialog/confirmDialogUtils.ts";

export interface CatalogPartRevisionDocumentsViewProps {
	catalogPartRevision: CatalogPartRevisionView;
}

export const CatalogPartRevisionDocumentsView = ({
	catalogPartRevision,
}: CatalogPartRevisionDocumentsViewProps) => {
	const [selectedDocument, setSelectedDocument] = useState<DocumentConnectionView | null>(null);
	const [rulesFormIsDirty, setRulesFormIsDirty] = useState(false);

	const showConfirmDialog = useConfirmDialog();

	const setSelectedDocumentWrapped = async (doc: DocumentConnectionView | null) => {
		if (rulesFormIsDirty) {
			const confirmed = await confirmUnsavedChangesWillBeLost(showConfirmDialog);
			if (!confirmed) return;
		}
		setRulesFormIsDirty(false);
		setSelectedDocument(doc);
	};

	return (
		<DocumentsOfSingleObjectView
			objectRef={{
				objectType: "CATALOG_PART_REVISION",
				objectId: catalogPartRevision.catalogPartRevisionId,
			}}
			viewId={"D19C10F84BD5E65B"}
			selectedDocument={selectedDocument}
			setSelectedDocument={setSelectedDocumentWrapped}
			extraViews={[
				{
					type: "panel",
					hidden: !catalogPartRevision.isConfigurable,
					title: i18n.t("configurator_rules"),
					content:
						selectedDocument == null ?
							<CenteredTypography>{i18n.t("select_a_document")}</CenteredTypography>
						:	<CatalogPartRevisionDocumentConfigurationRulesView
								key={selectedDocument.documentId}
								document={selectedDocument}
								catalogPartRevision={catalogPartRevision}
								onDirtyStateChanged={setRulesFormIsDirty}
							/>,
				},
			]}
		/>
	);
};
