import { ConfigurationComponent } from "src/api/generated/erp/db/types/tables/configurationComponent.ts";
import { ConfiguratorFieldComponentPreview } from "src/components/views/erp/configurator/managing/productFamilyVersions/components/componentPreview/impl/ConfiguratorFieldComponentPreview.tsx";
import { HorizontalBox } from "src/components/common/box/HorizontalBox.tsx";
import { SubConfiguratorComponentPreview } from "src/components/views/erp/configurator/managing/productFamilyVersions/components/componentPreview/impl/SubConfiguratorComponentPreview.tsx";
import { mergeSx } from "src/utils/styles.ts";
import { useDrag, useDrop } from "react-dnd";
import { Box } from "@mui/material";
import {
	CONFIGURATOR_COMPONENT_DND_KEY,
	ConfiguratorComponentDragItem,
} from "src/components/views/erp/configurator/managing/productFamilyVersions/components/configuratorComponentDnd.ts";
import { useContextOrThrow } from "src/utils/useContextOrThrow.tsx";
import { ProductFamilyVersionManagingViewContext } from "src/components/views/erp/configurator/managing/productFamilyVersions/ProductFamilyVersionManagingViewContext.tsx";
import { useContextMenu } from "src/components/common/contextMenu/useContextMenu.ts";
import { AavoContextMenu } from "src/components/common/contextMenu/AavoContextMenu.tsx";
import { ConfiguratorComponentPreviewContextMenu } from "src/components/views/erp/configurator/managing/productFamilyVersions/components/componentPreview/ConfiguratorComponentPreviewContextMenu.tsx";
import { logError } from "src/errorHandling/errorLogging.ts";
import { SectionBreakComponentPreview } from "src/components/views/erp/configurator/managing/productFamilyVersions/components/componentPreview/impl/SectionBreakComponentPreview.tsx";
import {
	LibraryComponentReferencePreview
} from "src/components/views/erp/configurator/managing/productFamilyVersions/components/componentPreview/impl/LibraryComponentReferencePreview.tsx";

export interface ConfiguratorComponentPreviewProps {
	layoutColumnCount: number;
	component: ConfigurationComponent;
}

export const ConfiguratorComponentPreview = (props: ConfiguratorComponentPreviewProps) => {
	const { component, layoutColumnCount } = props;
	return (
		<HorizontalBox
			sx={
				component.spanAllColumns ?
					{ gridColumn: "1 / -1" }
				:	{
						gridColumnStart: component.placeOnNewRow ? `1` : "auto",
						gridColumnEnd: `span ${Math.min(component.columnSpan, layoutColumnCount)}`,
					}
			}
		>
			<ComponentDropArea {...props} type="before" />
			<ComponentWrapper {...props} />
			<ComponentDropArea {...props} type="after" fillSpace />
		</HorizontalBox>
	);
};

const ComponentWrapper = ({ component }: ConfiguratorComponentPreviewProps) => {
	const { selectedComponents, selectComponent } = useContextOrThrow(ProductFamilyVersionManagingViewContext);

	const [openContextMenu, contextMenuState] = useContextMenu();

	const [{ isDragging }, dragRef] = useDrag<
		ConfiguratorComponentDragItem,
		unknown,
		{ isDragging: boolean }
	>(
		() => ({
			type: CONFIGURATOR_COMPONENT_DND_KEY,
			item: {
				type: "existingComponent",
				componentId: component.configurationComponentId,
				currentPosition: component.orderNum,
			},
			canDrag: selectedComponents.length < 2,
			collect: (monitor) => ({
				isDragging: monitor.isDragging(),
			}),
		}),
		[selectedComponents, component],
	);

	const isSelected = selectedComponents
		.map((c) => c.configurationComponentId)
		.includes(component.configurationComponentId);

	const spanHorizontally = !(component.componentType === "FIELD" && component.fieldType === "CHECKBOX");

	return (
		<>
			<HorizontalBox
				ref={dragRef}
				onClick={(e) =>
					selectComponent({
						component,
						keepExistingSelections: e.ctrlKey,
					})
				}
				onContextMenu={(e) => {
					if (selectedComponents.length < 2) {
						selectComponent({
							component,
							keepExistingSelections: false,
						});
					}
					openContextMenu({
						mouseEvent: e,
						content: <ConfiguratorComponentPreviewContextMenu component={component} />,
					});
				}}
				sx={mergeSx(
					{
						flex: spanHorizontally ? "1 1 100%" : "0 0 auto",
						alignSelf: "center",
						alignItems: "stretch",
						"& *": {
							pointerEvents: "none",
						},
						transition: "background-color 0.3s",
					},
					isSelected ?
						{
							backgroundColor: "secondary.light",
							borderRadius: "4px",
						}
					:	{
							":hover": {
								backgroundColor: "grey.200",
							},
						},
					isDragging && {
						opacity: 0.8,
					},
				)}
			>
				<Content component={component} />
			</HorizontalBox>
			<AavoContextMenu state={contextMenuState} />
		</>
	);
};

const Content = ({ component }: { component: ConfigurationComponent }) => {
	switch (component.componentType) {
		case "FIELD":
			return <ConfiguratorFieldComponentPreview component={component} />;
		case "SUB_CONFIGURATOR":
			return <SubConfiguratorComponentPreview component={component} />;
		case "SUB_CONFIGURATOR_LIST":
			return <SubConfiguratorComponentPreview component={component} />;
		case "SECTION_BREAK":
			return <SectionBreakComponentPreview component={component} />;
		case "LIBRARY_COMPONENT_REFERENCE":
			return <LibraryComponentReferencePreview component={component} />;
		case "TAB": {
			logError("Tab component should not be rendered in ConfiguratorComponentPreview");
			return null;
		}
	}
};

interface ComponentDropAreaProps extends ConfiguratorComponentPreviewProps {
	fillSpace?: boolean;
	type: "before" | "after";
}

const ComponentDropArea = ({ component, fillSpace, type: dropAreaType }: ComponentDropAreaProps) => {
	const { onComponentDropped } = useContextOrThrow(ProductFamilyVersionManagingViewContext);

	const onDrop = (item: ConfiguratorComponentDragItem) => {
		onComponentDropped({
			dragItem: item,
			parentTabComponentId: component.parentTabComponentId ?? undefined,
			targetPosition: resolveDropTargetPosition(item)
		});
	};

	const [{ isDraggingOver }, dropRef] = useDrop<
		ConfiguratorComponentDragItem,
		unknown,
		{
			isDraggingOver: boolean;
		}
	>(
		() => ({
			accept: CONFIGURATOR_COMPONENT_DND_KEY,
			drop: onDrop,
			collect: (monitor) => {
				return {
					isDraggingOver: monitor.isOver(),
				};
			},
		}),
		[onDrop],
	);

	return (
		<Box
			ref={dropRef}
			sx={{
				backgroundColor: "secondary.main",
				borderRadius: 1,
				width: isDraggingOver ? "3rem" : "1.5rem",
				opacity: isDraggingOver ? 0.8 : 0,
				transitionDuration: "0.1s",
				flexGrow: fillSpace ? 1 : 0,
			}}
		/>
	);

	function resolveDropTargetPosition(item: ConfiguratorComponentDragItem) {
		const dropTargetPosition = component.orderNum;
		const draggedComponentPosition = item.type === "existingComponent" ? item.currentPosition : undefined;
		const isCurrentlyAboveDropTarget =
			draggedComponentPosition != null && draggedComponentPosition < dropTargetPosition;

		switch (dropAreaType) {
			case "before": {
				return isCurrentlyAboveDropTarget ? dropTargetPosition - 1 : dropTargetPosition;
			}
			case "after":
				return isCurrentlyAboveDropTarget ? dropTargetPosition : dropTargetPosition + 1;
		}
	}
};
