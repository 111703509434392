// This file is automatically generated. Do not edit manually.

import { ServerSideDataModelRequest } from "src/api/generated/common/dataGrids/serverSideDataModelRequest";
import { ServerSideDataModelResult } from "src/api/generated/common/dataGrids/serverSideDataModelResult";
import { ProductionLineOperationShopOrdersView } from "src/api/generated/erp/db/types/tables/productionLineOperationShopOrdersView";
import { Workcenter } from "src/api/generated/erp/db/types/tables/workcenter";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ProductionLineJobQueueCompletedApi {
	export async function getCompletedOperations(args: {
		includeCompletedShopOrders?: boolean;
		dataModelRequest: ServerSideDataModelRequest;
	}): Promise<ProductionLineJobQueueCompletedApi_CompletedOperationsDataGridData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/production/productionLineJobQueue/getCompletedOperations",
			method: "POST",
			data: {
				includeCompletedShopOrders: args.includeCompletedShopOrders,
				dataModelRequest: args.dataModelRequest,
			},
		});
		return response as ProductionLineJobQueueCompletedApi_CompletedOperationsDataGridData;
	}
}

export interface ProductionLineJobQueueCompletedApi_CompletedOperationsDataGridData {
	dataModelResult: ServerSideDataModelResult<ProductionLineOperationShopOrdersView>;
	workCenter: Workcenter;
}
