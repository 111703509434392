import { AavoMosaic } from "src/components/common/mosaic/AavoMosaic.tsx";
import i18n from "i18next";
import { useState } from "react";
import { PartCategory } from "src/api/generated/erp/db/types/tables/partCategory.ts";
import { useRefreshRef } from "src/utils/useRefreshRef.ts";
import { CatalogPartsDataGrid } from "src/components/views/erp/parts/catalogPart/CatalogPartsDataGrid.tsx";
import { CatalogPartRevisionsDataGrid } from "src/components/views/erp/parts/catalogPart/CatalogPartRevisionsDataGrid.tsx";
import { CenteredTypography } from "src/components/common/CenteredTypography.tsx";
import { CatalogPartSitesDataGrid } from "src/components/views/erp/parts/catalogPart/CatalogPartSitesDataGrid.tsx";
import { CatalogPartAttributesForm } from "src/components/views/erp/parts/catalogPart/CatalogPartAttributesForm.tsx";
import { CatalogPartReferencesDataGrid } from "src/components/views/erp/parts/catalogPart/CatalogPartReferencesDataGrid.tsx";
import { CatalogPartWorkspacesDataGrid } from "src/components/views/erp/parts/catalogPart/CatalogPartWorkspacesDataGrid.tsx";
import { PartSelectionPartCategoryTreeView } from "src/components/views/erp/parts/PartSelectionPartCategoryTreeView.tsx";

export interface CatalogPartsPageProps {
	catalogPartId?: number;
}

export const CatalogPartsPage = ({ catalogPartId }: CatalogPartsPageProps) => {
	const [selectedPartCategory, setSelectedPartCategory] = useState<PartCategory | null>(null);
	const [selectedPartId, setSelectedPartId] = useState<number | undefined>(undefined);

	const partsDataGridRefreshRef = useRefreshRef();

	const selectPartTypography = <CenteredTypography children={i18n.t("select_a_part")} />;

	return (
		<AavoMosaic
			viewId={"AC6542DA37892FB5"}
			layout={{
				type: "row",
				splitPercentage: catalogPartId == null ? 20 : 0,
				first: {
					type: "panel",
					title: i18n.t("part_categories"),
					content: (
						<PartSelectionPartCategoryTreeView
							selectedPartCategory={selectedPartCategory}
							setSelectedPartCategory={(partCategory) => {
								setSelectedPartCategory(partCategory);
								partsDataGridRefreshRef.refresh();
							}}
						/>
					),
				},
				second: {
					type: "column",
					first: {
						type: "panel",
						title: i18n.t("catalog_parts"),
						content: (
							<CatalogPartsDataGrid
								key={selectedPartCategory?.partCategoryId ?? "-1"}
								partCategoryId={selectedPartCategory?.partCategoryId}
								onSelectionChanged={(parts) => {
									setSelectedPartId(parts[0]?.catalogPartId);
								}}
								onlyCatalogPartId={catalogPartId}
							/>
						),
					},
					second: {
						type: "tab",
						items: [
							{
								type: "panel",
								title: i18n.t("revisions"),
								content:
									selectedPartId == null ? selectPartTypography : (
										<CatalogPartRevisionsDataGrid
											key={selectedPartId}
											catalogPartId={selectedPartId}
										/>
									),
							},
							{
								type: "panel",
								title: i18n.t("sites"),
								content:
									selectedPartId == null ? selectPartTypography : (
										<CatalogPartSitesDataGrid
											key={selectedPartId}
											catalogPartId={selectedPartId}
										/>
									),
							},
							{
								type: "panel",
								title: i18n.t("attributes"),
								content:
									selectedPartId == null ? selectPartTypography : (
										<CatalogPartAttributesForm
											key={selectedPartId}
											catalogPartId={selectedPartId}
										/>
									),
							},
							{
								type: "panel",
								title: i18n.t("referencing_parts"),
								content:
									selectedPartId == null ? selectPartTypography : (
										<CatalogPartReferencesDataGrid
											key={selectedPartId}
											catalogPartId={selectedPartId}
										/>
									),
							},
							{
								type: "panel",
								title: i18n.t("connected_workspaces"),
								content:
									selectedPartId == null ? selectPartTypography : (
										<CatalogPartWorkspacesDataGrid
											key={selectedPartId}
											catalogPartId={selectedPartId}
										/>
									),
							},
						],
					},
				},
			}}
		/>
	);
};
