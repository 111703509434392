// This file is automatically generated. Do not edit manually.

import { Reclamation } from "src/api/generated/erp/db/types/tables/reclamation";
import { CostEventPriceListView } from "src/api/generated/erp/db/types/tables/costEventPriceListView";
import { CustomerOrderView } from "src/api/generated/erp/db/types/tables/customerOrderView";
import { ReclamationCategory } from "src/api/generated/erp/db/types/tables/reclamationCategory";
import { ReclamationCause } from "src/api/generated/erp/db/types/tables/reclamationCause";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace ReclamationFormApi {
	export async function getInitData(args: {
		reclamationId: number | null | undefined;
		customerOrderId?: number | null | undefined;
	}): Promise<ReclamationFormApi_InitData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/reclamations/ReclamationForm/getInitData",
			method: "POST",
			data: {
				reclamationId: args.reclamationId,
				customerOrderId: args.customerOrderId,
			},
		});
		return response as ReclamationFormApi_InitData;
	}

	export async function insert(args: {
		reclamation: Reclamation;
		customerOrderId: number | null | undefined;
	}): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/reclamations/ReclamationForm/insert",
			method: "POST",
			data: {
				reclamation: args.reclamation,
				customerOrderId: args.customerOrderId,
			},
		});
		return response as number;
	}

	export async function update(args: { reclamation: Reclamation }): Promise<number> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/reclamations/ReclamationForm/update",
			method: "POST",
			data: {
				reclamation: args.reclamation,
			},
		});
		return response as number;
	}
}

export interface ReclamationFormApi_InitData {
	costEventPriceLists: Array<CostEventPriceListView>;
	customerOrder: CustomerOrderView | null | undefined;
	reclamation: Reclamation | null | undefined;
	reclamationCategories: Array<ReclamationCategory>;
	reclamationCauses: Array<ReclamationCause>;
}
