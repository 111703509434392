import { AavoMosaic } from "src/components/common/mosaic/AavoMosaic.tsx";
import { ControlChartParentGroupDataGrid } from "src/components/views/spc/basedata/controlCharts/ControlChartParentGroupDataGrid.tsx";
import i18n from "i18next";
import { ControlChartParentGroup } from "src/api/generated/postgres/db/types/public_/tables/controlChartParentGroup.ts";
import { useState } from "react";
import { ControlChartGroupDataGrid } from "src/components/views/spc/basedata/controlCharts/ControlChartGroupDataGrid.tsx";
import { CenteredTypography } from "src/components/common/CenteredTypography.tsx";
import { ControlChartsDataGrid } from "src/components/views/spc/basedata/controlCharts/ControlChartsDataGrid.tsx";
import { ChartGroupView } from "src/api/generated/postgres/db/types/public_/tables/chartGroupView.ts";
import { UserNotificationsOfSourceDataGrid } from "src/components/views/notifications/UserNotificationsOfSourceDataGrid.tsx";
import { ControlChartDataGridApi } from "src/api/generated/spc/controlChart/api/controlChartDataGridApi.ts";
import { useRefreshRef } from "src/utils/useRefreshRef.ts";

export const ControlChartsManagementView = () => {
	const [selectedParentGroup, setSelectedParentGroup] = useState<ControlChartParentGroup | undefined>(
		undefined,
	);
	const [selectedChartGroup, setSelectedChartGroup] = useState<ChartGroupView | undefined>(undefined);
	const controlChartRefreshRef = useRefreshRef();
	return (
		<AavoMosaic
			viewId={"E0F50F1CA200D7DB"}
			layout={{
				type: "row",
				splitPercentage: 30,
				first: {
					type: "column",
					first: {
						type: "panel",
						title: i18n.t("parent_groups"),
						content: (
							<ControlChartParentGroupDataGrid
								setSelectedParentGroup={(selectedRow) => {
									setSelectedParentGroup(selectedRow);
									setSelectedChartGroup(undefined);
								}}
							/>
						),
					},
					second: {
						type: "panel",
						title: i18n.t("chart_groups"),
						content:
							selectedParentGroup == null ?
								<CenteredTypography children={i18n.t("select_parent_group")} />
							:	<ControlChartGroupDataGrid
									key={selectedParentGroup.id}
									controlChartParentGroupId={selectedParentGroup.id}
									setSelectedControlChartGroup={setSelectedChartGroup}
								/>,
					},
				},
				second: {
					type: "tab",
					items: [
						{
							type: "panel",
							title: i18n.t("control_charts"),
							content:
								selectedChartGroup == null ?
									<CenteredTypography children={i18n.t("select_chart_group")} />
								:	<ControlChartsDataGrid
										key={selectedChartGroup.chartGroupId}
										fetchData={(props) =>
											ControlChartDataGridApi.getControlChartsInChartGroup({
												chartGroupId: selectedChartGroup.chartGroupId,
												...props,
											})
										}
										formProps={{
											chartGroupId: selectedChartGroup.chartGroupId,
										}}
										refreshRef={controlChartRefreshRef}
										cloneProps={{
											type: "chartGroup",
											chartGroupId: selectedChartGroup.chartGroupId,
										}}
									/>,
						},
						{
							type: "panel",
							title: i18n.t("notifications"),
							content:
								selectedChartGroup == null ?
									<CenteredTypography children={i18n.t("select_chart_group")} />
								:	<UserNotificationsOfSourceDataGrid
										key={selectedChartGroup.chartGroupId}
										sourceType={"CHART_GROUP"}
										sourceId={selectedChartGroup.chartGroupId}
									/>,
						},
					],
				},
			}}
		/>
	);
};
