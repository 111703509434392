import { PartialTenantCustomizations } from "src/tenantCustomizations/TenantCustomizations";
import { OmegakeittiotPurchaseRequestDataGridActionBarComponents } from "./OmegakeittiotPurchaseRequestDataGridActionBarComponents";

export const omegakeittiotCustomizations: PartialTenantCustomizations = {
	tenantConfig: {
		erp: {
			enabled: true,
		},
		tasks: {
			enabled: false,
		},
		documentsEnabled: false,
	},
	purchaseRequestsDataGridActionBarComponents:
		OmegakeittiotPurchaseRequestDataGridActionBarComponents,
} as const;
