import {FieldPath, FieldPathValue, useController, UseControllerProps} from "react-hook-form";
import {mergeSx} from "src/utils/styles.ts";
import {FormFieldLayoutProps, formFieldLayoutPropsToSx} from "src/components/common/forms/styles.ts";
import {AavoCheckbox, AavoCheckboxProps} from "src/components/common/inputFields/AavoCheckbox.tsx";

export interface FormCheckboxProps<TFieldValues extends object, TFieldName extends FieldPath<TFieldValues>>
	extends Omit<AavoCheckboxProps, "name" | "value" | "control" | "onBlur" | "checked">,
		Pick<UseControllerProps<TFieldValues, TFieldName>, "control" | "name" | "rules">,
		FormFieldLayoutProps {
	label: string;
}

export const FormCheckbox = <TFieldValues extends object, TFieldName extends FieldPath<TFieldValues>>({
	name,
	control,
	rules,
	label,
	spanGridColumns,
	startNewGridRow,
	sx,
	onChange,
	disabled,
	...other
}: FormCheckboxProps<TFieldValues, TFieldName>) => {
	const { field } = useController({
		name,
		control,
		rules,
		defaultValue: false as FieldPathValue<TFieldValues, TFieldName>,
	});
	return (
		<AavoCheckbox
			label={label}
			sx={mergeSx(
				formFieldLayoutPropsToSx({
					spanGridColumns,
					startNewGridRow,
				}),
				sx,
			)}
			checked={field.value}
			onChange={(value, event) => {
				field.onChange(value, event);
				onChange?.(value, event);
			}}
			onBlur={field.onBlur}
			disabled={disabled || field.disabled}
			{...other}
		/>
	);
};
