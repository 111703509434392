// This file is automatically generated. Do not edit manually.

import { t } from "i18next";

export const ProjectActivityMaterialStateValues = ["INITIAL", "ORDERED", "CANCELLED"] as const;

export type ProjectActivityMaterialState = (typeof ProjectActivityMaterialStateValues)[number];

export const getProjectActivityMaterialStateLabels = () => ({
	INITIAL: t("initial"),
	ORDERED: t("ordered"),
	CANCELLED: t("cancelled"),
});

export const getProjectActivityMaterialStateLabel = (value: ProjectActivityMaterialState): string => {
	return getProjectActivityMaterialStateLabels()[value];
};
