import { PartConfiguratorView } from "../PartConfiguratorView";
import { ConfigurationCreationApi } from "src/api/generated/erp/configurator/api/configurationCreationApi";
import { UnsavedCustomerOrderLinePartConfiguratorType } from "src/api/generated/erp/configurator/configuratorType/impl/unsavedCustomerOrderLinePartConfiguratorType.ts";
import { CustomerOrderLine } from "src/api/generated/erp/db/types/tables/customerOrderLine.ts";
import { FormResult } from "src/components/common/forms/types.ts";

export interface CustomerOrderLineBeforeCreateConfiguratorViewProps {
	customerOrderLine: CustomerOrderLine; // Unsaved customerOrderLine
	initialValuesFromConfigurationId: number | undefined;
	onCompleted: (result: FormResult<number>) => void;
}

export const CustomerOrderLineBeforeCreateConfiguratorView = ({
	customerOrderLine,
	initialValuesFromConfigurationId,
	onCompleted,
}: CustomerOrderLineBeforeCreateConfiguratorViewProps) => {
	const configuratorType: UnsavedCustomerOrderLinePartConfiguratorType = {
		type: "unsavedCustomerOrderLine",
		customerOrderLine: customerOrderLine,
		initialValuesFromConfigurationId: initialValuesFromConfigurationId,
		isTestConfigurator: false,
	};

	return (
		<PartConfiguratorView
			configuratorType={configuratorType}
			onCompleted={async (result) => {
				if (result.type === "success") {
					const { propertyValues, configurationSessionId } = result.value;
					const partConfigurationId =
						await ConfigurationCreationApi.createPartConfigurationForUnsavedCustomerOrderLine({
							configuratorType: configuratorType,
							propertyValues: propertyValues,
							configurationSessionId: configurationSessionId,
						});
					onCompleted({ type: "success", value: partConfigurationId });
				} else {
					onCompleted(result);
				}
			}}
		/>
	);
};
