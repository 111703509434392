import HighchartsReact, { HighchartsReactProps, HighchartsReactRefObject } from "highcharts-react-official";
import HighchartsGantt from "highcharts/highcharts-gantt";
import { VerticalBox } from "../box/VerticalBox";
import * as Highcharts from "highcharts";
import { useResizeObserver } from "src/utils/useResizeObserver.ts";
import { useRef } from "react";

export interface AavoGanttViewProps extends Pick<HighchartsReactProps, "immutable"> {
	options: Highcharts.Options;
}

export const AavoGanttView = ({ options, immutable = false }: AavoGanttViewProps) => {
	const containerRef = useRef<HTMLDivElement>(null);
	const chartRef = useRef<HighchartsReactRefObject>(null);

	const setChartHeight = () => {
		if (!chartRef.current || !containerRef.current) return;
		const containerHeight = containerRef.current.clientHeight;

		const scrollablePlotAreaCurrentMinHeight =
			chartRef.current.chart.options.chart?.scrollablePlotArea?.minHeight ?? 0;
		const scrollablePlotAreaMinHeight = Math.max(
			containerHeight + 10,
			scrollablePlotAreaCurrentMinHeight,
		);

		console.log(
			"setHeight",
			containerHeight,
			scrollablePlotAreaCurrentMinHeight,
			scrollablePlotAreaMinHeight,
		)

		chartRef.current.chart.update(
			{
				chart: {
					height: containerHeight,
					scrollablePlotArea: {
						minHeight: scrollablePlotAreaMinHeight,
					},
				},
			},
			true,
			false,
			false,
		);
	};

	useResizeObserver(
		containerRef,
		() => {
			setChartHeight();
		},
		[chartRef.current],
	);

	return (
		<VerticalBox
			ref={containerRef}
			sx={{
				flex: 1,
				display: "flex",
				alignItems: "stretch",
				overflow: "hidden",
				position: "relative",
				"& > *": {
					flex: 1,
				},
			}}
		>
			<HighchartsReact
				ref={chartRef}
				highcharts={HighchartsGantt}
				constructorType={"ganttChart"}
				allowChartUpdate={true}
				immutable={immutable}
				options={options}
			/>
		</VerticalBox>
	);
};
